import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import SummarySection from '../../../components/SummarySection';
import { AgentMarketingFeeBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import bulletBlueUrl from '../assets/bullet-blue.svg';
import chevronDownIconUrl, {
  ReactComponent as ChevronDownIcon,
} from '../assets/chevron-down-grey-small.svg';
import chevronUpIconUrl, {
  ReactComponent as ChevronUpIcon,
} from '../assets/chevron-up-grey-small.svg';

const ValueLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #203553;

  margin: 20px 0 0 0;
`;

const InclusionsContainer = styled.div`
  border-top: 1px solid rgba(232, 234, 237, 1);
  margin: 20px -20px -7px -20px;
  padding: 0 20px;
`;

const InclusionsButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 0 0;
  padding: 0;

  text-align: left;
  border: none;
  background-color: #ffffff;

  :hover {
    text-decoration-line: underline;
    text-decoration-color: #ffffff;
  }
`;

const InclusionsButtonLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #a6aeba;
  letter-spacing: 0.28px;
  margin: 0 12px 0 0;
`;

const Inclusions = styled.ul`
  margin: 23px 0 14px 0;

  list-style-image: url(${bulletBlueUrl});

  margin: 16px 5px 0 5px;
  padding-left: 20px;
`;

const Inclusion = styled.li`
  margin: 23px 0 14px 0;

  list-style-image: url(${bulletBlueUrl});

  margin: 0 0 9px 0;

  opacity: 0.8;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #203553;
  letter-spacing: 0.2px;
  line-height: 23px;
  padding-left: 14px;
`;

export const usePrecacheForAgentMarketingFee: () => void = () => {
  usePrecacheImages([chevronUpIconUrl, chevronDownIconUrl, bulletBlueUrl]);
};

export interface AgentMarketingFeeProps {
  agentMarketingFee: AgentMarketingFeeBaseFragment;
  onEditClick?: () => void;
  editLabel?: string;
  hideEdit?: boolean;
}

const AgentMarketingFee: FC<AgentMarketingFeeProps> = (props) => {
  const {
    agentMarketingFee,
    onEditClick,
    editLabel = 'Edit',
    hideEdit = false,
  } = props;

  const [inclusionsExpanded, setExclusionsExpanded] = useState<boolean>(false);

  const onInclusionsButtonClick = useCallback(
    () => setExclusionsExpanded((inclusionsExpanded) => !inclusionsExpanded),
    [],
  );

  return (
    <SummarySection
      title="Marketing Fee"
      editHref="#"
      onEditClick={onEditClick}
      editLabel={editLabel}
      hideEdit={hideEdit}>
      <ValueLabel>
        ${new Intl.NumberFormat().format(agentMarketingFee.costDollars)}
      </ValueLabel>

      {agentMarketingFee.marketingInclusions.length > 0 && (
        <InclusionsContainer>
          {inclusionsExpanded && (
            <Inclusions>
              {agentMarketingFee.marketingInclusions.map(
                (marketingInclusion, i) => (
                  <Inclusion key={i}>{marketingInclusion}</Inclusion>
                ),
              )}
            </Inclusions>
          )}

          <InclusionsButton onClick={onInclusionsButtonClick}>
            <InclusionsButtonLabel>
              View marketing inclusions
            </InclusionsButtonLabel>

            {inclusionsExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </InclusionsButton>
        </InclusionsContainer>
      )}
    </SummarySection>
  );
};

export default AgentMarketingFee;
