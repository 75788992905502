import React, { FC } from 'react';
import styled from 'styled-components';

const ItemContainer = styled.button.attrs({
  type: 'button',
})`
  background-color: Transparent;
  border: none;
  overflow: hidden;
  outline: none;
  margin: 21px 0 0 0;
  padding: 0;
`;

const Label = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #1745b0;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 0 0 0 0;
  cursor: pointer;
`;

const HiddenFileInputField = styled.input`
  display: none;
`;

export interface EditUserMediaLabelButtonProps {
  label: string;
  accept?: string;
  onFileSelected: (file: File) => void;
  disabled?: boolean;
  className?: string;
}

const EditUserMediaLabelButton: FC<EditUserMediaLabelButtonProps> = (props) => {
  const { label, accept, onFileSelected, disabled = false, className } = props;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (file) {
      onFileSelected(file);
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <ItemContainer
      disabled={disabled}
      onClick={handleClick}
      className={className}>
      <HiddenFileInputField
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        ref={hiddenFileInput}
        onChange={changeHandler}
      />
      <Label>{label}</Label>
    </ItemContainer>
  );
};

export default EditUserMediaLabelButton;
