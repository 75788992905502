import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';
import ASBlockButton from '../../../components/ASBlockButton';
import theme from '../../../constants/theme';
import { AppointmentRequestBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import defaultPropertyImageUrl from '../assets/default-property-image.svg';
import { usePrecacheForPropertyCardTemplate } from './PropertyCardTemplate';

const NewBadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  background: #fc4e6f;
  border-radius: 0 9px 0 4px;
  padding: 5px 11px;

  z-index: 3;

  font-family: Poppins, Arial, sans-serif;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: -0.14px;
  text-align: center;
`;

const Separator = styled.div`
  margin: 20px 0 24px 0;
  opacity: 0.14;
  border-top: 1px solid #203553;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0 0 20px 0;

  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 10px;
  position: relative;
  padding: 20px;
`;

const TopContainer = styled.div`
  display: flex;
`;

const PropertyImage = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
`;

const LocationContainer = styled.div`
  padding-left: 14px;
`;

const StreetAddress = styled.p`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-size: 18px;
  color: #203553;
  margin: 0;
`;

const Suburb = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 0;
`;

const DetailsContainer = styled.div``;

const ButtonsContainer = styled.div`
  max-width: 294px;
  display: flex;
  width: 100%;
  align-self: flex-end;
  margin: 32px 0 0 0;
  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    max-width: 100%;
  }
`;

const SellerName = styled.p`
  margin: 0;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-size: 18px;
  color: #203553; ;
`;

const EmailButtonLink = styled.a`
  flex: 1;
  display: flex;
`;

const EmailButton = styled(ASBlockButton)`
  flex: 1;
  display: flex;
`;

const CallButtonLink = styled.a`
  flex: 1;
  display: flex;
  margin-left: 10px;
`;

const CallButton = styled(ASBlockButton)`
  flex: 1;
  display: flex;
`;

const DetailsText = styled.p`
  margin: 0 0 6px 0;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
`;

const DateTitle = styled(DetailsText)`
  margin: 16px 0 6px 0;
`;

const DateText = styled.p`
  margin: 0 0 9px 0;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #203553;
  letter-spacing: 0.2px;
`;

export const usePrecacheForAppointmentRequestCard: () => void = () => {
  usePrecacheForPropertyCardTemplate();
  usePrecacheImages([defaultPropertyImageUrl]);
};

export interface AppointmentRequestCardProps {
  appointmentRequest: AppointmentRequestBaseFragment;
  onRead: (id: string) => void;
}

const AppointmentRequestCard: FC<AppointmentRequestCardProps> = (props) => {
  const { appointmentRequest, onRead } = props;

  const photoUrl =
    appointmentRequest.property.photos[0]?.userMedia.thumbnailUrl;

  return (
    <Container>
      <TopContainer>
        <PropertyImage src={photoUrl ?? defaultPropertyImageUrl} />
        <LocationContainer>
          <StreetAddress>
            {appointmentRequest.property.streetName}
          </StreetAddress>
          <Suburb>
            {appointmentRequest.property.suburb.name}
            {', '}
            {appointmentRequest.property.suburb.state}
          </Suburb>
        </LocationContainer>
      </TopContainer>
      <Separator />
      <DetailsContainer>
        <SellerName>{appointmentRequest.sellerName}</SellerName>
        <DetailsText>{appointmentRequest.sellerEmail}</DetailsText>
        <DetailsText>{appointmentRequest.sellerMobileNumber}</DetailsText>
        <DateTitle>Date &amp; Time</DateTitle>
        <DateText>
          {moment(
            appointmentRequest.requestedAppointmentDate,
            'YYYY-MM-DD',
          ).format('dddd Do MMMM')}
          {' - '}
          {moment(
            appointmentRequest.requestedAppointmentTime,
            'hh:mm:ss',
          ).format('h:mma')}
        </DateText>
      </DetailsContainer>
      <ButtonsContainer>
        {appointmentRequest.sellerEmail !== undefined && (
          <EmailButtonLink href={`mailto:${appointmentRequest.sellerEmail}`}>
            <EmailButton
              onClick={() => onRead(appointmentRequest.id)}
              background={'#FFF'}
              textColor="#1F58EE"
              border="1px solid #1F58EE">
              Email
            </EmailButton>
          </EmailButtonLink>
        )}
        {appointmentRequest.sellerMobileNumber !== undefined && (
          <CallButtonLink href={`tel:${appointmentRequest.sellerMobileNumber}`}>
            <CallButton onClick={() => onRead(appointmentRequest.id)}>
              Call
            </CallButton>
          </CallButtonLink>
        )}
      </ButtonsContainer>
      {!appointmentRequest.seenByAgent && (
        <NewBadgeContainer>New</NewBadgeContainer>
      )}
    </Container>
  );
};

export default AppointmentRequestCard;
