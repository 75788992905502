import range from 'lodash.range';
import React, { FC } from 'react';
import styled from 'styled-components';
import ASCaption from './ASCaption';
import ASHeader from './ASHeader';

const Container = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`;

const PropertyDetailsHeader = styled.h2`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.2px;
  text-align: center;

  margin-top: 30px;
  margin-bottom: 32px;
`;

const StepHeader = styled(ASHeader)<{ description: boolean }>`
  margin-bottom: ${(props) => (props.description ? '7px' : '23px')};
  text-align: center;
`;

const StepDescription = styled(ASCaption)`
  margin: 0 20px 23px 20px;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  align-self: center;
  max-width: 250px;
`;

const ProgressBarSegment = styled.div<{ highlighted: boolean }>`
  flex: 1;
  height: 6px;

  background-color: rgba(244, 245, 246, 5);

  margin: 0px 4px;
  border-radius: 0px;
  position: relative;
  overflow: hidden;
`;

interface ProgressBarSegmentBackground {
  index: number;
  totalHighlighted: number;
}
const ProgressBarSegmentBackground = styled.div<ProgressBarSegmentBackground>`
  width: ${(props) => props.totalHighlighted * 100 + '%'};
  bottom: 0;
  top: 0;
  height: 100%;
  margin-left: ${(props) => -props.index * 100 + '%'};
  left: ${(props) => -props.index * 100 + '%'};
  background-image: linear-gradient(90deg, #1745b0 0%, #1745b0 100%);
`;

interface StepNavigationHeaderProps {
  title: string;
  stepName: string;
  stepIndex: number;
  stepCount: number;
  stepDescription?: string;
}

const StepNavigationHeader: FC<StepNavigationHeaderProps> = (props) => {
  const { title, stepName, stepIndex, stepCount, stepDescription } = props;

  return (
    <Container>
      <PropertyDetailsHeader>{title}</PropertyDetailsHeader>
      <ProgressBar>
        {range(0, stepCount).map((i) => (
          <ProgressBarSegment highlighted={i < stepIndex} key={i}>
            {i < stepIndex && (
              <ProgressBarSegmentBackground
                index={i}
                totalHighlighted={stepCount + 1}
              />
            )}
          </ProgressBarSegment>
        ))}
      </ProgressBar>

      <StepHeader description={!!stepDescription}>{stepName}</StepHeader>
      {stepDescription && <StepDescription>{stepDescription}</StepDescription>}
    </Container>
  );
};

export default StepNavigationHeader;
