import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import createListingPlusBlockSvgUrl from '../assets/create-listing-plus-block.svg';
import noPropertiesPlaceholderIconUrl, {
  ReactComponent as NoPropertiesPlaceholderIcon,
} from '../assets/no-properties-placeholder.svg';
import EmptyPlaceholder from './EmptyPlaceholder';

const NoPropertiesPlaceholderImage = styled(NoPropertiesPlaceholderIcon)`
  /* TODO: width */
`;

export const usePrecacheForNoPropertiesPlaceholder: () => void = () => {
  usePrecacheImages([
    createListingPlusBlockSvgUrl,
    noPropertiesPlaceholderIconUrl,
  ]);
};

const UnverifiedSeller: FC = () => {
  return (
    <EmptyPlaceholder
      image={<NoPropertiesPlaceholderImage />}
      title="Verification Pending | AgentSpot"
      explanation="Thank you for submitting your appraisal requests. Once we verify property ownership details the Agent will be notified"></EmptyPlaceholder>
  );
};

export default UnverifiedSeller;
