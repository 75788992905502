import { useEffect } from 'react';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useIsGhostUser from './useIsGhostUser';
import useLogout from './useLogout';

const useLogoutGhostUser: () => void = () => {
  const isAuthenticated = useIsAuthenticated();
  const isGhostUser = useIsGhostUser();

  const logout = useLogout();

  // Logout ghost user on page load
  useEffect(() => {
    if (isAuthenticated && isGhostUser) {
      logout();
    }
  }, [isAuthenticated, isGhostUser]);
};

export default useLogoutGhostUser;
