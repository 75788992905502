import React, { FC } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import {
  SuburbBaseFragment,
  SuburbWithAgentStatsFragment,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import selectedCheckUri from '../assets/selected-check.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.13px;
  line-height: 25px;
  margin: 0;
`;

const TrialPricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PricingContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
`;

const SuburbContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`;

const DetailsText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 0;
`;

export interface SuburbHeaderProps {
  suburb: SuburbBaseFragment | SuburbWithAgentStatsFragment;
  isFreeTrial?: boolean;
  showPricing?: boolean;
  showCostAfterTrial?: boolean;
}

export const usePrecacheForSuburbHeader: () => void = () => {
  usePrecacheImages([selectedCheckUri]);
};

const SuburbHeader: FC<SuburbHeaderProps> = ({
  suburb,
  isFreeTrial,
  showPricing = true,
}) => {
  return (
    <Container>
      <SuburbContainer>
        <Title>{suburb.name}</Title>
        <DetailsText>
          {suburb.state} {suburb.postcode}
        </DetailsText>
      </SuburbContainer>
      <PricingContainer>
        <TrialPricingContainer>
          {showPricing && isFreeTrial && <Title>Free</Title>}
        </TrialPricingContainer>

        {showPricing && !isFreeTrial && (
          <>
            <Title>${config.SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS}</Title>
            <DetailsText>/Year</DetailsText>
          </>
        )}
      </PricingContainer>
    </Container>
  );
};

export default SuburbHeader;
