import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';
import { propertyTypeToString } from '../../../constants/EnumMappings';
import { PropertyBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import statBulletIconUrl, {
  ReactComponent as StatBulletIcon,
} from '../assets/property-details-stat-bullet.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 13px 0;
  padding: 0 20px 0 20px;
`;

const SubmittedAtLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  margin: 0 0 15px 0;
`;

const PropertyTypeLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1745b0;
  margin: 16px 0 15px 0;
`;

const StreetAddressLabel = styled.h2`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 27px;
  color: #203553;
  margin: 0 0 6px 0;
`;

const SuburbLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.17px;
  margin: 0 0 15px 0;
`;

const SummaryStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StatBullet = styled(StatBulletIcon)`
  margin: 0 12px;
  width: 4px;
  height: 4px;
`;

const SummaryStatLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.17px;
`;

export const usePrecacheForPropertyDetailsSummary: () => void = () => {
  usePrecacheImages([statBulletIconUrl]);
};

export interface PropertyDetailsSummaryProps {
  property: PropertyBaseFragment;
  showSubmittedAt?: boolean;
  showPropertyType?: boolean;
}

const PropertyDetailsSummary: FC<PropertyDetailsSummaryProps> = (props) => {
  const { property, showSubmittedAt, showPropertyType } = props;

  return (
    <Container>
      {showSubmittedAt && (
        <SubmittedAtLabel>
          Submitted on {moment(property.submittedAt).format('D MMM YYYY')}
        </SubmittedAtLabel>
      )}
      {showPropertyType && (
        <PropertyTypeLabel>
          {propertyTypeToString[property.propertyType]}
        </PropertyTypeLabel>
      )}
      <StreetAddressLabel>{property.streetName}</StreetAddressLabel>
      <SuburbLabel>
        {property.suburb.name}, {property.suburb.state}
      </SuburbLabel>
      <SummaryStatsContainer>
        <SummaryStatLabel>
          {property.numberOfBedrooms ?? 0} Bed
        </SummaryStatLabel>
        <StatBullet />
        <SummaryStatLabel>
          {property.numberOfBathrooms ?? 0} Bath
        </SummaryStatLabel>
        <StatBullet />
        <SummaryStatLabel>
          {property.numberOfCarSpaces ?? 0} Car
        </SummaryStatLabel>
      </SummaryStatsContainer>
    </Container>
  );
};

export default PropertyDetailsSummary;
