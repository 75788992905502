import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import ASActionButton from '../../../components/ASActionButton';
import ASHeader from '../../../components/ASHeader';
import ASMultilineText from '../../../components/ASMultilineText';
import BackNavLink, {
  usePrecacheForBackNavLink,
} from '../../../components/BackNavLink';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import SummarySection from '../../../components/SummarySection';
import UserMediaSquare, {
  UserMediSquareVideoOverlay,
} from '../../../components/UserMediaSquare';
import { UserMediaFieldValueState } from '../../../containers/UserMediaField';
import {
  MyCompleteAppraisalRequestsDocument,
  MyPendingAppraisalRequestsDocument,
  useSubmitPropertyAppraisalMutation,
} from '../../../graphql/generated';
import renderPropertyPrice from '../../../src/utils/renderPropertyPrice';
import AgentCommission from '../components/AgentCommission';
import AgentMarketingFee from '../components/AgentMarketingFee';
import AppraisalSummaryComparableSaleRowProps from '../components/AppraisalSummaryComparableSaleRow';
import AppraisalSummaryValueRow from '../components/AppraisalSummaryValueRow';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalSubmitted } from './NewAppraisalSubmitted';

const Container = styled.div`
  margin: 24px 0 48px 0;
`;

const AdditionalNotesContainer = styled.div`
  margin: 17px 0 0 0;
`;

const AppraisalVideo = styled(UserMediaSquare)`
  width: 100%;
  min-height: 170px;
  margin: 20px 0 0 0;
`;

const AppraisalVideoOverlay = styled(UserMediSquareVideoOverlay)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  background-color: rgba(32, 53, 84, 0.13);

  position: absolute;

  padding: 15px;
`;

export const usePrecacheForNewAppraisalSummary: () => void = () => {
  usePrecacheForBackNavLink();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  id?: string;
}

const NewAppraisalSummary: React.FC = () => {
  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const [
    submitPropertyAppraisal,
    { loading: submitPropertyAppraisalLoading },
  ] = useSubmitPropertyAppraisalMutation({
    refetchQueries: [
      { query: MyPendingAppraisalRequestsDocument, variables: { first: 10 } },
      { query: MyCompleteAppraisalRequestsDocument, variables: { first: 10 } },
    ],
  });

  const onSubmitClick = useCallback(async () => {
    if (!draftPropertyAppraisal) return;

    await submitPropertyAppraisal({
      variables: {
        propertyAppraisalId: draftPropertyAppraisal.id,
      },
    });
    toast(
      'Your appraisal has been sent directly to the potential vendor. Good luck from AgentSpot',
    );
    history.push(`/?tab=complete`);
  }, [draftPropertyAppraisal]);

  const onEditCommussionClick = useCallback(() => {
    history.push(
      `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/fees`,
    );
  }, []);

  usePrecacheForNewAppraisalSubmitted();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Appraisal Summary | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {draftPropertyAppraisal && (
        <>
          <StepPageTemplate.ContentContainer $extraWide>
            <BackNavLink
              href={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/additional-notes`}
            />
            <ASHeader>Appraisal Summary</ASHeader>
            <Container>
              {draftPropertyAppraisal.appraisalVideo && (
                <SummarySection
                  title="Appraisal Video"
                  editHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/video`}>
                  <AppraisalVideo
                    userMedia={draftPropertyAppraisal.appraisalVideo}
                    hideRemoveButton
                    videoOverlay={
                      <AppraisalVideoOverlay
                        userMedia={draftPropertyAppraisal.appraisalVideo}
                        videoModal={false}
                      />
                    }
                    mediaUploadingStatus={UserMediaFieldValueState.Ready}
                  />
                </SummarySection>
              )}

              <SummarySection
                title="Estimated selling range"
                editHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/selling-range`}>
                <AppraisalSummaryValueRow
                  title="Selling range"
                  value={`${renderPropertyPrice(
                    draftPropertyAppraisal.sellingRangeMin,
                  )} - ${renderPropertyPrice(
                    draftPropertyAppraisal.sellingRangeMax,
                  )}`}
                />
              </SummarySection>

              {draftPropertyAppraisal.marketingFee && (
                <AgentMarketingFee
                  agentMarketingFee={draftPropertyAppraisal.marketingFee}
                  editLabel="Edit"
                  onEditClick={onEditCommussionClick}
                />
              )}

              {draftPropertyAppraisal.commission && (
                <AgentCommission
                  agentCommission={draftPropertyAppraisal.commission}
                  editLabel="Edit"
                  onEditClick={onEditCommussionClick}
                />
              )}

              <SummarySection
                title="Comparable sales"
                editHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/comparable-sales`}>
                {draftPropertyAppraisal.comparableSales?.map(
                  (comparableSale, i) => (
                    <AppraisalSummaryComparableSaleRowProps
                      key={i}
                      title={`Sale Listing ${i + 1}`}
                      value={comparableSale}
                    />
                  ),
                )}
              </SummarySection>

              <SummarySection
                title="Additional notes"
                editHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/additional-notes`}>
                <AdditionalNotesContainer>
                  <ASMultilineText
                    text={draftPropertyAppraisal.additionalNotes ?? ''}
                  />
                </AdditionalNotesContainer>
              </SummarySection>
            </Container>
          </StepPageTemplate.ContentContainer>

          <NavigationFooter
            rightSideComponent={
              <ASActionButton
                onClick={onSubmitClick}
                loading={submitPropertyAppraisalLoading}>
                Submit appraisal
              </ASActionButton>
            }
          />
        </>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalSummary;
