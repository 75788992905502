import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import goldCoinUrl, {
  ReactComponent as GoldCoin,
} from '../../../assets/gold-coin.svg';
import theme from '../../../constants/theme';
import {
  PropertyAppraisalBaseFragment,
  useStaticCashbackAmountQuery,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import AgentAppraisalRow from './AgentAppraisalRow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 20px 0 20px;
  margin-top: 10px;
  border-top: 1px solid #e8eaed;
`;

const Title = styled.h2`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: #a6aeba;
  padding-top: 30px;
  border-top: 1px solid #e8eaed;
  margin: 0 0 16px 0;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    border-top: none;
  }
`;

const CashbackRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f9f9fa;
  border-radius: 11px;
  padding: 31px 20px;
  margin-bottom: 25px;
`;

const CashbackTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
`;

const CashbackText = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #58687e;
  letter-spacing: 0.19px;
  line-height: 23px;
  margin-bottom: 15px;
`;

const AgentLinkText = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.19px;
  line-height: 23px;
  margin-bottom: 0;

  :hover {
    color: #1745b0;
    text-decoration-color: #1745b0;
  }
`;

const PlaceholderRow = styled.div`
  background-color: #f9f9fa;
  border-radius: 11px;
  padding: 31px 20px;
  margin-bottom: 25px;
`;

const PlaceholderTitle = styled.h4`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 'bold';
  font-size: 18px;
`;

const PlaceholderText = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #58687e;
  letter-spacing: 0.19px;
  line-height: 23px;
  margin-bottom: 0;
`;

export const usePrecacheForPropertyDetailsAppraisals: () => void = () => {
  usePrecacheImages([goldCoinUrl]);
};

export interface PropertyDetailsAppraisalsProps {
  id: string;
  propertyId: string;
  appraisals: Array<PropertyAppraisalBaseFragment>;
  propertySoldUnconditionally: boolean;
}

const PropertyDetailsAppraisals: FC<PropertyDetailsAppraisalsProps> = (
  props,
) => {
  const { appraisals, propertySoldUnconditionally } = props;
  const history = useHistory();

  const {
    data: cashbackAmount,
    loading: loadingCashbackAmount,
  } = useStaticCashbackAmountQuery();

  const onAgentAppraisalClick = (appraisalId: string, propertyId: string) => {
    history.push(`/property/${propertyId}/appraisal/${appraisalId}`);
  };

  return (
    <Container id={props.id}>
      <Title>
        {appraisals?.length > 0
          ? `${appraisals.length} Appraisals received`
          : 'Appraisals'}{' '}
      </Title>
      {!appraisals?.length && (
        <PlaceholderRow>
          <PlaceholderTitle>Waiting for appraisals</PlaceholderTitle>
          <PlaceholderText>
            You should have an appraisal from an agent in the next 48 hours,
            please check back soon.
          </PlaceholderText>
        </PlaceholderRow>
      )}
      {!loadingCashbackAmount &&
        cashbackAmount &&
        appraisals?.length > 0 &&
        !propertySoldUnconditionally && (
          <CashbackRow>
            <GoldCoin />
            <CashbackTextContainer>
              <CashbackText>{`Get $${cashbackAmount?.staticCashbackAmount} cash when you sell your property with an AgentSpot agent`}</CashbackText>
              <AgentLinkText to={`/property/${props.propertyId}/sale-status`}>
                Choose Agent & Claim
              </AgentLinkText>
            </CashbackTextContainer>
          </CashbackRow>
        )}
      {appraisals?.map((appraisal, index) => (
        <AgentAppraisalRow
          key={index}
          appraisal={appraisal}
          property={appraisal.property}
          onClick={() =>
            onAgentAppraisalClick(appraisal?.id, appraisal.property.id)
          }
        />
      ))}
    </Container>
  );
};

export default PropertyDetailsAppraisals;
