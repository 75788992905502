import React, { FC } from 'react';
import styled from 'styled-components';
import notFoundIconUrl, {
  ReactComponent as NotFoundIcon,
} from '../assets/not-found-ic.svg';
import ASBlockLink from '../components/ASBlockLink';
import { usePrecacheForNavigationHeader } from '../components/NavigationHeader';
import theme from '../constants/theme';
import { usePrecacheForLandingFooter } from '../features/landing/components/LandingFooter';
import usePrecacheImages from '../hooks/usePrecacheImages';

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${theme.spacing.pageInset} 100px ${theme.spacing.pageInset};
`;

const Title = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: bold;
  font-size: 28px;
  color: #203553;
  line-height: 40px;
  margin-bottom: 13px;
  text-align: center;
`;

const Description = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #8f9aa9;
  text-align: center;
  letter-spacing: 0.19px;
  text-align: center;
  line-height: 26px;
  max-width: 442px;
  margin-bottom: 55px;
`;

const NotFoundIconStyled = styled(NotFoundIcon)`
  margin-bottom: 31px;
  margin-top: 170px;
`;

export const usePrecacheForNotFoundPlaceholder: () => void = () => {
  usePrecacheImages([notFoundIconUrl]);
};

const NotFoundPlaceholder: FC = (props) => {
  const { children } = props;

  usePrecacheForNavigationHeader();
  usePrecacheForLandingFooter();

  return (
    <ContentContainer>
      {children}
      <NotFoundIconStyled />
      <Title>Something went wrong</Title>
      <Description>
        {`We either can't find the page your looking for or you don't have
        permission to access the page.`}
      </Description>
      <ASBlockLink to="/">Go home</ASBlockLink>
    </ContentContainer>
  );
};

export default NotFoundPlaceholder;
