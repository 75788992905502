import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import closeModalButtonUrl, {
  ReactComponent as CloseModalButton,
} from '../../../assets/close-modal.svg';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASModal from '../../../components/ASModal';
import BackNavButton from '../../../components/BackNavButton';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  MySubscribedSuburbsDocument,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
  useSubscribeToSuburbsMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForBackNavButton } from '../../properties/components/SaleStatusNavigationHeader';
import useSuburbCart from '../hooks/useSuburbCart';
import { UPDATE_SUBURB_CART } from '../store/cartTypes';
import AgentSuburbsAdded, {
  usePrecacheForAgentSuburbsAdded,
} from './AgentSuburbsAdded';
import CardDetailsForm from './CardDetailsForm';
import SuburbPaymentForm from './SuburbPaymentForm';
import SuburbSelectForm from './SuburbSelectForm';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin-bottom: 14px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const Title = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 'bold';
  font-size: 25px;
  color: #203553;
  margin-left: 20px;
  margin-right: 20px;
`;

interface SlideItemProps {
  $slideIndex: number;
  $activeSlideIndex: number;
  $noScroll?: boolean;
}

const ItemsContainer = styled.div`
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  overflow-x: hidden;
  width: 100%;
  position: relative;
`;

const SlideItem = styled.div<SlideItemProps>`
  padding: ${(props) => (props.$noScroll ? '0' : ' 0 20px 0 20px')};
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  overflow-y: ${(props) => (props.$noScroll ? 'hidden' : 'scroll')};

  transform: translateX(
    ${(props) => `${(props.$slideIndex - props.$activeSlideIndex) * 100}%`}
  );
  transition: transform 250ms ease-in-out;
`;

const PaymentContaier = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ConfirmPaymentButton = styled(ASActionButton)`
  margin-bottom: 20px;
  align-self: flex-end;
`;

const ADD_SUBURB_STEP_INDEX = 0;
const CONFIRM_PAYMENT_STEP_INDEX = 1;
const ADD_PAYMENT_STEP_INDEX = 2;

export const usePrecacheForAgentUpdateSuburbController: () => void = () => {
  usePrecacheImages([closeModalButtonUrl]);
  usePrecacheForBackNavButton();
};

export interface AgentUpdateSuburbControllerProps {
  onRequestClose: () => void;
  isOpen: boolean;
}

const AgentUpdateSuburbController: FC<AgentUpdateSuburbControllerProps> = ({
  onRequestClose,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const [cartSuburbIds, , setCartSuburbIds] = useSuburbCart();

  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [
    subscribeToSuburbs,
    { error: subscribeToSuburbError, loading: subscribeToSuburbsLoading },
  ] = useSubscribeToSuburbsMutation({
    refetchQueries: [
      'getSuburbFromSearch',
      'getSuburbFromSearchWithNearbySuburbs',
      'myAgentSuburbSubscriptionFreeTrialState',
      { query: MySubscribedSuburbsDocument },
    ],
  });

  const [step, setStep] = useState(ADD_SUBURB_STEP_INDEX);
  const [checkoutComplete, setCheckoutComplete] = useState(false);

  let title = '';
  switch (step) {
    case ADD_SUBURB_STEP_INDEX:
      title = 'Add New Suburb';
      break;
    case CONFIRM_PAYMENT_STEP_INDEX:
      title = 'Payment';
      break;
    case ADD_PAYMENT_STEP_INDEX:
      title = 'Add New Card';
      break;
  }

  const onAddSuburbs = (suburbs: Array<string>) => {
    dispatch({
      type: UPDATE_SUBURB_CART,
      payload: {
        suburbIds: suburbs,
      },
    });

    setStep(CONFIRM_PAYMENT_STEP_INDEX);
  };

  const onBackClick = useCallback(() => {
    if (step === ADD_SUBURB_STEP_INDEX) {
      closeModal();
    } else if (step === CONFIRM_PAYMENT_STEP_INDEX) {
      setStep(ADD_SUBURB_STEP_INDEX);
    } else if (step === ADD_PAYMENT_STEP_INDEX) {
      setStep(CONFIRM_PAYMENT_STEP_INDEX);
    }
  }, [step]);

  const onConfirmPayment = useCallback(async () => {
    try {
      const { data: subscribeToSuburbsData } = await subscribeToSuburbs({
        variables: { suburbIds: cartSuburbIds },
      });

      if (subscribeToSuburbsData) {
        setCartSuburbIds([]);
        setCheckoutComplete(true);
      }
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, [cartSuburbIds]);

  const onCardAdded = () => {
    setStep(CONFIRM_PAYMENT_STEP_INDEX);
  };

  const onAddCardClicked = () => {
    setStep(ADD_PAYMENT_STEP_INDEX);
  };

  const closeModal = () => {
    console.trace();
    setCartSuburbIds([]);
    onRequestClose();
    setTimeout(() => {
      setCheckoutComplete(false);
      setStep(ADD_SUBURB_STEP_INDEX);
    }, 250);
  };

  usePrecacheForAgentSuburbsAdded();

  return (
    <ASModal
      expand
      showHeader={false}
      isOpen={isOpen}
      onRequestClose={closeModal}
      preventScroll
      noPadding>
      <Container>
        {checkoutComplete ? (
          <AgentSuburbsAdded
            plural={(cartSuburbIds?.length ?? 0) > 1}
            onDoneClicked={closeModal}
          />
        ) : (
          <>
            <Header>
              {step === ADD_SUBURB_STEP_INDEX ? (
                <div style={{ height: 35 }} />
              ) : (
                <BackNavButton onClick={onBackClick} />
              )}
              <CloseButton onClick={closeModal}>
                <CloseModalButton />
              </CloseButton>
            </Header>
            <Title>{title}</Title>

            <ItemsContainer>
              <SlideItem $slideIndex={0} $activeSlideIndex={step} $noScroll>
                <SuburbSelectForm
                  submitDisabled={step !== ADD_SUBURB_STEP_INDEX}
                  onAddClicked={onAddSuburbs}
                  scroll
                />
              </SlideItem>

              <SlideItem $slideIndex={1} $activeSlideIndex={step}>
                <PaymentContaier>
                  <SuburbPaymentForm
                    numberOfFreeTrialsClaimed={
                      freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                        .numberOfFreeTrialsClaimed ?? 0
                    }
                    totalFreeTrialsAllowed={
                      freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                        .totalFreeTrialsAllowed ?? 0
                    }
                    onEditPaymentMethodClick={onAddCardClicked}
                    onAddPaymentDetailsClick={onAddCardClicked}
                  />

                  {subscribeToSuburbError && (
                    <ASErrorLabel>
                      {apolloErrorToString(subscribeToSuburbError)}
                    </ASErrorLabel>
                  )}
                  <ConfirmPaymentButton
                    onClick={onConfirmPayment}
                    loading={subscribeToSuburbsLoading}>
                    Confirm Subscription
                  </ConfirmPaymentButton>
                </PaymentContaier>
              </SlideItem>

              <SlideItem $slideIndex={2} $activeSlideIndex={step}>
                <CardDetailsForm onCardAdded={onCardAdded} />
              </SlideItem>
            </ItemsContainer>
          </>
        )}
      </Container>
    </ASModal>
  );
};

export default AgentUpdateSuburbController;
