import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 17px 0 0 0;
`;

const ItemTitle = styled.h5`
  margin: 0 0 13px 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
`;

const ItemValue = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.2px;
`;

export interface AppraisalSummaryValueRowProps {
  title: string;
  value: string;
}

const AppraisalSummaryValueRow: FC<AppraisalSummaryValueRowProps> = (props) => {
  const { title, value } = props;

  return (
    <Container>
      <ItemTitle>{title}</ItemTitle> <ItemValue>{value}</ItemValue>
    </Container>
  );
};

export default AppraisalSummaryValueRow;
