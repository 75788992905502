import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import client from '../../../client';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  PropertyCompleteType,
  PropertyWithAppraisalsFragment,
  PropertyWithAppraisalsFragmentDoc,
  usePropertyQuery,
  useStaticCashbackAmountQuery,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

export const usePrecacheForPropertySold: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForNavigationHeader();
  usePrecacheForStepCompleteContent();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  propertyId?: string;
}

const PropertySold: FC = () => {
  const { propertyId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const cachedProperty =
    client.readFragment<PropertyWithAppraisalsFragment>({
      id: `Property:${propertyId}`,
      fragment: PropertyWithAppraisalsFragmentDoc,
      fragmentName: 'PropertyWithAppraisals',
    }) ?? undefined;

  const { data: propertyData } = usePropertyQuery({
    variables: { id: typeof propertyId === 'string' ? propertyId : '' },
  });

  const property: PropertyWithAppraisalsFragment | undefined =
    propertyData?.property ?? cachedProperty;

  if (
    property &&
    property.completeType === PropertyCompleteType.SoldUnconditionally
  ) {
    return <Redirect to={`/property/${propertyId}`} />;
  }

  const onBackClicked = () => {
    history.push(`/property/${propertyId}`);
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Property Sold | AgentSpot</title>
      </Helmet>
      {cashbackAmount && property && (
        <StepCompleteContent
          title="Congratulations!"
          body={`Once we have confirmed the unconditional sale with your agent, $${Intl.NumberFormat().format(
            cashbackAmount?.staticCashbackAmount,
          )} will be paid into your account`}
          nextButtonLabel="Back to Property"
          onNextButtonClick={onBackClicked}
        />
      )}
    </StepPageTemplate.Container>
  );
};

export default PropertySold;
