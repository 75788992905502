import { Button } from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import ASModal, { usePrecacheForASModal } from '../../../components/ASModal';
import ASParagraph from '../../../components/ASParagraph';
import { useAcceptPaymentConditionMutation } from '../../../graphql/generated';

const BodyText = styled(ASParagraph)`
  text-align: left;
  margin: 0;
`;

const OptionHeader = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  padding: 16px 20px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.14px;
  margin-left: 8px;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
`;

const HiddenField = styled.input`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

interface AgentAddPaymentDetailsToViewPropertyModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onPaymentDetailsSet: () => void;
  id: string;
}

export const usePrecacheAgentAddPaymentDetailsToViewPropertyModal: () => void = () => {
  usePrecacheForASModal();
};

const AgentAddPaymentDetailsToViewPropertyModal: FC<AgentAddPaymentDetailsToViewPropertyModalProps> = (
  props,
) => {
  const { isOpen, onRequestClose, onPaymentDetailsSet, id } = props;

  const [acceptTerms, setAcceptTerms] = useState(false);

  const [acceptPaymentCondition] = useAcceptPaymentConditionMutation();

  const onFormComplete = useCallback(async () => {
    const { data } = await acceptPaymentCondition({
      variables: { id },
    });
    if (data?.acceptPaymentCondition) onPaymentDetailsSet();
  }, [setAcceptTerms]);

  return (
    <ASModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <BodyText>
        By accepting, you acknowledge receipt of this appraisal lead from
        AgentSpot and agree to pay $1000 +GST upon the property&apos;s
        unconditional status. Property details will be disclosed upon agreement.
      </BodyText>
      <OptionHeader>
        <HiddenField
          type="checkbox"
          name="terms"
          onChange={(e) => setAcceptTerms(e.target.checked)}
        />
        <CheckBox checked={acceptTerms ?? false} />
        <Label>Accept the above terms</Label>
      </OptionHeader>
      <ButtonContainer>
        <Button
          color="primary"
          variant="contained"
          disabled={!acceptTerms}
          onClick={onFormComplete}>
          Submit
        </Button>
      </ButtonContainer>
    </ASModal>
  );
};

export default AgentAddPaymentDetailsToViewPropertyModal;
