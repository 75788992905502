import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { ReactComponent as CloseCross } from '../assets/close-modal-cross.svg';
import { pxToRem } from '../utils/pxToRem';

const ASToastContainer = styled(ToastContainer).attrs({
  autoClose: false,
  position: 'top-center',
  transition: Slide,
  hideProgressBar: true,
  closeOnClick: true,
  closeButton: <CloseCross />,
})`
  top: 14px;
  width: 100vw;
  max-width: 450px;
  padding-left: 10px;
  padding-right: 10px;

  .Toastify__toast {
    display: flex;
    flex-direction: row;
    align-items: center;

    min-height: 56px;
    padding: 10px 16px 10px 10px;

    font-family: Inter, Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: ${pxToRem(13)};
    color: #203553;

    background: #ffffff;
    box-shadow: 0 6px 20px 0 rgba(32, 53, 83, 0.3);
    border-radius: 6px;
  }

  .Toastify__close-button {
  }

  .Toastify__progress-bar {
  }
`;

export default ASToastContainer;
