import { Field, FieldProps, useField, useFormikContext } from 'formik';
import React, { FC, ReactNode } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import styled from 'styled-components';

const InputShape = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  border: 1px solid ${(props) => (props.error ? '#fc4e6F' : '#282828')};
  border-radius: 0px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.2px;
  padding: 0 0;
  overflow: hidden;
`;

const InputField = styled(NumberFormat)<{ $shrinkLeftPadding: boolean }>`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  border: none;
  flex-grow: 1;
  padding: 18px 20px 18px
    ${(props) => (props.$shrinkLeftPadding ? '10px' : '20px')};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  :focus {
    outline: none;
  }

  ::placeholder {
    color: #282828;
  }
`;

const PrefixContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 0 0 0 20px;
`;

const SuffixContainer = styled.div`
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 0 20px 0 0;
  transform: translateY(-1px);
`;

export interface NumberInputProps extends NumberFormatProps {
  fieldName: string;
  maxLength?: number;
  placeholder: string;
  renderPrefix?: () => ReactNode;
  renderSuffix?: () => ReactNode;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  showErrorIfValueSet?: boolean;
  showThousandSeparator?: boolean;
  useStringValue?: boolean;
}

const NumberInputField: FC<NumberInputProps> = (props) => {
  const {
    fieldName,
    maxLength = 11,
    placeholder,
    renderPrefix,
    renderSuffix,
    className,
    showErrorAfterTouch = false,
    showErrorAfterFormSubmit = true,
    showErrorIfValueSet = false,
    showThousandSeparator = true,
    useStringValue = false,
    decimalScale = 0,
    ...rest
  } = props;

  const [{ value }, { error, touched }] = useField({
    name: fieldName,
  });

  const { submitCount } = useFormikContext();

  const showError =
    (showErrorAfterFormSubmit && submitCount > 0) ||
    (showErrorAfterTouch && touched) ||
    (showErrorIfValueSet && value);

  return (
    <InputShape error={showError && !!error} className={className}>
      {renderPrefix ? (
        <PrefixContainer>{renderPrefix()}</PrefixContainer>
      ) : null}
      <Field name={fieldName}>
        {({ field, form }: FieldProps) => (
          <InputField
            name={fieldName}
            value={field.value}
            thousandSeparator={showThousandSeparator}
            allowNegative={false}
            onValueChange={(value: { floatValue: number; value: string }) =>
              form.setFieldValue(
                fieldName,
                !useStringValue ? value.floatValue : value.value,
              )
            }
            onBlur={field.onBlur}
            placeholder={placeholder}
            maxLength={maxLength}
            decimalScale={decimalScale}
            $shrinkLeftPadding={!!renderPrefix}
            {...rest}
          />
        )}
      </Field>
      {renderSuffix ? (
        <SuffixContainer> {renderSuffix()} </SuffixContainer>
      ) : null}
    </InputShape>
  );
};

export default NumberInputField;
