import moment from 'moment';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import theme from '../../../constants/theme';
import { SubscribedSuburbBaseFragment } from '../../../graphql/generated';
import SuburbHeader from './SuburbHeader';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 25px 20px;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 12px;
`;

const DetailsText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 0;
`;

const TextButton = styled.button`
  border: none;
  padding: 0;
  margin: 24px 0 0 0;
  background: none;
  align-items: flex-end;
  align-self: flex-start;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const RenewText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #86c245;
  letter-spacing: 0.2px;
  margin: 0;
`;

const CancelText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #fc4e6f;
  letter-spacing: 0.2px;
  margin: 0;
`;

const CostText = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.13px;
  line-height: 25px;
  margin: 12px 0 0 0;
`;

export interface ActiveSuburbCardProps {
  subsribedSuburb: SubscribedSuburbBaseFragment;
  onRenewSuburb: (subsribedSuburb: SubscribedSuburbBaseFragment) => void;
  onCancelSuburb: (subsribedSuburb: SubscribedSuburbBaseFragment) => void;
}

const ActiveSuburbCard: FC<ActiveSuburbCardProps> = ({
  subsribedSuburb,
  onCancelSuburb,
  onRenewSuburb,
}) => {
  const { suburb } = subsribedSuburb;
  const isFreeTrial = true;

  const cancelled = !subsribedSuburb.subscriptionToRenew;
  const subscriptionExpiryFormatted = moment(
    subsribedSuburb.subscriptionExpiresAt,
  ).format('DD MMM YYYY');

  const statusText = useMemo(() => {
    if (isFreeTrial) {
      return ``;
    } else if (cancelled) {
      return `Cancelled - Expires on ${subscriptionExpiryFormatted}`;
    } else {
      return `Renews on ${subscriptionExpiryFormatted}`;
    }
  }, [subsribedSuburb, cancelled, isFreeTrial]);

  return (
    <Container>
      <SuburbHeader suburb={suburb} showPricing={false} />
      <CostText>
        {isFreeTrial ? 'Free' : config.SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS}
      </CostText>
      <DetailsText>{statusText}</DetailsText>
      {cancelled ? (
        <TextButton onClick={() => onRenewSuburb(subsribedSuburb)}>
          <RenewText>Renew</RenewText>
        </TextButton>
      ) : (
        <TextButton onClick={() => onCancelSuburb(subsribedSuburb)}>
          <CancelText>Cancel subscription</CancelText>
        </TextButton>
      )}
    </Container>
  );
};

export default ActiveSuburbCard;
