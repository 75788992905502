import React, { FC } from 'react';
import styled from 'styled-components';
import addBluePlusBlockIconUrl from '../assets/add-blue-plus-block.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';
import AddItemRowSmallButton, { RowSmallButton } from './AddItemRowSmallButton';

const Label = styled.label`
  display: flex;
  align-self: stretch;
  text-align: center;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #58687e;
  letter-spacing: 0.3px;
  margin: 0;
  padding: 0;
  line-height: 25px;
`;

const DisabledButton = styled(RowSmallButton)`
  justify-content: center;
`;

export const usePrecacheFoAddSuburbsButton: () => void = () => {
  usePrecacheImages([addBluePlusBlockIconUrl]);
};

export interface AddSuburbsButtonProps {
  onClick: () => void;
  suburbsAdded: number;
  maxSuburbs: number;
}

const AddSuburbsButton: FC<AddSuburbsButtonProps> = (props) => {
  const { onClick, suburbsAdded, maxSuburbs } = props;

  return (
    <>
      {suburbsAdded >= maxSuburbs ? (
        <DisabledButton>
          <Label>
            {suburbsAdded}/{maxSuburbs} suburbs selected
          </Label>
        </DisabledButton>
      ) : (
        <AddItemRowSmallButton label="Add Suburbs" onClick={onClick} />
      )}
    </>
  );
};

export default AddSuburbsButton;
