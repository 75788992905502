import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import editIconUri, {
  ReactComponent as EditIcon,
} from '../../../assets/edit-ic.svg';
import { UserMediaFieldValueState } from '../../../containers/UserMediaField';
import {
  useMeQuery,
  useUpdateAgentProfileDetailsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import AgentProfileDetailsForm, {
  ProfileDetailsFormValues,
} from './AgentProfileDetailsForm';

const EditButton = styled.button`
  display: flex;
  border: none;
  padding: 0;
  margin: 0 20px 10px 0;
  background: none;
  align-items: flex-end;
  min-height: 20px;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const EditIconStyled = styled(EditIcon)`
  margin-right: 10px;
  margin-bottom: 2px;
`;

const ButtonText = styled.p<{ color: string }>`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.color ?? '#1745B0'};
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
`;

const EditingButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 27px;
`;

const validationSchema = Yup.object().shape({
  profilePictureUserMedia: Yup.object({
    userMedia: Yup.object().required(),
    state: Yup.string().oneOf([UserMediaFieldValueState.Ready]).required(),
  }).required('Profile picture is required'),
  bio: Yup.string().required('Bio is required').min(1).max(500),
  agencyLogoUserMedia: Yup.object({
    userMedia: Yup.object().required(),
    state: Yup.string().oneOf([UserMediaFieldValueState.Ready]).required(),
  }).required('Agency logo is required'),
  agencyName: Yup.string().required('Agency name is required'),
  agencyBio: Yup.string().required('Agency bio is required'),
});

export const usePrecacheForAgentEditAgentProfile: () => void = () => {
  usePrecacheImages([editIconUri]);
};

const AgentEditAgentProfile: FC = () => {
  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({ skip: !isAuthenticated });

  const [editEnabled, setEditEnabled] = useState(false);

  const [updateAgentProfileDetails] = useUpdateAgentProfileDetailsMutation();

  const initialValues: ProfileDetailsFormValues = meData?.me?.agentProfile
    ? {
        profilePictureUserMedia: meData.me.agentProfile.profilePicture
          ? {
              userMedia: meData.me.agentProfile.profilePicture,
              state: UserMediaFieldValueState.Ready,
            }
          : undefined,
        bio: meData.me.agentProfile.bio ?? '',
        agencyLogoUserMedia: meData?.me.agentProfile?.agencyLogo
          ? {
              userMedia: meData.me.agentProfile.agencyLogo,
              state: UserMediaFieldValueState.Ready,
            }
          : undefined,
        agencyName: meData?.me.agentProfile?.agencyName ?? '',
        agencyBio: meData?.me.agentProfile?.agencyBio ?? '',
      }
    : {
        profilePictureUserMedia: undefined,
        bio: '',
        agencyLogoUserMedia: undefined,
        agencyName: '',
        agencyBio: '',
      };

  const onSubmit = useCallback(async (values: ProfileDetailsFormValues) => {
    try {
      await updateAgentProfileDetails({
        variables: {
          ...values,

          profilePictureUserMediaId: values.profilePictureUserMedia!.userMedia
            .id,
          agencyLogoUserMediaId: values.agencyLogoUserMedia!.userMedia.id,
        },
      });

      setEditEnabled(false);

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  return (
    <>
      {meData && (
        <Formik<ProfileDetailsFormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange>
          {({ submitForm, values, resetForm }) => {
            return (
              <>
                <EditingButtonsContainer>
                  {editEnabled ? (
                    <>
                      <EditButton onClick={submitForm}>
                        <ButtonText color="#1745B0">Save Edits</ButtonText>
                      </EditButton>
                      <EditButton
                        onClick={() => {
                          resetForm();
                          setEditEnabled(false);
                        }}>
                        <ButtonText color="#FF6481">Discard Edits</ButtonText>
                      </EditButton>
                    </>
                  ) : (
                    <EditButton onClick={() => setEditEnabled(true)}>
                      <EditIconStyled />
                      <ButtonText color="#1745B0">Edit details</ButtonText>
                    </EditButton>
                  )}
                </EditingButtonsContainer>
                <AgentProfileDetailsForm
                  editEnabled={editEnabled}
                  bioLength={values.bio.length}
                />
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default AgentEditAgentProfile;
