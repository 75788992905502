import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';

const Input = styled.textarea<{ error?: boolean }>`
  padding: 18px;
  border-radius: 0px;
  border: 1px solid ${(props) => (props.error ? '#fc4e6F' : '#282828')};
  min-height: 147px;
  max-height: 400px;
  box-shadow: inset none;

  :focus {
    outline: none;
  }

  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;

  -webkit-appearance: none;

  ::placeholder {
    color: #282828;
  }
`;

export interface MultLineTextFieldProps {
  fieldName: string;
  placeholder?: string;
  maxLength?: number;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  disabled?: boolean;
  className?: string;
}

const MultiLineTextField: FC<MultLineTextFieldProps> = (props) => {
  const {
    fieldName,
    placeholder = 'Type here...',
    maxLength,
    showErrorAfterTouch = false,
    showErrorAfterFormSubmit = true,
    disabled = false,
    className,
  } = props;

  const [{ value, onChange }, { error, touched }] = useField({
    name: fieldName,
  });

  const { submitCount } = useFormikContext();

  const showError =
    (showErrorAfterFormSubmit && submitCount > 0) ||
    (showErrorAfterTouch && touched);

  return (
    <Input
      name={fieldName}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      placeholder={placeholder}
      error={showError && !!error}
      disabled={disabled}
      className={className}
    />
  );
};

export default MultiLineTextField;
