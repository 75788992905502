import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import StepCompleteContent from '../../../components/StepCompleteContent';
import theme from '../../../constants/theme';
import { usePrecacheForLogin } from './Login';

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    height: 80vh;
  }
`;

export const usePrecacheForChangePasswordSuccess: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
  usePrecacheForLogin();
};

const ChangePasswordSuccess: FC = () => {
  const history = useHistory();

  const onContinueClick = useCallback(() => {
    history.push('/login');
  }, []);

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Reset Password Sent | AgentSpot</title>
      </Helmet>
      <PageContentContainer>
        <StepCompleteContent
          title="Password Changed"
          body="Your password has been reset, you can now log in with your new password"
          nextButtonLabel="Return to Log in"
          onNextButtonClick={onContinueClick}
        />
      </PageContentContainer>
    </SideBySidePageTemplate>
  );
};

export default ChangePasswordSuccess;
