import React, { FC } from 'react';
import styled from 'styled-components';
import ASMultilineText from '../../../components/ASMultilineText';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import feesDividerUri from '../assets/appraisal-fees-divider.svg';
import feesIconUri from '../assets/appraisal-fees-ic.svg';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 30px 20px;

  justify-content: center;
  background-color: #fff;
  margin-bottom: 15px;

  @media print {
    page-break-inside: avoid;
    margin-bottom: 40px;
  }
`;

const Title = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(15)};
  color: #203553;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 17px;
`;

export const usePrecacheForFeesBox: () => void = () => {
  usePrecacheImages([feesIconUri, feesDividerUri]);
};

export interface AppraisalAdditionalNotesProps {
  additionalNotes: string;
}

const AppraisalAdditionalNotes: FC<AppraisalAdditionalNotesProps> = ({
  additionalNotes,
}) => {
  return (
    <Container>
      <Title>Additional Notes</Title>
      <ASMultilineText text={additionalNotes} />
    </Container>
  );
};

export default AppraisalAdditionalNotes;
