import { useEffect } from 'react';

/**
 * Causes images to be loaded and cachedby browser for immediate display once displayed
 */
const usePrecacheImages: (imageUrls: string[]) => void = (imageUrls) => {
  useEffect(() => {
    imageUrls.forEach((url) => {
      const nonDisplayedImage = new Image();
      nonDisplayedImage.src = url;
    });
  }, [imageUrls]);
};

export default usePrecacheImages;
