import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import noAppraisalsPlaceholderIconUrl, {
  ReactComponent as NoAppraisalsPlaceholderIcon,
} from '../assets/no-appraisals-placeholder.svg';
import EmptyPlaceholder from './EmptyPlaceholder';

const NoAppraisalsPlaceholderImage = styled(NoAppraisalsPlaceholderIcon)`
  width: 273px;
  height: 192px;
`;

export const usePrecacheForNoCompleteAppraisalsPlaceholder: () => void = () => {
  usePrecacheImages([noAppraisalsPlaceholderIconUrl]);
};

const NoCompleteAppraisalsPlaceholder: FC = () => {
  return (
    <EmptyPlaceholder
      image={<NoAppraisalsPlaceholderImage />}
      title="You don’t have any appraised properties just yet"
      explanation="Once you've submitted an appraisal the property will appear here"
    />
  );
};

export default NoCompleteAppraisalsPlaceholder;
