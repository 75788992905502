import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    justify-content: center;
  }

  flex-grow: 1;
  padding: 50px 0 0 0;
`;

const Title = styled.h5`
  margin: 23px 0 15px 0;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: #203553;
  text-align: center;
`;

const Explanation = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;
`;

export interface EmptyPlaceholderProps {
  title: string;
  image: ReactNode;
  explanation: string;
}

const EmptyPlaceholder: FC<EmptyPlaceholderProps> = (props) => {
  const { image, title, explanation, children } = props;

  return (
    <Container>
      {image}
      <Title>{title}</Title>
      <Explanation>{explanation}</Explanation>
      {children}
    </Container>
  );
};

export default EmptyPlaceholder;
