import styled from 'styled-components';

const ASCaption = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #a6aeba;
  letter-spacing: 0.14px;
  text-align: center;
  line-height: 23px;
`;

export default ASCaption;
