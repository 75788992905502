import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useLastKnownIsGhostUser from '../../../hooks/useLastKnownIsGhostUser';

const useIsGhostUser: () => boolean | null = () => {
  const isAuthenticated = useIsAuthenticated();
  const lastKnownIsGhostUser = useLastKnownIsGhostUser();

  return isAuthenticated && lastKnownIsGhostUser;
};

export default useIsGhostUser;
