import React from 'react';
import styled from 'styled-components';
import ASBlockLink from '../components/ASBlockLink';
import theme from '../constants/theme';
import ASMenuButton, { MenuButton } from './ASMenuButton';

const Container = styled.div`
  display: none;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    justify-content: flex-start;
  }
`;

const SignUpButton = styled(ASBlockLink)`
  background-image: linear-gradient(
    90deg,
    #1745b0 0%,
    #1f58ee 52%,
    #001cdf 100%
  );
  padding: 12px 16px;

  :hover {
    text-decoration-line: none;
    color: #ffffff;
  }

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    padding: 12px 12px;
  }
`;

const UnauthenticatedMenuActions: React.FC = () => {
  return (
    <Container>
      <ASMenuButton path="/about-the-company" textButton="About" />
      <ASMenuButton path="/how-it-works" textButton="How it works" />
      <ASMenuButton path="/real-state-agent-info" textButton="For Agents" />
      <ASMenuButton path="/find-real-estate-agents" textButton="For Sellers" />
      <MenuButton to="/login">Login</MenuButton>
      <SignUpButton to="/get-started">Sign Up</SignUpButton>
    </Container>
  );
};

export default UnauthenticatedMenuActions;
