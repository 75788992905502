import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AddItemRowSmallButton from '../../../components/AddItemRowSmallButton';
import config from '../../../config';
import { useMyDefaultPaymentMethodQuery } from '../../../graphql/generated';
import PaymentMethodRow, {
  usePrecacheFoPaymentMethodRow,
} from '../components/PaymentMethodRow';
import SuburbCheckoutRow from '../components/SuburbCheckoutRow';
import useSuburbCart from '../hooks/useSuburbCart';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 0 20px 0;
`;

const TotalsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 27px 0;
`;

const TotalLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #203553;
  letter-spacing: 0.15px;
  line-height: 24px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const TotalValueLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #58687e;
  letter-spacing: 0.12px;
`;

const SectionTitle = styled.h3`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #203553;
  letter-spacing: 0.13px;
  line-height: 25px;
  margin: 32px 0 10px 0;
`;

const CommissonDisclaimerBody = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
`;

const SuburbCheckoutRowStyled = styled(SuburbCheckoutRow)<{
  $isLastItem: boolean;
}>`
  padding: 0 0 19px 0;
  margin: 10px 0 17px 0;
  border-bottom-color: #d2d7dd;
  border-bottom-style: solid;
  border-bottom-width: ${(props) => (props.$isLastItem ? 0 : 1)};
`;

export interface SuburbPaymentFormProps {
  onAddPaymentDetailsClick: () => void;
  onEditPaymentMethodClick: () => void;
  numberOfFreeTrialsClaimed: number;
  totalFreeTrialsAllowed: number;
}

export const usePrechacheForSuburbPaymentForm: () => void = () => {
  useSuburbCart();
  usePrecacheFoPaymentMethodRow();
};

const SuburbPaymentForm: FC<SuburbPaymentFormProps> = ({
  onAddPaymentDetailsClick,
  onEditPaymentMethodClick,
  numberOfFreeTrialsClaimed,
  totalFreeTrialsAllowed,
}) => {
  const [cartSuburbIds, cartSuburbs] = useSuburbCart();

  const {
    data: myDefaultPatymentMethodData,
  } = useMyDefaultPaymentMethodQuery();

  const totalCost =
    ((cartSuburbIds.length ?? 0) -
      (totalFreeTrialsAllowed - numberOfFreeTrialsClaimed)) *
    config.SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS;

  return (
    <Container>
      <SectionTitle>Referral Fee</SectionTitle>
      <CommissonDisclaimerBody>
        A referral fee of ${new Intl.NumberFormat().format(1000)} (+GST) will be
        payable to AgentSpot when a property is sold unconditionally. For more
        information{' '}
        <Link to="/terms-and-conditions/?tab=terms">
          View Terms &amp; Conditions and Privacy policy
        </Link>
      </CommissonDisclaimerBody>
      {myDefaultPatymentMethodData &&
        (myDefaultPatymentMethodData?.myDefaultPaymentMethod ? (
          <PaymentMethodRow
            paymentMethod={myDefaultPatymentMethodData.myDefaultPaymentMethod}
            onEditClick={onEditPaymentMethodClick}
          />
        ) : (
          <AddItemRowSmallButton
            label="Add Payment Details"
            description="You can skip this and complete later"
            onClick={onAddPaymentDetailsClick}
          />
        ))}
      <SectionTitle>Service Suburbs</SectionTitle>
      {cartSuburbs.map((suburb, index) => (
        <SuburbCheckoutRowStyled
          suburb={suburb}
          key={suburb.id}
          isFreeTrial={
            totalFreeTrialsAllowed - numberOfFreeTrialsClaimed - index > 0
          }
          showCostAfterTrial
          $isLastItem={index === cartSuburbs.length - 1}
        />
      ))}

      <TotalsRow>
        <TotalLabel>TOTAL COST</TotalLabel>
        <TotalContainer>
          <TotalValueLabel>
            {totalCost > 0
              ? `$${new Intl.NumberFormat().format(totalCost)}`
              : 'Free'}
          </TotalValueLabel>
        </TotalContainer>
      </TotalsRow>
    </Container>
  );
};

export default SuburbPaymentForm;
