import React, { FC } from 'react';
import styled from 'styled-components';
import closeModalButtonUel from '../assets/close-modal.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';
import ASBlockButton from './ASBlockButton';
import ASErrorLabel from './ASErrorLabel';
import ASModal from './ASModal';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  justify-content: center;
  margin: 35px 0 0 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  border: none;
  justify-content: center;
  margin: 26px 0 0 0;
`;

const ButtonContainer = styled.div<{ index: number; buttonCount: number }>`
  display: flex;
  flex: 1;
  margin: ${({ index, buttonCount }) => {
    let margin = '0';

    if (index === 0 && buttonCount > 0) {
      margin = '0 10px 0 0';
    } else if (index > 0 && index < buttonCount - 1) {
      margin = '0 10px 0 0';
    }

    return margin;
  }};
`;

const Title = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 'bold';
  text-align: center;
  align-self: center;
  font-size: 25px;
  color: #203553;
  margin: 0;
`;

const BodyText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;
  margin: 13px 20px 0px 20px;
`;

const Button = styled(ASBlockButton)`
  width: 100%;
`;

export const usePrecacheForASPromptModal: () => void = () => {
  usePrecacheImages([closeModalButtonUel]);
};

export enum PromptModalButtonTypes {
  Affirmative,
  Neutral,
  Destructive,
}

export interface PromptModalButton {
  type: PromptModalButtonTypes;
  title: string;
  loading?: boolean;
  onClick: () => void;
}

export interface ASPromptModalProps {
  isOpen: boolean;
  title: string;
  body: string[];
  onRequestClose: () => void;
  expand?: boolean;
  buttons: Array<PromptModalButton>;
  error?: string;
}

const ASPromptModal: FC<ASPromptModalProps> = (props) => {
  const {
    isOpen,
    title,
    body,
    buttons,
    onRequestClose,
    expand = false,
    error,
  } = props;
  return (
    <ASModal
      showHeader={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      expand={expand}
      slideOnMobile={false}>
      <ContentContainer>
        <Title>{title}</Title>
        {body.map((bodyLine, i) => (
          <BodyText key={i}>{bodyLine}</BodyText>
        ))}

        {error && <ASErrorLabel>{error}</ASErrorLabel>}
      </ContentContainer>
      <ButtonsContainer>
        {buttons.map((button, index) => {
          let background = undefined;
          let border = undefined;
          let textColor = undefined;

          switch (button.type) {
            case PromptModalButtonTypes.Affirmative:
              background = undefined;
              border = undefined;
              break;
            case PromptModalButtonTypes.Destructive:
              background = '#FFF';
              border = '1px solid #FC4E6F';
              textColor = '#FC4E6F';
              break;
            case PromptModalButtonTypes.Neutral:
              background = '#FFF';
              border = '1px solid #1745B0';
              textColor = '#1745B0';
              break;
            default:
              break;
          }

          return (
            <ButtonContainer
              key={index}
              index={index}
              buttonCount={buttons.length}>
              <Button
                loading={button.loading}
                onClick={button.onClick}
                background={background}
                border={border}
                textColor={textColor}>
                {button.title}
              </Button>
            </ButtonContainer>
          );
        })}
      </ButtonsContainer>
    </ASModal>
  );
};

export default ASPromptModal;
