import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ASHeader from '../../../components/ASHeader';
import BackNavLink, {
  usePrecacheForBackNavLink,
} from '../../../components/BackNavLink';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { usePrecacheForAgentSignupComplete } from './AgentSignupComplete';
import AgentSuburbsCheckout, {
  usePrecacheForAgentSuburbsCheckout,
} from './AgentSuburbsCheckout';

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  padding: 16px 0 20px 20px;

  width: 100%;
  max-width: 670px;
  overflow: visible;
`;

export const usePrecacheForAgentSignupPayment: () => void = () => {
  usePrecacheForBackNavLink();
  usePrecacheForNavigationHeader();
  usePrecacheForAgentSuburbsCheckout();
  usePrecacheForStepTemplate();
};

const AgentSignupPayment: FC = () => {
  usePrecacheForAgentSignupComplete();

  return (
    <StepPageTemplate.Container
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Pricing &amp; Payment | AgentSpot</title>
      </Helmet>

      <HeadingContainer>
        <BackNavLink href="/agent/signup/summary" />
        <ASHeader>Pricing &amp; Payment</ASHeader>
      </HeadingContainer>
      <AgentSuburbsCheckout />
    </StepPageTemplate.Container>
  );
};

export default AgentSignupPayment;
