import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ASBlockLink = styled(Link)`
  border: none;
  background-image: linear-gradient(90deg, #1745b0 0%, #001cdf 100%);
  border-radius: 9px;
  padding: 18px 20px;
  color: #ffffff;
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.3px;
  text-align: center;

  :hover {
    text-decoration-line: none;
    box-shadow: 0px 2px 4px 1px #d1d1d1;
    color: #ffffff;
  }
`;

export default ASBlockLink;
