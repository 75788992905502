import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import client from '../../../client';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader from '../../../components/NavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  PropertyCompleteType,
  PropertyCompleteTypeWithoutAgentspot,
  PropertyWithAppraisalsFragment,
  PropertyWithAppraisalsFragmentDoc,
  usePropertyCompletedWithoutAgentSpotMutation,
  usePropertyQuery,
  useStaticCashbackAmountQuery,
} from '../../../graphql/generated';
import { pxToRem } from '../../../utils/pxToRem';
import SaleStatusNavigationHeader, {
  usePrecacheForBackNavButton,
} from '../components/SaleStatusNavigationHeader';
import SaleStatusOptionField, {
  usePrecacheForSaleStatusOptionField,
} from '../components/SaleStatusOptionField';
import { usePrecacheForPropertyNotSold } from './PropertyNotSold';
import { usePrecacheForSelectSaleAgent } from './SelectSaleAgent';

const Question = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(16)};
  color: #58687e;
  letter-spacing: 0.15px;
  text-align: center;
  margin: 16px 0 0 0;
  padding: 0;
`;

const Description = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(14)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 5px 0 43px 0;
  text-align: center;
  align-self: center;
`;

const SubmitButton = styled(ASActionButton).attrs({ type: 'submit' })``;

interface FormValues {
  saleStatus:
    | PropertyCompleteType
    | PropertyCompleteTypeWithoutAgentspot
    | null;
}

interface ExpectedPathParams {
  propertyId?: string;
}

export const usePrecacheForSaleStatus: () => void = () => {
  usePrecacheForStepTemplate();
  usePrecacheForBackNavButton();
  usePrecacheForSaleStatusOptionField();
};

const SaleStatus: FC = () => {
  const { propertyId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const cachedProperty =
    client.readFragment<PropertyWithAppraisalsFragment>({
      id: `Property:${propertyId}`,
      fragment: PropertyWithAppraisalsFragmentDoc,
      fragmentName: 'PropertyWithAppraisals',
    }) ?? undefined;

  const { data: propertyData } = usePropertyQuery({
    variables: { id: typeof propertyId === 'string' ? propertyId : '' },
  });

  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const [
    markPropertyCompletedWithoutAgentSpot,
    {
      loading: markingCompletedWithoutAgentSpot,
      error: markCompletedWithoutAgentSpotError,
    },
  ] = usePropertyCompletedWithoutAgentSpotMutation();

  const property: PropertyWithAppraisalsFragment | undefined =
    propertyData?.property ?? cachedProperty;

  const initialValues = {
    saleStatus: null,
  } as FormValues;

  const validateFormValues = useCallback(
    (values: FormValues) => {
      const errors: { [key in keyof FormValues]?: string } = {};

      if (
        !values.saleStatus ||
        (values.saleStatus &&
          (!property || property.propertyAppraisals.length === 0))
      ) {
        errors.saleStatus = 'Required';
      }

      return errors;
    },
    [property],
  );

  const onSubmitRequest = useCallback(async (values: FormValues) => {
    try {
      if (
        values.saleStatus ===
          PropertyCompleteTypeWithoutAgentspot.DecidedNotToUseAgentspot ||
        values.saleStatus ===
          PropertyCompleteTypeWithoutAgentspot.DecidedNotToSell
      ) {
        await markPropertyCompletedWithoutAgentSpot({
          variables: {
            propertyId: propertyId!,
            completeType: values.saleStatus,
          },
        });
        history.push(`/property/${propertyId}/not-sold`);
      } else {
        history.push(`/property/${propertyId}/sale-status/sold/agent`);
      }
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  if (
    property &&
    property.completeType === PropertyCompleteType.SoldUnconditionally
  ) {
    return <Redirect to={`/property/${propertyId}`} />;
  }

  usePrecacheForSelectSaleAgent(
    typeof propertyId === 'string' ? propertyId : '',
  );
  usePrecacheForPropertyNotSold();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Sale Status | AgentSpot</title>
      </Helmet>

      {property && cashbackAmount && (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmitRequest}
          validate={validateFormValues}
          validateOnMount={true}
          validateOnChange>
          {({ submitForm, isValid, touched, errors }) => {
            const submitDisabled = !isValid;
            const error: string | undefined =
              (touched['saleStatus'] && errors.saleStatus) ||
              (markCompletedWithoutAgentSpotError &&
                apolloErrorToString(markCompletedWithoutAgentSpotError));

            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <SaleStatusNavigationHeader
                    backHref={`/property/${propertyId}`}
                    pageTitle="Sale Status"
                  />
                  <Question>Have you sold your property yet?</Question>
                  <Description>
                    {`Sale must be unconditional. We will contact the agent to
                    confirm. No $${Intl.NumberFormat().format(
                      cashbackAmount?.staticCashbackAmount,
                    )} cash offers will be paid in
                    cases of false claims. `}
                    <Link to="/terms-and-conditions">View conditions.</Link>
                  </Description>
                  <SaleStatusOptionField
                    fieldName="saleStatus"
                    label="Yes, it sold unconditionally"
                    fieldId={PropertyCompleteType.SoldUnconditionally}
                  />
                  <SaleStatusOptionField
                    fieldName="saleStatus"
                    label="Decided not to use AgentSpot"
                    fieldId={PropertyCompleteType.DecidedNotToUseAgentspot}
                  />
                  <SaleStatusOptionField
                    fieldName="saleStatus"
                    label="No, decided not to sell"
                    fieldId={PropertyCompleteType.DecidedNotToSell}
                  />
                  {error && <ASErrorLabel>{error}</ASErrorLabel>}
                </StepPageTemplate.ContentContainer>
                <NavigationFooter
                  rightSideComponent={
                    <SubmitButton
                      loading={markingCompletedWithoutAgentSpot}
                      disabled={submitDisabled}
                      visiblyDisabled={submitDisabled}
                      onClick={submitForm}>
                      Continue
                    </SubmitButton>
                  }
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default SaleStatus;
