import React, { FC, ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import agentSpotLogoIconUrl from '../assets/agentspot-logo-white.svg';
import theme from '../constants/theme';
import {
  useMeQuery,
  useResendVerificationEmailMutation,
} from '../graphql/generated';
import usePrecacheImages from '../hooks/usePrecacheImages';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from './NavigationHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  background-color: white;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1110px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const MenuContainer = styled.div`
  display: none;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    /* flex: 1; */
    flex-shrink: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    border-right: 1px solid #e8eaed;
  }
`;

const ContentSideContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex: 3;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #ffffff;
    padding: 0;
  }
`;

const PageContentContainer = styled.div<{ $noHorzintalPadding: boolean }>`
  display: flex;
  flex-direction: column;

  align-items: stretch;
  width: 100%;
  flex-grow: 1;

  padding: ${(props) => (props.$noHorzintalPadding ? '0' : '0 20px 0 60px')};
  margin-bottom: 0px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    margin-bottom: 0;
    /* min-height: 100vh; */
  }
`;

const Filler = styled.div`
  display: flex;
  width: 100%;

  flex-grow: 1;
`;

const Footer = styled.div`
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  border-top: none !important;
  padding: 0;
  overflow: visible;
  background-color: white;
`;

const FooterContentsContainer = styled.div<{ $noHorzintalPadding: boolean }>`
  border-top: none;
  width: calc(100% + 40px);
  border-top: 1px solid #e8eaed;

  margin: 0 0 0 -40px;

  padding: ${(props) =>
    props.$noHorzintalPadding ? '0 0 0 40px' : ' 0 20px 15px 60px'};

  background-color: white;
`;

const Alert = styled.div`
  width: 100%;
  background-color: #d68f3f;
  color: white;
  padding: 16px 60px;
  font-size: 15px;
`;

const Button = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  font-weight: 600;
  padding: 0;
`;

const BoldText = styled.span`
  color: white;
  font-weight: 600;
`;

export const usePrecacheForSplitDashboardPageTemplate: () => void = () => {
  usePrecacheImages([agentSpotLogoIconUrl]);

  usePrecacheForNavigationHeader();
};

export interface SplitDashboardPageTemplateProps {
  menu: ReactNode;
  navigationHeaderContent?: ReactNode;
  noHorzintalPadding?: boolean;
  footer?: ReactNode;
  padFooter?: boolean;
}

const SplitDashboardPageTemplate: FC<SplitDashboardPageTemplateProps> = (
  props,
) => {
  const {
    menu,
    navigationHeaderContent,
    noHorzintalPadding = false,
    footer,
    padFooter = false,
    children,
  } = props;
  const { data: meData } = useMeQuery();
  const [resendEmailModal, setResendEmailModal] = useState(false);

  const [resendVerificationEmail] = useResendVerificationEmailMutation();

  const onResendEmailClick = useCallback(async () => {
    const { data } = await resendVerificationEmail();
    if (data) setResendEmailModal(true);
  }, []);

  return (
    <Container>
      <ContentContainer>
        <MenuContainer>{menu}</MenuContainer>

        <ContentSideContainer>
          <NavigationHeader
            menuButton
            menu={menu}
            rightSideComponent={navigationHeaderContent}
          />
          {meData && !meData.me.isEmailVerified && (
            <Alert>
              Please verify your email to start receiving appraisals.{' '}
              {resendEmailModal ? (
                <BoldText>Email sent.</BoldText>
              ) : (
                <Button onClick={() => onResendEmailClick()}>
                  Resend email.
                </Button>
              )}
            </Alert>
          )}
          <PageContentContainer $noHorzintalPadding={noHorzintalPadding}>
            {children}
            {footer && (
              <>
                <Filler />
                <Footer>
                  <FooterContentsContainer $noHorzintalPadding={!padFooter}>
                    {' '}
                    {footer}
                  </FooterContentsContainer>
                </Footer>{' '}
              </>
            )}
          </PageContentContainer>
        </ContentSideContainer>
      </ContentContainer>
    </Container>
  );
};

export default SplitDashboardPageTemplate;
