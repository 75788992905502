import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import moreMenuDotsIconUrl from '../../../assets/more-menu-dots.svg';
import client from '../../../client';
import InnerNavigationHeader, {
  usePrecachForInnerNavigationHeader,
} from '../../../components/InnerNavigationHeader';
import { usePrecacheForNavigationHeader } from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import SellerMenu, {
  usePrecacheForSellerMenu,
} from '../../../components/SellerMenu';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import {
  PropertyBaseFragmentDoc,
  PropertyCompleteType,
  PropertyState,
  PropertyWithAppraisalsFragment,
  usePropertyQuery,
} from '../../../graphql/generated';
import useIsDesktop from '../../../hooks/useIsDekstop';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import CreateListingButton from '../components/CreateListingButton';
import PropertyDetailsAppraisals, {
  usePrecacheForPropertyDetailsAppraisals,
} from '../components/PropertyDetailsAppraisals';
import PropertyDetailsDetails, {
  usePrecacheForPropertyDetailsDetails,
} from '../components/PropertyDetailsDetails';
import PropertyDetailsSummary, {
  usePrecacheForPropertyDetailsSummary,
} from '../components/PropertyDetailsSummary';
import PropertyDetailsToggle, {
  usePrecacheForPropertyDetailsToggle,
} from '../components/PropertyDetailsToggle';
import PropertyPhotoSwiper, {
  usePrecacheForPropertyPhotoSwiper,
} from '../components/PropertyPhotoSwiper';
import UniversalPropertyActionMenu from '../components/UniversalPropertyActionMenu';
import { usePrecacheForPropertyAppraisalDetails } from './PropertyAppraisalDetails';
import { usePrecacheForSaleStatus } from './SaleStatus';

export const usePrecacheForPropertyDetails: () => void = () => {
  usePrecacheImages([moreMenuDotsIconUrl]);

  usePrecachForInnerNavigationHeader();
  usePrecacheForNavigationHeader();
  usePrecacheForPropertyPhotoSwiper();
  usePrecacheForPropertyDetailsSummary();
  usePrecacheForPropertyDetailsDetails();
  usePrecacheForPropertyDetailsAppraisals();
  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForPropertyDetailsToggle();
};

const HiddenContainer = styled.div`
  margin: 20px 0 0 0;
`;
const ActionMenuContainer = styled.div`
  margin: 20px 20px;
`;

interface ExpectedPathParams {
  id?: string;
}

const PropertyDetails: FC = () => {
  const { id } = useParams<ExpectedPathParams>();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const cachedProperty =
    client.readFragment<PropertyWithAppraisalsFragment>({
      id: `Property:${id}`,
      fragment: PropertyBaseFragmentDoc,
      fragmentName: 'PropertyBase',
    }) ?? undefined;

  const { data: propertyData, error } = usePropertyQuery({
    variables: { id: typeof id === 'string' ? id : '' },
  });

  const property: PropertyWithAppraisalsFragment | undefined =
    propertyData?.property ?? cachedProperty;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleIsExpanded = useCallback(() => setIsExpanded(!isExpanded), [
    isExpanded,
  ]);

  useEffect(() => {
    const hash = history.location.hash;
    // Check if there is a hash and if an element with that id exists
    const el = hash && document.getElementById(hash.substr(1));
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [history.location.hash, property]);

  useEffect(() => {
    if (
      property?.propertyAppraisals &&
      property?.propertyAppraisals.length > 0
    ) {
      setIsExpanded(false);
    }
  }, [property]);

  usePrecacheForPropertyAppraisalDetails();
  usePrecacheForSellerMenu();
  usePrecacheForSaleStatus();
  usePrecacheForPropertyAppraisalDetails();

  return (
    <SplitDashboardPageTemplate
      menu={<SellerMenu />}
      noHorzintalPadding
      navigationHeaderContent={<CreateListingButton />}>
      <Helmet>
        <title>Property Details | AgentSpot</title>
      </Helmet>
      {!property && error && error.message === ErrorCodes.NotFound && (
        <NotFoundPlaceholder />
      )}

      {property && (
        <>
          <InnerNavigationHeader
            backHref="/"
            pageTitle="Property Details"
            rightSideComponent={
              !(
                property?.state === PropertyState.Complete &&
                property?.completeType ===
                  PropertyCompleteType.SoldUnconditionally
              ) && <UniversalPropertyActionMenu property={property} />
            }
          />
          {property?.propertyAppraisals?.length > 0 ? (
            <>
              <PropertyDetailsSummary property={property} showSubmittedAt />
              <PropertyDetailsToggle
                expanded={isExpanded}
                onToggle={toggleIsExpanded}
              />
              {isExpanded && (
                <>
                  <PropertyPhotoSwiper property={property} />
                  <HiddenContainer>
                    <PropertyDetailsDetails property={property} />
                  </HiddenContainer>
                </>
              )}
              {property &&
                !isDesktop &&
                !(
                  property.state === PropertyState.Complete &&
                  property.completeType ===
                    PropertyCompleteType.SoldUnconditionally
                ) && (
                  <ActionMenuContainer>
                    <UniversalPropertyActionMenu property={property} />
                  </ActionMenuContainer>
                )}
              <PropertyDetailsAppraisals
                id={'appraisals'}
                appraisals={property?.propertyAppraisals}
                propertySoldUnconditionally={
                  property?.state === PropertyState.Complete &&
                  property?.completeType ===
                    PropertyCompleteType.SoldUnconditionally
                }
                propertyId={property.id}
              />
            </>
          ) : (
            <>
              <PropertyDetailsSummary property={property} showSubmittedAt />
              <PropertyPhotoSwiper property={property} />
              <PropertyDetailsToggle
                expanded={isExpanded}
                onToggle={toggleIsExpanded}
              />
              {isExpanded && <PropertyDetailsDetails property={property} />}
              {property && !isDesktop && (
                <ActionMenuContainer>
                  <UniversalPropertyActionMenu property={property} />
                </ActionMenuContainer>
              )}
              <PropertyDetailsAppraisals
                id={'appraisals'}
                appraisals={property?.propertyAppraisals}
                propertySoldUnconditionally={
                  property?.state === PropertyState.Complete &&
                  property?.completeType ===
                    PropertyCompleteType.SoldUnconditionally
                }
                propertyId={property.id}
              />
            </>
          )}
        </>
      )}
    </SplitDashboardPageTemplate>
  );
};

export default PropertyDetails;
