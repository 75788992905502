import { useField, useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import ASErrorLabel from '../components/ASErrorLabel';
import UserMediaAddButton from '../components/UserMediaAddButton';
import UserMediaSquare from '../components/UserMediaSquare';
import { apolloErrorToString } from '../constants/ErrorCodes';
import {
  useCreateUserMediaMutation,
  UserMediaBaseFragment,
} from '../graphql/generated';

const Container = styled.div<{ $horizontal: boolean }>`
  margin: 0 0 16px 0;
  ${(props) =>
    props.$horizontal &&
    ` 
      display: flex;
      flex-direction: row;
      align-items: center;
  `}
`;

export enum UserMediaFieldValueState {
  Uploading = 'UPLOADING',
  Ready = 'READY',
}
export interface UserMediaFieldValue {
  userMedia: UserMediaBaseFragment;
  state: UserMediaFieldValueState;
}
export interface UserMediaFielDetailRenderProps {
  value?: UserMediaFieldValue;
  isUploading: boolean;
  onFileSelected: (file: File) => Promise<void>;
  setValue: (value?: UserMediaFieldValue) => void;
}

export interface UserMediaFieldProps {
  fieldName: string;
  accept: string;
  addButtonTitle?: string;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  showErrorIfValueSet?: boolean;
  disabled?: boolean;
  setLoadingUserMedia?: (
    value: React.SetStateAction<UserMediaBaseFragment[]>,
  ) => void;
  renderBottomDetail?: (props: UserMediaFielDetailRenderProps) => void;
  className?: string;
  lightIcon?: boolean;
  horizontal?: boolean;
}

const UserMediaField: React.FC<UserMediaFieldProps> = (props) => {
  const {
    fieldName,
    showErrorAfterTouch = false,
    showErrorAfterFormSubmit = true,
    showErrorIfValueSet = false,
    setLoadingUserMedia,
    renderBottomDetail,
    className,
    accept,
    addButtonTitle,
    disabled,
    lightIcon = false,
    horizontal = false,
  } = props;

  const [
    { value: fieldValue },
    { error: fieldError, touched },
    { setValue: setFieldValue },
  ] = useField<UserMediaFieldValue | undefined>({
    name: fieldName,
  });

  const { submitCount } = useFormikContext();

  const showError =
    (showErrorAfterFormSubmit && submitCount > 0) ||
    (showErrorAfterTouch && touched) ||
    (showErrorIfValueSet && fieldValue);

  const [
    createUserMedia,
    { error: createUserMediaError },
  ] = useCreateUserMediaMutation();

  const [uploadError, setUploadError] = useState<Error | null>(null);

  const removeLoadingUserMedia = (userMediaId: string) => {
    if (setLoadingUserMedia) {
      setLoadingUserMedia((loadingUserMedia) =>
        loadingUserMedia.filter((um) => um.id !== userMediaId),
      );
    }
  };

  const onFileSelected: (file: File) => Promise<void> = useCallback(
    async (file: File) => {
      const fileExtension = file.name.split('.').pop();

      if (!fileExtension) return;

      setUploadError(null);

      const { data: createUserMediaData } = await createUserMedia({
        variables: {
          mimeType: file.type,
          fileExtension,
        },
      });

      if (createUserMediaData) {
        const userMedia = createUserMediaData.createUserMedia.userMedia;

        try {
          if (setLoadingUserMedia) {
            setLoadingUserMedia((loadingUserMedia) => [
              ...loadingUserMedia,
              userMedia,
            ]);
          }

          setFieldValue({
            userMedia,
            state: UserMediaFieldValueState.Uploading,
          });

          const uploadResult = await fetch(
            createUserMediaData.createUserMedia.uploadUrl,
            {
              method: 'PUT',
              body: file,
            },
          );

          if (uploadResult.status == 200) {
            setFieldValue({ userMedia, state: UserMediaFieldValueState.Ready });
          } else {
            setFieldValue(undefined);
          }

          removeLoadingUserMedia(userMedia.id);
        } catch (e) {
          console.error(e);

          if (!('graphQlErrors' in e)) {
            setUploadError(e);
          }

          setFieldValue(undefined);

          removeLoadingUserMedia(userMedia.id);
        }
      }
    },
    [],
  );

  const userMedia = fieldValue?.userMedia;

  return (
    <Container $horizontal={horizontal}>
      {userMedia ? (
        <UserMediaSquare
          userMedia={userMedia}
          className={className}
          mediaUploadingStatus={fieldValue?.state}
        />
      ) : (
        <UserMediaAddButton
          accept={accept}
          onFileSelected={onFileSelected}
          title={addButtonTitle}
          className={className}
          disabled={disabled}
          lightIcon={lightIcon}
        />
      )}

      {renderBottomDetail &&
        renderBottomDetail({
          value: fieldValue,
          isUploading: fieldValue?.state == UserMediaFieldValueState.Uploading,
          onFileSelected,
          setValue: setFieldValue,
        })}

      {createUserMediaError && (
        <ASErrorLabel>{apolloErrorToString(createUserMediaError)}</ASErrorLabel>
      )}
      {uploadError && (
        <ASErrorLabel>
          An error occured whilst upuploading the media
        </ASErrorLabel>
      )}
      {showError && fieldError && <ASErrorLabel>{fieldError} </ASErrorLabel>}
    </Container>
  );
};

export default UserMediaField;
