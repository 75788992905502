import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 20px 0 15px 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }

  body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const ScrollableContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const SelectedTab = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #1745b0;
  margin: 0 20px 0 0;

  :hover {
    text-decoration-line: none;
  }
`;

const SelectedTabLabel = styled.div`
  display: flex;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #1745b0;
`;

const UnselectedTab = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px 0;
  border-bottom: 2px solid transparent;
  margin: 0 20px 0 0;

  :hover {
    text-decoration-line: none;
  }
`;

const UnselectedTabLabel = styled.div`
  display: flex;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 600;
  font-size: 17px;
  color: #a6aeba;
`;

const AlertBadge = styled.div`
  display: inline-flex;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb3040;

  width: 19px;
  height: 19px;
  border-radius: 10px;

  margin: 0 0 0 4px;
`;

const AlertBadgeLabel = styled.div`
  display: flex;
  font-family: Poppins, Arial, sans-serif;
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: -0.14px;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;
export interface Tab {
  label: string;
  href: string;
  alertCount?: number;
}

export interface TabBarProps {
  tabs: Tab[];
  selectedTabIndex: number;
  onTabClick?: (tabIndex: number) => void;
  replaceNav?: boolean;
}

const TabBar: FC<TabBarProps> = (props) => {
  const { tabs, selectedTabIndex, onTabClick, replaceNav = true } = props;
  return (
    <Container>
      <ScrollableContent>
        {tabs.map(({ label, href, alertCount }, i) =>
          i === selectedTabIndex ? (
            <SelectedTab to={href} key={i} replace={replaceNav}>
              <SelectedTabLabel>{label}</SelectedTabLabel>
              {alertCount !== undefined && alertCount > 0 && (
                <AlertBadge>
                  <AlertBadgeLabel>{alertCount}</AlertBadgeLabel>
                </AlertBadge>
              )}
            </SelectedTab>
          ) : (
            <UnselectedTab
              to={href}
              onClick={() => onTabClick && onTabClick(i)}
              replace={replaceNav}
              key={i}>
              <UnselectedTabLabel>{label}</UnselectedTabLabel>
              {alertCount !== undefined && alertCount > 0 && (
                <AlertBadge>
                  <AlertBadgeLabel>{alertCount}</AlertBadgeLabel>
                </AlertBadge>
              )}
            </UnselectedTab>
          ),
        )}
      </ScrollableContent>
    </Container>
  );
};

export default TabBar;
