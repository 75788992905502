import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import SideBySidePageTemplate from '../../../components/SideBySidePageTemplate';
import StepCompleteContent from '../../../components/StepCompleteContent';
import theme from '../../../constants/theme';
import { useVerifyEmailMutation } from '../../../graphql/generated';

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    height: 80vh;
  }
`;

interface ExpectedPathParams {
  token?: string;
}

const VerifyEmail: FC = () => {
  const history = useHistory();
  const { token } = useParams<ExpectedPathParams>();
  const [success, setSuccess] = useState(false);
  const [verifyEmail, { loading }] = useVerifyEmailMutation();

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { data } = await verifyEmail({
            variables: { emailVerificationToken: token },
          });

          if (data) {
            setSuccess(true);
          }
          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        } catch {
          setSuccess(false);
          // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
        }
      })();
    }
  }, []);

  const onContinueClick = useCallback(() => {
    history.push('/login');
  }, []);

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Email Verification | AgentSpot</title>
      </Helmet>
      <PageContentContainer>
        {!loading && (
          <StepCompleteContent
            {...(success
              ? {
                  title: 'Email Verified',
                  body: 'Your email has been verified.',
                }
              : {
                  hideCheckmark: true,
                  title: 'Invalid/ Expired Token',
                  body: 'Please log in and resend verification email again.',
                })}
            nextButtonLabel="Return to Log in"
            onNextButtonClick={onContinueClick}
          />
        )}
      </PageContentContainer>
    </SideBySidePageTemplate>
  );
};

export default VerifyEmail;
