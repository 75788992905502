import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: #8f9aa9;
  margin: 0 0 30px 0;
`;

const NavLinkIconContainer = styled.div`
  width: 17px;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavLinkLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.15px;

  margin-top: 2px;

  ${Link}:hover & {
    color: #58687e;
    text-decoration: underline;
    text-decoration-color: #58687e;
  }
`;

const AlertBadge = styled.div`
  display: inline-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fb3040;

  width: 19px;
  height: 19px;
  border-radius: 10px;

  margin: 0 0 0 4px;
`;

const AlertBadgeLabel = styled.div`
  display: flex;
  font-family: Poppins, Arial, sans-serif;
  font-weight: 700;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: -0.14px;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

export interface MenuLinkProps {
  label: string;
  icon: ReactNode;
  href: string;
  alertCount?: number;
}

const MenuLink: FC<MenuLinkProps> = (props) => {
  const { label, icon: Icon, href, alertCount } = props;

  return (
    <Link exact activeStyle={{ color: '#203553' }} to={href}>
      <NavLinkIconContainer>{Icon}</NavLinkIconContainer>
      <NavLinkLabel>{label}</NavLinkLabel>
      {alertCount !== undefined && alertCount > 0 && (
        <AlertBadge>
          <AlertBadgeLabel>{alertCount}</AlertBadgeLabel>
        </AlertBadge>
      )}
    </Link>
  );
};

export default MenuLink;
