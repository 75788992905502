import React, { FC, ReactNode } from 'react';
export const viewportContext = React.createContext({});

interface ViewportProviderProps {
  children: ReactNode;
}

export interface WindowSize {
  width: number;
  height: number;
}

const ViewportProvider: FC<ViewportProviderProps> = ({ children }) => {
  // This is the exact same logic that we previously had in our hook

  // const [width, setWidth] = React.useState(window.innerWidth);
  // const [height, setHeight] = React.useState(window.innerHeight);
  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleWindowResize = () => {
    // setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
    setWindowSize({ width: window.innerWidth, height: window.innerHeight });
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */
  return (
    <viewportContext.Provider value={windowSize}>
      {children}
    </viewportContext.Provider>
  );
};

export default ViewportProvider;
