import React, { FC } from 'react';
import styled from 'styled-components';
import ASMultilineText from '../../../components/ASMultilineText';
import {
  additionalFeatureToString,
  areYouThePropertyOwnerToString,
  capitalImprovementsToString,
  estimatedSaleValueRangeToString,
  idealTimeFrameOfSaleToString,
  preferredMethodOfSaleToString,
} from '../../../constants/EnumMappings';
import { PropertyBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import chevronDownUrl from '../assets/chevron-down-blue.svg';
import chevronUpUrl from '../assets/chevron-up-blue.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0 20px 25px 20px;
`;
const DetailSectionTitle = styled.h4`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.25px;
  margin: 0 0 14px 0;
`;

const DetailSaleValueTitle = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 0 0 14px 0;
`;

const DetailSaleValueValueLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #58687e;
  letter-spacing: 0.27px;
  margin: 0 0 14px 0;
`;

const DetailSection = styled.div``;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #e8eaed;
  padding: 15px 0 14px 0;
`;

const DetailRowTitle = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #8f9aa9;
  letter-spacing: 0.2px;
  flex-shrink: 1;
`;

const DetailRowValue = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.18px;
  text-align: right;
`;

const FeaturesList = styled.ul`
  columns: 2;
  padding: 0 20px;
  margin: 0 0 14px 0;
`;

const FeaturesListItem = styled.li`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #a6aeba;
  letter-spacing: 0.16px;
`;

const AdditionalInformation = styled(ASMultilineText)`
  font-family: Inter, Arial, sans-serif !important;
  font-size: 13px !important;
  color: #a6aeba !important;
  letter-spacing: 0.14px !important;
  text-align: left !important;
  line-height: 23px !important;
`;

export const usePrecacheForPropertyDetailsDetails: () => void = () => {
  usePrecacheImages([chevronDownUrl, chevronUpUrl]);
};

export interface PropertyDetailsDetailsProps {
  property: PropertyBaseFragment;
}

const PropertyDetailsDetails: FC<PropertyDetailsDetailsProps> = (props) => {
  const { property } = props;

  return (
    <Container>
      <DetailSectionTitle>Sale information</DetailSectionTitle>
      <DetailSection>
        {property.estimatedSaleValueRange && (
          <>
            <DetailSaleValueTitle>Ideal sale value</DetailSaleValueTitle>
            <DetailSaleValueValueLabel>
              {
                estimatedSaleValueRangeToString[
                  property.estimatedSaleValueRange
                ]
              }
            </DetailSaleValueValueLabel>
          </>
        )}

        {property.idealTimeframeOfSale && (
          <DetailRow>
            <DetailRowTitle>Sale timeframe</DetailRowTitle>
            <DetailRowValue>
              {idealTimeFrameOfSaleToString[property.idealTimeframeOfSale]}
            </DetailRowValue>
          </DetailRow>
        )}

        {property.preferredMethodOfSale && (
          <DetailRow>
            <DetailRowTitle>Preferred method of sale</DetailRowTitle>
            <DetailRowValue>
              {preferredMethodOfSaleToString[property.preferredMethodOfSale]}
            </DetailRowValue>
          </DetailRow>
        )}

        {property.areYouThePropertyOwner && (
          <DetailRow>
            <DetailRowTitle>Are you the property owner?</DetailRowTitle>
            <DetailRowValue>
              {areYouThePropertyOwnerToString[property.areYouThePropertyOwner]}
            </DetailRowValue>
          </DetailRow>
        )}
        {property.noPropertyOwnerReason && (
          <>
            <DetailSectionTitle>
              Reason for your property appraisal request
            </DetailSectionTitle>
            <AdditionalInformation
              text={property.noPropertyOwnerReason}></AdditionalInformation>
          </>
        )}
      </DetailSection>

      {(!!property.numberOfBedrooms ||
        !!property.numberOfBathrooms ||
        !!property.numberOfToilets ||
        !!property.numberOfLivingSpaces) && (
        <>
          <DetailSectionTitle>Spaces</DetailSectionTitle>
          <DetailSection>
            {property.numberOfBedrooms !== undefined && (
              <DetailRow>
                <DetailRowTitle>Bedrooms</DetailRowTitle>
                <DetailRowValue>{property.numberOfBedrooms}</DetailRowValue>
              </DetailRow>
            )}
            {property.numberOfBathrooms !== undefined && (
              <DetailRow>
                <DetailRowTitle>Bathrooms</DetailRowTitle>
                <DetailRowValue>{property.numberOfBathrooms}</DetailRowValue>
              </DetailRow>
            )}
            {property.numberOfToilets !== undefined && (
              <DetailRow>
                <DetailRowTitle>Toilets</DetailRowTitle>
                <DetailRowValue>{property.numberOfToilets}</DetailRowValue>
              </DetailRow>
            )}
            {property.numberOfLivingSpaces !== undefined && (
              <DetailRow>
                <DetailRowTitle>Living Spaces</DetailRowTitle>
                <DetailRowValue>{property.numberOfLivingSpaces}</DetailRowValue>
              </DetailRow>
            )}
          </DetailSection>
        </>
      )}

      {!!property.numberOfCarSpaces && (
        <>
          <DetailSectionTitle>Parking</DetailSectionTitle>
          <DetailSection>
            {property.numberOfCarSpaces !== undefined && (
              <DetailRow>
                <DetailRowTitle>Car Spaces</DetailRowTitle>
                <DetailRowValue>{property.numberOfCarSpaces}</DetailRowValue>
              </DetailRow>
            )}
          </DetailSection>
        </>
      )}

      {(!!property.landSizeSqm ||
        !!property.propertySizeSqm ||
        !!property.propertyAge) && (
        <>
          <DetailSectionTitle>Sizes and age</DetailSectionTitle>
          <DetailSection>
            {!!property.landSizeSqm && (
              <DetailRow>
                <DetailRowTitle>Land Size</DetailRowTitle>
                <DetailRowValue>
                  {property.landSizeSqm}m<sup>2</sup>
                </DetailRowValue>
              </DetailRow>
            )}
            {!!property.propertySizeSqm && (
              <DetailRow>
                <DetailRowTitle>Property Size</DetailRowTitle>
                <DetailRowValue>
                  {property.propertySizeSqm}m<sup>2</sup>
                </DetailRowValue>
              </DetailRow>
            )}
            {!!property.propertyAge && (
              <DetailRow>
                <DetailRowTitle>Property age</DetailRowTitle>
                <DetailRowValue>{property.propertyAge} Years</DetailRowValue>
              </DetailRow>
            )}
          </DetailSection>
        </>
      )}

      {property.additionalFeatures && property.additionalFeatures.length > 0 && (
        <>
          <DetailSectionTitle>Features</DetailSectionTitle>
          <FeaturesList>
            {property.additionalFeatures.map((additionalFeaure, i) => (
              <FeaturesListItem key={i}>
                {additionalFeatureToString[additionalFeaure]}
              </FeaturesListItem>
            ))}
          </FeaturesList>
        </>
      )}

      {property.capitalImprovements && (
        <DetailRow>
          <DetailRowTitle>Capital Improvements</DetailRowTitle>
          <DetailRowValue>
            {capitalImprovementsToString[property.capitalImprovements]}
          </DetailRowValue>
        </DetailRow>
      )}

      {property.additionalInformation && (
        <>
          <DetailSectionTitle>Additional Information</DetailSectionTitle>
          <AdditionalInformation
            text={property.additionalInformation}></AdditionalInformation>
        </>
      )}
    </Container>
  );
};

export default PropertyDetailsDetails;
