import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

export const usePrecacheForAgentSignupComplete: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForNavigationHeader();
  usePrecacheForStepCompleteContent();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  propertyId?: string;
  appraisalId?: string;
}

const AppointmentRequestComplete: FC = () => {
  const { propertyId } = useParams<ExpectedPathParams>();
  const history = useHistory();
  usePrecacheForAgentSignupComplete();

  const onBackClicked = () => {
    history.push(`/property/${propertyId}#appraisals`);
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Appointment Requested | AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Appointment Requested!"
        body="The Agent willl get in touch via phone or text to set up the appointment within the next 24 hours."
        nextButtonLabel="Back to Appraisals"
        onNextButtonClick={onBackClicked}
      />
    </StepPageTemplate.Container>
  );
};

export default AppointmentRequestComplete;
