import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthState,
  LoginCompleteAction,
  LOGIN_COMPLETE,
} from '../features/auth/store/authTypes';
import { useMeLazyQuery } from '../graphql/generated';
import { RootState } from '../store';
import usePrevious from './usePrevious';

const useFetchMeToCompleteLogin: (onCompleted?: () => void) => void = (
  onCompleted,
) => {
  const dispatch = useDispatch();

  const { loginInProgress, authToken } = useSelector<RootState, AuthState>(
    (state) => state.auth,
  );

  const [fetchMe] = useMeLazyQuery({
    onCompleted: (meData) => {
      dispatch({
        type: LOGIN_COMPLETE,
        payload: {
          userType: meData.me.userType,
          isGhostUser: meData.me.isGhostUser,
        },
      } as LoginCompleteAction);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (loginInProgress && authToken) {
      (async () => {
        fetchMe();
      })();
    }
  }, [loginInProgress, authToken]);

  const prevLoginInProgress = usePrevious(loginInProgress);
  useEffect(() => {
    if (!loginInProgress && prevLoginInProgress && authToken) {
      if (onCompleted) onCompleted();
    }
  }, [loginInProgress, prevLoginInProgress, authToken]);
};

export default useFetchMeToCompleteLogin;
