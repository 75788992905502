import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { additionalFeatureToString } from '../../../constants/EnumMappings';
import {
  PropertyAdditionalFeature,
  PropertyCapitalImprovements,
  PropertyQuestionRequirement,
  useUpdatePropertyDetailsMutation,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import PropertySizeQuestionIcon from '../assets/property-size-question.png';
import { usePrecacheForOptionField } from '../components/OptionField';
import PropertyQuestionCheckBoxRowField from '../components/PropertyQuestionCheckBoxRowField';
import PropertyQuestionNumberInputRowField from '../components/PropertyQuestionNumberInputRowField';
import PropertyQuestionRadioRowField from '../components/PropertyQuestionRadioRowField';
import { usePrecacheForPropertyQuestionRowWithField } from '../components/PropertyQuestionRowWithField';
import { usePrecacheForQuestionOptionSelect } from '../components/QuestionOptionSelect';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForSaleInformation } from './SaleInformation';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding: 0 0 30px 0;
`;

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  align-items: center;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  margin: 0 0 30px 0;
`;

function reapplyHeatingAndCoolingToAdditionalFatures(
  selectedAdditinalFeatures: Array<PropertyAdditionalFeature>,
  previousAdditionalFeatures: Array<PropertyAdditionalFeature>,
): Array<PropertyAdditionalFeature> {
  return [
    ...selectedAdditinalFeatures,
    ...previousAdditionalFeatures.filter(
      (feature) =>
        feature === PropertyAdditionalFeature.Cooling ||
        feature === PropertyAdditionalFeature.Heating,
    ),
  ];
}

function filterHeatingAndCoolingFromAdditionalFatures(
  additionalFeatures: Array<PropertyAdditionalFeature>,
): Array<PropertyAdditionalFeature> {
  return additionalFeatures.filter(
    (feature) =>
      feature !== PropertyAdditionalFeature.Cooling &&
      feature !== PropertyAdditionalFeature.Heating,
  );
}

export const usePrecacheForFeatures: () => void = () => {
  usePrecacheImages([PropertySizeQuestionIcon]);

  usePrecacheForOptionField();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
  usePrecacheForPropertyQuestionRowWithField();
  usePrecacheForQuestionOptionSelect();
};

interface FormValues {
  additionalFeatures: Array<PropertyAdditionalFeature>;
  propertySizeSqm?: number;
  landSizeSqm?: number;
  propertyAge?: number;
  capitalImprovements?: PropertyCapitalImprovements;
}

interface ConditionalQuestionProps {
  questionRequirement: PropertyQuestionRequirement;
}

const ConditionalQuestion: FC<ConditionalQuestionProps> = (props) => {
  const { questionRequirement, children } = props;

  return (
    <>
      {questionRequirement !== PropertyQuestionRequirement.NotRequired
        ? children
        : null}
    </>
  );
};

const Features: FC = () => {
  const history = useHistory();
  const [skipToEnd, setSkipToEnd] = useState(false);

  const {
    draftProperty,
    loading: draftPropertyLoading,
  } = useCurrentDraftProperty();

  const [
    updatePropertyDetails,
    { loading: updatePropertyDetailsLoading },
  ] = useUpdatePropertyDetailsMutation();

  const [updateUserDetails] = useUpdateUserDetailsMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftProperty) return;

      await updatePropertyDetails({
        variables: {
          propertyId: draftProperty.id,
          propertyDetails: {
            additionalFeatures: reapplyHeatingAndCoolingToAdditionalFatures(
              values.additionalFeatures,
              draftProperty.additionalFeatures ?? [],
            ),
            propertySizeSqm: values.propertySizeSqm,
            landSizeSqm: values.landSizeSqm,
            propertyAge: values.propertyAge,
            capitalImprovements: values.capitalImprovements,
          },
        },
      });

      const nextPage =
        '/new-property/' + (skipToEnd ? 'account-password' : 'sale-info');

      updateUserDetails({
        variables: {
          nextPage: nextPage,
        },
      });

      history.push(nextPage);
    },
    [draftProperty, skipToEnd],
  );
  const validateFormValues = useCallback(
    (values: FormValues) => {
      const errors: { [key in keyof FormValues]?: string } = {};
      if (
        draftProperty?.propertySizeSqmQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        !values?.propertySizeSqm
      ) {
        errors.propertySizeSqm = 'Required';
      }

      if (
        draftProperty?.landSizeSqmQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        !values?.landSizeSqm
      ) {
        errors.landSizeSqm = 'Required';
      }

      if (
        draftProperty?.propertyAgeQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        !values?.propertyAge
      ) {
        errors.propertyAge = 'Required';
      }

      return errors;
    },
    [draftProperty],
  );

  const initialValues = !draftProperty
    ? {
        propertySizeSqm: undefined,
        landSizeSqm: undefined,
        propertyAge: undefined,
        heatingAndCooling: undefined,
        additionalFeatures: [],
        capitalImprovements: undefined,
      }
    : {
        propertySizeSqm: draftProperty.propertySizeSqm || undefined,
        landSizeSqm: draftProperty.landSizeSqm || undefined,
        propertyAge: draftProperty.propertyAge || undefined,
        additionalFeatures: draftProperty
          ? filterHeatingAndCoolingFromAdditionalFatures(
              draftProperty.additionalFeatures ?? [],
            )
          : [],
        capitalImprovements:
          draftProperty.capitalImprovements || PropertyCapitalImprovements.Yes,
      };

  usePrecacheForSaleInformation();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Additional Features | AgentSpot</title>
      </Helmet>
      <StepNavigationHeader
        title="Property Details"
        stepCount={6}
        stepIndex={3}
        stepName="Additional Features"
      />

      {!draftProperty ? null : (
        <Formik<FormValues>
          initialValues={initialValues}
          validate={validateFormValues}
          onSubmit={onSubmit}>
          {({ submitForm }) => {
            return (
              <>
                <PageContentContainer>
                  <PropertyQuestionCheckBoxRowField
                    fieldName="additionalFeatures"
                    label=""
                    options={[
                      {
                        value: PropertyAdditionalFeature.Pool,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.Pool
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.Spa,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.Spa
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.OutdoorArea,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.OutdoorArea
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.Balcony,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.Balcony
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.BuiltInRobes,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.BuiltInRobes
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.Ensuite,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.Ensuite
                          ],
                      },
                      {
                        value: PropertyAdditionalFeature.Study,
                        label:
                          additionalFeatureToString[
                            PropertyAdditionalFeature.Study
                          ],
                      },
                    ]}
                  />
                  <InputGroup>
                    <PropertyQuestionRadioRowField
                      fieldName="capitalImprovements"
                      label="Capital Improvements"
                      box={4}
                      options={[
                        {
                          value: PropertyCapitalImprovements.Yes,
                          label: 'Yes',
                        },
                        {
                          value: PropertyCapitalImprovements.No,
                          label: 'No',
                        },
                      ]}
                    />
                  </InputGroup>
                  <RowContainer>
                    <ConditionalQuestion
                      questionRequirement={
                        draftProperty.propertySizeSqmQuestionRequirement
                      }>
                      <PropertyQuestionNumberInputRowField
                        fieldName="propertySizeSqm"
                        maxLength={false}
                        label="Property Size"
                        icon={<></>}
                        placeholder=""
                        suffix="sqm"
                      />
                    </ConditionalQuestion>

                    <ConditionalQuestion
                      questionRequirement={
                        draftProperty.landSizeSqmQuestionRequirement
                      }>
                      <PropertyQuestionNumberInputRowField
                        fieldName="landSizeSqm"
                        maxLength={false}
                        label="Land Size"
                        icon={<></>}
                        placeholder=""
                        suffix="sqm"
                      />
                    </ConditionalQuestion>
                    <ConditionalQuestion
                      questionRequirement={
                        draftProperty.propertyAgeQuestionRequirement
                      }>
                      <PropertyQuestionNumberInputRowField
                        fieldName="propertyAge"
                        maxLength={false}
                        label="Property Age"
                        icon={<></>}
                        placeholder=""
                        suffix="years"
                      />
                    </ConditionalQuestion>
                  </RowContainer>
                </PageContentContainer>
                <StepNavigationFooter
                  backHref="/new-property/details"
                  onNextClick={() => {
                    setSkipToEnd(false);
                    submitForm();
                  }}
                  onSkipClick={() => {
                    setSkipToEnd(true);
                    submitForm();
                  }}
                  nextDisabled={
                    draftPropertyLoading || updatePropertyDetailsLoading
                  }
                  loading={updatePropertyDetailsLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default Features;
