import React, { FC } from 'react';
import styled from 'styled-components';
import addBluePlusBlockIconUrl, {
  ReactComponent as AddBluePlusBlock,
} from '../assets/add-blue-plus-block.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Button = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: #ffffff;

  border: 1px solid #e8eaed;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  :hover {
    text-decoration-line: none;
    box-shadow: 0px 2px 4px 1px #d1d1d1;
    color: #1745b0;
  }

  padding: 38px;
`;

const Label = styled.label`
  display: flex;
  flex-grow: 1;
  margin: 19px 0 0 0;

  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.3px;

  :hover {
    cursor: pointer;
  }
`;

export const usePrecacheFoAddItemRowLargeButton: () => void = () => {
  usePrecacheImages([addBluePlusBlockIconUrl]);
};

export interface AddItemRowLargeButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const AddItemRowLargeButton: FC<AddItemRowLargeButtonProps> = (props) => {
  const { label, onClick, className } = props;

  return (
    <Button onClick={onClick} className={className}>
      <AddBluePlusBlock />
      <Label>{label}</Label>
    </Button>
  );
};

export default AddItemRowLargeButton;
