import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import facebookLogoIconUrl from '../../../assets/facebook-ic.svg';
import linkedinLogoIconUrl from '../../../assets/linkedin-ic.svg';
import twitterLogoIconUrl from '../../../assets/twitter-ic.svg';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';

const Container = styled.footer`
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 113px;
  align-items: center;
  justify-content: center;
  padding: 10;
  background-color: #203553;
`;

const ContentsContainer = styled.nav`
  width: 100%;
  max-width: 1170px;
  padding: ${theme.spacing.pageInset};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: center;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column-reverse;
    margin: 44px 0 50px 0;
  }
`;

const Copyright = styled.p`
  opacity: 0.5;
  font-family: Inter, Arial, sans-serif;
  font-size: ${pxToRem(12)};
  color: #ffffff;
  margin: 0 17px 0 0;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    margin: 0;
  }
`;

const FooterLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-size: ${pxToRem(12)};
  color: #ffffff;
  margin: 0 15px 0 15px;
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
  }
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column;
    align-items: center;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    margin-top: 23px;
  }
`;

const ContactUsHeader = styled.p`
  font-family: Poppins, Arial, sans-serif;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  color: #ffffff;
  margin: 0 30px 0 30px;
  letter-spacing: 1px;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    align-items: center;
    margin: 0 10px 4px 10px;
  }
`;

const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    align-items: center;
  }
`;

const ContactUsRow = styled.a`
  font-family: Inter, Arial, sans-serif;
  margin: 0 12px 0 0;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  color: #ffffff;

  :hover {
    color: #ffffff;
    text-decoration-line: underline;
    text-decoration-color: #ffffff;
  }

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    margin: 4px 12px 0 0;
  }
`;

export const usePrecacheForLandingFooter: () => void = () => {
  usePrecacheImages([
    twitterLogoIconUrl,
    facebookLogoIconUrl,
    linkedinLogoIconUrl,
  ]);
};

const LandingFooter: FC = () => {
  return (
    <Container>
      <ContentsContainer>
        <LeftSideContainer>
          <Copyright>© AgentSpot {new Date().getFullYear()}</Copyright>
          <LinksContainer>
            <FooterLink to="/terms-and-conditions">Terms of Service</FooterLink>
            <FooterLink to="/terms-and-conditions">Privacy Policy</FooterLink>
          </LinksContainer>
        </LeftSideContainer>
        <RightSideContainer>
          <ContactUsHeader>CONTACT US</ContactUsHeader>
          <ContactUsContainer>
            <ContactUsRow href="mailto:support@agentspot.com.au">
              support@agentspot.com.au
            </ContactUsRow>
            <ContactUsRow href="tel:1300510510/"> 1300 510 510</ContactUsRow>
          </ContactUsContainer>
        </RightSideContainer>
      </ContentsContainer>
    </Container>
  );
};

export default LandingFooter;
