const theme = {
  colours: {
    lightText: '#444444',
  },
  spacing: {
    pageInset: '20px',
  },
  layout: {
    maxPageWidth: '1280px',
    desktopContentWidth: '770px',
    desktopBreakpoint: '770px',
    desktopContainedContentWidth: '570px',
  },
  style: {
    border: '1px solid #d2d7dd',
  },
};

export default theme;
