import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import arrowUrl, {
  ReactComponent as Arrow,
} from '../assets/property-question-row-arrow.svg';

const Container = styled.div`
  border-top: 1px solid #e8eaed;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 20px 15px 20px 20px;

  :hover {
    cursor: pointer;
  }
`;

const LabelColumn = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  margin: 0 15px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 0;
  margin-bottom: 7px;

  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #58687e;
  letter-spacing: 0.11px;
`;

const Value = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.17px;
`;

const Placeholder = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #1745b0;
  letter-spacing: 0.15px;
`;

const IconContainer = styled.div`
  width: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const usePrecacheForPropertyQuestionRow: () => void = () => {
  usePrecacheImages([arrowUrl]);
};

export interface PropertyQuestionRowProps<T> {
  value?: T;
  placeholder?: string;
  onClick: () => void;
  label: string;
  icon: ReactNode;
  valueToString: (value: T) => string;
}

const PropertyQuestionRow = <T,>(
  props: PropertyQuestionRowProps<T>,
): ReactElement => {
  const { value, placeholder, onClick, icon, label, valueToString } = props;

  return (
    <Container onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <LabelColumn>
        <Label>{label}</Label>
        {value ? (
          <Value>{valueToString(value)}</Value>
        ) : (
          <Placeholder>{placeholder ?? ''}</Placeholder>
        )}
      </LabelColumn>
      <Arrow />
    </Container>
  );
};

export default PropertyQuestionRow;
