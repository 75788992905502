import { Formik } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASActionButton from '../../../components/ASActionButton';
import ASHeader from '../../../components/ASHeader';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import { usePrecacheForSearchSelectField } from '../../../components/SearchSelectField';
import { usePrecacheForStepNavigationFooter } from '../../../components/StepNavigationFooter';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { usePrecacheForUserMediaAddButton } from '../../../components/UserMediaAddButton';
import { usePrecacheUserMediaSquare } from '../../../components/UserMediaSquare';
import theme from '../../../constants/theme';
import { UserMediaFieldValueState } from '../../../containers/UserMediaField';
import {
  MeDocument,
  useMeQuery,
  useUpdateAgentProfileDetailsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import AgentProfileDetailsForm, {
  ProfileDetailsFormValues,
} from './AgentProfileDetailsForm';
import { usePrecacheForAgentSignupSuburbs } from './AgentSignupSuburbs';

const PageContainer = styled(StepPageTemplate.Container)`
  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    /* Provides extra space for agency search */
    padding-bottom: 80px;
  }
`;

const PageContentForm = styled(StepPageTemplate.ContentContainerForm)`
  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    /* Provides extra space for agency search */
    margin-bottom: 140px;
  }
`;

const Header = styled(ASHeader)`
  margin-bottom: 20px;
`;

export const usePrecacheForAgentSignupProfileDetails: () => void = () => {
  usePrecacheUserMediaSquare();
  usePrecacheForUserMediaAddButton();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForSearchSelectField();
  usePrecacheForStepTemplate();
};

const validationSchema = Yup.object().shape({
  profilePictureUserMedia: Yup.object({
    userMedia: Yup.object().required(),
    state: Yup.string().oneOf([UserMediaFieldValueState.Ready]).required(),
  }).required('Profile picture is required'),
  bio: Yup.string().required('Bio is required').min(1).max(500),
  agencyLogoUserMedia: Yup.object({
    userMedia: Yup.object().required(),
    state: Yup.string().oneOf([UserMediaFieldValueState.Ready]).required(),
  }).required('Agency logo is required'),
  agencyName: Yup.string().required('Agency name is required'),
  agencyBio: Yup.string().required('Agency bio is required'),
});

const AgentSignupProfileDetails: FC = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({ skip: !isAuthenticated });
  const [
    updateAgentProfileDetails,
    { loading },
  ] = useUpdateAgentProfileDetailsMutation({
    refetchQueries: [{ query: MeDocument }],
    awaitRefetchQueries: true,
  });

  const onSubmit = useCallback(async (values: ProfileDetailsFormValues) => {
    await updateAgentProfileDetails({
      variables: {
        ...values,

        profilePictureUserMediaId: values.profilePictureUserMedia!.userMedia.id,
        agencyLogoUserMediaId: values.agencyLogoUserMedia!.userMedia.id,
      },
    });

    history.push('/agent/signup/complete');
  }, []);

  const initialValues: ProfileDetailsFormValues = useMemo(
    () =>
      meData?.me?.agentProfile
        ? {
            profilePictureUserMedia: meData.me.agentProfile.profilePicture
              ? {
                  userMedia: meData.me.agentProfile.profilePicture,
                  state: UserMediaFieldValueState.Ready,
                }
              : undefined,
            bio: meData.me.agentProfile.bio ?? '',
            agencyLogoUserMedia: meData?.me.agentProfile?.agencyLogo
              ? {
                  userMedia: meData.me.agentProfile.agencyLogo,
                  state: UserMediaFieldValueState.Ready,
                }
              : undefined,
            agencyName: meData?.me.agentProfile?.agencyName ?? '',
            agencyBio: meData?.me.agentProfile?.agencyBio ?? '',
          }
        : {
            profilePictureUserMedia: undefined,
            bio: '',
            agencyLogoUserMedia: undefined,
            agencyName: '',
            agencyBio: '',
          },
    [!!meData?.me],
  );

  usePrecacheForAgentSignupSuburbs();

  // TODO: block during upload?

  return (
    <PageContainer
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Profile Details | AgentSpot</title>
      </Helmet>

      {meData?.me && (
        <Formik<ProfileDetailsFormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}>
          {({ submitForm, isValid }) => {
            return (
              <>
                <PageContentForm>
                  <Header>Profile details</Header>

                  <AgentProfileDetailsForm />
                </PageContentForm>
                <NavigationFooter
                  rightSideComponent={
                    <ASActionButton
                      onClick={submitForm}
                      disabled={!isValid || loading}
                      visiblyDisabled={!isValid}
                      loading={loading}>
                      Confirm
                    </ASActionButton>
                  }
                />
              </>
            );
          }}
        </Formik>
      )}
    </PageContainer>
  );
};

export default AgentSignupProfileDetails;
