import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationFooter from '../../../components/NavigationFooter';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import theme from '../../../constants/theme';
import {
  MySubscribedSuburbsDocument,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
  useMyDefaultPaymentMethodQuery,
  UserType,
  useSubscribeToSuburbsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useSuburbCart from '../hooks/useSuburbCart';
import AgentAddPaymentDetailsModal, {
  usePrecacheAgentAddPaymentDetailsModal,
} from './AgentAddPaymentDetailsModal';
import SuburbPaymentForm, {
  usePrechacheForSuburbPaymentForm,
} from './SuburbPaymentForm';

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const ConfirmButton = styled(ASActionButton)`
  text-align: left;
  width: 100%;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    width: 225px;
  }
`;

export const usePrecacheForAgentSuburbsCheckout: () => void = () => {
  usePrechacheForSuburbPaymentForm();

  const isAuthenticated = useIsAuthenticated(UserType.Agent);

  useMyDefaultPaymentMethodQuery({ skip: !isAuthenticated });

  usePrecacheAgentAddPaymentDetailsModal();
  usePrecacheForStepTemplate();
};

const AgentSuburbsCheckout: FC = () => {
  const history = useHistory();

  const [cartSuburbIds, , setCartSuburbIds] = useSuburbCart();

  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState<boolean>(
    false,
  );

  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [
    subscribeToSuburbs,
    { error: subscribeToSuburbError, loading: subscribeToSuburbsLoading },
  ] = useSubscribeToSuburbsMutation({
    refetchQueries: [
      'getSuburbFromSearch',
      'getSuburbFromSearchWithNearbySuburbs',
      { query: MySubscribedSuburbsDocument },
    ],
  });

  const onSubmit = useCallback(async () => {
    try {
      const { data: subscribeToSuburbsData } = await subscribeToSuburbs({
        variables: { suburbIds: cartSuburbIds },
      });

      if (subscribeToSuburbsData) {
        setCartSuburbIds([]);
        history.push('/agent/signup/payment-processed');
      }
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, [cartSuburbIds]);

  const onAddPaymentDetailsClick = useCallback(() => {
    setAddPaymentModalOpen(true);
  }, []);

  const onEditPaymentMethodClick = useCallback(() => {
    setAddPaymentModalOpen(true);
  }, []);

  return (
    <Form>
      <StepPageTemplate.ContentContainerForm $extraWide>
        <SuburbPaymentForm
          numberOfFreeTrialsClaimed={
            freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
              .numberOfFreeTrialsClaimed ?? 0
          }
          totalFreeTrialsAllowed={
            freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
              .totalFreeTrialsAllowed ?? 0
          }
          onEditPaymentMethodClick={onEditPaymentMethodClick}
          onAddPaymentDetailsClick={onAddPaymentDetailsClick}
        />
        {subscribeToSuburbError && (
          <ASErrorLabel>
            {apolloErrorToString(subscribeToSuburbError)}{' '}
          </ASErrorLabel>
        )}
      </StepPageTemplate.ContentContainerForm>
      <NavigationFooter
        rightSideComponent={
          <ConfirmButton onClick={onSubmit} loading={subscribeToSuburbsLoading}>
            Confirm
          </ConfirmButton>
        }
      />

      <AgentAddPaymentDetailsModal
        isOpen={addPaymentModalOpen}
        onRequestClose={() =>
          setAddPaymentModalOpen((addPaymentModalOpen) => !addPaymentModalOpen)
        }
      />
    </Form>
  );
};

export default AgentSuburbsCheckout;
