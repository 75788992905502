import { Field, useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

const Container = styled.label<{ checked?: boolean; name?: string }>`
  ${({ name }) =>
    name === 'isPrivacyPolicyAccepted' &&
    'display: flex; flex-direction: row; align-items: center; cursor: pointer; margin: 0 0 12px 0; background: #ffffff; position: relative;'}

  ${({ name }) =>
    name === 'askAgentSpotForMoreAgents' &&
    'display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;'}
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  margin: 0;
  position: relative;
  top: 10px;
`;

const ConditionsText = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 20px 0 0 15px;
`;

const AskAgentSpotText = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 20px 0 0 15px;
`;

const ConditionsLink = styled.a`
  color: #1745b0;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 25px;
`;

const HiddenField = styled(Field).attrs({
  type: 'checkbox',
})`
  display: none;
`;

export const usePrecacheForPrivacyPolicyCheckField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

export interface PrivacyPolicyCheckFieldProps {
  name: string;
}

const PrivacyPolicyCheckField: FC<PrivacyPolicyCheckFieldProps> = (props) => {
  const { name } = props;

  const [{ checked }] = useField({
    name,
    type: 'checkbox',
  });

  return (
    <Container checked={checked} name={name}>
      <HiddenField name={name} />
      <CheckBox checked={checked ?? false} />

      {name === 'askAgentSpotForMoreAgents' && (
        <AskAgentSpotText>
          Would you like us to find additional agents?
        </AskAgentSpotText>
      )}

      {name === 'isPrivacyPolicyAccepted' && (
        <ConditionsText>
          By signing up to AgentSpot, I agree to the{' '}
          <ConditionsLink href="/terms-and-conditions" target="_blank">
            Conditions of use and Privacy policy
          </ConditionsLink>
        </ConditionsText>
      )}
    </Container>
  );
};

export default PrivacyPolicyCheckField;
