import { Formik } from 'formik';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import {
  AgentCommissionBaseFragment,
  AgentMarketingFeeBaseFragment,
} from '../../../graphql/generated';
import AgentCommissionInputModalField, {
  usePrecacheForAgentCommissionInputModalField,
} from '../components/AgentCommissionInputField';
import AgentMarketingFeeInputModalField from '../components/AgentMarketingFeeInputField';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalComparableSales } from './NewAppraisalComparableSales';

const FeeContainer = styled.div`
  margin: 0 0 15px 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const usePrecacheForNewAppraisalFees: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForAgentCommissionInputModalField;
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  id?: string;
}

interface FormValues {
  marketingFee?: AgentMarketingFeeBaseFragment;
  commission?: AgentCommissionBaseFragment;
}

const validationSchema = Yup.object().shape({
  // marketingFee: Yup.object().required(),
  commission: Yup.object().required(),
});

const NewAppraisalFees: React.FC = () => {
  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const onSubmit = useCallback(async () => {
    history.push(
      `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/comparable-sales`,
    );
  }, []);

  const initialValues = !draftPropertyAppraisal
    ? null
    : ({
        marketingFee: draftPropertyAppraisal.marketingFee,
        commission: draftPropertyAppraisal.commission,
      } as FormValues);

  usePrecacheForNewAppraisalComparableSales();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Appraisal Fees | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {!draftPropertyAppraisal ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount>
          {({ submitForm, isValid }) => {
            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <StepNavigationHeader
                    title="Appraise property"
                    stepName="Fees & Commission"
                    stepIndex={2}
                    stepCount={5}
                    stepDescription={
                      (initialValues?.commission ||
                        initialValues?.marketingFee) &&
                      'We have pre-populated your fees and commission from your last sale'
                    }
                  />
                  <FeeContainer>
                    <AgentMarketingFeeInputModalField
                      propertyAppraisalId={draftPropertyAppraisal.id}
                      fieldName="marketingFee"
                    />
                  </FeeContainer>
                  <FeeContainer>
                    <AgentCommissionInputModalField
                      propertyAppraisalId={draftPropertyAppraisal.id}
                      fieldName="commission"
                    />
                  </FeeContainer>
                </StepPageTemplate.ContentContainer>
                <StepNavigationFooter
                  backHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/selling-range`}
                  onNextClick={submitForm}
                  nextDisabled={!isValid}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalFees;
