import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import client from '../../../client';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NumberInputField from '../../../components/NumberInputField';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  PropertyCompleteType,
  PropertyWithAppraisalsFragment,
  PropertyWithAppraisalsFragmentDoc,
  usePropertyQuery,
  usePropertySoldThroughAgentSpotMutation,
  useStaticCashbackAmountQuery,
} from '../../../graphql/generated';
import { pxToRem } from '../../../utils/pxToRem';
import { ReactComponent as GoldCoinStars } from '../assets/gold-coin-stars.svg';
import SaleStatusNavigationHeader from '../components/SaleStatusNavigationHeader';
import { usePrecacheForPropertySold } from './PropertySold';

const Question = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(16)};
  color: #58687e;
  letter-spacing: 0.15px;
  text-align: center;
  margin: 16px 0 20px 0;
  padding: 0;
  max-width: 308px;
  align-self: center;
`;

const GoldCoinStarsStyled = styled(GoldCoinStars)`
  margin: 19px 0;
  align-self: center;
`;

const Description = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(14)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 5px 0 20px 0;
  text-align: center;
  max-width: 308px;
  align-self: center;
`;

const InputLabel = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

interface FormValues {
  accountNumber: string;
  bsb: string;
}

interface ExpectedPathParams {
  propertyId?: string;
  agentId?: string;
}

const validationSchema = Yup.object().shape({
  accountNumber: Yup.string().required('Account number is required'),
  bsb: Yup.string()
    .matches(/^\d{6}$/, 'BSB is invalid')
    .required('BSB is required'),
});

export const usePrecacheForBankDetails: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

const BankDetails: FC = () => {
  const history = useHistory();
  const { propertyId, agentId } = useParams<ExpectedPathParams>();

  const cachedProperty =
    client.readFragment<PropertyWithAppraisalsFragment>({
      id: `Property:${propertyId}`,
      fragment: PropertyWithAppraisalsFragmentDoc,
      fragmentName: 'PropertyWithAppraisals',
    }) ?? undefined;

  const { data: propertyData } = usePropertyQuery({
    variables: { id: typeof propertyId === 'string' ? propertyId : '' },
  });

  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const [
    markPropertySoldThroughAgentSpot,
    { loading: markSoldLoading, error: markSoldError },
  ] = usePropertySoldThroughAgentSpotMutation();

  const property: PropertyWithAppraisalsFragment | undefined =
    propertyData?.property ?? cachedProperty;

  const onSubmit = useCallback(
    async (values: FormValues) => {
      try {
        if (!property) return;

        await markPropertySoldThroughAgentSpot({
          variables: {
            propertyId: propertyId!,
            sellingAgentId: agentId!,
            accountNumber: values.accountNumber,
            bsb: values.bsb,
          },
        });

        history.push(`/property/${propertyId}/sold`);
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
      } catch {
        // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
      }
    },
    [property],
  );

  const initialValues = {
    accountNumber: '',
    bsb: '',
  } as FormValues;

  usePrecacheForPropertySold();

  if (
    property &&
    property.completeType === PropertyCompleteType.SoldUnconditionally
  ) {
    return <Redirect to={`/property/${propertyId}`} />;
  }

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Bank Details | AgentSpot</title>
      </Helmet>
      {cashbackAmount &&
        property &&
        property.propertyAppraisals &&
        property.propertyAppraisals.length > 0 && (
          <Formik<FormValues>
            initialValues={initialValues!}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount>
            {({ submitForm, isValid, touched, errors }) => {
              const submitDisabled = !isValid;
              const error: string | undefined =
                (touched['bsb'] && errors.bsb) ||
                (touched['accountNumber'] && errors.accountNumber) ||
                (markSoldError && apolloErrorToString(markSoldError));

              return (
                <>
                  <StepPageTemplate.ContentContainer>
                    <SaleStatusNavigationHeader
                      backHref={`/property/${propertyId}/sale-status/sold/agent`}
                      pageTitle={`$${Intl.NumberFormat().format(
                        cashbackAmount?.staticCashbackAmount,
                      )} Cash Offer`}
                    />
                    <GoldCoinStarsStyled />
                    <Question>{`Get $${Intl.NumberFormat().format(
                      cashbackAmount?.staticCashbackAmount,
                    )} cash when you sell your property, courtesy of AgentSpot.`}</Question>
                    <Description>{`Just tell us which account to pay it into. Once we confirm the sale with your agent, the $${Intl.NumberFormat().format(
                      cashbackAmount?.staticCashbackAmount,
                    )} is yours!`}</Description>
                    <InputLabel>Account No.</InputLabel>
                    <NumberInputField
                      showErrorAfterTouch
                      fieldName="accountNumber"
                      placeholder="Account No."
                      maxLength={196}
                      useStringValue
                      showThousandSeparator={false}
                    />
                    <InputLabel>BSB</InputLabel>
                    <NumberInputField
                      showErrorAfterTouch
                      fieldName="bsb"
                      placeholder="BSB"
                      maxLength={6}
                      useStringValue
                      showThousandSeparator={false}
                    />
                    {error && <ASErrorLabel>{error}</ASErrorLabel>}
                  </StepPageTemplate.ContentContainer>

                  <NavigationFooter
                    rightSideComponent={
                      <ASActionButton
                        disabled={submitDisabled}
                        visiblyDisabled={submitDisabled}
                        loading={markSoldLoading}
                        onClick={submitForm}>
                        Continue
                      </ASActionButton>
                    }
                  />
                </>
              );
            }}
          </Formik>
        )}
    </StepPageTemplate.Container>
  );
};

export default BankDetails;
