import React, { FC } from 'react';
import ASModal, { usePrecacheForASModal } from '../../../components/ASModal';
import CardDetailsForm from './CardDetailsForm';

interface AgentAddPaymentDetailsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export const usePrecacheAgentAddPaymentDetailsModal: () => void = () => {
  usePrecacheForASModal();
};

const AgentAddPaymentDetailsModal: FC<AgentAddPaymentDetailsModalProps> = (
  props,
) => {
  const { isOpen, onRequestClose } = props;

  return (
    <ASModal isOpen={isOpen} title="Payment" onRequestClose={onRequestClose}>
      <CardDetailsForm onCardAdded={onRequestClose} />
    </ASModal>
  );
};

export default AgentAddPaymentDetailsModal;
