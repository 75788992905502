import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForNoAgents } from './NoAgents';
import { usePrecacheForSelectAgents } from './SelectAgents';

export const usePrecacheForDetailsComplete: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForNavigationHeader();
  usePrecacheForStepCompleteContent();
  usePrecacheForStepTemplate();
};

const DetailsComplete: FC = () => {
  const history = useHistory();
  usePrecacheForNoAgents();
  usePrecacheForSelectAgents();

  const onContinueClicked = () => {
    history.push('/new-property/select-agents');
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Property Details Complete | AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Property Saved"
        body="Please tap continue to select agents and request appraisals."
        caption="Note: The price you will receive is an estimate based on information you have provided"
        nextButtonLabel="Continue"
        onNextButtonClick={onContinueClicked}
      />
    </StepPageTemplate.Container>
  );
};

export default DetailsComplete;
