import QueryString, { ParsedQuery } from 'query-string';
import { useLocation } from 'react-router-dom';

const useQueryParams: <T extends ParsedQuery>() => T = <
  T extends ParsedQuery
>() => {
  const location = useLocation();

  return QueryString.parse(location.search, {
    decode: true,
  }) as T;
};

export default useQueryParams;
