import React, { FC } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import closeModalButtonUel, {
  ReactComponent as CloseModalButton,
} from '../assets/close-modal.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';
import './ASModal.css'; // CSS file used given the library wasn't fully customizable using Styled Components

const HeaderContainer = styled.div<{ $pad: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  margin-bottom: 28px;
  padding: ${(props) => (props.$pad ? '24px 20px 0px 20px' : 'auto')};
`;

const Title = styled.h1`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.25px;
  flex-grow: 1;
  margin: 0;
`;

const BoldTitle = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 'bold';
  font-size: 25px;
  color: #203553;
  flex-grow: 1;
  margin: 0;
`;

const CloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

export const usePrecacheForASModal: () => void = () => {
  usePrecacheImages([closeModalButtonUel]);
};

export interface ASModalProps {
  isOpen: boolean;
  title?: string;
  onRequestClose: () => void;
  expand?: boolean;
  showHeader?: boolean;
  slideOnMobile?: boolean;
  preventScroll?: boolean;
  noPadding?: boolean;
  padHeader?: boolean;
  boldTitle?: boolean;
  agent?: boolean;
}

const ASModal: FC<ASModalProps> = (props) => {
  const {
    isOpen,
    title,
    onRequestClose,
    expand = false,
    agent = false,
    showHeader = true,
    slideOnMobile = true,
    preventScroll = false,
    noPadding = false,
    padHeader = false,
    boldTitle = false,
    children,
  } = props;

  // Re-application of react-modal after open class *after* open (rather than immediately
  // as per current react-modal code) is required to make open animations work on Safari.
  // See: https://stackoverflow.com/questions/65072058/react-modal-animation-doesnt-work-in-safari
  const [wasOpen, setWasOpen] = React.useState(false);

  function afterOpenModal() {
    setTimeout(() => {
      setWasOpen(true);
    }, 0);
  }

  function afterClose() {
    setTimeout(() => {
      setWasOpen(false);
    }, 0);
  }

  return (
    <Modal
      shouldCloseOnEsc
      isOpen={isOpen}
      className={`as-modal ${expand ? 'as-modal--expand' : ''} ${
        agent ? 'as-modal--agent' : ''
      } ${wasOpen ? 'as-modal--after-open-safari' : ''} ${
        slideOnMobile
          ? 'as-modal--slide-on-mobile'
          : 'as-modal--no-slide-on-mobile'
      } ${preventScroll ? 'as-modal--prevent-scroll' : ''}  ${
        noPadding ? 'as-modal--no-padding' : ''
      }`}
      overlayClassName={`as-modal ${expand ? 'as-modal--expand' : ''} ${
        wasOpen ? 'as-modal--after-open-safari' : ''
      }`}
      bodyOpenClassName="as-modal__body--after-open"
      onRequestClose={onRequestClose}
      closeTimeoutMS={200}
      preventScroll
      onAfterOpen={afterOpenModal}
      onAfterClose={afterClose}>
      {showHeader && (
        <HeaderContainer $pad={padHeader}>
          {!boldTitle ? <Title>{title}</Title> : <BoldTitle>{title}</BoldTitle>}

          <CloseButton onClick={onRequestClose}>
            <CloseModalButton />
          </CloseButton>
        </HeaderContainer>
      )}
      {/* {agent && (
        <CloseButtonAgent onClick={onRequestClose}>
          <CloseModalButton />
        </CloseButtonAgent>
      )} */}
      {children}
    </Modal>
  );
};

export default ASModal;
