import { Field, useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

const Container = styled.label<{ checked?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 30px 20px;
  margin: 0 0 12px 0;

  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 8px;

  ${(props) =>
    props.checked
      ? `
    border: 1px solid #1745B0;
    padding: 29px 19px;
    `
      : ''}

  position: relative;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
`;

const LabelContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const NameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.25px;
  margin-right: 20px;
`;

const HiddenField = styled(Field).attrs({
  type: 'radio',
})`
  display: none;
`;

export const usePrecacheForSaleStatusOptionField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

export interface SaleStatusOptionFieldProps {
  fieldName: string;
  fieldId: string;
  label: string;
}

const SaleStatusOptionField: FC<SaleStatusOptionFieldProps> = (props) => {
  const { fieldName, fieldId, label } = props;

  const [{ checked }] = useField({
    name: fieldName,
    value: fieldId ?? undefined,
    type: 'radio',
  });

  return (
    <Container checked={checked}>
      <HiddenField name={fieldName} value={fieldId} />
      <LabelContainer>
        <NameLabel>{label}</NameLabel>
      </LabelContainer>
      <CheckBox checked={checked ?? false} />
    </Container>
  );
};

export default SaleStatusOptionField;
