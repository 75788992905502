import React, { FC } from 'react';
import { usePrecacheForIntroOfferHeader } from '../../../components/IntroOfferHeader';
import IntroOfferTemplate from './IntroOfferTemplate';

const IntroOfferSignupBannerSmall: FC = () => {
  usePrecacheForIntroOfferHeader();

  return <IntroOfferTemplate description={``} />;
};

export default IntroOfferSignupBannerSmall;
