import { AnyAction } from 'redux';
import {
  AuthActions,
  AuthState,
  LOGIN,
  LoginAction,
  LoginCompleteAction,
  LOGIN_BEGIN,
  LOGIN_COMPLETE,
  LOGOUT,
  LOGOUT_BEGIN,
  UpdateLastKnownIsGhostUser,
  UpdateLastKnownUserTypeAction,
  UPDATE_LAST_KNOWN_IS_GHOST_USER,
  UPDATE_LAST_KNOWN_USER_TYPE,
} from './authTypes';

const initialState: AuthState = {
  loginInProgress: false,
  logoutInProgress: false,
  authToken: null,
  lastKnownUserType: null,
  lastKnownIsGhostUser: null,
};

export default function authReducer(
  state = initialState,
  action: AuthActions | AnyAction,
): AuthState {
  switch (action.type) {
    case LOGIN_BEGIN: {
      return {
        ...state,
        loginInProgress: true,
        authToken: null,
        lastKnownUserType: null,
        lastKnownIsGhostUser: null,
      };
    }
    case LOGIN: {
      return {
        ...state,
        loginInProgress: true,
        authToken: (action as LoginAction).payload!.authToken,
      };
    }
    case LOGIN_COMPLETE: {
      return {
        ...state,
        loginInProgress: false,
        lastKnownUserType: (action as LoginCompleteAction).payload!.userType,
        lastKnownIsGhostUser: (action as LoginCompleteAction).payload!
          .isGhostUser,
      };
    }
    case LOGOUT_BEGIN: {
      return {
        ...state,
        logoutInProgress: true,
      };
    }
    case LOGOUT: {
      return {
        loginInProgress: false,
        logoutInProgress: false,
        lastKnownUserType: null,
        authToken: null,
        lastKnownIsGhostUser: null,
      };
    }
    case UPDATE_LAST_KNOWN_USER_TYPE: {
      return {
        ...state,
        lastKnownUserType: (action as UpdateLastKnownUserTypeAction).payload
          .currentUserType,
      };
    }
    case UPDATE_LAST_KNOWN_IS_GHOST_USER: {
      return {
        ...state,
        lastKnownIsGhostUser: (action as UpdateLastKnownIsGhostUser).payload
          .isGhostUser,
      };
    }
    default: {
      return state;
    }
  }
}
