import { AnyAction } from 'redux';
import { AuthState, LOGOUT } from '../../auth/store/authTypes';
import {
  CartActions,
  CartState,
  UpdateSuburbCartAction,
  UPDATE_SUBURB_CART,
} from './cartTypes';

const initialState: CartState = {
  suburbCart: [],
};

export default function authReducer(
  state = initialState,
  action: CartActions | AnyAction,
): CartState | AuthState {
  switch (action.type) {
    case UPDATE_SUBURB_CART: {
      return {
        ...state,
        suburbCart: (action as UpdateSuburbCartAction).payload.suburbIds,
      };
    }

    case LOGOUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
