import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import NavigationHeader from '../../../components/NavigationHeader';
import StepPageTemplate from '../../../components/StepPageTemplate';
import { useGetUserByTokenMutation } from '../../../graphql/generated';
import { LOGIN } from '../store/authTypes';

export const LoadingSpinnerContainer = styled.div`
  margin-top: 50px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ExpectedPathParams {
  token?: string;
}

const ContinueSteps: FC = () => {
  const history = useHistory();
  const { token } = useParams<ExpectedPathParams>();
  const [getUserByToken, { loading }] = useGetUserByTokenMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      (async () => {
        try {
          const { data } = await getUserByToken({
            variables: { continueToken: token },
          });

          if (data?.getUserByToken) {
            dispatch({
              type: LOGIN,
              payload: {
                authToken: data?.getUserByToken?.token,
              },
            });

            if (data?.getUserByToken?.user?.sellerProfile?.nextPage) {
              history.push(data?.getUserByToken?.user?.sellerProfile?.nextPage);
            } else {
              history.push('/');
            }
          }
          /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        } catch {
          toast.error('Token is invalid ', { toastId: 'token_is_invalid' });
          history.push('/');

          // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
        }
      })();
    }
  }, []);

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Continue | AgentSpot</title>
      </Helmet>
      {loading && (
        <LoadingSpinnerContainer>
          <BeatLoader
            color="#0e26d9"
            css="display: flex;
          justify-content: center;
          align-items: center;"
            size={50}
            margin={10}
            loading={loading}
          />
        </LoadingSpinnerContainer>
      )}
    </StepPageTemplate.Container>
  );
};

export default ContinueSteps;
