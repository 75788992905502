import * as dotenv from 'dotenv';

dotenv.config();

const config = {
  API_URL: process.env.REACT_APP_API_URI as string,
  NEXT_APP_URI: process.env.REACT_APP_NEXT_APP_URI as string,
  BUGSNAG_RELEASE_STAGE:
    process.env.REACT_APP_BUGSNAG_RELEASE_STAGE ?? ('development' as string),
  STRIPE_PULISHABLE_ID: process.env.REACT_APP_STRIPE_PULISHABLE_ID as string,
  SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS: Number(
    (process.env
      .REACT_APP_SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS as string) ?? '495',
  ),
  GOOGLE_ANALYTCS_TRACKING_ID:
    (process.env.REACT_APP_GOOGLE_ANALYTCS_TRACKING_ID as string) ?? null,
  MAX_AGENT_SUBURBS: Number(process.env.REACT_APP_MAX_AGENT_SUBURBS ?? 5) ?? 5,
};

export default config;
