import moment from 'moment';
import React, { FC, useState } from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import { ReactComponent as NextButton } from '../assets/calendar-next-button.svg';
import { ReactComponent as PrevButton } from '../assets/calendar-prev-button.svg';
import removeIcon from '../assets/remove-ic.svg';
import ASBlockButton from './ASBlockButton';
import ASModal from './ASModal';
import './Calendar.css';

const SelectButton = styled(ASBlockButton)`
  margin: 90px 0 21px 0;
  width: 100%;
`;

const RemoveButton = styled.button`
  background: url(${removeIcon}) no-repeat;
  border: none;
  background-color: transparent;
  width: 18px;
  height: 18px;
  margin: 0 17px;
`;

const InputButton = styled.div`
  display: flex;
  flex: 1;
`;

const InputShape = styled.div<{ error?: boolean }>`
  display: flex;
  width: 100%;
  height: 55px;
  flex-direction: row;
  align-items: center;
  position: relative;
  border: 1px solid ${(props) => (props.error ? '#fc4e6F' : '#282828')};
  border-radius: 10px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.2px;
  padding: 0 0;
  margin: 0 0 9px 0;
  overflow: hidden;
  background-color: #fff;
`;

const Text = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  border: none;
  padding: 20px;
  margin-top: 12px;
  color: #203553;
`;

const Placeholder = styled(Text)`
  color: #282828;
`;

interface Props {
  initialDate?: Date | undefined;
  onChange: (date: Date | undefined) => void;
  minDate?: Date | undefined;
}

const DatePicker: FC<Props> = ({ initialDate, onChange, minDate }) => {
  const [date, setDate] = useState<Date | undefined>(initialDate);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const toggleDatePicker = () => {
    console.log('toggle');

    setDatePickerOpen(!datePickerOpen);
  };

  const onSave = () => {
    setDatePickerOpen(false);
    onChange(date);
  };

  const onRemoveClick = () => {
    setDate(undefined);
    onChange(undefined);
  };

  return (
    <div>
      <InputShape>
        <InputButton onClick={toggleDatePicker}>
          {initialDate ? (
            <>
              <Text>
                {moment(initialDate.valueOf()).format('dddd Do MMMM')}
              </Text>
            </>
          ) : (
            <>
              <Placeholder>Date</Placeholder>
            </>
          )}
        </InputButton>
        {initialDate && <RemoveButton onClick={onRemoveClick} />}
      </InputShape>
      <ASModal
        isOpen={datePickerOpen}
        title="Select Date"
        boldTitle
        onRequestClose={() => setDatePickerOpen(false)}>
        <Calendar
          value={date}
          formatShortWeekday={(locale, date) =>
            moment(date).format('dd').charAt(0)
          }
          tileDisabled={({ date }) => date.getDay() === 0}
          minDetail={'month'}
          onChange={setDate}
          next2Label={null}
          nextLabel={<NextButton />}
          prevLabel={<PrevButton />}
          prev2Label={null}
          minDate={minDate}
        />
        <SelectButton onClick={onSave}>Select Date</SelectButton>
      </ASModal>
    </div>
  );
};

export default DatePicker;
