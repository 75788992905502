import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCreateGhostUserMutation,
  useMeLazyQuery,
  UserBaseFragment,
  UserType,
} from '../../../graphql/generated';
import useFetchMeToCompleteLogin from '../../../hooks/useFetchMeToCompleteLogin';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import { RootState } from '../../../store';
import { LOGIN, LOGIN_BEGIN } from '../../auth/store/authTypes';

/**
 * Hook to auther return the current user or immediately create a 'ghost' user session.
 */
const useCreateGhostUserIfNecessary: () => {
  isAuthenticated: boolean;
  user: UserBaseFragment | undefined;
} = () => {
  const isAuthenticated = useIsAuthenticated();
  const isAuthenticatedAsSeller = useIsAuthenticated(UserType.Seller);

  const [fetchMe, { data: meData, loading: meLoading }] = useMeLazyQuery();
  const [
    createGhostUser,
    { loading: createGhostUserLoading },
  ] = useCreateGhostUserMutation({
    refetchQueries: [],
  });
  const dispatch = useDispatch();

  // Create ghost user if user is not already authenticated
  useEffect(() => {
    if (!isAuthenticatedAsSeller && !isAuthenticated) {
      dispatch({
        type: LOGIN_BEGIN,
      });
    } else {
      fetchMe();
    }
  }, [isAuthenticatedAsSeller, isAuthenticated]);

  const loginInProgress = useSelector<RootState, boolean>(
    (state) => !!state.auth.loginInProgress,
  );
  useEffect(() => {
    if (loginInProgress) {
      (async () => {
        const { data } = await createGhostUser();

        dispatch({
          type: LOGIN,
          payload: {
            authToken: data?.createGhostUser?.token,
          },
        });
      })();
    }
  }, [loginInProgress]);

  useFetchMeToCompleteLogin();

  return {
    isAuthenticated,
    user: meData?.me,
    loading: meLoading || createGhostUserLoading,
  };
};

export default useCreateGhostUserIfNecessary;
