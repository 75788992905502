import styled from 'styled-components';

const ASHeader = styled.h3`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #203553;
  margin: 28px 0 0 0;
`;

export default ASHeader;
