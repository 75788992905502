import { ApolloError } from '@apollo/client';
import React, { FC, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ASHeader from '../../../components/ASHeader';
import ASSectionHeader from '../../../components/ASSectionHeader';
import SellerMenu, {
  usePrecacheForSellerMenu,
} from '../../../components/SellerMenu';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import { alertOnError } from '../../../constants/ErrorCodes';
import {
  CurrentDraftPropertyDocument,
  MyPropertiesDocument,
  PropertyState,
  useAgentsForPropertyLazyQuery,
  useMeQuery,
  useMyPropertiesQuery,
  usePublishPropertyMutation,
  UserType,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForAddress } from '../../new-property/containers/Address';
import useCurrentDraftProperty from '../../new-property/hooks/useCurrentDraftProperty';
import CreateListingButton, {
  usePrecacheForCreateListingButton,
} from '../components/CreateListingButton';
import NoPropertiesPlaceholder, {
  usePrecacheForNoPropertiesPlaceholder,
} from '../components/NoPropertiesPlaceholder';
import PropertyCard, {
  usePrecacheForPropertyCard,
} from '../components/PropertyCard';
import UnverifiedSeller from '../components/UnverifiedSeller';
import { usePrecacheForPropertyDetails } from './PropertyDetails';

const Header = styled(ASHeader)`
  text-align: left;
`;

const PropertiesInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  width: 100%;
  overflow: visible !important;
`;

export const usePrecacheForSellerDashboard: () => void = () => {
  const isAuthenticated = useIsAuthenticated(UserType.Seller);

  const { data: myPropertiesData } = useMyPropertiesQuery({
    variables: { first: 10 },
    skip: !isAuthenticated,
  });

  usePrecacheImages([
    ...((myPropertiesData?.myProperties?.edges
      ?.map((edge) => edge?.node?.photos[0]?.userMedia.thumbnailUrl)
      ?.filter((url) => url != undefined) as string[]) ?? []),
  ]);

  usePrecacheForCreateListingButton();
  usePrecacheForPropertyCard();
  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForNoPropertiesPlaceholder();
};

const SellerDashboard: FC = () => {
  const history = useHistory();
  const { data: meData } = useMeQuery();

  const { data: myPropertiesData, fetchMore } = useMyPropertiesQuery({
    variables: { first: 10 },
  });

  const { draftProperty } = useCurrentDraftProperty(false);
  const [
    getAgentsForProperty,
    { data: agentsForPropertyData },
  ] = useAgentsForPropertyLazyQuery();

  const handleError = (error: ApolloError): void => {
    if (error.message === 'verification_pending') {
      history.push('/');
    } else {
      alertOnError(error);
    }
  };

  const [publishProperty] = usePublishPropertyMutation({
    refetchQueries: [
      { query: CurrentDraftPropertyDocument },
      { query: MyPropertiesDocument, variables: { first: 10 } },
    ],
    onError: handleError,
  });

  // Fetch agents for property once draft property is loaded
  useEffect(() => {
    if (
      draftProperty &&
      myPropertiesData?.myProperties?.edges &&
      myPropertiesData?.myProperties?.edges.length === 0
    ) {
      getAgentsForProperty({ variables: { propertyId: draftProperty.id } });
    }
  }, [draftProperty]);

  useEffect(() => {
    if (meData?.me?.sellerProfile?.status === 'approved') {
      if (draftProperty && draftProperty.state == PropertyState.Draft) {
        if (
          agentsForPropertyData &&
          agentsForPropertyData.agentsForProperty.length == 0
        ) {
          publishProperty({
            variables: {
              propertyId: draftProperty.id,
              selectedAgents: [],
              askAgentSpotForMoreAgents: false,
            },
          }).then((result) => {
            if (result?.data) {
              history.push('/new-property/no-agents');
            }
          });
        } else {
          history.push('/new-property/select-agents');
        }
      }
    }
  }, [agentsForPropertyData]);

  const onScrollEndReached = useCallback(() => {
    if (myPropertiesData?.myProperties) {
      fetchMore({
        variables: {
          first: 10,
          after: myPropertiesData?.myProperties.pageInfo.endCursor,
        },
      });
    }
  }, [myPropertiesData, fetchMore]);

  usePrecacheForPropertyDetails();
  usePrecacheForAddress();
  usePrecacheForSellerMenu();

  return (
    <SplitDashboardPageTemplate
      menu={<SellerMenu />}
      navigationHeaderContent={<CreateListingButton />}>
      <>
        <Helmet>
          <title>My Properties | AgentSpot</title>
        </Helmet>
        <Header>My Properties</Header>
        {myPropertiesData?.myProperties?.edges &&
          (myPropertiesData?.myProperties?.edges.length > 0 ? (
            <PropertiesInfiniteScroll
              dataLength={myPropertiesData?.myProperties?.edges?.length ?? 0}
              next={onScrollEndReached}
              hasMore={
                myPropertiesData?.myProperties?.pageInfo.hasNextPage ?? false
              }
              loader={null}
              endMessage={null}>
              <ASSectionHeader>Appraisals Requested</ASSectionHeader>
              {myPropertiesData?.myProperties?.edges.map(
                (propertyEdge, i) =>
                  propertyEdge?.node && (
                    <PropertyCard
                      property={propertyEdge?.node}
                      key={propertyEdge?.node?.id ?? i}
                    />
                  ),
              )}
            </PropertiesInfiniteScroll>
          ) : meData?.me?.sellerProfile?.status !== 'approved' ? (
            <UnverifiedSeller />
          ) : (
            <NoPropertiesPlaceholder />
          ))}
      </>
    </SplitDashboardPageTemplate>
  );
};

export default SellerDashboard;
