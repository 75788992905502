import React, { FC } from 'react';
import styled from 'styled-components';
import bronzeBadgeUrl from '../../../assets/agent-badge-bronze.svg';
import diamondBadgeUrl from '../../../assets/agent-badge-diamond.svg';
import goldBadgeUrl from '../../../assets/agent-badge-gold.svg';
import platinumBadgeUrl from '../../../assets/agent-badge-platinum.svg';
import silverBadgeUrl from '../../../assets/agent-badge-silver.svg';
import verifiedBadgeUrl from '../../../assets/agent-badge-verified.svg';
import { AgentBadge } from '../../../graphql/generated';
import useAgentBadgeDetails from '../../../hooks/useAgentBadgeDetails';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';

const Badge = styled.img`
  width: 40px;
  height: 40px;
`;

const BadgeTitle = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(14)};
  color: #203553;
  letter-spacing: 0.17px;
  margin: 0;
`;

const SoldProperties = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(12)};
  color: #a6aeba;
  letter-spacing: 0.15px;
  font-weight: 500;
  margin: 0;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 12px;
`;

export const usePrecacheAgentBadges: () => void = () => {
  usePrecacheImages([
    diamondBadgeUrl,
    platinumBadgeUrl,
    goldBadgeUrl,
    silverBadgeUrl,
    bronzeBadgeUrl,
    verifiedBadgeUrl,
  ]);
};

export interface AgentBadgeRowProps {
  badge?: AgentBadge;
}

const AgentBadgeRow: FC<AgentBadgeRowProps> = ({ badge }) => {
  const agentBadgeDetails = useAgentBadgeDetails(badge);
  const propertiesSoldText = `${agentBadgeDetails?.saleCount}${
    agentBadgeDetails && agentBadgeDetails.saleCount > 1
      ? '+ properties sold'
      : ' property sold'
  }`;

  return (
    <BadgeContainer>
      <Badge src={agentBadgeDetails?.badgeUri} />
      <TextContainer>
        <BadgeTitle>{agentBadgeDetails?.name} member</BadgeTitle>
        <SoldProperties>{propertiesSoldText}</SoldProperties>
      </TextContainer>
    </BadgeContainer>
  );
};

export default AgentBadgeRow;
