import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForAgentDashboard } from '../../properties/containers/AgentDashboard';

export const usePrecacheForAgentSignupSubscriptionConfirmed: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForNavigationHeader();
  usePrecacheForStepCompleteContent();
  usePrecacheForStepTemplate();
};

const AgentSignupSubscriptionConfirmed: FC = () => {
  const history = useHistory();
  usePrecacheForAgentDashboard();

  const onDoneClick = () => {
    history.push('/agent/signup/profile-details');
  };

  return (
    <StepPageTemplate.Container
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Subscription Confirmed | AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Subscription Confirmed!"
        body="We’ve confirmed your subscription, you can now complete your profile"
        nextButtonLabel="Complete profile"
        onNextButtonClick={onDoneClick}
      />
    </StepPageTemplate.Container>
  );
};

export default AgentSignupSubscriptionConfirmed;
