import React, { FC } from 'react';
import styled from 'styled-components';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForAgentDashboard } from '../../properties/containers/AgentDashboard';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const usePrecacheForAgentSuburbsAdded: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForStepCompleteContent();
};

export interface AgentSuburbsAddedProps {
  onDoneClicked: () => void;
  plural: boolean;
}

const AgentSuburbsAdded: FC<AgentSuburbsAddedProps> = ({
  onDoneClicked,
  plural,
}) => {
  usePrecacheForAgentDashboard();

  return (
    <Container>
      <StepCompleteContent
        title={`Suburb${plural ? 's' : ''} added`}
        body={`You're all set up and ready to start receiving appraisals from sellers in your new suburb${
          plural ? 's' : ''
        }`}
        nextButtonLabel="Done"
        onNextButtonClick={onDoneClicked}
      />
    </Container>
  );
};

export default AgentSuburbsAdded;
