import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AddSuburbsButton from '../../../components/AddSuburbsButton';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import config from '../../../config';
import {
  SuburbBaseFragment,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
} from '../../../graphql/generated';
import IntroOfferSignupBannerSmall from '../components/IntroOfferSignupBannerSmall';
import SelectedSuburbCard, {
  usePrecacheForSelectedSuburbCard,
} from '../components/SelectedSuburbCard';
import useSuburbCart from '../hooks/useSuburbCart';
import AgentSignupSuburbSearch from './AgentSignupSuburbSearch';
import { usePrecacheForAgentSignupSummary } from './AgentSignupSummary';

const SelectSuburbsContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const usePrecacheForAgentSignupSuburbs: () => void = () => {
  useSuburbCart();
  usePrecacheForSelectedSuburbCard();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

const AgentSignupSuburbs: FC = () => {
  const history = useHistory();

  const [cartSuburbIds, cartSuburbs, setCartSuburbIds] = useSuburbCart();

  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [suburbSearchModalOpen, setSuburbSearchModalOpen] = useState(false);

  const onNextClick = useCallback(async () => {
    history.push('/agent/signup/summary');
  }, []);

  const onAddSuburbsClick = useCallback(() => {
    setSuburbSearchModalOpen(true);
  }, []);

  const onRemoveSuburbClick = useCallback(
    (suburb: SuburbBaseFragment) => {
      setCartSuburbIds(
        cartSuburbIds.filter((cartSuburbId) => cartSuburbId !== suburb.id),
      );
    },
    [cartSuburbIds],
  );

  usePrecacheForAgentSignupSummary();

  return (
    <StepPageTemplate.Container
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Service Suburbs | AgentSpot</title>
      </Helmet>
      <StepPageTemplate.ContentContainer>
        <StepNavigationHeader
          title="Sign up"
          stepName="Service suburb"
          stepIndex={2}
          stepCount={2}
          stepDescription={`Which suburbs do you want to service? You can select up to ${config.MAX_AGENT_SUBURBS} suburbs.`}
        />

        <IntroOfferSignupBannerSmall />

        <AddSuburbsButton
          maxSuburbs={Number(config.MAX_AGENT_SUBURBS)}
          suburbsAdded={cartSuburbIds.length}
          onClick={onAddSuburbsClick}
        />

        <SelectSuburbsContainer>
          {cartSuburbs.map((suburb, index) => {
            const isFreeTrial =
              (freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                .totalFreeTrialsAllowed ?? 0) -
                (freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                  .numberOfFreeTrialsClaimed ?? 0) -
                index >
              0;

            return (
              <SelectedSuburbCard
                suburb={suburb}
                onRemoveSuburbClick={onRemoveSuburbClick}
                isFreeTrial={isFreeTrial}
                key={suburb.id}
              />
            );
          })}
        </SelectSuburbsContainer>

        <AgentSignupSuburbSearch
          onRequestClose={() => setSuburbSearchModalOpen(false)}
          isOpen={suburbSearchModalOpen}
        />
      </StepPageTemplate.ContentContainer>
      <StepNavigationFooter
        backHref="/agent/signup/basic-details"
        onNextClick={onNextClick}
        nextDisabled={cartSuburbIds.length === 0}
      />
    </StepPageTemplate.Container>
  );
};

export default AgentSignupSuburbs;
