import Bugsnag from '@bugsnag/js';
import config from '../config';

const logError: (error: Error) => void = (error) => {
  // TODO: filter for expected errors (e.g. invalid credentials)
  if (config.BUGSNAG_RELEASE_STAGE == 'development') {
    console.log({ error });
  } else {
    Bugsnag.notify(error);
  }
};

export default logError;
