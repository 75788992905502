import React, { FC, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ASBlockButton from '../../../components/ASBlockButton';
import ASCaption from '../../../components/ASCaption';
import ASHeader from '../../../components/ASHeader';
import ASParagraph from '../../../components/ASParagraph';
import NavigationHeader from '../../../components/NavigationHeader';
import StepNavigationFooter from '../../../components/StepNavigationFooter';
import StepPageTemplate from '../../../components/StepPageTemplate';
import {
  useMeLazyQuery,
  useResendVerificationEmailMutation,
  useStaticCashbackAmountQuery,
} from '../../../graphql/generated';
import CashbackReminder from '../components/CashbackReminder';

const Header = styled(ASHeader)<{ description: boolean }>`
  margin-bottom: ${(props) => (props.description ? '7px' : '23px')};
  text-align: center;
`;

const StepDescription = styled(ASCaption)`
  margin: 0 20px 23px 20px;
`;

const Paragraph = styled(ASParagraph)`
  margin-top: 40px;
`;

const ContactUS = styled.a`
  color: #184cb8;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CashbackContainerReminder = styled.div`
  margin: 20px 0 0 0;
`;

const Button = styled(ASBlockButton)``;

const SellerEmailVerification: FC = () => {
  const history = useHistory();
  const [getMe, { data: meData }] = useMeLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const [
    resendVerificationEmail,
    { loading },
  ] = useResendVerificationEmailMutation();

  const sendVerificationEmail = async () => {
    const { data } = await resendVerificationEmail();
    if (data) {
      toast('Verification email sent successfully.');
    }
  };

  useEffect(() => {
    (async () => {
      if (meData?.me) {
        if (meData.me.isEmailVerified) {
          history.push('/new-property/select-agents');
        } else {
          toast('Please verify your email to continue.');
        }
      }
    })();
  }, [meData?.me]);

  const onContinueClicked = useCallback(async () => {
    getMe();
  }, []);

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Email Verification | AgentSpot</title>
      </Helmet>
      <StepPageTemplate.ContentContainer>
        <Header description={true}>Email Verification</Header>
        <StepDescription>Please verify your email</StepDescription>
        <Paragraph>A verification link has been sent to your email.</Paragraph>
        <ASCaption>
          If you haven&apos;t received your verification email,
          <br />
          Please check your spam folder, or{' '}
          <ContactUS href="mailto:support@agentspot.com.au" target="_blank">
            contact us.
          </ContactUS>
        </ASCaption>
        <ButtonWrapper>
          <Button onClick={() => sendVerificationEmail()}>Resend email</Button>
        </ButtonWrapper>
      </StepPageTemplate.ContentContainer>
      <StepNavigationFooter
        backHref={null}
        hideBackButton
        onNextClick={onContinueClicked}
        nextDisabled={loading}
        loading={loading}
        nextButtonLabel="Continue"
      />
      <CashbackContainerReminder>
        <CashbackReminder
          cashbackAmount={cashbackAmount?.staticCashbackAmount}
        />
      </CashbackContainerReminder>
    </StepPageTemplate.Container>
  );
};

export default SellerEmailVerification;
