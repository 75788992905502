import { Formik } from 'formik';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import editIconUri, {
  ReactComponent as EditIcon,
} from '../../../assets/edit-ic.svg';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import NumberInputField from '../../../components/NumberInputField';
import PasswordInputField from '../../../components/PasswordInputField';
import SellerMenu, {
  usePrecacheForSellerMenu,
} from '../../../components/SellerMenu';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import theme from '../../../constants/theme';
import {
  useMeQuery,
  useUpdateUserDetailsMutation,
  useUpdateUserPasswordMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import CreateListingButton, {
  usePrecacheForCreateListingButton,
} from '../../properties/components/CreateListingButton';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${theme.layout.desktopContainedContentWidth};
  overflow: visible;
`;

const EditButton = styled.button`
  display: flex;
  border: none;
  padding: 0;
  margin: 0 20px 10px 0;
  background: none;
  align-items: flex-end;
  min-height: 20px;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const EditIconStyled = styled(EditIcon)`
  margin-right: 10px;
  margin-bottom: 2px;
`;

const ButtonText = styled.p<{ color: string }>`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => props.color ?? '#1745B0'};
  letter-spacing: 0.2px;
  margin: 0;
  padding: 0;
`;

const EditingButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0 0 0;
`;

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

const LegalLink = styled(Link)`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 38px 0 0 0;
`;

const Header = styled(ASHeader)`
  text-align: left;
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}/, 'Phone number is invalid')
    .required('Phone number is required'),
  password: Yup.string(),
});

export const usePrecacheForSellerProfileSettings: () => void = () => {
  usePrecacheImages([editIconUri]);

  usePrecacheForCreateListingButton();
  usePrecacheForSplitDashboardPageTemplate();
};

interface ProfileDetailsFormValues {
  name: string;
  email: string;
  mobileNumber: string;
  password?: string;
  // status?: string | null | undefined;
}

const SellerProfileSettings: FC = () => {
  usePrecacheForSellerMenu();
  usePrecacheForSellerProfileSettings();

  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({ skip: !isAuthenticated });

  const [
    updateUserDetails,
    { loading: updatingUserDetails, error: updateDetailsError },
  ] = useUpdateUserDetailsMutation();

  const [
    updateUserPassword,
    { loading: updatingPassword, error: updatePasswordError },
  ] = useUpdateUserPasswordMutation();

  const [editEnabled, setEditEnabled] = useState(false);

  const initialValues: ProfileDetailsFormValues = meData?.me?.sellerProfile
    ? {
        name: meData.me.sellerProfile.name!,
        mobileNumber: `0${
          parsePhoneNumberFromString(meData.me.mobileNumber ?? '', 'AU')
            ?.nationalNumber
        }`,
        email: meData.me.email!,
        password: '1111111111',
        // status: meData.me.sellerProfile.status?.toUpperCase(),
      }
    : {
        name: '',
        mobileNumber: '',
        email: '',
        password: '1111111111',
        // status: '',
      };

  const onSubmit = useCallback(async (values: ProfileDetailsFormValues) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(
        values.mobileNumber,
        'AU',
      );

      if (values.mobileNumber && !parsedNumber) return; // This shouldn't happen thanks to our form validation

      await updateUserDetails({
        variables: {
          email: values.email,
          mobileNumber: parsedNumber?.number as string,
          name: values.name!,
        },
      });

      if (values.password) {
        await updateUserPassword({
          variables: {
            password: values.password,
          },
        });
      }

      setEditEnabled(false);

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  return (
    <SplitDashboardPageTemplate
      menu={<SellerMenu />}
      navigationHeaderContent={<CreateListingButton />}>
      <Helmet>
        <title>Profile | AgentSpot</title>
      </Helmet>
      <Header>Profile</Header>
      <FormContainer>
        {meData && (
          <Formik<ProfileDetailsFormValues>
            initialValues={initialValues!}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount={true}
            validateOnChange>
            {({ submitForm, touched, errors, resetForm, setFieldValue }) => {
              const error: string | undefined =
                (touched['name'] && errors.name) ||
                (touched['email'] && errors.email) ||
                (touched['mobileNumber'] && errors.mobileNumber) ||
                (touched['password'] && errors.password) ||
                (updateDetailsError &&
                  apolloErrorToString(updateDetailsError)) ||
                (updatePasswordError &&
                  apolloErrorToString(updatePasswordError));

              return (
                <>
                  <EditingButtonsContainer>
                    {editEnabled ? (
                      <>
                        <EditButton
                          onClick={submitForm}
                          disabled={updatingUserDetails || updatingPassword}>
                          <ButtonText color="#1745B0">Save Edits</ButtonText>
                        </EditButton>
                        <EditButton
                          disabled={updatingUserDetails || updatingPassword}
                          onClick={() => {
                            resetForm();
                            setEditEnabled(false);
                          }}>
                          <ButtonText color="#FF6481">Discard Edits</ButtonText>
                        </EditButton>
                      </>
                    ) : (
                      <EditButton
                        onClick={() => {
                          setFieldValue('password', '');
                          setEditEnabled(true);
                        }}
                        disabled={updatingUserDetails || updatingPassword}>
                        <EditIconStyled />
                        <ButtonText color="#1745B0">Edit details</ButtonText>
                      </EditButton>
                    )}
                  </EditingButtonsContainer>

                  <InputLabel>Full name</InputLabel>
                  <TextInputField
                    showErrorAfterTouch
                    name="name"
                    placeholder="Full name"
                    maxLength={196}
                    disabled={!editEnabled}
                  />

                  <InputLabel>Mobile number</InputLabel>
                  <NumberInputField
                    showErrorAfterTouch
                    fieldName="mobileNumber"
                    placeholder="Mobile number"
                    thousandSeparator={false}
                    allowLeadingZeros
                    isNumericString
                    useStringValue
                    disabled={!editEnabled}
                  />

                  <InputLabel>Email</InputLabel>
                  <TextInputField
                    showErrorAfterTouch
                    disabled={!editEnabled}
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    maxLength={196}
                  />

                  <InputLabel>Password</InputLabel>
                  <PasswordInputField
                    name="password"
                    placeholder="Password"
                    disabled={!editEnabled}
                  />

                  {/* <InputLabel>Status</InputLabel>
                  <TextInputField
                    showErrorAfterTouch
                    name="status"
                    placeholder="Status"
                    disabled={true}
                  /> */}
                  {error && <ASErrorLabel>{error}</ASErrorLabel>}

                  <LegalLink to="/terms-and-conditions">
                    View Legal Information
                  </LegalLink>
                </>
              );
            }}
          </Formik>
        )}
      </FormContainer>
    </SplitDashboardPageTemplate>
  );
};

export default SellerProfileSettings;
