import { useSelector } from 'react-redux';
import { RootState } from '../store';

/**
 * Tracks the user's last known isGhostUser state to assist with initial rendering of the application before the me query has returned
 */
const useLastKnownIsGhostUser: () => boolean | null = () =>
  useSelector<RootState, boolean | null>(
    (state) => state.auth.lastKnownIsGhostUser,
  );

export default useLastKnownIsGhostUser;
