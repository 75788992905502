import { ParsedQuery } from 'query-string';
import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import AgentMenu, {
  usePrecacheForAgentMenu,
} from '../../../components/AgentMenu';
import ASHeader from '../../../components/ASHeader';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import TabBar from '../../../components/TabBar';
import useQueryParams from '../../../hooks/useQueryParams';
import AgentEditAgentProfile, {
  usePrecacheForAgentEditAgentProfile,
} from './AgentEditAgentProfile';
import AgentEditDetails from './AgentEditDetails';
import AgentEditSuburbs from './AgentEditSuburbs';

const Header = styled(ASHeader)`
  text-align: left;
`;

const TabsContainer = styled.div`
  margin-top: 20px;
`;

const DETAILS_TAB_INDEX = 0;
const PROFILE_TAB_INDEX = 1;
const SUBURBS_TAB_INDEX = 2;

export const usePrecacheForAgentProfileSettings: () => void = () => {
  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForAgentEditAgentProfile();
};

interface ExpectedQueryParams extends ParsedQuery {
  tab: string | string[] | null;
}

const AgentProfileSettings: FC = () => {
  usePrecacheForAgentMenu();

  const { tab } = useQueryParams<ExpectedQueryParams>();

  const activeTabIndex = useMemo(() => {
    // TODO: (technical debt) make this be part of the standard tab bar!
    switch (tab) {
      case 'details':
        return 0;
      case 'profile':
        return 1;
      case 'suburbs':
        return 2;
      default:
        return 0;
    }
  }, [tab]);

  return (
    <SplitDashboardPageTemplate menu={<AgentMenu />}>
      <Helmet>
        <title>Profile | AgentSpot</title>
      </Helmet>

      <Header>Profile</Header>
      <TabBar
        tabs={[
          {
            href: '/agent/profile-settings/?tab=details',
            label: 'Details',
          },
          {
            href: '/agent/profile-settings/?tab=profile',
            label: 'Agent Profile',
          },
          {
            href: '/agent/profile-settings/?tab=suburbs',
            label: 'Suburbs',
          },
        ]}
        selectedTabIndex={activeTabIndex}
      />
      <TabsContainer>
        {activeTabIndex === DETAILS_TAB_INDEX && <AgentEditDetails />}
        {activeTabIndex === PROFILE_TAB_INDEX && <AgentEditAgentProfile />}
        {activeTabIndex === SUBURBS_TAB_INDEX && <AgentEditSuburbs />}
      </TabsContainer>
    </SplitDashboardPageTemplate>
  );
};

export default AgentProfileSettings;
