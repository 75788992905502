import React, { FC } from 'react';
import styled from 'styled-components';
import introOfferIconUrl, {
  ReactComponent as IntroOfferIcon,
} from '../assets/intro-offer-ic.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';
import { pxToRem } from '../utils/pxToRem';

const Container = styled.div`
  display: flex;
  flex: 0;
  border-radius: 100px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.22);
`;

const Title = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(12)};
  font-weight: 700;
  color: #ffffff;
  line-height: 24px;
  margin: 0 0 0 10px;
`;

export const usePrecacheForIntroOfferHeader: () => void = () => {
  usePrecacheImages([introOfferIconUrl]);
};

const IntroOfferHeader: FC = () => {
  return (
    <Container>
      <IntroOfferIcon />
      <Title>Free to join</Title>
    </Container>
  );
};

export default IntroOfferHeader;
