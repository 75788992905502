import { Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { FC, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASCaption from '../../../components/ASCaption';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NumberInputField from '../../../components/NumberInputField';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  useMeQuery,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

const Header = styled(ASHeader)<{ description: boolean }>`
  margin-bottom: ${(props) => (props.description ? '7px' : '23px')};
  text-align: center;
`;

const StepDescription = styled(ASCaption)`
  margin: 0 20px 23px 20px;
`;

export const usePrecacheForSellerSignupBasicDetails: () => void = () => {
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface FormValues {
  name: string;
  email: string;
  mobileNumber: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}/, 'Phone number is invalid')
    .required('Phone number is required'),
});

const SellerSignupBasicDetails: FC = () => {
  const history = useHistory();

  const { data: meData } = useMeQuery();

  const [
    updateUserDetails,
    { loading, error: updateDetailsError },
  ] = useUpdateUserDetailsMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(
        values.mobileNumber,
        'AU',
      );

      if (!parsedNumber) return;

      await updateUserDetails({
        variables: {
          ...values,
          mobileNumber: parsedNumber.number as string,
        },
      });

      history.push('/new-property/account-password');

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  const initialValues: FormValues = useMemo(
    () => ({
      mobileNumber: '',
      name: '',
      email: '',
    }),
    [],
  );

  if (meData?.me.isGhostUser === false) {
    return <Redirect to="/" />;
  }

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Create Account | AgentSpot</title>
      </Helmet>
      <Formik<FormValues>
        initialValues={initialValues!}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnMount={true}>
        {({ submitForm, errors, isValid, touched }) => {
          const error: string | undefined =
            (touched['name'] && errors.name) ||
            (touched['email'] && errors.email) ||
            (touched['mobileNumber'] && errors.mobileNumber) ||
            (updateDetailsError && apolloErrorToString(updateDetailsError));

          return (
            <>
              <StepPageTemplate.ContentContainer>
                <Header description={true}>Create Account</Header>
                <StepDescription>To save your property details</StepDescription>

                <InputLabel>Full name</InputLabel>
                <TextInputField
                  showErrorAfterTouch
                  name="name"
                  placeholder="Enter full name"
                  maxLength={196}
                />

                <InputLabel>Email</InputLabel>
                <TextInputField
                  showErrorAfterTouch
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                  maxLength={196}
                />

                <InputLabel>Phone number</InputLabel>
                <NumberInputField
                  showErrorAfterTouch
                  fieldName="mobileNumber"
                  placeholder="Phone number"
                  thousandSeparator={false}
                  allowLeadingZeros
                  isNumericString
                  useStringValue
                />
                {error && <ASErrorLabel>{error}</ASErrorLabel>}
              </StepPageTemplate.ContentContainer>
              <StepNavigationFooter
                backHref="/new-property/additional-info"
                onNextClick={submitForm}
                nextDisabled={!isValid || loading}
                loading={loading}
                nextButtonLabel="Save Property"
              />
            </>
          );
        }}
      </Formik>
    </StepPageTemplate.Container>
  );
};

export default SellerSignupBasicDetails;
