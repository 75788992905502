import React, { FC, useState } from 'react';
import styled from 'styled-components';
import infoIconUri, { ReactComponent as InfoIcon } from '../assets/c-info.svg';
import appointmentRequestsIconUrl, {
  ReactComponent as AppointmentRequestsIcon,
} from '../assets/calendar-menu-ic.svg';
import myPropertiesHouseIconUrl, {
  ReactComponent as MyPropertiesHouseIcon,
} from '../assets/my-properties-house.svg';
import profileSettingsSillhouetteIconUrl, {
  ReactComponent as ProfileSettingsSillhouetteIcon,
} from '../assets/profile-settings-silhouette.svg';
import { usePrecacheForAgentProfileSettings } from '../features/agent/containers/AgentProfileSettings';
import { usePrecacheForAgentDashboard } from '../features/properties/containers/AgentDashboard';
import {
  useMeQuery,
  useUnreadAppointmentRequestsCountQuery,
} from '../graphql/generated';
import useAgentBadgeDetails from '../hooks/useAgentBadgeDetails';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import usePrecacheImages from '../hooks/usePrecacheImages';
import AgentProfilePhoto from './AgentProfilePhoto';
import ASModal from './ASModal';
import MemberBadgeInfo from './MemberBadgeInfo';
import MenuLink from './MenuLink';
import MenuTemplate from './MenuTemplate';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 146px;
  margin: 0 0 50px 0;
`;

const AgencyNameLabel = styled.div`
  margin: 20px 0 15px 0;

  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #1745b0;
`;

const NameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: #203553;
  letter-spacing: 0.15px;
`;

const ProfileSettingsSillhouette = styled(ProfileSettingsSillhouetteIcon)`
  width: 17px;
  height: 19px;
`;

const MyPropertiesHouse = styled(MyPropertiesHouseIcon)`
  width: 17px;
  height: 18px;
`;

const PhotoContainer = styled.div`
  display: flex;
  flex: 0;
  position: relative;
`;

const BadgeContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  background-color: #fff;
  border-radius: 20px;
`;

const Badge = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 20px;
`;

export const InfoButton = styled.button`
  border: none;
  background: transparent;
  align-self: flex-end;
  margin: 0 0 9px 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
    color: transparent;
  }
`;

const InfoIconStyled = styled(InfoIcon)``;

/**
 * WARNING: Using this inside the precache hook of one of the linked pages will cause an infinite loop!
 *
 * Use inside render of any page showing this menu but do not include it in their precache hook.
 */
export const usePrecacheForAgentMenu: () => void = () => {
  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({
    skip: !isAuthenticated,
  });

  usePrecacheImages(
    [
      meData?.me?.agentProfile?.profilePicture?.url,
      meData?.me?.agentProfile?.profilePicture?.thumbnailUrl,
    ].filter((url) => url != undefined) as string[],
  );

  usePrecacheImages([
    profileSettingsSillhouetteIconUrl,
    appointmentRequestsIconUrl,
    myPropertiesHouseIconUrl,
    infoIconUri,
  ]);

  usePrecacheForAgentDashboard();
  usePrecacheForAgentProfileSettings();
};

const AgentMenu: FC = () => {
  const { data: meData } = useMeQuery();
  const agentProfile = meData?.me.agentProfile;
  const {
    data: unreadAppointmentRequestsCount,
  } = useUnreadAppointmentRequestsCountQuery();

  const agentBadgeDetails = useAgentBadgeDetails(agentProfile?.badge);
  const [badgeInfoModalOpen, setBadgeInfoModalOpen] = useState(false);

  return (
    <>
      <MenuTemplate
        profileSection={
          <ProfileContainer>
            {agentProfile && (
              <>
                <PhotoContainer>
                  <AgentProfilePhoto agent={agentProfile} />
                  {agentBadgeDetails?.badgeUri && (
                    <>
                      <BadgeContainer>
                        <Badge src={agentBadgeDetails?.badgeUri} />
                      </BadgeContainer>
                      <InfoButton onClick={() => setBadgeInfoModalOpen(true)}>
                        <InfoIconStyled />
                      </InfoButton>
                    </>
                  )}
                </PhotoContainer>
                <AgencyNameLabel>{agentProfile.agencyName}</AgencyNameLabel>
                <NameLabel>{agentProfile?.name ?? 'Anonymous User'} </NameLabel>
              </>
            )}
          </ProfileContainer>
        }
        links={
          <>
            <MenuLink
              label="My Properties"
              icon={<MyPropertiesHouse />}
              href="/"
            />
            <MenuLink
              label="Appointment Requests"
              icon={<AppointmentRequestsIcon />}
              href="/agent/appointment-requests"
              alertCount={Number(
                unreadAppointmentRequestsCount?.unreadAppointmentRequestsCount,
              )}
            />
            <MenuLink
              label="Profile Settings"
              icon={<ProfileSettingsSillhouette />}
              href="/agent/profile-settings"
            />
          </>
        }
      />
      <ASModal
        isOpen={badgeInfoModalOpen}
        showHeader={true}
        title="Member badges"
        boldTitle
        onRequestClose={() => setBadgeInfoModalOpen(false)}>
        <MemberBadgeInfo onRequestClose={() => setBadgeInfoModalOpen(false)} />
      </ASModal>
    </>
  );
};

export default AgentMenu;
