import React, { FC } from 'react';
import styled from 'styled-components';
import blockDirectionLeftIconUrl, {
  ReactComponent as BlockDirectionLeftIcon,
} from '../assets/block-direction-left.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const NavButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.2px;
  background-color: transparent;
  padding: 0;
  margin: 0;

  :hover {
    text-decoration-line: underline;
    text-decoration-color: #a6aeba;
  }

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    margin: 20px 0 0 0;
  }
`;

const BackLabel = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin: 0;
  color: #a6aeba;
  padding-left: 13px;
`;

const BlockDirectionLeft = styled(BlockDirectionLeftIcon)`
  width: 25px;
  height: 25px;
`;

export const usePrecacheForBackNavButton: () => void = () => {
  usePrecacheImages([blockDirectionLeftIconUrl]);
};
export interface BackNavButtonProps {
  onClick: () => void;
}

const BackNavButton: FC<BackNavButtonProps> = (props) => {
  const { onClick } = props;

  return (
    <NavButton onClick={onClick}>
      <BlockDirectionLeft />
      <BackLabel>Back</BackLabel>
    </NavButton>
  );
};

export default BackNavButton;
