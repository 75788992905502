import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import backgroundImage from '../assets/AS_BG.jpg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  padding-bottom: 40px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: flex-start;
  }
`;

const ContainerBackdrop = styled.div`
  display: none;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: initial;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;
    background-color: #07134f;
    /* In development it is common for this to flash when navigating, this doesn't seem to occur in the live environments */
    background-image: url(${backgroundImage});
    background-size: cover;
    background-position: center;
  }
`;

const PageContentContainerOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  position: relative;
  width: 100%;
  flex: 1;

  overflow: visible;
  background-color: #fff;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    border: ${theme.style.border};
    border-radius: 0px;
    overflow: visible;
    max-width: ${theme.layout.desktopContentWidth};
    min-height: 700px;
    min-height: min(80vh, 700px);
    min-height: min(calc(85vh - 80px), 700px);
    margin: 40px 0 80px 0;
    flex: initial;
  }
`;

interface PageContentContainerProps {
  $extraWide?: boolean;
}

const PageContentContainer = styled.div<PageContentContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  padding: 0 20px 32px 20px;
  width: 100%;
  max-width: ${(props) =>
    props.$extraWide ? '670px' : theme.layout.desktopContainedContentWidth};
  overflow: visible;
  flex: 1;
  background-color: #fff;
`;

const PageContentContainerForm = styled.form<PageContentContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  padding: 0 20px 32px 20px;

  width: 100%;
  max-width: ${(props) =>
    props.$extraWide ? '670px' : theme.layout.desktopContainedContentWidth};
  overflow: visible;
  background-color: #fff;
`;

const DesktopHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    display: none;
  }
`;

export const usePrecacheForStepTemplate: () => void = () => {
  usePrecacheImages([backgroundImage]);
};

export interface StepPageContainerProps {
  desktopHeader: ReactNode;
  className?: string;
}

const StepPageContainer: FC<StepPageContainerProps> = (props) => {
  const { desktopHeader, children, className } = props;

  return (
    <Container className={className}>
      <ContainerBackdrop />
      {desktopHeader && (
        <DesktopHeaderContainer>{desktopHeader}</DesktopHeaderContainer>
      )}
      <PageContentContainerOuter>{children}</PageContentContainerOuter>
    </Container>
  );
};

/**
 * Container and contents container have been seperated to allow for use cases where the
 * contents-container may not be renderable until page has finished loading.
 *
 * It also makes it easier for pages to customise the inner container if necessary.
 */
const StepPageTemplate = {
  Container: StepPageContainer,
  ContentContainer: PageContentContainer,
  ContentContainerForm: PageContentContainerForm,
};
export default StepPageTemplate;
