import { Field, Form, Formik, useField } from 'formik';
import React, { FC, ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

const OptionsContainer = styled(Form)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    grid-template-columns: auto auto auto auto auto;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 10px 21px 30px 20px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 5px 0 8px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.11px;
`;

const OptionLabel = styled.div<{ checked: boolean }>`
  ${(props) =>
    props.checked
      ? `background-color: #1745B0; color: #ffffff`
      : `color: #58687e;`};
  flex-grow: 1;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  width: 96px;
  height: 70px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.14px;
  border: 1px solid #58687e;
`;

const OptionContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

const HiddenField = styled(Field).attrs({
  type: 'radio',
})`
  display: none;
`;

interface OptionField {
  fieldName: string;
  value: string | undefined;
  label: string;
  anyValueSelected: boolean;
}

const OptionField: FC<OptionField> = (props) => {
  const { fieldName: name, value, label, anyValueSelected } = props;

  const [{ checked }] = useField({
    name,
    value: value ?? undefined,
    type: 'radio',
  });

  // This is a bit of a hack to force the radio buttons into having an undefined value
  const showAsChecked = checked || (!value && !anyValueSelected);

  return (
    <OptionContainer>
      <HiddenField name={name} value={value} />
      <OptionLabel checked={showAsChecked ?? false}>{label}</OptionLabel>
    </OptionContainer>
  );
};

export const usePrecacheForQuestionOptionSelect: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

export interface QuestionOptionSelectProps<T extends string | undefined> {
  initialValue: T | undefined;
  options: Array<{ value: T; label: string }>;
  onDone: (value: T | undefined) => void;
  label: string;
}

const QuestionOptionSelect = <T extends string | undefined>(
  props: QuestionOptionSelectProps<T>,
): ReactElement => {
  const { initialValue, options, onDone, label } = props;

  return (
    <>
      <Formik
        initialValues={{ questionValue: initialValue }}
        onSubmit={({ questionValue }) => onDone(questionValue)}>
        {({ submitForm, values: { questionValue } }) => {
          useEffect(() => {
            if (questionValue) {
              submitForm();
            }
          }, [questionValue, submitForm]);

          return (
            <Container>
              <Label>{label}</Label>
              <OptionsContainer>
                {options.map(({ value, label }, i) => (
                  <OptionField
                    fieldName={'questionValue'}
                    value={value}
                    label={label}
                    key={i}
                    anyValueSelected={!!questionValue}
                  />
                ))}
              </OptionsContainer>
            </Container>
          );
        }}
      </Formik>
    </>
  );
};

export default QuestionOptionSelect;
