import moment from 'moment';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import StatusBadge from '../../../components/StatusBadge';
import {
  PropertyAppraisalRequestBaseFragment,
  PropertyAppraisalRequestState,
  PropertyState,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import expiryTimeClockUrl, {
  ReactComponent as ExpiryTimeClockIcon,
} from '../assets/expiry-time-clock.svg';

const ExpiryTimeClock = styled(ExpiryTimeClockIcon)`
  margin: 0 7px 0 16px;
`;

const ExpiryLabelContainer = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #627186;
  letter-spacing: 0.18px;
  margin-top: 1px;
`;

export interface AppraisalRequestStatusBadgeProps {
  appraisalRequest: PropertyAppraisalRequestBaseFragment;
}

export const usePrecacheForAppraisalRequestStatusBadge: () => void = () => {
  usePrecacheImages([expiryTimeClockUrl]);
};

const AppraisalRequestStatusBadge: FC<AppraisalRequestStatusBadgeProps> = (
  props,
) => {
  const { appraisalRequest } = props;

  const statusBadgeMessage = useMemo(() => {
    if (appraisalRequest.property.state === PropertyState.Complete) {
      return 'Sold';
    } else if (
      appraisalRequest.state == PropertyAppraisalRequestState.Pending
    ) {
      return 'Pending Appraisal';
    } else if (
      appraisalRequest.state == PropertyAppraisalRequestState.Expired
    ) {
      return 'Expired';
    } else {
      return undefined;
    }
  }, [appraisalRequest]);

  const expiryTime = useMemo(() => {
    if (appraisalRequest.state == PropertyAppraisalRequestState.Pending) {
      const hours = moment(appraisalRequest.expiresAt).diff(
        moment(),
        'hour',
        true,
      );

      if (hours >= 1) {
        return `${Math.round(hours)}h left`;
      } else {
        const minutes = Math.max(
          moment(appraisalRequest.expiresAt).diff(moment(), 'minute'),
        );

        return `${Math.round(minutes)}m left`;
      }
    } else {
      return undefined;
    }
  }, [appraisalRequest]);

  return statusBadgeMessage ? (
    <StatusBadge>
      {statusBadgeMessage}
      {expiryTime && (
        <>
          <ExpiryTimeClock />
          <ExpiryLabelContainer>{expiryTime}</ExpiryLabelContainer>
        </>
      )}
    </StatusBadge>
  ) : null;
};

export default AppraisalRequestStatusBadge;
