import React, { FC } from 'react';
import styled from 'styled-components';
import AppraisalSummarySection from '../../../components/SummarySection';
import {
  AgentCommissionBaseFragment,
  AgentCommissionCommissionType,
} from '../../../graphql/generated';

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 0 0 0;
`;

const ValueLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
  color: #203553;
`;

const PercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GstLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #282828;
  margin: 0 0 0 6px;
`;

const PercentageCaption = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 4px 0 0 0;
`;

interface AgentCommissionProps {
  agentCommission: AgentCommissionBaseFragment;
  onEditClick?: () => void;
  editLabel?: string;
  hideEdit?: boolean;
}

const AgentCommission: FC<AgentCommissionProps> = (props) => {
  const {
    agentCommission,
    onEditClick,
    editLabel = 'Edit',
    hideEdit = false,
  } = props;

  return (
    <AppraisalSummarySection
      title="Commission"
      editHref="#"
      onEditClick={onEditClick}
      editLabel={editLabel}
      hideEdit={hideEdit}>
      {agentCommission.commissionType ===
        AgentCommissionCommissionType.Percentage && (
        <ValueContainer>
          <PercentageContainer>
            <ValueLabel>{agentCommission.percentageRate}%</ValueLabel>{' '}
            <GstLabel>inc. GST</GstLabel>
          </PercentageContainer>
          <PercentageCaption>of property value</PercentageCaption>
        </ValueContainer>
      )}
      {agentCommission.commissionType ===
        AgentCommissionCommissionType.FlatFee && (
        <ValueContainer>
          <ValueLabel>
            $
            {new Intl.NumberFormat().format(
              agentCommission.flatFeeCostDollars ?? 0,
            )}
          </ValueLabel>
        </ValueContainer>
      )}
    </AppraisalSummarySection>
  );
};

export default AgentCommission;
