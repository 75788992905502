import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import AgentMenu, {
  usePrecacheForAgentMenu,
} from '../../../components/AgentMenu';
import ASHeader from '../../../components/ASHeader';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import {
  MyAppointmentRequestsDocument,
  useAppointmentRequestSeenByAgentMutation,
  useMyAppointmentRequestsQuery,
  useMyCompleteAppraisalRequestsQuery,
  useMyPendingAppraisalRequestsQuery,
  UserType,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import AppointmentRequestCard from '../components/AppointmentRequestCard';
import { usePrecacheForAppraisalRequestCard } from '../components/AppraisalRequestCard';
import { usePrecacheForNoCompleteAppraisalsPlaceholder } from '../components/NoCompleteAppraisalsPlaceholder';
import NoPendingRequestsPlaceholder, {
  usePrecacheForNoPendingRequestsPlaceholder,
} from '../components/NoPendingRequestsPlaceholder';

const Header = styled(ASHeader)`
  text-align: left;
  margin: 28px 0 32px 0;
`;

const AppointmentRequestsInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  width: 100%;
  overflow: visible !important;
`;

export const usePrecacheForAgentAppointmentRequests: () => void = () => {
  const isAuthenticated = useIsAuthenticated(UserType.Agent);

  const { data: pendingAppraisalsData } = useMyPendingAppraisalRequestsQuery({
    variables: { first: 10 },
    skip: !isAuthenticated,
  });

  const { data: completeAppraisalsData } = useMyCompleteAppraisalRequestsQuery({
    variables: { first: 10 },
    skip: !isAuthenticated,
  });

  usePrecacheImages([
    ...((pendingAppraisalsData?.myPendingAppraisalRequests?.edges
      ?.map((edge) => edge?.node?.property.photos[0]?.userMedia.thumbnailUrl)
      ?.filter((url) => url != undefined) as string[]) ?? []),
    ...((completeAppraisalsData?.myCompleteAppraisalRequests?.edges
      ?.map((edge) => edge?.node?.property.photos[0]?.userMedia.thumbnailUrl)
      ?.filter((url) => url != undefined) as string[]) ?? []),
  ]);

  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForAppraisalRequestCard();
  usePrecacheForNoPendingRequestsPlaceholder();
  usePrecacheForNoCompleteAppraisalsPlaceholder();
};

const AgentAppointmentRequests: FC = () => {
  const {
    data: appointmentRequestData,
    fetchMore: fetchMoreAppointmentRequests,
  } = useMyAppointmentRequestsQuery({
    variables: { first: 10 },
  });

  const [
    appointmentRequestSeenByAgent,
  ] = useAppointmentRequestSeenByAgentMutation();

  const onAppointmentRequestsScrollEndReached = useCallback(() => {
    if (appointmentRequestData?.myAppointmentRequests) {
      fetchMoreAppointmentRequests({
        variables: {
          first: 10,
          after:
            appointmentRequestData?.myAppointmentRequests.pageInfo.endCursor,
        },
      });
    }
  }, [appointmentRequestData, fetchMoreAppointmentRequests]);

  const onRead = async (id: string) => {
    try {
      await appointmentRequestSeenByAgent({
        variables: {
          id: id,
        },
        refetchQueries: [
          {
            query: MyAppointmentRequestsDocument,
            variables: {
              first: 10,
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  };

  usePrecacheForAgentMenu();

  return (
    <SplitDashboardPageTemplate menu={<AgentMenu />}>
      <>
        <Helmet>
          <title>Properties | AgentSpot</title>
        </Helmet>
        <Header>Appointment Requests</Header>
        {appointmentRequestData?.myAppointmentRequests?.edges &&
          (appointmentRequestData.myAppointmentRequests.edges.length > 0 ? (
            <AppointmentRequestsInfiniteScroll
              dataLength={
                appointmentRequestData.myAppointmentRequests?.edges?.length ?? 0
              }
              /* eslint-disable-next-line */
              // @ts-ignore
              next={onAppointmentRequestsScrollEndReached}
              hasMore={
                appointmentRequestData.myAppointmentRequests?.pageInfo
                  .hasNextPage ?? false
              }
              loader={null}
              endMessage={null}>
              {appointmentRequestData.myAppointmentRequests?.edges?.map(
                (requestEdge, i) =>
                  requestEdge?.node && (
                    <AppointmentRequestCard
                      appointmentRequest={requestEdge?.node}
                      onRead={onRead}
                      key={requestEdge?.node?.id ?? i}
                    />
                  ),
              )}
            </AppointmentRequestsInfiniteScroll>
          ) : (
            <NoPendingRequestsPlaceholder />
          ))}
      </>
    </SplitDashboardPageTemplate>
  );
};

export default AgentAppointmentRequests;
