import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import theme from '../constants/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10;
  background-color: #ffffff;
  border-top: 1px solid #e8eaed;
  z-index: 2;
  border-radius: 0 0 15px 15px;
`;

const ContentsContainer = styled.nav`
  width: 100%;
  max-width: 1024px;
  padding: ${theme.spacing.pageInset};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const MiddleSpace = styled.div`
  flex-grow: 2;
`;
export interface NavigationFooterProps {
  leftSideComponent?: ReactNode;
  rightSideComponent?: ReactNode;
  skipText?: ReactNode;
}

const NavigationFooter: FC<NavigationFooterProps> = (props) => {
  const { leftSideComponent, rightSideComponent, skipText } = props;

  return (
    <Container>
      {skipText && skipText}
      <ContentsContainer>
        {leftSideComponent && leftSideComponent}
        <MiddleSpace />

        {rightSideComponent && rightSideComponent}
      </ContentsContainer>
    </Container>
  );
};

export default NavigationFooter;
