import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 0 0 0;
`;

const Paragraph = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #203553;
  letter-spacing: 0.2px;
  line-height: 23px;
  margin: 0 0 14px 0;

  :last-child {
    margin: 0;
  }
`;

export interface ASMultilineTextProps {
  text: string;
  className?: string;
}

const ASMultilineText: FC<ASMultilineTextProps> = (props) => {
  const { text, className } = props;

  const textLines = text
    .split('\n')
    .map((textLine) => textLine.trimEnd())
    .filter((textLine) => !!textLine);

  return (
    <Container>
      {textLines.map((textLine, i) => (
        <Paragraph className={className} key={i}>
          {textLine}
        </Paragraph>
      ))}
    </Container>
  );
};

export default ASMultilineText;
