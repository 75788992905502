import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';
import AgentProfilePhoto from '../../../components/AgentProfilePhoto';
import theme from '../../../constants/theme';
import {
  PropertyAppraisalBaseFragment,
  PropertyBaseFragment,
} from '../../../graphql/generated';
import fullAddressOfProperty from '../../../utils/fullAddressOfProperty';

const Container = styled.button`
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 10px;
  min-height: 175px;
  padding: 20px;
  margin-bottom: 25px;

  text-align: left;
  border: none;
`;

const AgentName = styled.h3`
  font-family: Inter, Arial, sans-serif;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.25px;
  font-weight: 600;

  ${Container}:hover & {
    text-decoration: underline;
    text-decoration-color: #203553;
  }
`;

const Agency = styled.h4`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #58687e;
  letter-spacing: 0.25px;
`;

const ApprasisedLocation = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #1745b0;
  letter-spacing: 0.15px;
  margin-top: 8px;
`;

const DateReceived = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #a6aeba;
  letter-spacing: 0.15px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

interface AgentAppraisalRowProps {
  appraisal: PropertyAppraisalBaseFragment;
  property: PropertyBaseFragment;
  onClick: () => void;
}

const AgentAppraisalRow: FC<AgentAppraisalRowProps> = (props) => {
  const { appraisal, property, onClick } = props;

  return (
    <Container onClick={onClick}>
      <AgentProfilePhoto agent={appraisal?.agent} showAgencyLogo={true} />
      <DetailsContainer>
        <AgentName>{appraisal?.agent?.name}</AgentName>
        <Agency>{appraisal?.agent?.agencyName}</Agency>
        <ApprasisedLocation>{`Appraisal for: ${fullAddressOfProperty(
          property,
        )}`}</ApprasisedLocation>
        <DateReceived>{`Received ${moment(appraisal?.sentAt)?.format(
          'DD MMM YYYY',
        )}`}</DateReceived>
      </DetailsContainer>
    </Container>
  );
};

export default AgentAppraisalRow;
