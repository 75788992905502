import React, { FC } from 'react';
import { createButton } from 'react-social-login-buttons';
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
} from 'reactjs-social-login';
import FbIcon from '../assets/Facebook.png';
import GoogleIcon from '../assets/Google.png';
import MicrosoftIcon from '../assets/Microsoft.png';
const FbIconC = () => <img src={FbIcon} height={'25'} />;
const GoogleIconC = () => <img src={GoogleIcon} height={'25'} />;
const MicrosoftIconC = () => <img src={MicrosoftIcon} height={'25'} />;

interface SocialSignUpProps {
  setFieldValue: (
    field: string,
    value: string,
    shouldValidate?: boolean | undefined,
  ) => void;
}

const SocialSignUp: FC<SocialSignUpProps> = ({ setFieldValue }) => {
  const MyFaceBookButton = createButton({
    text: 'Continue with Facebook',
    className: 'social-icons',
    style: {
      borderRadius: '0rem',
      backgroundColor: 'transparent',
      color: 'black',
      width: '80%',
      margin: '0 auto',
    },
    icon: FbIconC,
    iconSize: '10px',
    align: 'center',
    activeStyle: { backgroundColor: '#EFF0EE' },
  });

  const MyGoogleButton = createButton({
    text: 'Continue with Google',
    className: 'social-icons',
    style: {
      borderRadius: '0rem',
      backgroundColor: 'transparent',
      color: 'black',
      width: '80%',
      margin: '0 auto',
    },
    icon: GoogleIconC,
    align: 'center',
    activeStyle: { backgroundColor: '#EFF0EE' },
  });

  const MyMicrosoftButton = createButton({
    text: 'Continue with Microsoft',
    className: 'social-icons',
    style: {
      borderRadius: '0rem',
      backgroundColor: 'transparent',
      color: 'black',
      width: '80%',
      margin: '0 auto',
    },
    icon: MicrosoftIconC,
    align: 'center',
    activeStyle: { backgroundColor: '#EFF0EE' },
  });

  const handleResolve = ({ data }: IResolveParams) => {
    setFieldValue('name', data?.name);
    setFieldValue('email', data?.email);
    setFieldValue('socialEmail', data?.email);
  };

  return (
    <>
      <div className={`App`}>
        <LoginSocialGoogle
          client_id={process.env.REACT_APP_GG_APP_ID || ''}
          scope={'email profile openid'}
          onResolve={handleResolve}
          onReject={(err) => {
            console.log(err);
          }}>
          <MyGoogleButton />
        </LoginSocialGoogle>

        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || ''}
          onResolve={handleResolve}
          onReject={(err) => {
            console.log(err);
          }}>
          <MyFaceBookButton />
        </LoginSocialFacebook>

        <LoginSocialMicrosoft
          client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ''}
          scope={'email openid profile User.Read'}
          redirect_uri={process.env.REACT_APP_REDIRECT_URI || ''}
          onResolve={({ data }) => {
            setFieldValue('name', data?.displayName);
            setFieldValue('email', data?.mail);
            setFieldValue('socialEmail', data?.mail);
          }}
          onReject={(err) => {
            console.log(err);
          }}>
          <MyMicrosoftButton />
        </LoginSocialMicrosoft>
      </div>
    </>
  );
};

export default SocialSignUp;
