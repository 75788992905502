export const UPDATE_SUBURB_CART = 'UPDATE_SUBURB_CART';

export interface CartState {
  /**
   * Tracks the suburbs an agent has added to their cart before completing purchase
   */
  suburbCart: Array<string>;
}

export interface UpdateSuburbCartAction {
  type: typeof UPDATE_SUBURB_CART;
  payload: {
    suburbIds: Array<string>;
  };
}

export type CartActions = UpdateSuburbCartAction;
