import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { PaymentMethodBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import amexIconUrl from '../assets/card-icon-amex.svg';
import masterCardIconUrl from '../assets/card-icon-mastercard.svg';
import visaIconUrl from '../assets/card-icon-visa.svg';

const Container = styled.div`
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 10px;

  padding: 25px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CardIconImage = styled.img`
  width: 45px;
  height: 36px;

  object-fit: contain;
  object-position: center;
`;

const CardNumberLabel = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #384659;
  line-height: 22px;

  display: flex;
  flex-grow: 1;
  margin: 0 18px;
`;

const EditButton = styled.button.attrs({ type: 'button' })`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.3px;
  text-align: right;

  border: none;
  background-color: transparent;

  border: none;

  :hover {
    text-decoration-color: #1745b0;
  }
`;

export const usePrecacheFoPaymentMethodRow: () => void = () => {
  usePrecacheImages([masterCardIconUrl, visaIconUrl, amexIconUrl]);
};

export interface PaymentMethodRowProps {
  paymentMethod: PaymentMethodBaseFragment;
  onEditClick: () => void;
  disabled?: boolean;
}

const getCardIconForBrand: (brand: string) => string | null = (
  brand: string,
) => {
  // Card icons sourced from https://github.com/aaronfagan/svg-credit-card-payment-icons
  switch (brand) {
    case 'mastercard':
      return masterCardIconUrl;
    case 'visa':
      return visaIconUrl;
    case 'amex':
      return amexIconUrl;
    default:
      return null;
  }
};

const PaymentMethodRow: FC<PaymentMethodRowProps> = (props) => {
  const { paymentMethod, onEditClick, disabled } = props;

  const cardIconUrl = paymentMethod.cardBrand
    ? getCardIconForBrand(paymentMethod.cardBrand)
    : undefined;

  return (
    <Container>
      {cardIconUrl && <CardIconImage src={cardIconUrl} />}

      <CardNumberLabel>•••• {paymentMethod.cardSuffix}</CardNumberLabel>

      {!disabled && <EditButton onClick={onEditClick}>Edit</EditButton>}
    </Container>
  );
};

export default PaymentMethodRow;
