import { Field, useField } from 'formik';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { ReactComponent as CloseModalButton } from '../../../assets/close-cross-white.svg';
import AgentSelectProfilePhoto from '../../../components/AgentSelectProfilePhoto';
import ASModal from '../../../components/ASModal';
import { AgentBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { ReactComponent as DotSeparatorIcon } from '../assets/dot-separator.svg';

const Container = styled.label<{ checked?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 20px;
  margin: 0 0 12px 0;
  background: #ffffff;
  border-bottom: 1px solid #1745b0;

  ${(props) =>
    props.checked
      ? `
    border-bottom: 1px solid #1745b0;
    padding: 30px 20px;
    `
      : ''}

  position: relative;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${({ checked }) =>
    checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const NameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.25px;
  margin-right: 20px;
`;

const AgencyNameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  margin: 0;
`;

const StatsLabelContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 0;
`;

const StatsLabel = styled.span`
  font-family: Inter, Arial, sans-serif;
  font-size: 11px;
  color: #8f9aa9;
  letter-spacing: 0.14px;
  margin: 0;
  text-transform: capitalize;
`;

const DotSeparator = styled(DotSeparatorIcon)`
  margin: 0 6px;
  width: 3px;
  height: 3px;
`;

const HiddenField = styled(Field).attrs(({ type }) => ({
  type,
}))`
  display: none;
`;

const ViewAgentButton = styled(Link)`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.2px;
`;

export const usePrecacheForAgentOptionField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ProfilePhoto = styled.img`
  max-width: 150px;
  height: auto;
  border: 1px solid #ffffff;

  @media (max-width: 768px) {
    max-width: 100px;
  }
`;

const AgencyLogo = styled.img`
  position: absolute;
  left: 70px;
  height: auto;
  width: 75px;
  background-color: white;
  border: 1px solid #ffffff;
  border-radius: 4px;
  object-fit: fill;

  @media (max-width: 768px) {
    left: 45px;
    width: 50px;
  }
`;

const ModalNameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #1745b0;
  letter-spacing: 0.25px;
  text-align: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const ModalAgencyNameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #000;
  letter-spacing: 0.16px;
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ModalBioHeader = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 18px;
  color: #1745b0;
  letter-spacing: 0.15px;
  margin: 20px 0 10px 0;
  font-weight: 800;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ModalBio = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #58687e;
  letter-spacing: 0.15px;
  margin: 0 0 10px 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const CheckBoxLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.15px;
  font-weight: 800;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const ModalCheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: relative;
`;

const CloseButtonAgent = styled.button.attrs({ type: 'button' })`
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  position: sticky;
  bottom: 0;
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const NavigationArrow = styled.button`
  background: transparent;
  border: none;
  font-size: 24px;
  color: #1745b0;
  cursor: pointer;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;

const PaginationDot = styled.div<{ active: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#1745b0' : '#ccc')};
  margin: 0 5px;
  display: inline-block;

  @media (max-width: 768px) {
    height: 8px;
    width: 8px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ModalContent = styled.div`
  background-position: 0 150px;
  background: linear-gradient(to bottom, #1745b0 150px, #ffffff 150px);
  padding: 24px 20px 30px 20px;
  height: 80vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    background-position: 0 120px;
    background: linear-gradient(to bottom, #1745b0 120px, #ffffff 120px);
  }
`;

export interface AgentOptionFieldProps {
  fieldName: string;
  type?: string;
  agent: AgentBaseFragment;
  showStats?: boolean;
  agents?: AgentBaseFragment[];
  initialIndex?: number;
  selectedAgents?: string[];
}

const AgentOptionField: FC<AgentOptionFieldProps> = (props) => {
  const [agentInfoModalOpen, setAgentInfoModalOpen] = useState(false);

  const { fieldName, agent, type = 'checkbox', showStats = true } = props;

  const [{ checked }, , { setValue }] = useField({
    name: fieldName,
    value: agent.id,
    type: type,
  });

  const [currentIndex, setCurrentIndex] = useState(props?.initialIndex || 0);
  const agents = props?.agents || [];
  const viewAgent = agents[currentIndex];

  const handleCheckboxChange = () => {
    const isChecked = props?.selectedAgents?.includes(viewAgent.id) ?? false;
    if (isChecked) {
      // Remove viewAgent.id from the list
      setValue(
        props?.selectedAgents?.filter((id) => id !== viewAgent.id) || [],
      );
    } else {
      // Add viewAgent.id to the list
      setValue([...(props?.selectedAgents || []), viewAgent.id]);
    }
  };

  const handleNextAgent = () => {
    if (currentIndex < agents.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePreviousAgent = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNextAgent,
    onSwipedRight: handlePreviousAgent,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <>
      <Container checked={checked}>
        <HiddenField name={fieldName} value={agent.id} type={type} />
        <AgentSelectProfilePhoto agent={agent} showAgencyLogo />
        <LabelsContainer>
          <NameLabel>{agent.name}</NameLabel>
          <AgencyNameLabel>{agent.agencyName}</AgencyNameLabel>
          {showStats && (
            <StatsLabelContainer>
              <StatsLabel>
                {agent.numberOfEngagements} Current Listings
              </StatsLabel>
              <DotSeparator />
              <StatsLabel>{agent.badge?.toLocaleLowerCase()} Member</StatsLabel>
            </StatsLabelContainer>
          )}
          <ViewAgentButton
            to="#"
            onClick={() => {
              setAgentInfoModalOpen(true);
              setCurrentIndex(agents.findIndex((a) => a.id === agent.id));
            }}>
            View profile
          </ViewAgentButton>
        </LabelsContainer>
        <CheckBox checked={!!checked} />
      </Container>
      <ASModal
        showHeader={false}
        isOpen={agentInfoModalOpen}
        onRequestClose={() =>
          setAgentInfoModalOpen((agentInfoModalOpen) => !agentInfoModalOpen)
        }
        expand={true}
        agent={true}
        slideOnMobile={false}>
        <ModalContent {...swipeHandlers}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CloseButtonAgent
              onClick={() =>
                setAgentInfoModalOpen(
                  (agentInfoModalOpen) => !agentInfoModalOpen,
                )
              }>
              <CloseModalButton />
            </CloseButtonAgent>
          </div>
          <ImageContainer>
            <ProfilePhoto
              src={
                viewAgent.profilePicture?.thumbnailUrl ??
                viewAgent.profilePicture?.url
              }
            />
            <div
              style={{
                position: 'absolute',
                display: 'flex',
                alignItems: 'end',
                height: 'calc(100% + 15px)',
              }}>
              <AgencyLogo src={viewAgent.agencyLogo?.url} />
            </div>
          </ImageContainer>
          <HiddenField name={fieldName} value={viewAgent.id} type={type} />
          <ModalNameLabel>{viewAgent.name}</ModalNameLabel>
          <ModalAgencyNameLabel>{viewAgent.agencyName}</ModalAgencyNameLabel>
          <ModalBioHeader>Agent bio</ModalBioHeader>
          <ModalBio>{viewAgent.bio}</ModalBio>
          <ModalBioHeader>Agency bio</ModalBioHeader>
          <ModalBio>{viewAgent.agencyBio}</ModalBio>
          <CheckBoxContainer>
            {/* I need to check this when the current index agent id is in the selectedAgent id */}
            {/* <ModalCheckBox checked={!!checked} /> */}
            <ModalCheckBox
              checked={props?.selectedAgents?.includes(viewAgent.id) ?? false}
              onClick={() => handleCheckboxChange()}
            />
            <CheckBoxLabel>Select Agent</CheckBoxLabel>
          </CheckBoxContainer>

          {/* Navigation buttons */}
          <NavigationContainer>
            <NavigationArrow
              onClick={handlePreviousAgent}
              disabled={currentIndex === 0}>
              <svg
                fill="#1745b0"
                height="24px"
                width="24px"
                viewBox="0 0 330 330"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve">
                <path d="M79.394 154.389l150-149.996c5.857-5.858 15.355-5.858 21.213 0.001 5.857 5.858 5.857 15.355-0.001 21.213L111.213 165.001l139.394 139.393c5.857 5.858 5.857 15.355-0.001 21.213-5.857 5.857-15.355 5.857-21.213-0.001l-150-150.004c-2.814-2.813-4.394-6.628-4.394-10.606 0-3.979 1.58-7.795 4.394-10.607z" />
              </svg>
            </NavigationArrow>

            <PaginationContainer>
              {agents.map((_, index) => (
                <PaginationDot key={index} active={index === currentIndex} />
              ))}
            </PaginationContainer>

            <NavigationArrow
              onClick={handleNextAgent}
              disabled={currentIndex === agents.length - 1}>
              <svg
                fill="#1745b0"
                height="24px"
                width="24px"
                viewBox="0 0 330 330"
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve">
                <path d="M250.606 154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213 0.001-5.857 5.858-5.857 15.355 0.001 21.213l139.394 139.394L79.394 304.393c-5.857 5.858-5.857 15.355 0.001 21.213 5.857 5.857 15.355 5.857 21.213-0.001l150-150.004c2.814-2.813 4.394-6.628 4.394-10.606 0-3.979-1.58-7.795-4.394-10.607z" />
              </svg>
            </NavigationArrow>
          </NavigationContainer>
        </ModalContent>
      </ASModal>
    </>
  );
};

export default AgentOptionField;
