import { Formik } from 'formik';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NumberInputField from '../../../components/NumberInputField';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import { useConfirmPropertySaleMutation } from '../../../graphql/generated';
import { usePrecacheForAgentSaleFinalized } from './AgentSaleFinalized';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.h1`
  opacity: 0.5;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #2a335f;
  margin-bottom: 21px;
`;

const Title = styled.h2`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #203553;
  line-height: 55px;
  margin-bottom: 45px;
`;

const ConfirmButton = styled(ASBlockButton)`
  margin: 60px 0 21px 0;
  width: 100%;
`;

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

const PrefixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
`;

export const usePrecacheForConfirmSalePriceAndDate: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};
interface FormValues {
  salePrice?: number;
  contractDate: string;
}

const initialValues: FormValues = {
  salePrice: undefined,
  contractDate: '',
};

const validationSchema = Yup.object().shape({
  salePrice: Yup.string().required('Sale price is required'),
  contractDate: Yup.string().min(10).required('Contract date is required'),
});

interface ExpectedPathParams {
  id?: string;
}

const ConfirmSalePriceAndDate: FC = () => {
  const { id: propertySaleId } = useParams<ExpectedPathParams>();
  const [
    confirmPropertySale,
    { loading: loading, error: confrimPropertySaleError },
  ] = useConfirmPropertySaleMutation();

  const history = useHistory();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      if (!values.salePrice) return;

      const contractDate = moment(values.contractDate, 'YYYY-MM-DD').format(
        'YYYY-MM-DD',
      );

      const { data: confirmPropertySaleData } = await confirmPropertySale({
        variables: {
          id: propertySaleId!,
          salePrice: values.salePrice,
          contractDate,
        },
      });

      if (confirmPropertySaleData) {
        history.push(
          `/agent/property-sale/${propertySaleId}/confirm/finalized`,
        );
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  usePrecacheForAgentSaleFinalized();

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Confirm Sale | AgentSpot</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount }) => {
          const error: string | undefined =
            (submitCount > 0 && errors && Object.values(errors)[0]) ||
            (confrimPropertySaleError &&
              (confrimPropertySaleError.message === 'not_found'
                ? 'Property sale not found'
                : apolloErrorToString(confrimPropertySaleError)));

          return (
            <Form>
              <Header>Confirm Sale</Header>
              <Title>Enter the following information...</Title>

              <InputLabel>Sale price</InputLabel>
              <NumberInputField
                fieldName="salePrice"
                allowNegative={false}
                placeholder="Sale Price"
                renderPrefix={() => <PrefixLabel>$</PrefixLabel>}
              />
              <InputLabel>Contract date</InputLabel>
              <TextInputField
                type="date"
                name="contractDate"
                placeholder="Contract date"
              />
              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <ConfirmButton loading={loading} onClick={submitForm}>
                Confirm details
              </ConfirmButton>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default ConfirmSalePriceAndDate;
