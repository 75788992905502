import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../constants/theme';
import ASBlockLink from './ASBlockLink';

export const MenuButton = styled(ASBlockLink)<{ color?: string }>`
  background: #fff;
  padding: 12px 10px;
  margin-right: 12px;
  color: ${(props) => (props.color ? props.color : '#1f58ee')};

  :hover {
    color: #1f58ee;
    box-shadow: none;
  }

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    margin-right: 10px;
  }
`;

interface Props {
  path: string;
  textButton: string;
}

const ASMenuButton: React.FC<Props> = ({ path, textButton }) => {
  const { pathname } = useLocation();

  return (
    <MenuButton
      color={`${pathname === path ? '#1f58ee' : '#203553'}`}
      to={path}>
      {textButton}
    </MenuButton>
  );
};

export default ASMenuButton;
