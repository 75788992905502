import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ASModal from '../../../components/ASModal';
import { UPDATE_SUBURB_CART } from '../store/cartTypes';
import SuburbSelectForm from './SuburbSelectForm';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  position: relative;
  transform: translateX(0);
`;
export interface AgentSignupSuburbSearchProps {
  onRequestClose: () => void;
  isOpen: boolean;
}

const AgentSignupSuburbSearch: FC<AgentSignupSuburbSearchProps> = ({
  onRequestClose,
  isOpen,
}) => {
  const dispatch = useDispatch();

  const onAddSuburbs = (suburbs: Array<string>) => {
    dispatch({
      type: UPDATE_SUBURB_CART,
      payload: {
        suburbIds: suburbs,
      },
    });

    onRequestClose();
  };

  return (
    <ASModal
      expand
      isOpen={isOpen}
      title={'Suburb Search'}
      onRequestClose={onRequestClose}
      preventScroll
      noPadding
      boldTitle
      padHeader>
      <Container>
        <SuburbSelectForm onAddClicked={onAddSuburbs} scroll />
      </Container>
    </ASModal>
  );
};

export default AgentSignupSuburbSearch;
