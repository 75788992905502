import { useField, useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import React, {
  FC,
  FormEvent,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import clearCrossIconUrl, {
  ReactComponent as ClearCrossIcon,
} from '../assets/clear-cross-button.svg';
import searchPrefixIconUrl, {
  ReactComponent as SearchPrefixIcon,
} from '../assets/search-magnifying-glass.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';

interface InputShapeProps {
  $error: boolean;
  $optionsExpanded: boolean;
}

export const InputShape = styled.div<InputShapeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-style: solid;
  border-color: ${(props) => (props.$error ? '#fc4e6F' : '#000000')};
  border-width: ${(props) =>
    props.$optionsExpanded ? '1px 1px 0 1px' : '1px'};
  border-radius: '0px';
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.2px;
  padding: 0 20px;

  margin: 0 0 9px 0;
  overflow: hidden;

  position: relative;
  overflow: visible;
`;

export const SearchPrefix = styled(SearchPrefixIcon)`
  font-family: Inter, Arial, sans-serif;
  width: 18px;
  height: 18px;
  z-index: 2;
`;

export const InputField = styled.input<{ $truncate?: boolean }>`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  background: transparent;
  letter-spacing: 0.2px;
  border: none;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 18px 20px 18px 35px;
  margin: 0 -20px;
  min-width: 50px;

  text-overflow: ${(props) => (props.$truncate ? 'ellipsis' : 'auto')};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  :focus {
    outline: none;
  }

  ::placeholder {
    color: #282828;
  }
`;

export const OptionsContainer = styled.div<{ $error: boolean }>`
  position: absolute;

  top: 100%;
  left: -1px;
  right: -1px;

  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  background: #ffffff;
  border-style: solid;
  border-color: ${(props) => (props.$error ? '#fc4e6F' : '#282828')};
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 10px 10px;

  background-color: white;

  padding: 0 0 10px 0;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-family: 500;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  padding: 10px 20px 10px 20px;

  :last-child {
    border-bottom: none;
  }

  :hover {
    background-color: #f3f3f3;
    cursor: default;
  }
`;

export const NoResultsPlaceholder = styled(Option)`
  :hover {
    background-color: initial;
  }
`;

export const LoadingSpinnerContainer = styled.div`
  margin-top: 10px 0 0 0;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClearButton = styled.button`
  border: none;
  background: transparent;
  padding: 10px;
  margin-right: -10px;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
    color: transparent;
  }
`;

export const ClearCross = styled(ClearCrossIcon)`
  width: 18px;
  height: 18px;
`;

export interface SearchSelectFieldOption {
  value: string;
  label: string;
  icon?: string;
}

export const usePrecacheForSearchSelectField: () => void = () => {
  usePrecacheImages([searchPrefixIconUrl, clearCrossIconUrl]);
};
export interface SearchSelectFieldProps {
  fieldName: string;
  options: SearchSelectFieldOption[];
  optionsLoading: boolean;
  onSearchTermChange: (searchTerm: string) => void;
  placeholder: string;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  disabled?: boolean;
  renderOptionPrefix?: (option: SearchSelectFieldOption) => ReactElement;
  renderSelectedValuePrefix?: (option: SearchSelectFieldOption) => ReactElement;
}

const SearchSelectField: FC<SearchSelectFieldProps> = (props) => {
  const {
    fieldName,
    options,
    optionsLoading,
    showErrorAfterFormSubmit,
    showErrorAfterTouch,
    placeholder,
    onSearchTermChange,
    disabled,
    renderOptionPrefix,
    renderSelectedValuePrefix,
  } = props;

  const [{ value }, { error, touched }, { setValue }] = useField({
    name: fieldName,
  });

  const [searchTerm, setSearchTerm] = useState<string>(value?.label ?? '');

  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);

  const debounceOnSearchTermChange: (searchTerm: string) => void = useCallback(
    debounce(onSearchTermChange, 200),
    [],
  );

  const onSearchChanged = useCallback((e: FormEvent<HTMLInputElement>) => {
    const newSearchTerm = e.currentTarget.value;

    setValue(undefined); // Changing search term deselects the current value
    setSearchTerm(newSearchTerm);
    debounceOnSearchTermChange(newSearchTerm.length >= 3 ? newSearchTerm : '');
    setOptionsExpanded(newSearchTerm.length >= 3);
  }, []);

  const onClearClick = useCallback(() => {
    setValue(undefined);
    setSearchTerm('');
    onSearchTermChange('');
    setOptionsExpanded(false);
  }, []);

  const onOptionClick: (options: SearchSelectFieldOption) => void = useCallback(
    (selectedOption) => {
      setValue(selectedOption);
      setSearchTerm(selectedOption.label);
      setOptionsExpanded(false);
    },
    [],
  );

  const { submitCount } = useFormikContext();

  const showError =
    ((showErrorAfterFormSubmit && submitCount > 0) ||
      (showErrorAfterTouch && touched)) ??
    false;

  return (
    <InputShape
      $error={showError && !!error}
      $optionsExpanded={optionsExpanded}>
      {value && renderSelectedValuePrefix ? (
        renderSelectedValuePrefix?.(value)
      ) : (
        <SearchPrefix />
      )}
      <InputField
        value={searchTerm}
        onChange={onSearchChanged}
        placeholder={placeholder}
        disabled={disabled}
        $truncate={!!value}
      />

      {!!value && !disabled && (
        <ClearButton onClick={onClearClick}>
          <ClearCross />
        </ClearButton>
      )}

      {optionsExpanded && !disabled && (
        <OptionsContainer $error={showError && !!error}>
          {options.length == 0 && !optionsLoading && (
            <NoResultsPlaceholder>No results found</NoResultsPlaceholder>
          )}

          {options.map((option, i) => (
            <Option
              onClick={() => onOptionClick(option)}
              key={`${option.value}_${i}`}>
              {renderOptionPrefix?.(option)}
              {option.label}
            </Option>
          ))}

          {options.length == 0 && optionsLoading && (
            <LoadingSpinnerContainer>
              <BeatLoader
                color="#a6aeba"
                css="display: flex;"
                size={4}
                margin={1}
                loading={optionsLoading}
              />
            </LoadingSpinnerContainer>
          )}
        </OptionsContainer>
      )}
    </InputShape>
  );
};

export default SearchSelectField;
