import { Field, Form, Formik, useField } from 'formik';
import {
  default as React,
  FC,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import AddItemRowLargeButton, {
  usePrecacheFoAddItemRowLargeButton,
} from '../../../components/AddItemRowLargeButton';
import ASBlockButton from '../../../components/ASBlockButton';
import ASModal, { usePrecacheForASModal } from '../../../components/ASModal';
import NumberInputField from '../../../components/NumberInputField';
import theme from '../../../constants/theme';
import {
  AgentCommissionCommissionType,
  useUpdateDraftPropertyAppraisalCommissionMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import AgentCommission from './AgentCommission';

const OptionContainer = styled.div`
  border-top: 1px solid #e8eaed;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0 0 15px 0;

  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;

  /* TODO: focus... */
`;

const OptionHeader = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  padding: 16px 20px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.14px;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
`;

const GstLabel = styled.p`
  margin: 5px 0 0 0;
  align-self: flex-end;
  text-align: end;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  align-self: flex-end;
`;

const HiddenField = styled(Field)`
  display: none;
`;

const SuffixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
  padding-left: 11px;
`;

const PrefixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
`;

export const usePrecacheForCommissiontTypeOptionField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

interface CommissiontTypeOptionFieldProps {
  name: string;
  value: string;
  label: string;
}

const CommissiontTypeOptionField: FC<CommissiontTypeOptionFieldProps> = (
  props,
) => {
  const { name, value, label, children } = props;

  const [{ checked }] = useField({ name, value, type: 'radio' });

  return (
    <OptionContainer>
      <OptionHeader>
        <HiddenField name={name} value={value} type="radio" />
        <Label>{label}</Label>
        <CheckBox checked={checked ?? false} />
      </OptionHeader>
      {children}
    </OptionContainer>
  );
};

const OptionsContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
`;

const OptionInnerContainer = styled.div`
  padding: 0 20px 25px 20px;
`;

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;

  margin: 0 0 10px 0;
`;

const DoneButton = styled(ASBlockButton).attrs({
  type: 'button',
})`
  margin-top: 16px;
`;

export const usePrecacheForAgentCommissionInputModalField: () => void = () => {
  usePrecacheForASModal();
  usePrecacheFoAddItemRowLargeButton();
  usePrecacheForCommissiontTypeOptionField();
};

interface FormValues {
  commissionType?: AgentCommissionCommissionType;

  percentageRate?: number;
  flatFeeCostDollars?: number;
}

const validationSchema = Yup.object().shape({
  commissionType: Yup.string().required(),

  percentageRate: Yup.mixed().when('commissionType', {
    is: AgentCommissionCommissionType.Percentage,
    then: Yup.number().positive().max(100).required(),
  }),
  flatFeeCostDollars: Yup.mixed().when('commissionType', {
    is: AgentCommissionCommissionType.FlatFee,
    then: Yup.number().positive().required(),
  }),
});

export interface AgentCommissionInputModalFieldProps {
  propertyAppraisalId: string;
  fieldName: string;
}

const AgentCommissionInputModalField = (
  props: AgentCommissionInputModalFieldProps,
): ReactElement => {
  const { propertyAppraisalId, fieldName } = props;

  const [{ value }, , { setValue }] = useField({ name: fieldName });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [
    updateDraftPropertyAppraisalCommission,
  ] = useUpdateDraftPropertyAppraisalCommissionMutation();

  const onAddCommissionClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onSubmit = useCallback(async (values: FormValues) => {
    if (!values.commissionType) return;

    const { data } = await updateDraftPropertyAppraisalCommission({
      variables: {
        propertyAppraisalId,
        commissionType: values.commissionType,
        percentageRate:
          values.commissionType === AgentCommissionCommissionType.Percentage
            ? values.percentageRate
            : null,
        flatFeeCostDollars:
          values.commissionType === AgentCommissionCommissionType.FlatFee
            ? values.flatFeeCostDollars
            : null,
      },
    });

    setValue(data?.updateDraftPropertyAppraisalCommission.commission);
    setIsModalOpen(false);
  }, []);

  const initalValues = value ?? {};

  return (
    <>
      {value ? (
        <AgentCommission
          agentCommission={value}
          onEditClick={onAddCommissionClick}
          editLabel="Edit Commission"
        />
      ) : (
        <AddItemRowLargeButton
          label="Add your commission"
          onClick={onAddCommissionClick}
        />
      )}

      <ASModal
        isOpen={isModalOpen}
        title="Commission"
        onRequestClose={() => setIsModalOpen(false)}>
        <Formik
          initialValues={initalValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount>
          {({ submitForm, values }) => {
            return (
              <OptionsContainer>
                <CommissiontTypeOptionField
                  name="commissionType"
                  value={AgentCommissionCommissionType.Percentage}
                  label="Percentage">
                  {values.commissionType ===
                    AgentCommissionCommissionType.Percentage && (
                    <OptionInnerContainer>
                      <InputLabel>Percentage of property value</InputLabel>
                      <NumberInputField
                        fieldName="percentageRate"
                        placeholder="E.g. 5.25"
                        decimalScale={2}
                        showErrorIfValueSet
                        renderSuffix={() => <SuffixLabel>%</SuffixLabel>}
                      />
                    </OptionInnerContainer>
                  )}
                </CommissiontTypeOptionField>
                <CommissiontTypeOptionField
                  name="commissionType"
                  value={AgentCommissionCommissionType.FlatFee}
                  label="Flat fee">
                  {values.commissionType ===
                    AgentCommissionCommissionType.FlatFee && (
                    <OptionInnerContainer>
                      <InputLabel>Fee amount</InputLabel>
                      <NumberInputField
                        fieldName="flatFeeCostDollars"
                        placeholder={`E.g. $${new Intl.NumberFormat().format(
                          5000,
                        )}`}
                        decimalScale={2}
                        renderPrefix={() => <PrefixLabel>$</PrefixLabel>}
                      />
                      <GstLabel>inc. GST</GstLabel>
                    </OptionInnerContainer>
                  )}
                </CommissiontTypeOptionField>
                <DoneButton onClick={submitForm}>Done</DoneButton>
              </OptionsContainer>
            );
          }}
        </Formik>
      </ASModal>
    </>
  );
};

export default AgentCommissionInputModalField;
