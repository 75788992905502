import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moreDoteMenuDontsIconUrl from '../../../assets/more-menu-dots.svg';
import theme from '../../../constants/theme';
import { PropertyBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import defaultPropertyImageUrl from '../assets/default-property-image.svg';
import UniversalPropertyActionMenu from './UniversalPropertyActionMenu';

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0 0 20px 0;

  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 11px;
  position: relative;
`;

const FooterContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
  }
`;

const PhotosContainer = styled.div`
  padding-top: 52%; // Effectively sets height the 52% of elements own width
  height: 0;
  max-height: 420px;
  position: relative;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;

const PhotosContainerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #f4f5f6;
`;

const Photo = styled.img`
  background-color: #f4f5f6;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
`;

const BottomLeftStatusBadgeContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
`;

const LabelsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;

  padding: 25px 20px;

  position: relative;
`;

const StreetAddressLabel = styled.div`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #203553;
  letter-spacing: 0.25px;
  margin-right: 20px;

  ${Container}:hover & {
    text-decoration: underline;
    text-decoration-color: #203553;
  }
`;

const SuburbLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  margin: 0 0 10px 0;

  ${Container}:hover & {
    text-decoration: underline;
    text-decoration-color: #8f9aa9;
  }
`;

const MarkPropertyButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  border: none;
  background-color: transparent;
`;

export const usePrecacheForPropertyCardTemplate: () => void = () => {
  usePrecacheImages([moreDoteMenuDontsIconUrl, defaultPropertyImageUrl]);
};

export interface PropertyCardTemplateProps {
  property: PropertyBaseFragment;
  StatusBadgeComponent?: ReactNode;
  href: string;
  menuButtonVisible: boolean;
  onMenuClick?: () => void;
  bottomLabel?: ReactNode;
  dateStampsComponent?: ReactNode;
}

const PropertyCardTemplate: React.FC<PropertyCardTemplateProps> = (props) => {
  const {
    property,
    StatusBadgeComponent,
    href,
    bottomLabel,
    children,
    menuButtonVisible,
    dateStampsComponent,
  } = props;

  const photoUrl = property.photos[0]?.userMedia.thumbnailUrl;

  return (
    <Container to={href}>
      {children}
      <PhotosContainer>
        <PhotosContainerContent>
          <Photo src={photoUrl ?? defaultPropertyImageUrl} />
          {StatusBadgeComponent && (
            <BottomLeftStatusBadgeContainer>
              {StatusBadgeComponent}
            </BottomLeftStatusBadgeContainer>
          )}
        </PhotosContainerContent>
      </PhotosContainer>
      <FooterContainer>
        <LabelsContainer>
          <StreetAddressLabel>{property.streetName}</StreetAddressLabel>
          <SuburbLabel>
            {property.suburb.name} {property.suburb.state}
          </SuburbLabel>

          {bottomLabel}
        </LabelsContainer>
        {dateStampsComponent && dateStampsComponent}
        {menuButtonVisible && (
          <MarkPropertyButtonContainer>
            <UniversalPropertyActionMenu property={property} />
          </MarkPropertyButtonContainer>
        )}
      </FooterContainer>
    </Container>
  );
};

export default PropertyCardTemplate;
