import React, { FC, MouseEventHandler, useCallback } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ReactComponent as CloseModalIcon } from '../assets/close-cross-white.svg';
import { UserMediaBaseFragment } from '../graphql/generated';
import withClassNameShim from '../hooks/stylePropHelpers';

const ContainerModal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: column;
  position: relative;
`;

const ContainerModalWithOverlay = styled(
  withClassNameShim<Modal.Props>('overlayClassName')(ContainerModal),
)`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
`;

const CloseModalButton = styled.button`
  padding: 20px;
  position: absolute;
  top: 70px;
  left: 10px;
  z-index: 3000;
  padding: 20px;
  margin: 0;
  background: transparent;
  border: none;

  :hover {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
`;

const PhotoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Video = styled.video`
  max-height: 100%;
  max-width: 100%;
  background-color: #444444;

  :focus {
    outline: none;
  }
`;

export interface VideoPreviewModalProps {
  userMedia: UserMediaBaseFragment;
  isOpen: boolean;
  onRequestClose: () => void;
}

const VideoPreviewModal: FC<VideoPreviewModalProps> = (props) => {
  const { userMedia, isOpen, onRequestClose } = props;

  const onClickOutsideOfPhoto: MouseEventHandler<HTMLElement> = useCallback(() => {
    onRequestClose();
  }, []);

  const onClickInsideOfPhoto: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      // Prevents onClickOutsideOfPhoto being called if the photo is clicked directly
      e.stopPropagation();
    },
    [],
  );

  return (
    <ContainerModalWithOverlay
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick>
      <CloseModalButton onClick={onRequestClose}>
        <CloseModalIcon stroke="white" />
      </CloseModalButton>
      <PhotoContainer onClick={onClickOutsideOfPhoto}>
        <Video
          controls
          preload="auto"
          onClick={onClickInsideOfPhoto}
          poster={userMedia.thumbnailUrl}>
          <source src={userMedia.url} type={userMedia.mimeType} />
        </Video>
      </PhotoContainer>
    </ContainerModalWithOverlay>
  );
};

export default VideoPreviewModal;
