import { ApolloProvider } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Elements as ReactStripeElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { FC } from 'react';
import ReactGA from 'react-ga';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import SwiperCore, { Keyboard, Zoom } from 'swiper';
import client from './client';
import { LoginCookieSync } from './components/LoginCookieSync';
import config from './config';
import theme from './constants/theme';
import ASToastContainer from './containers/ASToastContainer';
import ViewportProvider from './containers/ViewportProvider';
import Routes from './Routes';
import store, { persistor } from './store';

Bugsnag.start({
  apiKey: '8eb65d5e8bd8fc348d3a54cbce79ac98',
  plugins: [new BugsnagPluginReact()],
  releaseStage: config.BUGSNAG_RELEASE_STAGE,
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

Modal.setAppElement('#root');

SwiperCore.use([Keyboard, Zoom]);

if (config.GOOGLE_ANALYTCS_TRACKING_ID) {
  ReactGA.initialize(config.GOOGLE_ANALYTCS_TRACKING_ID);
}

// Initialise stripe (loading stripe across the whole app rather than only loading once needed assists Stripe's fraud detection tooling)
const stripeSetupPromise = loadStripe(config.STRIPE_PULISHABLE_ID);

const App: FC = () => {
  return (
    // TODO: reenable bugnsag error boundry after development....
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ApolloProvider client={client}>
            <LoginCookieSync>
              <ReactStripeElements stripe={stripeSetupPromise}>
                <ThemeProvider theme={theme}>
                  <ViewportProvider>
                    <ASToastContainer />
                    <Routes />
                  </ViewportProvider>
                </ThemeProvider>
              </ReactStripeElements>
            </LoginCookieSync>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
