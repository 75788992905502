import React, { FC } from 'react';
import styled from 'styled-components';
import config from '../../../config';
import theme from '../../../constants/theme';
import {
  SubscribedSuburbBaseFragment,
  SuburbWithAgentStatsFragment,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import selectedCheckUri from '../assets/selected-check.svg';

const Container = styled.button<{
  $selected: boolean;
  $visiblyDisabled: boolean;
}>`
  display: flex;
  min-height: 137px;
  flex: 1;
  flex-direction: column;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 10px 10px 10px 20px;
  justify-content: flex-start;
  border: none;
  background-color: #ffffff;
  border: ${(props) =>
    props.$selected ? '1px solid #1745B0' : '1px solid #e8eaed'};

  opacity: ${(props) => (props.$visiblyDisabled ? 0.5 : 1)};

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    width: 100%;
  }
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
`;

const AvailabilityContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  min-height: 32px;
  justify-content: space-between;
`;

const AvailabilityText = styled.p<{ $avaialble: boolean }>`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: ${(props) => (props.$avaialble ? '#26c17f' : '#FC4E6F')};
  letter-spacing: 0.14px;
  margin: 0 0 6px 0;
`;

const AgentsDescription = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #8f9aa9;
  letter-spacing: 0.13px;
  line-height: 22px;
  margin: 15px 0 0 0;
`;

const DetailsText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 10px 0 0 0;
`;

const CostText = styled.p`
  display: flex;
  font-family: Inter;
  font-weight: 800;
  font-size: 18px;
  color: #1745b0;
  letter-spacing: 0.19px;
  line-height: 24px;
  margin: 0 5px 1px 0;
`;

const PricingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 10px 0 0 0;
  padding: 0;
`;

const SuburbContainer = styled.div<{ $hasSuburbSubscription: boolean }>`
  display: flex;
  margin: ${(props) =>
    props.$hasSuburbSubscription ? '10px 0 0 0' : '4px 0 0 0'};
  padding: 0;
`;

const Title = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.13px;
  line-height: 25px;
  margin: 0;
`;

export interface SuburbSelectCardProps {
  suburb: SuburbWithAgentStatsFragment;
  visiblyDisabled?: boolean;
  isFreeTrial: boolean;
  onClick: (suburb: SuburbWithAgentStatsFragment) => void;
  selected: boolean;
  suburbSubscription?: SubscribedSuburbBaseFragment;
}

export const usePrecacheForSuburbSelectCard: () => void = () => {
  usePrecacheImages([selectedCheckUri]);
};

const SuburbSelectCard: FC<SuburbSelectCardProps> = ({
  suburb,
  visiblyDisabled = false,
  isFreeTrial,
  onClick,
  selected,
  suburbSubscription,
}) => {
  const availableSlots =
    (suburb.subscribedAgentsMax ?? 5) - (suburb.subscribedAgentsCount ?? 5);
  const someSlotsAvailable = availableSlots > 0;

  return (
    <Container
      onClick={() => onClick(suburb)}
      disabled={
        !someSlotsAvailable ||
        suburbSubscription !== undefined ||
        visiblyDisabled
      }
      $visiblyDisabled={visiblyDisabled}
      $selected={selected}>
      {!suburbSubscription &&
        (someSlotsAvailable ? (
          <>
            <AvailabilityContainer>
              <AvailabilityText $avaialble>Available</AvailabilityText>

              <CheckBox checked={selected ?? false} />
            </AvailabilityContainer>
          </>
        ) : (
          <>
            <AvailabilityContainer>
              <AvailabilityText $avaialble={false}>
                Unavailable
              </AvailabilityText>
            </AvailabilityContainer>
          </>
        ))}
      <SuburbContainer
        $hasSuburbSubscription={suburbSubscription !== undefined}>
        <Title>{`${suburb.name}, ${suburb.state} ${suburb.postcode}`}</Title>
      </SuburbContainer>
      {!suburbSubscription && someSlotsAvailable && !isFreeTrial && (
        <PricingContainer>
          <CostText>
            $
            {new Intl.NumberFormat().format(
              config.SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS,
            )}
          </CostText>
          <DetailsText>/ Year</DetailsText>
        </PricingContainer>
      )}
      {!suburbSubscription && someSlotsAvailable && isFreeTrial && (
        <PricingContainer>
          <CostText>Free</CostText>
        </PricingContainer>
      )}
      {suburbSubscription && (
        <DetailsText>Already subscribed to this suburb</DetailsText>
      )}
      {!suburbSubscription && !someSlotsAvailable && (
        <AgentsDescription>
          No more agents can operate in this suburb
        </AgentsDescription>
      )}
    </Container>
  );
};

export default SuburbSelectCard;
