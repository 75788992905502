import React, { FC } from 'react';
import styled from 'styled-components';
import BackNavLink, { usePrecacheForBackNavLink } from './BackNavLink';
import NavigationFooter from './NavigationFooter';
import NextNavButton, { usePrecacheForNextNavButton } from './NextNavButton';

const BackLink = styled(BackNavLink)`
  margin: 0;
`;

const MiddleSpace = styled.div`
  margin: 0 10px;
`;

const Text = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  border: none;
  padding: 20px;
  margin-top: 12px;
  color: #203553;
`;

export const usePrecacheForStepNavigationFooter: () => void = () => {
  usePrecacheForBackNavLink();
  usePrecacheForNextNavButton();
};
export interface StepNavigationFooterProps {
  backHref: string | null;
  onNextClick: () => void;
  onSkipClick?: () => void;
  nextDisabled: boolean;
  hideBackButton?: boolean;
  loading?: boolean;
  nextButtonLabel?: string;
  showSkipText?: boolean;
}

const StepNavigationFooter: FC<StepNavigationFooterProps> = (props) => {
  const {
    backHref,
    nextDisabled,
    onNextClick,
    hideBackButton = false,
    loading = false,
    nextButtonLabel,
    onSkipClick,
    showSkipText = false,
  } = props;

  return (
    <NavigationFooter
      leftSideComponent={
        !hideBackButton && backHref ? <BackLink href={backHref} /> : null
      }
      skipText={
        onSkipClick &&
        showSkipText && (
          <Text>
            Skip to the end to send your details to selected agents, or continue
            adding property details for an even more precise appraisal.
          </Text>
        )
      }
      rightSideComponent={
        <>
          {onSkipClick && (
            <>
              <NextNavButton
                nextDisabled={nextDisabled}
                onNextClick={onSkipClick}
                loading={loading}
                label="Skip to End"
                widthForDesktop="174px"
              />
              <MiddleSpace />
            </>
          )}
          <NextNavButton
            nextDisabled={nextDisabled}
            onNextClick={onNextClick}
            loading={loading}
            label={nextButtonLabel}
            widthForDesktop="174px"
          />
        </>
      }
    />
  );
};

export default StepNavigationFooter;
