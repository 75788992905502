import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { usePrecacheForAppraisalRequestDetails } from './AppraisalRequestDetails';

export const usePrecacheForNewAppraisalSubmitted: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  id?: string;
}
const NewAppraisalSubmitted: FC = () => {
  const { id } = useParams<ExpectedPathParams>();
  const history = useHistory();

  usePrecacheForAppraisalRequestDetails();
  usePrecacheForAgentMenu();

  const onDoneClicked = () => {
    history.push(`/agent/appraisal-request/${id}/`);
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Appraisal Submitted| AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Appraisal Submitted"
        caption="You have submitted your appraisal for the property. The seller will inform you of the outcome shortly."
        nextButtonLabel="Done"
        onNextButtonClick={onDoneClicked}
      />
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalSubmitted;
