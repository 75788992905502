import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import Url from 'url-parse';
import { PropertyAppraisalComparableSaleBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import comparableSaleBadgeUrl, {
  ReactComponent as ComparableSaleBadgeIcon,
} from '../assets/comparable-sale-badge-empty-encircle-house.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #fff;
  padding: 15px 4px 25px 4px;
`;

const ComparableSaleBadgeContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ComparableSaleBadge = styled(ComparableSaleBadgeIcon)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
`;

const NumberLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1d55ed;
  letter-spacing: 0.15px;
  text-align: center;
  width: 60px;

  z-index: 2;
`;

const ContentContainer = styled.div`
  margin: 0 0 0 23px;
  display: flex;
  flex-direction: column;
`;

const Domain = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 0;
`;

const PropertyLink = styled.a`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #1745b0;
  letter-spacing: 0.2px;

  text-align: left;
  border: none;
  padding: 0;
  margin: 12px 0 0 0;
  background-color: #ffffff;

  :hover {
    color: #1745b0;
    text-decoration-line: underline;
    text-decoration-color: #1745b0;
  }

  @media print {
    display: none;
  }
`;

const AddressLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.2px;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 8px;
`;

const SalePriceLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  opacity: 0.7;
  margin-bottom: 20px;
  letter-spacing: 0.2px;
`;

export const usePrecacheForExternalPropertyPreview: () => void = () => {
  usePrecacheImages([comparableSaleBadgeUrl]);
};
export interface ExternalPropertyPreviewProps {
  comparableSale: PropertyAppraisalComparableSaleBaseFragment;
  number: number;
}

const ExternalPropertyPreview: FC<ExternalPropertyPreviewProps> = (props) => {
  const { comparableSale, number } = props;

  const cleanedUrl = comparableSale.url
    ? comparableSale.url.startsWith('https') ||
      comparableSale.url.startsWith('https')
      ? comparableSale.url
      : `https://${comparableSale.url}`
    : undefined;

  const domain: string | undefined = useMemo(() => {
    try {
      return cleanedUrl && new Url(cleanedUrl).hostname.toLowerCase();
    } catch {
      return '';
    }
  }, [cleanedUrl]);

  return (
    <Container>
      <ComparableSaleBadgeContainer>
        <NumberLabel>{number}</NumberLabel>
        <ComparableSaleBadge />
      </ComparableSaleBadgeContainer>
      <ContentContainer>
        <AddressLabel>{comparableSale.address}</AddressLabel>
        <SalePriceLabel>
          ${new Intl.NumberFormat().format(comparableSale.salePriceDollars)}
        </SalePriceLabel>
        {cleanedUrl && domain && (
          <>
            <Domain>{domain}</Domain>
            <PropertyLink href={cleanedUrl} target="_blank">
              View property
            </PropertyLink>
          </>
        )}
      </ContentContainer>
    </Container>
  );
};

export default ExternalPropertyPreview;

// Below is the currently unused variation of the comparable sales row with preview metadata

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   padding-bottom: 25px;
// `;

// const PreviewImage = styled.img`
//   border-radius: 8px;
//   /* object-fit: cover; */
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;

//   background-color: teal;
// `;

// const Title = styled.h2`
//   font-family: Inter, Arial, sans-serif;
//   font-weight: 600;
//   font-size: 15px;
//   color: #203553;
//   letter-spacing: 0.16px;
//   line-height: 24px;
//   margin-top: 15px;
//   margin-bottom: 1px;
// `;

// const Domain = styled.p`
//   font-family: Inter, Arial, sans-serif;
//   font-weight: 500;
//   font-size: 12px;
//   color: #8f9aa9;
//   letter-spacing: 0.15px;
//   margin-bottom: 12px;
// `;

// const Link = styled.button`
//   font-family: Inter, Arial, sans-serif;
//   font-size: 14px;
//   font-weight: 600;
//   color: #1745B0;
//   letter-spacing: 0.2px;

//   text-align: left;
//   border: none;
//   padding: 0;
//   background-color: #ffffff;

//   :hover {
//     text-decoration-line: underline;
//     text-decoration-color: #ffffff;
//   }
// `;

// const AspectRatioBox = styled.div`
//   position: relative;
//   ::after {
//     display: block;
//     content: '';
//     /* aspect ratio */
//     padding-bottom: 66.32%;
//   }
// `;

// export interface ExternalPropertyPreviewProps {
//   title: string;
//   domain: string;
//   imageUri: string;
// }

// const ExternalPropertyPreview: FC<ExternalPropertyPreviewProps> = ({
//   imageUri,
//   title,
//   domain,
// }) => {
//   return (
//     <Container>
//       <AspectRatioBox>
//         <PreviewImage src={imageUri} />
//       </AspectRatioBox>

//       <Title>{title}</Title>
//       <Domain>{domain}</Domain>
//       <Link>View property</Link>
//     </Container>
//   );
// };

// export default ExternalPropertyPreview;
