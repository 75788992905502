import { Field, useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

const Container = styled.label`
  border-top: 1px solid #e8eaed;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 16px 20px;
  margin: 0;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #58687e;
  letter-spacing: 0.14px;
`;

const CheckBox = styled.div<{ checked: boolean }>`
  background-image: ${(props) =>
    props.checked
      ? 'url(/assets/icons/checkbox-checked.svg)'
      : 'url(/assets/icons/checkbox-unchecked.svg)'};
  width: 30px;
  height: 30px;
  margin: 0;
`;

const HiddenField = styled(Field)`
  display: none;
`;

export const usePrecacheForOptionField: () => void = () => {
  usePrecacheImages([
    '/assets/icons/checkbox-checked.svg',
    '/assets/icons/checkbox-unchecked.svg',
  ]);
};

export interface FeatureOptionFieldProps {
  name: string;
  value: string;
  label: string;
  type?: 'radio' | 'checkbox';
}

const OptionField: FC<FeatureOptionFieldProps> = (props) => {
  const { name, value, label, type = 'checkbox' } = props;

  const [{ checked }] = useField({ name, value, type });

  return (
    <Container>
      <HiddenField name={name} value={value} type={type} />
      <Label>{label}</Label>
      <CheckBox checked={checked ?? false} />
    </Container>
  );
};

export default OptionField;
