import {
  DraftPropertyBaseFragment,
  PropertyBaseFragment,
} from '../graphql/generated';

const fullAddressOfProperty: (
  property?: PropertyBaseFragment | DraftPropertyBaseFragment | null,
) => string = (property) => {
  return property
    ? `${property.streetName} ${
        property.suburb.name
      }, ${property.suburb.state.toUpperCase()}`
    : '';
};

export default fullAddressOfProperty;
