import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import NumberInputField from '../../../components/NumberInputField';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 10px 21px 10px 20px;
`;
const LabelRow = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 15px 0;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 2px 0 0 15px;

  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #58687e;
  letter-spacing: 0.11px;
`;

const SuffixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
  padding-left: 11px;
`;

export interface PropertyQuestionNumberInputRowFieldProps {
  fieldName: string;
  placeholder: string;
  label: string;
  icon: ReactNode;
  suffix?: string;
  maxLength?: boolean;
}
const PropertyQuestionNumberInputRowField: FC<PropertyQuestionNumberInputRowFieldProps> = (
  props,
) => {
  const {
    placeholder,
    icon,
    label,
    fieldName,
    suffix,
    maxLength = true,
  } = props;

  return (
    <Container>
      <LabelRow>
        {icon}
        <Label>{label}</Label>
      </LabelRow>
      <NumberInputField
        fieldName={fieldName}
        placeholder={placeholder}
        maxLength={maxLength ? 2 : undefined}
        renderSuffix={
          suffix ? () => <SuffixLabel>{suffix}</SuffixLabel> : undefined
        }
        style={{
          width: '100%',
        }}
      />
    </Container>
  );
};

export default PropertyQuestionNumberInputRowField;
