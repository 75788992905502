// import urlMetadata from 'url-metadata';
import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { PropertyAppraisalComparableSaleBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import comparableSalesIcon, {
  ReactComponent as ComparableSalesIcon,
} from '../assets/appraisal-comparable-ic.svg';
import ExternalPropertyPreview, {
  usePrecacheForExternalPropertyPreview,
} from './ExternalPropertyPreview';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 30px 20px 10px 20px;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 15px;

  @media print {
    page-break-inside: avoid;
    padding: 30px 40px;
    margin-bottom: 40px;
  }
`;

const Title = styled.h4`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(13)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: 33px;

  @media print {
    text-align: start;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: ${pxToRem(18)};
    font-weight: 800;
    color: #203553;
    letter-spacing: 0.19px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

const ComparableSalesIconStyled = styled(ComparableSalesIcon)`
  align-self: center;
  margin-bottom: 18px;

  @media print {
    align-self: flex-start;
  }
`;

const PreviewContainer = styled.div<{ $showSeparator: boolean }>`
  border-bottom: ${(props) =>
    props.$showSeparator ? '1px solid #e8eaed' : null};
  margin-bottom: ${(props) => (props.$showSeparator ? '10px' : 0)};
`;

export const usePrecacheForComparableSales: () => void = () => {
  usePrecacheImages([comparableSalesIcon]);
  usePrecacheForExternalPropertyPreview();
};

export interface AppraisalComparableSalesProps {
  comparableSales: Array<PropertyAppraisalComparableSaleBaseFragment>;
}

const AppraisalComparableSales: FC<AppraisalComparableSalesProps> = ({
  comparableSales,
}) => {
  return (
    <Container>
      <ComparableSalesIconStyled />
      <Title>Comparable Sales</Title>
      {comparableSales?.map((comparableSale, index) => (
        <PreviewContainer
          key={index}
          $showSeparator={
            comparableSales?.length > 0 && index !== comparableSales?.length - 1
          }>
          <ExternalPropertyPreview
            comparableSale={comparableSale}
            number={index + 1}
          />
        </PreviewContainer>
      ))}
    </Container>
  );
};

export default AppraisalComparableSales;
