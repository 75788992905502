import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import dollarSignIconUrl, {
  ReactComponent as DollarSignIcon,
} from '../../../assets/dollar-sign.svg';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import NumberInputField from '../../../components/NumberInputField';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { useUpdateDraftPropertyAppraisalSellingRangeMutation } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalVideo } from './NewAppraisalVideo';

const DollarSign = styled(DollarSignIcon)`
  margin: 0 0 0 0;
`;

const RangeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 12px 0;
  justify-content: center;
`;

const PriceInputDivider = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 22px;
  color: #798698;
  text-align: center;

  vertical-align: center;
  margin: 0 5px;
`;

export const usePrecacheForNewAppraisalSellingRange: () => void = () => {
  usePrecacheImages([dollarSignIconUrl]);

  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  id?: string;
}

interface FormValues {
  sellingRangeMin?: number;
  sellingRangeMax?: number;
}

const validationSchema = Yup.object().shape({
  sellingRangeMin: Yup.number()
    .nullable(true)
    .required('Min price is required')
    .min(50_000, 'Min price must be at least $50,000'),
  sellingRangeMax: Yup.number()
    .nullable(true)
    .required('Max price is required')
    .min(50_000, 'Max price must be at least $50,000')
    .moreThan(
      Yup.ref('sellingRangeMin') as Reference<number>,
      'Max price must be larger than the min price',
    ),
});

const NewAppraisalSellingRange: FC = () => {
  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const [
    updateDraftPropertyAppraisalSellingRange,
    { loading: updateDraftPropertyAppraisalSellingRangeLoading },
  ] = useUpdateDraftPropertyAppraisalSellingRangeMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { sellingRangeMin, sellingRangeMax } = values;

      if (!draftPropertyAppraisal || !sellingRangeMin || !sellingRangeMax)
        return;

      await updateDraftPropertyAppraisalSellingRange({
        variables: {
          propertyAppraisalId: draftPropertyAppraisal.id,
          sellingRangeMin,
          sellingRangeMax,
        },
      });

      history.push(
        `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/fees`,
      );
    },
    [draftPropertyAppraisal],
  );

  const initialValues = !draftPropertyAppraisal
    ? null
    : ({
        sellingRangeMin: draftPropertyAppraisal.sellingRangeMin,
        sellingRangeMax: draftPropertyAppraisal.sellingRangeMax,
      } as FormValues);

  usePrecacheForNewAppraisalVideo();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Selling Range | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {draftPropertyAppraisal && (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ submitForm, errors, values, touched, setFieldValue }) => {
            const error: string | undefined | false =
              Object.values(touched).filter((touched) => !!touched).length >
                0 &&
              errors &&
              Object.values(errors)[0];

            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <StepNavigationHeader
                    title="Appraise property"
                    stepName="Estimated selling range"
                    stepIndex={1}
                    stepCount={5}
                    stepDescription={
                      'Enter an estimated sale range for this property'
                    }
                  />
                  <RangeInputContainer>
                    <NumberInputField
                      fieldName="sellingRangeMin"
                      allowNegative={false}
                      placeholder="Min price"
                      renderPrefix={() => <DollarSign />}
                      onBlur={() => {
                        if (values.sellingRangeMin && !values.sellingRangeMax) {
                          setFieldValue(
                            'sellingRangeMax',
                            Math.round(values.sellingRangeMin * 1.1),
                          );
                        }
                      }}
                    />

                    <PriceInputDivider>-</PriceInputDivider>

                    <NumberInputField
                      fieldName="sellingRangeMax"
                      allowNegative={false}
                      placeholder="Max price"
                      renderPrefix={() => <DollarSign />}
                    />
                  </RangeInputContainer>
                  {error && <ASErrorLabel>{error}</ASErrorLabel>}
                </StepPageTemplate.ContentContainer>

                <StepNavigationFooter
                  backHref={`/agent/appraisal-request/${propertyAppraisalRequestId}`}
                  onNextClick={submitForm}
                  nextDisabled={updateDraftPropertyAppraisalSellingRangeLoading}
                  loading={updateDraftPropertyAppraisalSellingRangeLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalSellingRange;
