import React, { FC, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import client from '../../../client';
import AgentMenu, {
  usePrecacheForAgentMenu,
} from '../../../components/AgentMenu';
import ASActionButton, {
  usePrecacheForASActionButton,
} from '../../../components/ASActionButton';
import InnerNavigationHeader, {
  usePrecachForInnerNavigationHeader,
} from '../../../components/InnerNavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import {
  PropertyAppraisalRequestBaseFragment,
  PropertyAppraisalRequestBaseFragmentDoc,
  PropertyAppraisalRequestState,
  PropertyBaseFragment,
  usePropertyAppraisalRequestQuery,
  usePropertyAppraisalRequestSeenByAgentMutation,
  useStartNewDraftPropertyAppraisalMutation,
} from '../../../graphql/generated';
import AppraisalRequestStatusBadge from '../components/AppraisalRequestStatusBadge';
import PropertyDetailsAppraisalSummary from '../components/PropertyDetailsAppraisalSummary';
import PropertyDetailsDetails, {
  usePrecacheForPropertyDetailsDetails,
} from '../components/PropertyDetailsDetails';
import PropertyDetailsSummary, {
  usePrecacheForPropertyDetailsSummary,
} from '../components/PropertyDetailsSummary';
import PropertyPhotoSwiper, {
  usePrecacheForPropertyPhotoSwiper,
} from '../components/PropertyPhotoSwiper';

const AppraisePropertyButton = styled(ASActionButton)`
  margin-top: 15px;
  width: 100%;
`;

const BottomSpace = styled.div`
  height: 20px;
`;

export const usePrecacheForAppraisalRequestDetails: () => void = () => {
  usePrecachForInnerNavigationHeader();
  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForPropertyPhotoSwiper();
  usePrecacheForPropertyDetailsSummary();
  usePrecacheForPropertyDetailsDetails();
  usePrecacheForASActionButton();
};
interface ExpectedPathParams {
  id?: string;
}

const AppraisalRequestDetails: FC = () => {
  const { id } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const cachedPropertyAppraisalRequest =
    client.readFragment<PropertyAppraisalRequestBaseFragment>({
      id: `PropertyAppraisalRequest:${id}`,
      fragment: PropertyAppraisalRequestBaseFragmentDoc,
      fragmentName: 'PropertyAppraisalRequestBase',
    }) ?? undefined;

  const {
    data: propertyAppraisalRequestData,
    error: error,
  } = usePropertyAppraisalRequestQuery({
    variables: { id: typeof id === 'string' ? id : '' },
  });

  const [
    propertyAppraisalRequestSeenByAgent,
  ] = usePropertyAppraisalRequestSeenByAgentMutation();

  const [
    startNewPropertyAppraisal,
    { loading: startNewPropertyAppraisalLoading },
  ] = useStartNewDraftPropertyAppraisalMutation();

  const propertyAppraisalRequest:
    | PropertyAppraisalRequestBaseFragment
    | undefined =
    propertyAppraisalRequestData?.propertyAppraisalRequest ??
    cachedPropertyAppraisalRequest;

  const property: PropertyBaseFragment | undefined =
    propertyAppraisalRequest?.property;

  const onAppraisePropertyClick = useCallback(async () => {
    if (!propertyAppraisalRequest) return;

    if (!propertyAppraisalRequest.draftPropertyAppraisal) {
      await startNewPropertyAppraisal({
        variables: {
          propertyAppraisalRequestId: propertyAppraisalRequest.id,
        },
      });
    }

    history.push(`/agent/appraisal-request/${id}/appraise`);
  }, [propertyAppraisalRequest]);

  // Mark agent as seen if necessary
  useEffect(() => {
    if (
      propertyAppraisalRequestData?.propertyAppraisalRequest &&
      !propertyAppraisalRequestData?.propertyAppraisalRequest.seenByAgent
    ) {
      propertyAppraisalRequestSeenByAgent({
        variables: {
          id: propertyAppraisalRequestData.propertyAppraisalRequest.id,
        },
      });
    }
  }, [propertyAppraisalRequestData, id]);

  // If property details hidden, push user back to dashboard where clicking property will prompt them for their credit card
  useEffect(() => {
    if (
      propertyAppraisalRequestData?.propertyAppraisalRequest.property
        .detailsHidden
    ) {
      history.push(
        `/?collect_payment_details_for_property_appraisal_request=${id}`,
      );
    }
  }, [id, propertyAppraisalRequestData]);

  const backHref =
    propertyAppraisalRequest?.state === PropertyAppraisalRequestState.Complete
      ? '/?tab=complete'
      : '/';

  usePrecacheForAgentMenu();

  return (
    <SplitDashboardPageTemplate
      menu={<AgentMenu />}
      noHorzintalPadding
      footer={
        propertyAppraisalRequest?.state ===
        PropertyAppraisalRequestState.Pending ? (
          <AppraisePropertyButton
            onClick={onAppraisePropertyClick}
            loading={startNewPropertyAppraisalLoading}>
            Appraise property
          </AppraisePropertyButton>
        ) : undefined
      }
      padFooter>
      <Helmet>
        <title>Appraisal Request | AgentSpot</title>
      </Helmet>
      {!property && error && error.message === ErrorCodes.NotFound && (
        <NotFoundPlaceholder />
      )}

      {property && (
        <>
          <InnerNavigationHeader
            backHref={backHref}
            pageTitle="Property Details"
          />
          <PropertyPhotoSwiper
            property={property}
            StatusBadgeComponent={
              propertyAppraisalRequest && (
                <AppraisalRequestStatusBadge
                  appraisalRequest={propertyAppraisalRequest}
                />
              )
            }
          />
          <PropertyDetailsSummary property={property} showPropertyType />
          <PropertyDetailsDetails property={property} />
          {propertyAppraisalRequest?.propertyAppraisal ? (
            <PropertyDetailsAppraisalSummary
              propertyAppraisal={propertyAppraisalRequest?.propertyAppraisal}
            />
          ) : (
            <BottomSpace />
          )}
        </>
      )}
    </SplitDashboardPageTemplate>
  );
};

export default AppraisalRequestDetails;
