// configureStore.js

import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistState } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import cart from './features/agent/store/cartReducer';
import { CartState } from './features/agent/store/cartTypes';
import auth from './features/auth/store/authReducer';
import { AuthState } from './features/auth/store/authTypes';

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['cart'], Might be worth adding this to the whitelist later
};

export interface RootState {
  auth: AuthState;
  cart: CartState;
  _persist?: PersistState;
}

const rootReducer = combineReducers({ auth, cart });

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(logger));

export default store;
export const persistor = persistStore(store);
