import React from 'react';
import Select, {
  GroupTypeBase,
  OptionTypeBase,
  StylesConfig,
} from 'react-select';

const customStyles: StylesConfig<
  OptionTypeBase,
  boolean,
  GroupTypeBase<OptionTypeBase>
> = {
  container: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  option: (provided) => ({
    ...provided,
    padding: 10,
    fontFamily: 'Inter, Arial, sans-serif',
    fontSize: '0.875rem',
    color: '#203553',
    letterSpacing: '0.2px',
    lineHeight: '23px',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  control: () => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #282828',
    borderRadius: 10,
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    color: '#a6aeba',
    letterSpacing: 0.2,
    minHeight: 55,
    marginTop: 13,
    paddingLeft: 12,
  }),
  indicatorsContainer: () => ({
    marginRight: 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: () => ({
    color: '#282828',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
};

/* eslint-disable-next-line */
// @ts-ignore
// eslint-disable-next-line
export const SelectField: React.FC<any> = ({
  options,
  field,
  placeholder,
  form,
}) => (
  <Select
    options={options}
    name={field.name}
    styles={customStyles}
    placeholder={placeholder}
    value={
      options
        ? options.find((option: OptionTypeBase) => option.value === field.value)
        : ''
    }
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
  />
);
