import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import directionRightArrowUrl, {
  ReactComponent as DirectionRightArrow,
} from '../../../assets/direction-right-arrow.svg';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import addSuburbIconUrl, {
  ReactComponent as AddSuburbIcon,
} from '../assets/select_suburb.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #ffffff;
  border: ${theme.style.border};
  padding: 32px 25px;
  background-color: #fff;
  margin-bottom: 12px;
  background-image: linear-gradient(90deg, #1745b0 0%, #001cdf 100%);
  margin: 24px 0;
  border-radius: 10px;
`;

const DetailsText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(13)};
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0.18px;
  line-height: 23px;
  margin: 0;
`;

const BannerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const Title = styled.p<{ $fontSize?: number }>`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${(props) =>
    props.$fontSize ? pxToRem(props.$fontSize) : pxToRem(18)};
  font-weight: 700;
  color: #ffffff;
  line-height: 24px;
  margin: 0px;
`;

const ActionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

interface AddSuburbBannerProps {
  description?: string;
  actionDescription?: string;
}

const ActionLink = styled(Link)`
  :hover {
    text-decoration-line: none;
  }
`;

export const usePrecacheForAddSuburbBanner: () => void = () => {
  usePrecacheImages([addSuburbIconUrl, directionRightArrowUrl]);
};

const AddSuburbBanner: FC<AddSuburbBannerProps> = ({
  description,
  actionDescription,
}) => {
  return (
    <Container>
      <BannerHeader>
        <AddSuburbIcon />
        <Title>Add suburb</Title>
      </BannerHeader>
      <DetailsText>{description}</DetailsText>
      <ActionLink to="/agent/profile-settings/?tab=suburbs&isModalOpen=true">
        <ActionContainer>
          <Title $fontSize={14}>{actionDescription}</Title>
          <DirectionRightArrow />
        </ActionContainer>
      </ActionLink>
    </Container>
  );
};

export default AddSuburbBanner;
