import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ActionMenuItem from '../../../components/ActionMenuItem';
import { usePrecacheForASModal } from '../../../components/ASModal';
import theme from '../../../constants/theme';
import { PropertyBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import starIconUrl, {
  ReactComponent as StarIcon,
} from '../assets/mark-favourite-star-white.svg';

export const usePrecacheForPropertyActionMenu: () => void = () => {
  usePrecacheImages([starIconUrl]);

  usePrecacheForASModal();
};
const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 25px 25px;
  margin: 15px;

  background: linear-gradient(90deg, #1745b0 20%, #001cdf 80%);
  border-radius: 10px;
  top: 35px;
  right: 0;
  z-index: 3;
  border: ${theme.style.border};
`;

export interface UniversalPropertyActionMenuProps {
  property: PropertyBaseFragment;
}

export const usePrecacheForUniversalPropertyActionMenu: () => void = () => {
  usePrecacheImages([starIconUrl]);

  usePrecacheForASModal();
};

const UniversalPropertyActionMenu: FC<UniversalPropertyActionMenuProps> = (
  props,
) => {
  const { property } = props;

  return (
    <>
      <Container to={`/property/${property.id}/sale-status`}>
        <ActionMenuItem
          textColour={'white'}
          icon={<StarIcon />}
          title="Mark property sale status"
        />
      </Container>
    </>
  );
};

export default UniversalPropertyActionMenu;
