import { ApolloError } from '@apollo/client';

export enum ErrorCodes {
  RequiredQuestionsNotAnswered = 'required_questions_not_answered',
  PasswordTooShort = 'password_too_short',
  EmailIsAlreadyInUse = 'email_is_already_in_use',
  EmailInvalid = 'email_invalid',
  InvalidCredentials = 'invalid_credentials',
  UserMediaFileExtensionNotSupported = 'user_media_file_extension_not_supported',
  AppointmentAlreadyRequestedWithThisAgent = 'appointment_already_requested_with_this_agent',
  CannotUpdateCompleteTypeForUnconditionallySoldProperty = 'cannot_update_complete_type_for_uncoditionally_sold_property',
  PaymentDeclined = 'payment_declined',
  SomeSuburbAtCapactiy = 'some_suburb_at_capacity',
  SomeSuburbAlreadySubscribedTo = 'some_suburb_already_subscribed_to',
  AgentProfileIsIncomplete = 'agents_profile_is_incomplete',
  UserMissingStripeCustomer = 'user_missing_stripe_customer',
  InvalidPasswordRestToken = 'invalid_password_reset_token',
  SuburbSubscriptionManuallyManaged = 'suburb_subscription_manually_managed',
  NotFound = 'not_found',
}

export const errorCodesToMessage: { [key in string]: string } = {
  [ErrorCodes.RequiredQuestionsNotAnswered]:
    'Some required questions have not been answered.',
  [ErrorCodes.PasswordTooShort]: 'Password is too short',
  [ErrorCodes.EmailIsAlreadyInUse]: 'Email address is already in use',
  [ErrorCodes.EmailInvalid]: 'Email address is invalid',
  [ErrorCodes.InvalidCredentials]: 'Incorrect email or password',
  [ErrorCodes.UserMediaFileExtensionNotSupported]:
    'Media with unsupported uploaded',
  [ErrorCodes.AppointmentAlreadyRequestedWithThisAgent]:
    'Appointment already requested with this agent',
  [ErrorCodes.CannotUpdateCompleteTypeForUnconditionallySoldProperty]:
    'Property is already marked as unconditionally sold',
  [ErrorCodes.PaymentDeclined]: 'Payment declined',
  [ErrorCodes.SomeSuburbAtCapactiy]:
    'One of these suburbs is no longer available',
  [ErrorCodes.SomeSuburbAlreadySubscribedTo]:
    'You are already subscribed to one of these suburbs',
  [ErrorCodes.AgentProfileIsIncomplete]:
    'You are missing a required field on your profile',
  [ErrorCodes.UserMissingStripeCustomer]:
    'You are missing a default payment method',
  [ErrorCodes.InvalidPasswordRestToken]: 'Password reset link no longer valid',
  [ErrorCodes.SuburbSubscriptionManuallyManaged]:
    'This suburb subscription can only be manually managed by AgentSpot admins',
  [ErrorCodes.NotFound]: 'Not found',
};

export const apolloErrorToString: (error: ApolloError) => string = (error) => {
  if (error.message == 'Unexpected token < in JSON at position 0') {
    return 'Unexpected server error';
  } else if (error.message in errorCodesToMessage) {
    return errorCodesToMessage[error.message];
  } else if (error.networkError) {
    return 'Encountered network error.';
  } else {
    return 'An unexpected error occurred.';
  }
};

export const alertOnError = (error: ApolloError): void => {
  if (error.message in errorCodesToMessage) {
    alert(errorCodesToMessage[error.message]);
  } else {
    alert('An unexpected error occurred, please try again later.');
  }
};
