import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../constants/theme';

const Container = styled.div`
  max-width: 80vw;
  height: 100%;

  padding: 105px 45px 27px 20px;
  background-color: #ffffff;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    width: 300px;
  }

  display: flex;
  flex-direction: column;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-grow: 1;
  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    flex-grow: initial;
  }
`;

const LogOutLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  :hover {
    text-decoration: underline;
    color: #a6aeba;
    text-decoration-color: #a6aeba;
  }
`;

export interface MenuTemplateProps {
  profileSection: ReactNode;
  links: ReactNode;
}

const MenuTemplate: FC<MenuTemplateProps> = (props) => {
  const { profileSection: ProfileSection, links: Links } = props;

  return (
    <Container>
      {ProfileSection}
      <LinksContainer>{Links}</LinksContainer>
      <LogOutLink to="/logout">Log Out</LogOutLink>
    </Container>
  );
};

export default MenuTemplate;
