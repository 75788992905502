import React, { FC } from 'react';
import styled from 'styled-components';
import IntroOfferHeader, {
  usePrecacheForIntroOfferHeader,
} from '../../../components/IntroOfferHeader';
import config from '../../../config';
import theme from '../../../constants/theme';
import { pxToRem } from '../../../utils/pxToRem';

const Container = styled.div<{
  $alignment: 'flex-start' | 'center' | undefined;
  $verticalPadding: number | undefined;
}>`
  display: flex;
  flex: 0;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  padding: ${(props) =>
    props.$verticalPadding ? `${props.$verticalPadding}px 25px` : '32px 25px'};
  align-items: ${(props) => props.$alignment ?? 'center'};
  background-color: #fff;
  margin-bottom: 12px;
  background-image: linear-gradient(90deg, #1745b0 0%, #001cdf 100%);
  margin: 0 0 32px 0;
  border-radius: 10px;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    margin: 0 -20px 32px -20px;
    border-radius: 0;
  }
`;

const DetailsText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(13)};
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin: 0;
`;

const FooterNote = styled(DetailsText)`
  margin-top: 8px;
`;

const Title = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(20)};
  font-weight: 700;
  color: #ffffff;
  line-height: 24px;
  margin: 16px 0 4px 0;
`;

interface IntroOfferTemplateProps {
  description: string;
  footerNote?: string;
  alignment?: 'flex-start' | 'center';
  verticalPadding?: number;
}

const IntroOfferTemplate: FC<IntroOfferTemplateProps> = ({
  description,
  footerNote,
  alignment,
  verticalPadding,
}) => {
  usePrecacheForIntroOfferHeader();

  return (
    <Container $alignment={alignment} $verticalPadding={verticalPadding}>
      <IntroOfferHeader />
      <Title>{`Lock in up to ${config.MAX_AGENT_SUBURBS} suburbs for 12 months`}</Title>
      <DetailsText>{description}</DetailsText>
      <FooterNote>{footerNote}</FooterNote>
    </Container>
  );
};

export default IntroOfferTemplate;
