import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import noAppraisalsPlaceholderIconUrl, {
  ReactComponent as NoAppraisalsPlaceholderIcon,
} from '../assets/no-appraisals-placeholder.svg';
import EmptyPlaceholder from './EmptyPlaceholder';

const NoAppraisalsPlaceholderImage = styled(NoAppraisalsPlaceholderIcon)`
  width: 273px;
  height: 192px;
`;

export const usePrecacheForNoPendingRequestsPlaceholder: () => void = () => {
  usePrecacheImages([noAppraisalsPlaceholderIconUrl]);
};

const NoPendingRequestsPlaceholder: FC = () => {
  return (
    <EmptyPlaceholder
      image={<NoAppraisalsPlaceholderImage />}
      title="No requests right now"
      explanation="You'll receive appraisals requests here when sellers request you as an agent"
    />
  );
};

export default NoPendingRequestsPlaceholder;
