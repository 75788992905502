import { ParsedQuery } from 'query-string';
import React, { FC, ReactNode, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Maybe,
  PropertyAppraisalRequestEdgeBaseFragment,
} from '../../../graphql/generated';
import useQueryParams from '../../../hooks/useQueryParams';
import AgentAddPaymentDetailsToViewPropertyModal from '../../agent/containers/AgentAddPaymentDetailsToViewPropertyModal';
import AppraisalRequestCard from '../components/AppraisalRequestCard';

interface ExpectedQueryParams extends ParsedQuery {
  tab: string | string[] | null;
  collect_payment_details_for_property_appraisal_request:
    | string
    | string[]
    | null;
}

const AppraisalRequestsInfiniteScroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  width: 100%;
  overflow: visible !important;
`;

export interface AppraisalRequestListProps {
  appraisalRequestEdges?: Maybe<PropertyAppraisalRequestEdgeBaseFragment>[];
  hasMore: boolean;
  onScrollEndReached?: () => void;
  emptyPlaceholder: ReactNode;
  showAppraisal?: boolean;
}

const AppraisalRequestList: FC<AppraisalRequestListProps> = (props) => {
  const {
    appraisalRequestEdges,
    hasMore,
    onScrollEndReached,
    emptyPlaceholder,
    showAppraisal = false,
  } = props;

  const history = useHistory();
  const {
    tab,
    collect_payment_details_for_property_appraisal_request: collectPaymentDetailsForPropertyAppraisalRequest,
  } = useQueryParams<ExpectedQueryParams>();

  const paymentDetailsModalIsOpen =
    collectPaymentDetailsForPropertyAppraisalRequest !== undefined;

  const onRequestPaymentDetailsModalComplete = useCallback(() => {
    history.replace(
      `/agent/appraisal-request/${collectPaymentDetailsForPropertyAppraisalRequest}`,
    );
  }, [collectPaymentDetailsForPropertyAppraisalRequest, history]);
  const activeTab = tab === 'complete' ? 'complete' : 'pending';

  const onRequestPaymentDetailsModalClose = useCallback(() => {
    history.replace(`/?tab=${activeTab}`);
  }, [activeTab, history]);

  return (
    <>
      {collectPaymentDetailsForPropertyAppraisalRequest && (
        <AgentAddPaymentDetailsToViewPropertyModal
          id={collectPaymentDetailsForPropertyAppraisalRequest.toString()}
          isOpen={paymentDetailsModalIsOpen}
          onRequestClose={onRequestPaymentDetailsModalClose}
          onPaymentDetailsSet={onRequestPaymentDetailsModalComplete}
        />
      )}
      {appraisalRequestEdges ? (
        appraisalRequestEdges.length > 0 ? (
          <AppraisalRequestsInfiniteScroll
            dataLength={appraisalRequestEdges?.length ?? 0}
            /* eslint-disable-next-line */
            // @ts-ignore
            next={onScrollEndReached}
            hasMore={hasMore}
            loader={null}
            endMessage={null}
            scrollThreshold={0.8}>
            {appraisalRequestEdges.map(
              (appraisalEdge, i) =>
                appraisalEdge?.node && (
                  <>
                    <AppraisalRequestCard
                      appraisalRequest={appraisalEdge?.node}
                      key={appraisalEdge?.node?.id ?? i}
                      showAppraisal={showAppraisal}
                    />
                  </>
                ),
            )}
          </AppraisalRequestsInfiniteScroll>
        ) : (
          emptyPlaceholder
        )
      ) : null}
    </>
  );
};

export default AppraisalRequestList;
