import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import React, {
  FC,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  MyCompleteAppraisalRequestsDocument,
  MyDefaultPaymentMethodDocument,
  MyPendingAppraisalRequestsDocument,
  useMeQuery,
  UserType,
  useStripeConfirmPaymentMethodMutation,
  useStripeCreateSetupIntentMutation,
} from '../../../graphql/generated';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  margin: 0 -5px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  margin: 0 5px 20px 5px;
  width: 100%;
`;

const InputLabel = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 0 0 10px 0;
`;

const InputShape = styled.div`
  border: 1px solid #282828;
  border-radius: 10px;
  padding: 18px 20px;
  width: 100%;
`;

const AddButton = styled(ASActionButton).attrs({ type: 'submit' })`
  margin-top: 15px;
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily: 'Inter, Arial, sans-serif',
      fontSize: '14px',
      color: '#58687e',
      '::placeholder': {
        color: 'rgba(220, 220, 220, 1)',
      },
    },
    invalid: {
      color: '#fc4e6F',
    },
  },
};

export interface CardDetailsFormProps {
  header?: ReactNode;
  onCardAdded: () => void;
}

const CardDetailsForm: FC<CardDetailsFormProps> = ({ onCardAdded, header }) => {
  const { data: meData, loading: meLoading } = useMeQuery();

  const [
    stripeCreateSetupIntent,
    {
      data: stripeCreateSetupIntentData,
      loading: stripeCreateSetupIntentLoading,
      error: stripeCreateSetupIntentError,
    },
  ] = useStripeCreateSetupIntentMutation();

  const [
    stripeConfirmPaymentMethod,
    {
      loading: stripeConfirmPaymentMethodLoading,
      error: stripeConfirmPaymentMethodError,
    },
  ] = useStripeConfirmPaymentMethodMutation({
    refetchQueries: [
      { query: MyDefaultPaymentMethodDocument },
      ...(meData?.me.userType === UserType.Agent
        ? [
            {
              query: MyPendingAppraisalRequestsDocument,
              variables: {
                first: 10,
              },
            },
            {
              query: MyCompleteAppraisalRequestsDocument,
              variables: {
                first: 10,
              },
            },
          ]
        : []),
    ],
    awaitRefetchQueries: true,
  });

  // Start new Stripe setup intent on page load
  useEffect(() => {
    if (!stripeCreateSetupIntentData?.stripeCreateSetupIntent) {
      stripeCreateSetupIntent();
    }
  }, [stripeCreateSetupIntentData]);

  const clientSecret =
    stripeCreateSetupIntentData?.stripeCreateSetupIntent.clientSecret;

  const stripe = useStripe();
  const elements = useElements();

  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [stripeError, setStripeError] = useState<string | null>(null);

  const onSubmit: (event: FormEvent) => Promise<void> = useCallback(
    async (event: FormEvent) => {
      event.preventDefault(); // Prevent form from posting data directly

      if (!stripe || !elements) {
        return; // Stripe.js has not yet loaded.
      }

      if (!clientSecret || !meData?.me?.agentProfile?.name) {
        return; // Page isn't ready
      }

      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
        return; // Page not ready
      }

      setStripeError(null);
      setStripeLoading(true);

      try {
        const result = await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: meData?.me?.agentProfile?.name,
            },
          },
        });

        setStripeLoading(false);

        if (result.error) {
          setStripeError(result.error.message ?? 'Unexpected error');
          stripeCreateSetupIntent(); // Refresh setup intent

          // Display result.error.message in your UI.
        } else {
          if (result.setupIntent.payment_method) {
            stripeConfirmPaymentMethod({
              variables: {
                stripePaymentMethodId: result.setupIntent.payment_method,
              },
            })
              .then(() => onCardAdded())
              .catch(() => {
                stripeCreateSetupIntent(); // Refresh setup intent
              });
          }
        }
      } catch (e) {
        setStripeLoading(false);
        setStripeError(
          typeof e?.message == 'string' ? e.message : 'Unexpected error',
        );
        stripeCreateSetupIntent(); // Refresh setup intent
      }
    },

    [clientSecret, stripe, elements, meData],
  );

  const disableSubmitButton =
    stripeLoading ||
    meLoading ||
    stripeCreateSetupIntentLoading ||
    stripeConfirmPaymentMethodLoading ||
    !!stripeCreateSetupIntentError;

  const loading = stripeLoading || stripeConfirmPaymentMethodLoading;

  const error =
    stripeError ||
    (stripeCreateSetupIntentError &&
      apolloErrorToString(stripeCreateSetupIntentError)) ||
    (stripeConfirmPaymentMethodError &&
      apolloErrorToString(stripeConfirmPaymentMethodError));

  return (
    <Container>
      <Form>
        {header}
        <InputRow>
          <InputContainer>
            <InputLabel>Card number</InputLabel>
            <InputShape>
              <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
            </InputShape>
          </InputContainer>
        </InputRow>

        <InputRow>
          <InputContainer>
            <InputLabel>Expiry</InputLabel>
            <InputShape>
              <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
            </InputShape>
          </InputContainer>

          <InputContainer>
            <InputLabel>CVC</InputLabel>
            <InputShape>
              <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
            </InputShape>
          </InputContainer>
        </InputRow>

        {error && <ASErrorLabel>{error}</ASErrorLabel>}

        <AddButton
          disabled={disableSubmitButton}
          loading={loading}
          onClick={onSubmit}>
          Add payment details
        </AddButton>
      </Form>
    </Container>
  );
};

export default CardDetailsForm;
