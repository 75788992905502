import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import { useSendPasswordResetEmailMutation } from '../../../graphql/generated';
import { usePrecacheForResetPasswordSuccess } from './RestPasswordSuccess';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(ASHeader)`
  margin-bottom: 21px;
`;

const Expalanation = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  line-height: 22px;
  margin: 0 0 40px 0;
`;

const SendLinkButton = styled(ASBlockButton)`
  margin: 26px 0 21px 0;
  width: 100%;
`;

export const usePrecacheForResetPassword: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};
interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
});

const ResetPassword: FC = () => {
  const history = useHistory();

  const [
    sendPasswordResetEmail,
    { loading, error: sendPasswordResetEmailError },
  ] = useSendPasswordResetEmailMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { data } = await sendPasswordResetEmail({
        variables: {
          email: values.email,
        },
      });

      if (data) {
        history.push('/reset-password/success');
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  usePrecacheForResetPasswordSuccess();

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Reset Password | AgentSpot</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount }) => {
          const error: string | undefined =
            (submitCount > 0 && errors && Object.values(errors)[0]) ||
            (sendPasswordResetEmailError &&
              apolloErrorToString(sendPasswordResetEmailError));

          return (
            <Form>
              <Header>Reset Password</Header>

              <Expalanation>
                Enter the email address you used to sign up with and we will
                email you a password reset link
              </Expalanation>

              <TextInputField
                name="email"
                type="email"
                placeholder="Email address"
              />
              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <SendLinkButton loading={loading} onClick={submitForm}>
                Send link
              </SendLinkButton>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default ResetPassword;
