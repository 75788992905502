import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import GooglePlacesSelectField from '../../../components/GooglePlacesSelectField';
import TextInputField from '../../../components/TextInputField';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: stretch;
`;

const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

const SubmitButton = styled(ASBlockButton)`
  margin: 70px 0 21px 0;
  width: 100%;
`;

interface FormValues {
  streetAddress: string;
  suburb?: google.maps.places.PlaceResult;
}

const initialValues: FormValues = {
  streetAddress: '',
  suburb: undefined,
};

const validationSchema = Yup.object().shape({
  streetAddress: Yup.string().required('Street address is required'),
  suburb: Yup.object().required('Suburb is required'),
});

export interface SuburbDetails {
  suburbs: Array<string>;
  state: string;
  postcode: string;
}

export interface ManualSuburbFormProps {
  onAddressSubmitted: (
    formattedAddress: string,
    suburbDetails: google.maps.places.PlaceResult,
  ) => void;
}

const ManualSuburbForm: FC<ManualSuburbFormProps> = ({
  onAddressSubmitted,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      if (values.streetAddress && values.suburb) {
        onAddressSubmitted(values.streetAddress, values.suburb);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount, setFieldValue }) => {
          const error: string | false | undefined =
            submitCount > 0 && errors && Object.values(errors)[0];

          return (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}>
              <TextInputField
                name="streetAddress"
                placeholder="Street Address"
              />
              <GooglePlacesSelectField
                fieldName="suburb"
                placeholder="Suburb"
                searchTerm={searchTerm}
                onOptionSelected={(suburb) => {
                  setFieldValue('suburb', suburb);
                }}
                onSearchTermChanged={setSearchTerm}
                googleApiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                types={['(cities)']}
              />
              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <SubmitButton onClick={submitForm}>Done</SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ManualSuburbForm;
