import styled from 'styled-components';

const StatusBadge = styled.div`
  padding: 10px 15px 12px 15px;

  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #203553;
  letter-spacing: 0.18px;

  background: #ffffff;
  border-radius: 7px;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default StatusBadge;
