import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString, ErrorCodes } from '../../../constants/ErrorCodes';
import { usePropertySaleQuery } from '../../../graphql/generated';
import fullAddressOfProperty from '../../../utils/fullAddressOfProperty';
import { usePrecacheForConfirmSalePriceAndDate } from './ConfirmSalePriceAndDate';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled.h1`
  opacity: 0.5;
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #2a335f;
  margin-bottom: 21px;
`;

const Title = styled.h2`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: 700;
  font-size: 40px;
  color: #203553;
  line-height: 55px;
  margin-bottom: 45px;
`;

const ConfirmButton = styled(ASBlockButton)`
  margin: 60px 0 21px 0;
  width: 100%;
`;

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

export const usePrecacheForConfirmSale: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};
interface FormValues {
  clientName: string;
  propertyAddress: string;
}

const validationSchema = Yup.object().shape({
  clientName: Yup.string().required('Client name is required'),
  propertyAddress: Yup.string().required('Property address is required'),
});

interface ExpectedPathParams {
  id?: string;
}

const ConfirmSale: FC = () => {
  const { id: propertySaleId } = useParams<ExpectedPathParams>();
  const {
    data: propertySaleData,
    loading: loading,
    error: propertySaleError,
  } = usePropertySaleQuery({ variables: { id: propertySaleId ?? '' } });

  const history = useHistory();

  const onSubmit = useCallback(async () => {
    try {
      history.push(`/agent/property-sale/${propertySaleId}/confirm/details`);

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  const initialValues: FormValues = propertySaleData
    ? {
        clientName: propertySaleData.propertySale.sellerName ?? '',
        propertyAddress: fullAddressOfProperty(
          propertySaleData.propertySale.property,
        ),
      }
    : {
        clientName: '',
        propertyAddress: '',
      };

  usePrecacheForConfirmSalePriceAndDate();

  const propertySale = propertySaleData?.propertySale;

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Confirm Sale | AgentSpot</title>
      </Helmet>

      {!loading &&
        !propertySale &&
        propertySaleError &&
        propertySaleError.message === ErrorCodes.NotFound && (
          <NotFoundPlaceholder />
        )}
      {!loading && propertySale && (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ submitForm, errors, submitCount }) => {
            const error: string | undefined =
              (submitCount > 0 && errors && Object.values(errors)[0]) ||
              (propertySaleError &&
                (propertySaleError.message === 'not_found'
                  ? 'Property sale not found'
                  : apolloErrorToString(propertySaleError)));

            return (
              <Form>
                <Header>Confirm Sale</Header>
                <Title>Confirm that you sold this property...</Title>

                <InputLabel>Clients name</InputLabel>
                <TextInputField
                  name="clientName"
                  placeholder="Clients name"
                  disabled
                />
                <InputLabel>Property address</InputLabel>
                <TextInputField
                  name="propertyAddress"
                  placeholder="Property address"
                  disabled
                />
                {submitCount > 0 && error && (
                  <ASErrorLabel>{error}</ASErrorLabel>
                )}

                <ConfirmButton disabled={loading} onClick={submitForm}>
                  Confirm details
                </ConfirmButton>
              </Form>
            );
          }}
        </Formik>
      )}
    </SideBySidePageTemplate>
  );
};

export default ConfirmSale;
