import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import {
  DraftPropertyAppraisalBaseFragment,
  usePropertyAppraisalRequestQuery,
} from '../../../graphql/generated';

const useDraftPropertyAppraisal: (
  propertyAppraisalRequestId?: string,
) => {
  draftPropertyAppraisal?: DraftPropertyAppraisalBaseFragment | null;
  loading: boolean;
  notFound: boolean;
} = (propertyAppraisalRequestId) => {
  const history = useHistory();

  const { data, error, loading } = usePropertyAppraisalRequestQuery({
    variables: {
      id: propertyAppraisalRequestId!,
    },
    skip: !propertyAppraisalRequestId,
  });

  useEffect(() => {
    if (
      data?.propertyAppraisalRequest &&
      !data.propertyAppraisalRequest.draftPropertyAppraisal
    ) {
      history.push(`/agent/appraisal-request/${propertyAppraisalRequestId}`);
    }
  }, [propertyAppraisalRequestId, data]);

  return {
    draftPropertyAppraisal:
      data?.propertyAppraisalRequest.draftPropertyAppraisal,
    loading,
    notFound: !!error && error.message === ErrorCodes.NotFound,
  };
};

export default useDraftPropertyAppraisal;
