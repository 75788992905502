import { FC, useEffect } from 'react';
import config from '../../../config';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useLogout from '../hooks/useLogout';
import Cookies from 'js-cookie';

const Logout: FC = () => {
  const isAuthenticated = useIsAuthenticated();

  const logout = useLogout();

  // Logout user on page load
  useEffect(() => {
    if (isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      Cookies.set('loginStatus', 'loggedOut', { domain: '.agentspot.com.au' });
      window.location.replace(config.NEXT_APP_URI);
    }
  }, [isAuthenticated]);

  return null;
};

export default Logout;
