import { Field, FieldAttributes, useField, useFormikContext } from 'formik';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const InputShape = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  border: 1px solid ${(props) => (props.error ? '#fc4e6F' : '#282828')};
  border-radius: 0px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.2px;
  padding: 0 0;

  margin: 0 0 9px 0;
  overflow: hidden;
`;

const InputField = styled(Field)`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  border: none;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 18px 20px;
  margin: 0;
  min-width: 50px;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  :focus {
    outline: none;
  }

  ::placeholder {
    color: #282828;
  }
`;

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface TextInputFieldProps extends FieldAttributes<any> {
  maxLength?: number;
  placeholder: string;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  renderPrefix?: () => ReactNode;
  renderSuffix?: () => ReactNode;
  disabled?: boolean;
  suffixPadding?: number;
}

const TextInputField: FC<TextInputFieldProps> = (props) => {
  const {
    name,
    placeholder,
    renderPrefix,
    renderSuffix,
    className,
    showErrorAfterTouch = false,
    showErrorAfterFormSubmit = true,
    disabled,
    suffixPadding,
    ...rest
  } = props;

  const [, { error, touched }] = useField({
    name,
  });

  const { submitCount } = useFormikContext();

  const showError =
    (showErrorAfterFormSubmit && submitCount > 0) ||
    (showErrorAfterTouch && touched);

  return (
    <InputShape error={showError && !!error} className={className}>
      {renderPrefix ? renderPrefix() : null}
      <InputField
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        style={{ paddingRight: suffixPadding ?? 20 }}
        {...rest}
      />
      {renderSuffix ? renderSuffix() : null}
    </InputShape>
  );
};

export default TextInputField;
