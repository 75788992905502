import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { AgentCommissionCommissionType } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import feesDividerUri, {
  ReactComponent as FeedDivider,
} from '../assets/appraisal-fees-divider.svg';
import feesIconUri, {
  ReactComponent as FeesIcon,
} from '../assets/appraisal-fees-ic.svg';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 30px 0;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 15px;

  @media print {
    page-break-inside: avoid;
    padding: 30px 40px;
    margin-bottom: 40px;
  }
`;

const FeesIconStyled = styled(FeesIcon)`
  align-self: center;
  margin-bottom: 18px;

  @media print {
    align-self: flex-start;
  }
`;

const FeesDividerStyled = styled(FeedDivider)`
  align-self: center;

  @media print {
    margin: 0 65px 0 65px;
  }
`;

const Title = styled.h4`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(13)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  margin-bottom: 18px;

  @media print {
    text-align: start;
  }
`;

const Header = styled(Title)`
  @media print {
    text-align: left;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: ${pxToRem(18)};
    font-weight: 800;
    color: #203553;
    letter-spacing: 0.19px;
    line-height: 26px;
    margin-bottom: 40px;
  }
`;

const FeeDescription = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(12)};
  color: #a6aeba;
  letter-spacing: 0.15px;
  text-align: center;
  margin: 0;
`;

const Value = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(18)};
  color: #203553;
  font-weight: 600;
  letter-spacing: 0.14px;
  text-align: center;
`;

const FeesBreakdownContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 7px;
  padding: 0 30px 40px 30px;

  @media print {
    justify-content: flex-start;
    padding: 0 0 40px 0;
  }
`;

const MarketingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media print {
    flex: 0;
    align-items: flex-start;
  }
`;

const CommissionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media print {
    flex: 0;
    align-items: flex-start;
  }
`;

const PercentageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GST = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: ${pxToRem(10)};
  color: #a6aeba;
  letter-spacing: 0.12px;
  text-align: center;
  margin-left: 3px;
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  background: #1745b0;
`;

const InclusionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 9px 0;
`;

const Inclusion = styled.p`
  opacity: 0.8;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(14)};
  color: #203553;
  letter-spacing: 0.2px;
  line-height: 23px;
  margin: 0 0 0 18px;
`;

const InclusionsTitle = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(15)};
  color: #203553;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin-bottom: 17px;
`;

const InclusionsContainer = styled.div`
  border-top: 1px solid #e8eaed;
  padding: 35px 20px 0 20px;
`;

export const usePrecacheForFeesBox: () => void = () => {
  usePrecacheImages([feesIconUri, feesDividerUri]);
};

export interface AppraisalFeesBoxProps {
  commisionRate?: number;
  commisionFee?: number;
  commisionType: AgentCommissionCommissionType;
  marketingFee: number;
  marketingInclusions: Array<string>;
}

const AppraisalFeesBox: FC<AppraisalFeesBoxProps> = ({
  marketingFee,
  commisionType,
  commisionRate,
  commisionFee,
  marketingInclusions,
}) => {
  return (
    <Container>
      <FeesIconStyled />
      <Header>Fees & Commission</Header>
      <FeesBreakdownContainer>
        <MarketingContainer>
          <Title>Marketing</Title>
          <Value>{`$${new Intl.NumberFormat().format(marketingFee)}`}</Value>
          <FeeDescription>one time fee</FeeDescription>
        </MarketingContainer>
        <FeesDividerStyled />
        <CommissionContainer>
          <Title>Commission</Title>
          {commisionType === AgentCommissionCommissionType.Percentage &&
            commisionRate && (
              <>
                <PercentageContainer>
                  <Value>{`${new Intl.NumberFormat().format(
                    commisionRate,
                  )}%`}</Value>
                  <GST>INC. GST</GST>
                </PercentageContainer>
                <FeeDescription>of total sale</FeeDescription>
              </>
            )}
          {commisionType === AgentCommissionCommissionType.FlatFee &&
            commisionFee && (
              <>
                <Value>{`$${new Intl.NumberFormat().format(
                  commisionFee,
                )}`}</Value>
                <FeeDescription>one time fee</FeeDescription>
              </>
            )}
        </CommissionContainer>
      </FeesBreakdownContainer>
      <InclusionsContainer>
        <InclusionsTitle>Marketing inclusions</InclusionsTitle>
        {marketingInclusions.map((inclusion, index) => (
          <InclusionRow key={index}>
            <Dot />
            <Inclusion>{inclusion}</Inclusion>
          </InclusionRow>
        ))}
      </InclusionsContainer>
    </Container>
  );
};

export default AppraisalFeesBox;
