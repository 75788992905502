import React from 'react';
import styled from 'styled-components';
import ASMultilineText from '../../../components/ASMultilineText';
import SummarySection from '../../../components/SummarySection';
import UserMediaSquare, {
  UserMediSquareVideoOverlay,
} from '../../../components/UserMediaSquare';
import { UserMediaFieldValueState } from '../../../containers/UserMediaField';
import { PropertyAppraisalBaseFragment } from '../../../graphql/generated';
import AgentCommission from '../components/AgentCommission';
import AgentMarketingFee from '../components/AgentMarketingFee';
import AppraisalSummaryComparableSaleRow from './AppraisalSummaryComparableSaleRow';

const Container = styled.div`
  margin: 0 20px 20px 20px;
  border-top: 1px solid #e8eaed;
  padding: 24px 0 0 0;
`;

const Title = styled.h3`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-weight: bold;
  font-size: 17px;
  color: #a6aeba;
  margin: 0 0 20px 0;
`;

const AdditionalNotesContainer = styled.div`
  margin: 17px 0 0 0;
`;

const AdditionalNotes = styled(ASMultilineText)``;

const AppraisalVideo = styled(UserMediaSquare)`
  width: 100%;
  min-height: 170px;
  margin: 20px 0 0 0;
`;

const AppraisalVideoOverlay = styled(UserMediSquareVideoOverlay)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  background-color: rgba(32, 53, 84, 0.13);

  position: absolute;

  padding: 15px;
`;

export interface PropertyDetailsAppraisalSummaryProps {
  propertyAppraisal: PropertyAppraisalBaseFragment;
}

const PropertyDetailsAppraisalSummary: React.FC<PropertyDetailsAppraisalSummaryProps> = (
  props,
) => {
  const { propertyAppraisal } = props;

  return (
    <Container>
      <Title>Your appraisal</Title>
      {propertyAppraisal.appraisalVideo && (
        <SummarySection title="Appraisal Video" hideEdit>
          <AppraisalVideo
            userMedia={propertyAppraisal.appraisalVideo}
            hideRemoveButton
            videoOverlay={
              <AppraisalVideoOverlay
                userMedia={propertyAppraisal.appraisalVideo}
                videoModal={false}
              />
            }
            mediaUploadingStatus={UserMediaFieldValueState.Ready}
          />
        </SummarySection>
      )}

      {/* <SummarySection title="Estimated selling range" hideEdit>
        <AppraisalSummaryValueRow
          title="Selling range"
          value={`${renderPropertyPrice(
            propertyAppraisal.sellingRangeMin,
          )} - ${renderPropertyPrice(propertyAppraisal.sellingRangeMax)}`}
        />
      </SummarySection> */}

      {propertyAppraisal.marketingFee && (
        <AgentMarketingFee
          agentMarketingFee={propertyAppraisal.marketingFee}
          hideEdit
        />
      )}

      {propertyAppraisal.commission && (
        <AgentCommission
          agentCommission={propertyAppraisal.commission}
          hideEdit
        />
      )}

      <SummarySection title="Comparable sales" hideEdit>
        {propertyAppraisal.comparableSales?.map((comparableSale, i) => (
          <AppraisalSummaryComparableSaleRow
            key={i}
            title={`Sale Listing ${i + 1}`}
            value={comparableSale}
          />
        ))}
      </SummarySection>

      <SummarySection title="Additional notes" hideEdit>
        <AdditionalNotesContainer>
          <AdditionalNotes text={propertyAppraisal.additionalNotes ?? ''} />
        </AdditionalNotesContainer>
      </SummarySection>
    </Container>
  );
};

export default PropertyDetailsAppraisalSummary;
