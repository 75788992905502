import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DraftPropertyBaseFragment,
  useCurrentDraftPropertyLazyQuery,
  useCurrentDraftPropertyQuery,
  UserType,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';

const useCurrentDraftProperty: (
  navigateAwayIfNoDraft?: boolean,
  from?: string,
) => {
  loading: boolean;
  draftProperty: DraftPropertyBaseFragment | null | undefined;
} = (navigateAwayIfNoDraft = true, from = '') => {
  // Add default value for "from" parameter if not provided
  const isAuthenticated = useIsAuthenticated(UserType.Seller);
  // Non-lazy cache only query used to temporarily show cached data before lazy query is called
  // (to avoid flashing of screen when navigating between pages)
  const { data: cachedDraftPropertyData } = useCurrentDraftPropertyQuery({
    fetchPolicy: 'cache-only',
    skip: !isAuthenticated,
  });
  const [
    currentDraftProperty,
    { data: draftPropertyData, loading },
  ] = useCurrentDraftPropertyLazyQuery({ fetchPolicy: 'cache-and-network' });

  const history = useHistory();

  // Fetch current draft only if authenticated
  useEffect(() => {
    if (isAuthenticated) {
      currentDraftProperty();
    }
  }, [isAuthenticated]);

  // Navigate to start of new property flow if there is no current draft property
  useEffect(() => {
    if (
      isAuthenticated &&
      !loading &&
      draftPropertyData &&
      draftPropertyData.currentDraftProperty == null
    ) {
      if (from === 'no-agent') {
        history.push('/');
      }
      if (navigateAwayIfNoDraft) {
        history.push('/new-property/address');
      }
    }
  }, [isAuthenticated, draftPropertyData, loading, from]);

  return {
    loading:
      loading || (isAuthenticated && !draftPropertyData) || !isAuthenticated,
    draftProperty:
      draftPropertyData?.currentDraftProperty ??
      cachedDraftPropertyData?.currentDraftProperty,
  };
};

export default useCurrentDraftProperty;
