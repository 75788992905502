import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import { useStaticCashbackAmountQuery } from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForAgentSignup } from '../../agent/containers/AgentSignup';
import { usePrecacheForLogin } from '../../auth/containers/Login';
import useIsGhostUser from '../../auth/hooks/useIsGhostUser';
import { usePrecacheForAddress } from '../../new-property/containers/Address';
import getStartedActionArrowUrl, {
  ReactComponent as GetStartedActinArrow,
} from '../assets/get-started-action-arrow.svg';
import CashbackSignupBox from '../components/CashbackSignupBox';

const Title = styled.h1`
  font-family: Inter, Arial, sans-serif;
  font-weight: bold;
  font-size: 21px;
  color: #203553;
  margin-bottom: 27px;
`;

const ActionLink = styled(Link)`
  background: #fcfcff;
  box-shadow: 0 6px 20px 0 rgba(32, 53, 83, 0.1);
  border-radius: 8px;
  letter-spacing: 0.28px;
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  line-height: 22px;
  padding: 24px 0 18px 0;
  margin-bottom: 15px;

  :hover {
    text-decoration-line: none;
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e7ed;
`;

const ActionLinkHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 9px;
  padding: 0 20px;
`;

const ActionLinkHeader = styled.h2`
  font-family: Inter, Arial, sans-serif;
  font-size: 17px;
  color: #203553;
  letter-spacing: 0.28px;
  flex-grow: 1;
  margin-right: 12px;
`;

const ActionDescription = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  line-height: 22px;
  margin-right: 25px;
  padding: 0 20px;
`;

const AlreadyHaveAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 22px;
  margin: 12px 0;

  background: #f8f9fa;
  border-radius: 11px;
`;

const AlreadyHaveAccountLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;
  margin-right: 8px;
`;

const AlreadyHaveAccountLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;

  color: #1745b0;
  :hover {
    color: #1745b0;
  }
`;

const TermsText = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin-top: 10px;
  text-align: center;
`;

export const usePrecacheForGetStarted: () => void = () => {
  usePrecacheImages([getStartedActionArrowUrl]);

  usePrecacheForSideBySidePageTemplate();
};

const GetStarted: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [isAuthenticatedAtFirstRender] = useState(isAuthenticated);
  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const isGhostUser = useIsGhostUser();

  usePrecacheForAddress();
  usePrecacheForAgentSignup();
  usePrecacheForLogin();

  if (isAuthenticated && isAuthenticatedAtFirstRender && !isGhostUser) {
    return <Redirect to="/" />;
  }

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Get Started | AgentSpot</title>
      </Helmet>
      <Title>Get Started</Title>
      <ActionLink
        to={{
          pathname: '/new-property/address',
        }}>
        <ActionLinkHeaderRow>
          <ActionLinkHeader>I&apos;m selling my property</ActionLinkHeader>
          <GetStartedActinArrow />
        </ActionLinkHeaderRow>
        <ActionDescription>
          Receive up to 5 appraisals from agents in your local area within 48
          hrs.
        </ActionDescription>
        <Separator />
        <CashbackSignupBox
          cashbackAmount={cashbackAmount?.staticCashbackAmount}
        />
      </ActionLink>
      <AlreadyHaveAccountContainer>
        <AlreadyHaveAccountLabel>Halfway through?</AlreadyHaveAccountLabel>
        <AlreadyHaveAccountLink
          to={{
            pathname: '/continue-signup',
          }}>
          Continue where you left off
        </AlreadyHaveAccountLink>
      </AlreadyHaveAccountContainer>
      <ActionLink to="/agent/signup">
        <ActionLinkHeaderRow>
          <ActionLinkHeader>I&apos;m a real estate agent</ActionLinkHeader>
          <GetStartedActinArrow />
        </ActionLinkHeaderRow>
        <ActionDescription>
          Get appraisal requests, set up appointments and get new clients.
        </ActionDescription>
      </ActionLink>
      <AlreadyHaveAccountContainer>
        <AlreadyHaveAccountLabel>
          Already have an account?
        </AlreadyHaveAccountLabel>
        <AlreadyHaveAccountLink
          to={{
            pathname: '/login',
          }}>
          Log in
        </AlreadyHaveAccountLink>
      </AlreadyHaveAccountContainer>
      <TermsText>
        {`By signing up you agree to the AgentSpot`}
        <br />
        <Link to="/terms-and-conditions/?tab=terms">{`Terms & Conditions`}</Link>
        {` and `}
        <Link to="/terms-and-conditions/?tab=privacy">{`Privacy Policy`}</Link>
      </TermsText>
    </SideBySidePageTemplate>
  );
};

export default GetStarted;
