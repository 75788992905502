import styled from 'styled-components';

const ASErrorLabel = styled.div`
  font-family: Inter;
  font-size: 14px;
  color: #fc4e6f;
  letter-spacing: 0.15px;
  line-height: 25px;
`;

export default ASErrorLabel;
