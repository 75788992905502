import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import ASActionButton from '../../../components/ASActionButton';
import ASHeader from '../../../components/ASHeader';
import BackNavLink, {
  usePrecacheForBackNavLink,
} from '../../../components/BackNavLink';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import ProfileDetailsSummaryValueRow from '../../../components/ProfileDetailsSummaryValueRow';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import SummarySection from '../../../components/SummarySection';
import UserMediaSquare from '../../../components/UserMediaSquare';
import config from '../../../config';
import {
  useMeQuery,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import SuburbCheckoutRow from '../components/SuburbCheckoutRow';
import useSuburbCart from '../hooks/useSuburbCart';
import { usePrecacheForAgentSignupPayment } from './AgentSignupPayment';

const Header = styled(ASHeader)`
  margin-bottom: 20px;
`;

const SignupSummarySection = styled(SummarySection)`
  margin: 0 0 22px 0;
`;

const ProfilePicture = styled(UserMediaSquare)`
  margin: 15px 0 0 0;
  width: 61px;
  height: 61px;
`;

const SuburbsContainer = styled.div`
  margin: 15px 0 0 0;
`;

const SuburbSummaryLine = styled(SuburbCheckoutRow)`
  padding: 0 0 15px 0;
`;

const CostText = styled.p`
  display: flex;
  flex: 1;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.13px;
  line-height: 25px;
  margin: 0;
`;

const CartSuburbContainer = styled.div<{ $isLastItem: boolean }>`
  margin: 0 0 17px 0;
  padding: 0 0 17px 0;
  border-bottom-color: #d2d7dd;
  border-bottom-style: solid;
  border-bottom-width: ${(props) => (props.$isLastItem ? 0 : 1)};
`;

export const usePrecacheForAgentSignupSummary: () => void = () => {
  usePrecacheForBackNavLink();
  usePrecacheForNavigationHeader();
  useSuburbCart();
  usePrecacheForStepTemplate();
};

const AgentSignupSummary: FC = () => {
  const history = useHistory();

  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({ skip: !isAuthenticated });
  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [, cartSuburbs] = useSuburbCart();

  const onSubmitClick = useCallback(() => {
    history.push('/agent/signup/payment');
  }, []);

  usePrecacheForAgentSignupPayment();

  return (
    <StepPageTemplate.Container
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Profile Summary | AgentSpot</title>
      </Helmet>
      <StepPageTemplate.ContentContainer $extraWide>
        <BackNavLink href="/agent/signup/suburbs" />
        <Header>Profile Summary</Header>
        {meData?.me?.agentProfile && (
          <SignupSummarySection
            title="Profile details"
            editHref="/agent/signup/basic-details">
            {meData.me.agentProfile.profilePicture && (
              <ProfilePicture
                userMedia={meData.me.agentProfile.profilePicture}
                hideRemoveButton
              />
            )}
            <ProfileDetailsSummaryValueRow
              title="Name"
              value={meData.me.agentProfile.name ?? 'n/a'}
            />
            <ProfileDetailsSummaryValueRow
              title="Mobile number"
              value={meData.me.mobileNumber ?? 'n/a'}
            />
          </SignupSummarySection>
        )}

        <SignupSummarySection
          title="Service suburbs"
          editHref="/agent/signup/suburbs">
          <SuburbsContainer>
            {cartSuburbs.map((suburb, index) => {
              const isFreeTrial =
                (freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                  .totalFreeTrialsAllowed ?? 0) -
                  (freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                    .numberOfFreeTrialsClaimed ?? 0) -
                  index >
                0;

              return (
                <CartSuburbContainer
                  key={suburb.id}
                  $isLastItem={index === cartSuburbs.length - 1}>
                  <SuburbSummaryLine
                    suburb={suburb}
                    isFreeTrial={isFreeTrial}
                    showPricing={false}
                  />
                  <CostText>
                    {isFreeTrial
                      ? 'Free'
                      : `$${new Intl.NumberFormat().format(
                          config.SUBURB_SUBSCRIPTION_ANNUAL_PRICE_DOLLARS,
                        )}`}
                  </CostText>
                </CartSuburbContainer>
              );
            })}
          </SuburbsContainer>
        </SignupSummarySection>
      </StepPageTemplate.ContentContainer>
      <NavigationFooter
        rightSideComponent={
          <ASActionButton onClick={onSubmitClick}>Confirm</ASActionButton>
        }
      />
    </StepPageTemplate.Container>
  );
};

export default AgentSignupSummary;
