import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { usePrecacheForASModal } from '../../../components/ASModal';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { propertyTypeToString } from '../../../constants/EnumMappings';
import theme from '../../../constants/theme';
import {
  PropertyAdditionalFeature,
  PropertyQuestionRequirement,
  PropertyType,
  useMeQuery,
  useUpdatePropertyDetailsMutation,
  useUpdatePropertyTypeMutation,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import bathroomsQuestionIconUrl from '../assets/bathrooms-question.svg';
import bedroomsQuestionIconUrl from '../assets/bedrooms-question.svg';
import carSpacesQuestionIconUrl from '../assets/car-spaces-question.svg';
import heatingAndCoolingIconUrl from '../assets/heating-and-cooling-question.svg';
import livingSpacesQuestionIcon from '../assets/living-spaces-question.svg';
import propertyAgeQuestionIcon from '../assets/property-age-question.svg';
import PropertySizeQuestionIcon from '../assets/property-size-question.png';
import propertyTypeQuestionIconUrl from '../assets/property-type-question.svg';
import toiletsQuestionIconUrl from '../assets/toilets-question.svg';
import PropertyQuestionRadioRowField from '../components/PropertyQuestionRadioRowField';
import { usePrecacheForPropertyQuestionRowWithField } from '../components/PropertyQuestionRowWithField';
import QuestionOptionSelect, {
  usePrecacheForQuestionOptionSelect,
} from '../components/QuestionOptionSelect';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForFeatures } from './Features';
import { usePrecacheForSaleInformation } from './SaleInformation';

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  padding: 0 0 30px 0;
  @media (max-width: ${theme.layout.desktopBreakpoint}) {
    padding-bottom: 150px;
  }
`;

const StickyContainer = styled.div`
  @media (max-width: ${theme.layout.desktopBreakpoint}) {
    position: fixed;
    bottom: 0;
  }
`;

const PropertyNumberQuestionsContainer = styled.div`
  border-top: 1px solid #e8eaed;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 17px 0 10px 0;
`;

enum HeatingAndCooling {
  None = 'None',
  Heating = 'Heating',
  Cooling = 'Cooling',
  Both = 'Both',
}

export const heatingAndCoolingToString: {
  [key in HeatingAndCooling]: string;
} = {
  [HeatingAndCooling.None]: 'None',
  [HeatingAndCooling.Heating]: 'Heating only',
  [HeatingAndCooling.Cooling]: 'Cooling only',
  [HeatingAndCooling.Both]: 'Heating & Cooling',
};

function additionalFeaturesToHeatingAndCooling(
  additionalFeatures: Array<PropertyAdditionalFeature>,
) {
  if (
    additionalFeatures.includes(PropertyAdditionalFeature.Cooling) &&
    additionalFeatures.includes(PropertyAdditionalFeature.Heating)
  ) {
    return HeatingAndCooling.Both;
  } else if (additionalFeatures.includes(PropertyAdditionalFeature.Heating)) {
    return HeatingAndCooling.Heating;
  } else if (additionalFeatures.includes(PropertyAdditionalFeature.Cooling)) {
    return HeatingAndCooling.Cooling;
  } else {
    return HeatingAndCooling.None;
  }
}

function heatingAndCoolingToAdditionalFeatures(
  heatingAndCooling: HeatingAndCooling,
  additionalFeatures: Array<PropertyAdditionalFeature>,
) {
  const otherAdditionalFeatures = additionalFeatures.filter(
    (feature) =>
      feature !== PropertyAdditionalFeature.Cooling &&
      feature !== PropertyAdditionalFeature.Heating,
  );

  switch (heatingAndCooling) {
    case HeatingAndCooling.Both: {
      return [
        PropertyAdditionalFeature.Cooling,
        PropertyAdditionalFeature.Heating,
        ...otherAdditionalFeatures,
      ];
    }
    case HeatingAndCooling.Heating: {
      return [PropertyAdditionalFeature.Heating, ...otherAdditionalFeatures];
    }
    case HeatingAndCooling.Cooling: {
      return [PropertyAdditionalFeature.Cooling, ...otherAdditionalFeatures];
    }
    case HeatingAndCooling.None: {
      return [...otherAdditionalFeatures];
    }
  }
}

interface ConditionalQuestionProps {
  questionRequirement: PropertyQuestionRequirement;
}

const ConditionalQuestion: FC<ConditionalQuestionProps> = (props) => {
  const { questionRequirement, children } = props;

  return (
    <>
      {questionRequirement !== PropertyQuestionRequirement.NotRequired
        ? children
        : null}
    </>
  );
};

interface FormValues {
  numberOfBedrooms?: number | string;
  numberOfBathrooms?: number | string;
  numberOfToilets?: number | string;
  numberOfCarSpaces?: number | string;
  numberOfLivingSpaces?: number | string;
  heatingAndCooling?: HeatingAndCooling;
}

export const usePrecacheForDetails: () => void = () => {
  usePrecacheImages([
    bathroomsQuestionIconUrl,
    bedroomsQuestionIconUrl,
    carSpacesQuestionIconUrl,
    heatingAndCoolingIconUrl,
    livingSpacesQuestionIcon,
    propertyAgeQuestionIcon,
    PropertySizeQuestionIcon,
    propertyTypeQuestionIconUrl,
    toiletsQuestionIconUrl,
  ]);

  usePrecacheForASModal();
  usePrecacheForQuestionOptionSelect();
  usePrecacheForPropertyQuestionRowWithField();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

// TODO: why does opening a modal shift the page content? (probably because of the area no longer being scrollable)

const Details: FC = () => {
  const history = useHistory();

  const { draftProperty } = useCurrentDraftProperty();
  const isAuthenticated = useIsAuthenticated();
  const { data: meData } = useMeQuery({ skip: !isAuthenticated });

  const [skipToEnd, setSkipToEnd] = useState(false);

  const [
    updatePropertyDetails,
    { loading: updatePropertyDetailsLoading },
  ] = useUpdatePropertyDetailsMutation();
  const [updateUserDetails] = useUpdateUserDetailsMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftProperty) return;

      await updatePropertyDetails({
        variables: {
          propertyId: draftProperty.id,
          propertyDetails: {
            numberOfBedrooms: values.numberOfBedrooms
              ? Number(values.numberOfBedrooms)
              : undefined,
            numberOfBathrooms: values.numberOfBathrooms
              ? Number(values.numberOfBathrooms)
              : undefined,
            numberOfToilets: values.numberOfToilets
              ? Number(values.numberOfToilets)
              : undefined,
            numberOfCarSpaces: values.numberOfCarSpaces
              ? Number(values.numberOfCarSpaces)
              : undefined,
            numberOfLivingSpaces: values.numberOfLivingSpaces
              ? Number(values.numberOfLivingSpaces)
              : undefined,
            additionalFeatures: heatingAndCoolingToAdditionalFeatures(
              values.heatingAndCooling!,
              draftProperty.additionalFeatures ?? [],
            ),
          },
        },
      });

      if (skipToEnd) {
        const nextPage = !meData?.me?.profileComplete
          ? '/new-property/account-password'
          : '/new-property/details-complete';

        updateUserDetails({
          variables: {
            nextPage: nextPage,
          },
        });

        history.push(nextPage);
      } else if (
        draftProperty.additionalFeaturesQuestionRequirement !==
        PropertyQuestionRequirement.NotRequired
      ) {
        updateUserDetails({
          variables: {
            nextPage: '/new-property/features',
          },
        });

        history.push('/new-property/features');
      } else {
        updateUserDetails({
          variables: {
            nextPage: '/new-property/sale-info',
          },
        });
        history.push('/new-property/sale-info');
      }
    },
    [draftProperty, updatePropertyDetails, skipToEnd],
  );

  const initialValues = !draftProperty
    ? null
    : {
        propertyType: draftProperty.propertyType || undefined,
        numberOfBedrooms: String(draftProperty.numberOfBedrooms) || undefined,
        numberOfBathrooms: String(draftProperty.numberOfBathrooms) || undefined,
        numberOfToilets: String(draftProperty.numberOfToilets) || undefined,
        numberOfCarSpaces: String(draftProperty.numberOfCarSpaces) || undefined,
        numberOfLivingSpaces:
          String(draftProperty.numberOfLivingSpaces) || undefined,
        heatingAndCooling: draftProperty.additionalFeatures
          ? additionalFeaturesToHeatingAndCooling(
              draftProperty.additionalFeatures,
            )
          : undefined,
      };

  const validateFormValues = useCallback(
    (values: FormValues) => {
      const errors: { [key in keyof FormValues]?: string } = {};

      if (
        draftProperty?.numberOfBedroomsQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        (values?.numberOfBedrooms === undefined ||
          values?.numberOfBedrooms === 'null')
      ) {
        errors.numberOfBedrooms = 'Required';
      }

      if (
        draftProperty?.numberOfBathroomsQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        (values?.numberOfBathrooms === undefined ||
          values?.numberOfBathrooms === 'null')
      ) {
        errors.numberOfBathrooms = 'Required';
      }

      if (
        draftProperty?.numberOfToiletsQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        (values?.numberOfToilets === undefined ||
          values?.numberOfToilets === 'null')
      ) {
        errors.numberOfToilets = 'Required';
      }

      if (
        draftProperty?.numberOfCarSpacesQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        (values?.numberOfCarSpaces === undefined ||
          values?.numberOfCarSpaces === 'null')
      ) {
        errors.numberOfCarSpaces = 'Required';
      }

      if (
        draftProperty?.numberOfLivingSpacesQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        (values?.numberOfLivingSpaces === undefined ||
          values?.numberOfLivingSpaces === 'null')
      ) {
        errors.numberOfLivingSpaces = 'Required';
      }

      if (
        draftProperty?.additionalFeaturesQuestionRequirement ==
          PropertyQuestionRequirement.Required &&
        !values?.heatingAndCooling
      ) {
        errors.heatingAndCooling = 'Required';
      }

      return errors;
    },
    [draftProperty],
  );
  const [updatePropertyType] = useUpdatePropertyTypeMutation();

  const onPropertyTypeChange = useCallback(
    (revalidate: () => void) => async (propertyType: PropertyType) => {
      if (!draftProperty) return;

      await updatePropertyType({
        variables: { propertyId: draftProperty.id, propertyType },
      });

      revalidate();
    },
    [draftProperty],
  );

  usePrecacheForFeatures();
  usePrecacheForSaleInformation(); // Some propety types will skip the features page

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Property Details | AgentSpot</title>
      </Helmet>
      <StepNavigationHeader
        title="Property Details"
        stepCount={6}
        stepIndex={2}
        stepName="Property Details"
      />

      {!draftProperty ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validate={validateFormValues}
          validateOnMount={true}>
          {({ isValid, submitForm, validateForm }) => {
            return (
              <>
                <PageContentContainer>
                  <QuestionOptionSelect<PropertyType>
                    initialValue={draftProperty.propertyType}
                    label="Property type"
                    options={[
                      {
                        value: PropertyType.House,
                        label: propertyTypeToString[PropertyType.House],
                      },
                      {
                        value: PropertyType.ApartmentOrUnit,
                        label:
                          propertyTypeToString[PropertyType.ApartmentOrUnit],
                      },
                      {
                        value: PropertyType.BlockOfUnits,
                        label: propertyTypeToString[PropertyType.BlockOfUnits],
                      },
                      {
                        value: PropertyType.Townhouse,
                        label: propertyTypeToString[PropertyType.Townhouse],
                      },
                      {
                        value: PropertyType.Land,
                        label: propertyTypeToString[PropertyType.Land],
                      },
                      {
                        value: PropertyType.Acreage,
                        label: propertyTypeToString[PropertyType.Acreage],
                      },
                      {
                        value: PropertyType.RetirementLiving,
                        label:
                          propertyTypeToString[PropertyType.RetirementLiving],
                      },
                    ]}
                    onDone={(value) => {
                      if (value) {
                        onPropertyTypeChange(validateForm)(value);
                      }
                    }}
                  />
                  {(draftProperty.numberOfBedroomsQuestionRequirement !==
                    PropertyQuestionRequirement.NotRequired ||
                    draftProperty.numberOfBathroomsQuestionRequirement !==
                      PropertyQuestionRequirement.NotRequired ||
                    draftProperty.numberOfToiletsQuestionRequirement !==
                      PropertyQuestionRequirement.NotRequired ||
                    draftProperty.numberOfCarSpacesQuestionRequirement !==
                      PropertyQuestionRequirement.NotRequired ||
                    draftProperty.numberOfBedroomsQuestionRequirement !==
                      PropertyQuestionRequirement.NotRequired ||
                    draftProperty.numberOfLivingSpacesQuestionRequirement !==
                      PropertyQuestionRequirement.NotRequired) && (
                    <PropertyNumberQuestionsContainer>
                      <ConditionalQuestion
                        questionRequirement={
                          draftProperty.numberOfBedroomsQuestionRequirement
                        }>
                        <PropertyQuestionRadioRowField
                          fieldName="numberOfBedrooms"
                          label="Bedrooms"
                          box={5}
                          options={[
                            {
                              value: '1',
                              label: '1',
                            },
                            {
                              value: '2',
                              label: '2',
                            },
                            {
                              value: '3',
                              label: '3',
                            },
                            {
                              value: '4',
                              label: '4',
                            },
                            {
                              value: '5',
                              label: '5+',
                            },
                          ]}
                        />
                      </ConditionalQuestion>

                      <ConditionalQuestion
                        questionRequirement={
                          draftProperty.numberOfBathroomsQuestionRequirement
                        }>
                        <PropertyQuestionRadioRowField
                          fieldName="numberOfBathrooms"
                          label="Bathrooms"
                          box={5}
                          options={[
                            {
                              value: '1',
                              label: '1',
                            },
                            {
                              value: '2',
                              label: '2',
                            },
                            {
                              value: '3',
                              label: '3',
                            },
                            {
                              value: '4',
                              label: '4',
                            },
                            {
                              value: '5',
                              label: '5+',
                            },
                          ]}
                        />
                      </ConditionalQuestion>

                      <ConditionalQuestion
                        questionRequirement={
                          draftProperty.numberOfToiletsQuestionRequirement
                        }>
                        <PropertyQuestionRadioRowField
                          fieldName="numberOfToilets"
                          label="Toilets"
                          box={5}
                          options={[
                            {
                              value: '1',
                              label: '1',
                            },
                            {
                              value: '2',
                              label: '2',
                            },
                            {
                              value: '3',
                              label: '3',
                            },
                            {
                              value: '4',
                              label: '4',
                            },
                            {
                              value: '5',
                              label: '5+',
                            },
                          ]}
                        />
                      </ConditionalQuestion>

                      <ConditionalQuestion
                        questionRequirement={
                          draftProperty.numberOfCarSpacesQuestionRequirement
                        }>
                        <PropertyQuestionRadioRowField
                          fieldName="numberOfCarSpaces"
                          label="Under Cover Parking Spaces"
                          box={5}
                          options={[
                            {
                              value: '1',
                              label: '1',
                            },
                            {
                              value: '2',
                              label: '2',
                            },
                            {
                              value: '3',
                              label: '3',
                            },
                            {
                              value: '4',
                              label: '4',
                            },
                            {
                              value: '5',
                              label: '5+',
                            },
                          ]}
                        />
                      </ConditionalQuestion>

                      <ConditionalQuestion
                        questionRequirement={
                          draftProperty.numberOfLivingSpacesQuestionRequirement
                        }>
                        <PropertyQuestionRadioRowField
                          fieldName="numberOfLivingSpaces"
                          label="Lounge / Living Spaces"
                          box={5}
                          options={[
                            {
                              value: '1',
                              label: '1',
                            },
                            {
                              value: '2',
                              label: '2',
                            },
                            {
                              value: '3',
                              label: '3',
                            },
                            {
                              value: '4',
                              label: '4',
                            },
                            {
                              value: '5',
                              label: '5+',
                            },
                          ]}
                        />
                      </ConditionalQuestion>
                    </PropertyNumberQuestionsContainer>
                  )}

                  <ConditionalQuestion
                    questionRequirement={
                      draftProperty.additionalFeaturesQuestionRequirement
                    }>
                    <PropertyQuestionRadioRowField
                      fieldName="heatingAndCooling"
                      label="Heating / Cooling"
                      box={5}
                      options={[
                        {
                          value: HeatingAndCooling.None,
                          label:
                            heatingAndCoolingToString[HeatingAndCooling.None],
                        },
                        {
                          value: HeatingAndCooling.Heating,
                          label:
                            heatingAndCoolingToString[
                              HeatingAndCooling.Heating
                            ],
                        },
                        {
                          value: HeatingAndCooling.Cooling,
                          label:
                            heatingAndCoolingToString[
                              HeatingAndCooling.Cooling
                            ],
                        },
                        {
                          value: HeatingAndCooling.Both,
                          label:
                            heatingAndCoolingToString[HeatingAndCooling.Both],
                        },
                      ]}
                    />
                  </ConditionalQuestion>
                </PageContentContainer>
                <StickyContainer>
                  <StepNavigationFooter
                    backHref="/new-property/address"
                    onNextClick={() => {
                      setSkipToEnd(false);
                      submitForm();
                    }}
                    onSkipClick={() => {
                      setSkipToEnd(true);
                      submitForm();
                    }}
                    showSkipText={true}
                    nextDisabled={!isValid || updatePropertyDetailsLoading}
                    loading={updatePropertyDetailsLoading}
                  />
                </StickyContainer>
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default Details;
