import { Formik } from 'formik';
import { ParsedQuery } from 'query-string';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import PasswordInputField from '../../../components/PasswordInputField';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import { useLoginMutation } from '../../../graphql/generated';
import useFetchMeToCompleteLogin from '../../../hooks/useFetchMeToCompleteLogin';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import useQueryParams from '../../../hooks/useQueryParams';
import { usePrecacheForAddress } from '../../new-property/containers/Address';
import { usePrecacheForAgentDashboard } from '../../properties/containers/AgentDashboard';
import { usePrecacheForSellerDashboard } from '../../properties/containers/SellerDashboard';
import useIsGhostUser from '../hooks/useIsGhostUser';
import useLogoutGhostUser from '../hooks/useLogoutGhostUser';
import { LOGIN } from '../store/authTypes';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(ASHeader)`
  margin-bottom: 21px;
`;

const ForgotPasswordLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-family: 500;
  font-size: 14px;
  color: #7a7a7a;
  letter-spacing: 0.15px;
  text-align: right;
  line-height: 25px;

  :hover {
    color: #7a7a7a;
  }
`;

const SignupButton = styled(ASBlockButton)`
  margin: 26px 0 21px 0;
  width: 100%;
`;

const AlreadyHaveAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 22px;
  margin-top: 12px;

  background: #f8f9fa;
  border-radius: 11px;
`;

const AlreadyHaveAccountLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;
  margin-right: 8px;
`;

const AlreadyHaveAccountLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;

  color: #1745b0;
  :hover {
    color: #1745b0;
  }
`;

export const usePrecacheForLogin: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};
interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  password: Yup.string().required('Password is required'),
});

interface ExpectedQueryParams extends ParsedQuery {
  from: string | string[] | null;
}

const Login: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const isGhostUser = useIsGhostUser();
  const [isAuthenticatedAtFirstRender] = useState(isAuthenticated);
  const { from } = useQueryParams<ExpectedQueryParams>();

  const history = useHistory();
  const dispatch = useDispatch();

  const [loginMutation, { loading, error: loginError }] = useLoginMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { data } = await loginMutation({
        variables: {
          email: values.email,
          password: values.password,
        },
      });

      if (data?.login) {
        dispatch({
          type: LOGIN,
          payload: {
            authToken: data?.login?.token,
          },
        });
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  useFetchMeToCompleteLogin(() => {
    history.push(typeof from == 'string' ? from : '/');
  });

  usePrecacheForAgentDashboard();
  usePrecacheForSellerDashboard();
  usePrecacheForAddress();

  useLogoutGhostUser();

  if (isAuthenticated && isAuthenticatedAtFirstRender && !isGhostUser) {
    return <Redirect to="/" />;
  }

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Log In | AgentSpot</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount }) => {
          const error: string | undefined =
            (submitCount > 0 && errors && Object.values(errors)[0]) ||
            (loginError && apolloErrorToString(loginError));

          return (
            <Form>
              <Header>Log in to AgentSpot</Header>

              <TextInputField
                name="email"
                placeholder="Email address"
                type="email"
              />
              <PasswordInputField name="password" placeholder="Password" />
              <ForgotPasswordLink to="/reset-password">
                Forgot password?
              </ForgotPasswordLink>
              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <SignupButton loading={loading} onClick={submitForm}>
                Log in
              </SignupButton>
              <AlreadyHaveAccountContainer>
                <AlreadyHaveAccountLabel>
                  Halfway through?
                </AlreadyHaveAccountLabel>
                <AlreadyHaveAccountLink
                  to={{
                    pathname: '/continue-signup',
                  }}>
                  Continue where you left off
                </AlreadyHaveAccountLink>
              </AlreadyHaveAccountContainer>
              <AlreadyHaveAccountContainer>
                <AlreadyHaveAccountLabel>
                  Don&apos;t have an account?
                </AlreadyHaveAccountLabel>
                <AlreadyHaveAccountLink
                  to={{
                    pathname: '/get-started',
                  }}>
                  Sign Up
                </AlreadyHaveAccountLink>
              </AlreadyHaveAccountContainer>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default Login;
