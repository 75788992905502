import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrevious from '../../../hooks/usePrevious';
import { RootState } from '../../../store';
import {
  LOGOUT,
  LogoutAction,
  LogoutBeginAction,
  LOGOUT_BEGIN,
} from '../store/authTypes';

const useLogout: () => () => void = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const prevIsAuthenticated = usePrevious(isAuthenticated);
  const apolloClient = useApolloClient();

  // Start logout process
  const logout = useCallback(() => {
    dispatch({
      type: LOGOUT_BEGIN,
    } as LogoutBeginAction);
  }, []);

  // Actually logout
  const readyToLogout = useSelector<RootState, boolean | null>(
    (state) => state.auth.logoutInProgress,
  );

  useEffect(() => {
    (async () => {
      if (readyToLogout) {
        dispatch({
          type: LOGOUT,
        } as LogoutAction);
      }
    })();
  }, [readyToLogout]);

  // Clear apollo cache on logout
  useEffect(() => {
    if (prevIsAuthenticated && !isAuthenticated) {
      apolloClient.clearStore();
    }
  }, [isAuthenticated, prevIsAuthenticated]);

  return logout;
};

export default useLogout;
