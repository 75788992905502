import { Field, Formik } from 'formik';
import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASActionButton from '../../../components/ASActionButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import DatePicker from '../../../components/DatePicker';
import NavigationFooter from '../../../components/NavigationFooter';
import NavigationHeader from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import { SelectField } from '../../../components/SelectField';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString, ErrorCodes } from '../../../constants/ErrorCodes';
import theme from '../../../constants/theme';
import {
  usePropertyAppraisalQuery,
  useRequestAppointmentForAgentFromPropertyAppraisalMutation,
} from '../../../graphql/generated';
import { intervalsBetweenTimes } from '../../../utils/intervalsBetweenTimes';
import { pxToRem } from '../../../utils/pxToRem';
import SaleStatusNavigationHeader, {
  usePrecacheForBackNavButton,
} from '../components/SaleStatusNavigationHeader';

const AgentName = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #a6aeba;
  letter-spacing: 0.15px;
  text-align: center;
  margin: -5px 0 0 0;
  padding: 0;
`;

const Description = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(14)};
  font-weight: 500;
  color: #58687e;
  letter-spacing: 0.2px;
  line-height: 23px;
  margin: 36px 0 30px 0;
`;

const SubmitButton = styled(ASActionButton).attrs({ type: 'submit' })`
  margin-top: 15px;
  width: 100%;
  max-width: ${theme.layout.desktopBreakpoint};
`;

export const usePrecacheForRequestAppointment: () => void = () => {
  usePrecacheForStepTemplate();
};

interface FormValues {
  date: Date | undefined;
  time: Date | undefined;
}

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  time: Yup.date().required('Time is required'),
});

interface ExpectedPathParams {
  propertyId?: string;
  appraisalId?: string;
}

const RequestAppointment: FC = () => {
  const { propertyId, appraisalId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { data: appraisalData, error } = usePropertyAppraisalQuery({
    variables: {
      id: typeof appraisalId === 'string' ? appraisalId : '',
    },
  });

  const [
    requestAppraisalAppointment,
    { loading: requestAppointmentLoading, error: requestAppointmentError },
  ] = useRequestAppointmentForAgentFromPropertyAppraisalMutation();

  const initialValues: FormValues = {
    date: undefined,
    time: undefined,
  };

  const onSubmitRequest = useCallback(async (values: FormValues) => {
    try {
      await requestAppraisalAppointment({
        variables: {
          propertyAppraisalId: appraisalId ?? '',
          appointmentDate: moment(values.date).format('YYYY-MM-DD'),
          appointmentTime: moment(values.time).format('HH:mm:ss'),
        },
      });

      history.push(
        `/property/${propertyId}/appraisal/${appraisalId}/request-complete`,
      );

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  usePrecacheForBackNavButton();

  const options = intervalsBetweenTimes(
    moment().set({ hour: 8, minutes: 0, seconds: 0 }),
    moment().set({ hour: 18, minutes: 0, seconds: 0 }),
    30,
  ).map((time) => {
    return { value: time.toDate(), label: time.format('h:mma') };
  });

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Request Appointment | AgentSpot</title>
      </Helmet>

      {!appraisalData && error && error.message === ErrorCodes.NotFound && (
        <NotFoundPlaceholder />
      )}

      {appraisalData && (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmitRequest}
          validationSchema={validationSchema}
          validateOnMount={true}>
          {({
            submitForm,
            values,
            isValid,
            setFieldValue,
            errors,
            touched,
          }) => {
            console.log('values: ', values);

            console.log({
              date: values.date && moment(values.date).format('YYYY-MM-DD'),
              time: values.time && moment(values.time).format('HH:mm:ss'),
            });

            const error: string | undefined =
              (touched['date'] && errors.date) ||
              (touched['time'] && errors.time) ||
              (requestAppointmentError &&
                apolloErrorToString(requestAppointmentError));

            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <SaleStatusNavigationHeader
                    backHref={`/property/${propertyId}/appraisal/${appraisalId}`}
                    pageTitle="Preferred Appointment Date & Time"
                  />
                  <AgentName>{`with ${appraisalData?.propertyAppraisal.agent.name}`}</AgentName>
                  <Description>
                    Enter the date and time of when you’d like to have an
                    appointment with this agent. The Agent will then get in
                    touch with you via phone or email (approx. within 24h)
                  </Description>
                  <DatePicker
                    initialDate={values.date}
                    minDate={new Date()}
                    onChange={(date) => {
                      setFieldValue('date', date);
                    }}
                  />
                  <Field
                    name={'time'}
                    placeholder="Time"
                    component={SelectField}
                    options={options}
                  />
                  {error && <ASErrorLabel>{error}</ASErrorLabel>}
                </StepPageTemplate.ContentContainer>
                <NavigationFooter
                  rightSideComponent={
                    <SubmitButton
                      visiblyDisabled={!isValid}
                      loading={requestAppointmentLoading}
                      onClick={submitForm}>
                      Submit Appointment Request
                    </SubmitButton>
                  }
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default RequestAppointment;
