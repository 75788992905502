import { useField } from 'formik';
import React, { ReactElement, ReactNode } from 'react';
import PropertyQuestionRow, {
  usePrecacheForPropertyQuestionRow,
} from './PopertyQuestionRow';

interface PropertyQuestionRowWithFieldProps<T> {
  fieldName: string;
  placeholder?: string;
  onClick: () => void;
  label: string;
  icon: ReactNode;
  valueToString: (value: T) => string;
}

export const usePrecacheForPropertyQuestionRowWithField: () => void = () => {
  usePrecacheForPropertyQuestionRow();
};

const PropertyQuestionRowWithField = <T,>(
  props: PropertyQuestionRowWithFieldProps<T>,
): ReactElement => {
  const { fieldName, ...rest } = props;

  const [{ value }] = useField({ name: fieldName });

  return <PropertyQuestionRow value={value} {...rest} />;
};

export default PropertyQuestionRowWithField;
