import React, { FC } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';

const Button = styled.button<{
  background?: string | undefined;
  border?: string | undefined;
  textColor?: string | undefined;
}>`
  border: ${(props) => props.border ?? 'none'};
  background: ${(props) =>
    props.background ?? 'linear-gradient(90deg, #1745B0 0%, #001cdf 100%)'};
  border-radius: 0px;
  padding: 19px 20px;
  color: ${(props) => (props.textColor ? props.textColor : '#ffffff')};
  font-family: Inter, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  :hover {
    text-decoration-line: none;
    box-shadow: 0px 2px 4px 1px #d1d1d1;
  }
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
`;

interface ASBlockButtonProps {
  loading?: boolean;
  background?: string;
  border?: string;
  textColor?: string;
}

const ASBlockButton: FC<
  ASBlockButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const { loading = false, children, ...rest } = props;

  return (
    <Button disabled={loading} type={'button'} {...rest}>
      {!loading ? (
        children
      ) : (
        <LoadingSpinnerContainer>
          <BeatLoader
            color="#ffffff"
            css="display: flex;"
            size={6}
            margin={2}
            loading={loading}
          />
        </LoadingSpinnerContainer>
      )}
    </Button>
  );
};

export default ASBlockButton;
