import React, { FC } from 'react';
import styled from 'styled-components';
import goldCoinUrl from '../../../assets/gold-coin.svg';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-text: center;
  background: #d68f40;
  padding: 20px;
`;

const Title = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 800;
  font-size: ${pxToRem(18)};
  color: #ffffff;
  align-self: center;
  line-height: 23px;
  letter-spacing: 0.19;
  margin: 0;
`;

const Description = styled.span`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(18)};
  color: #ffffff;
  letter-spacing: 0.19px;
  line-height: 23px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex: 1;
`;

export interface CashbackReminderProps {
  cashbackAmount: number | undefined;
  style?: React.CSSProperties;
}

export const usePrecacheForCashbackReminder: () => void = () => {
  usePrecacheImages([goldCoinUrl]);
};

const CashbackReminder: FC<CashbackReminderProps> = ({
  cashbackAmount,
  style,
}) => {
  return (
    <Container style={style}>
      <TextContainer>
        <Title>
          {`Receive a $${cashbackAmount} bonus `}
          <Description>when you sell with AgentSpot agent</Description>
        </Title>
      </TextContainer>
    </Container>
  );
};

export default CashbackReminder;
