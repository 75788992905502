import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blockDirectionLeftIconUrl, {
  ReactComponent as BlockDirectionLeftIcon,
} from '../assets/block-direction-left.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.2px;
  border: 1px solid #a6aeba;
  padding: 10px 20px;
  :hover {
    text-decoration: none;
  }

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    margin: 28px 0 0 0;
  }
`;

const BackLabel = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin: 0;
  color: #1745b0;
  padding-left: 13px;
`;

const BlockDirectionLeft = styled(BlockDirectionLeftIcon)`
  width: 25px;
  height: 25px;
`;

export const usePrecacheForBackNavLink: () => void = () => {
  usePrecacheImages([blockDirectionLeftIconUrl]);
};
export interface BackNavLinkProps {
  href: string;
  className?: string;
}

const BackNavLink: FC<BackNavLinkProps> = (props) => {
  const { href, className } = props;

  return (
    <NavLink to={href} rel="prev" className={className}>
      <BlockDirectionLeft />
      <BackLabel>Back</BackLabel>
    </NavLink>
  );
};

export default BackNavLink;
