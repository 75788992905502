import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import completeCheckmarkUrl, {
  ReactComponent as CompleteCheckmark,
} from '../../../assets/complete-checkmark.svg';
import ASHeader from '../../../components/ASHeader';
import ASParagraph from '../../../components/ASParagraph';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import { usePrecacheForStepCompleteContent } from '../../../components/StepCompleteContent';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';

const Header = styled(ASHeader)`
  text-align: center;
  margin-bottom: 25px;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    height: 80vh;
  }
`;

export const usePrecacheForResetPasswordSuccess: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
  usePrecacheForStepCompleteContent();
  usePrecacheImages([completeCheckmarkUrl]);
};

const ResetPasswordSuccess: FC = () => {
  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Reset Password Sent | AgentSpot</title>
      </Helmet>
      <PageContentContainer>
        <CompleteCheckmark />
        <Header>Link sent</Header>
        <ASParagraph>
          We have sent you an email with a password reset link.
        </ASParagraph>
      </PageContentContainer>
    </SideBySidePageTemplate>
  );
};

export default ResetPasswordSuccess;
