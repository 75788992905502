import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { SuburbBaseFragment } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import removeSuburbCrossUrl, {
  ReactComponent as RemoveSuburbCrossIcon,
} from '../assets/remove-suburb-cross.svg';
import SuburbHeader from './SuburbHeader';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 25px 20px;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 15px;
`;

const RemoveButton = styled.button`
  border: none;
  padding: 0;
  margin: 20px 0 0 0;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const RemoveSuburbCross = styled(RemoveSuburbCrossIcon)`
  width: 14px;
  height: 14px;
  margin: 0 8px 0 0;
`;

const RemoveText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  margin: 0;
`;

export interface SelectedSuburbCardProps {
  suburb: SuburbBaseFragment;
  isFreeTrial: boolean;
  onRemoveSuburbClick: (suburb: SuburbBaseFragment) => void;
}

export const usePrecacheForSelectedSuburbCard: () => void = () => {
  usePrecacheImages([removeSuburbCrossUrl]);
};

const SelectedSuburbCard: FC<SelectedSuburbCardProps> = (props) => {
  const { suburb, onRemoveSuburbClick, isFreeTrial } = props;

  return (
    <Container>
      <SuburbHeader suburb={suburb} isFreeTrial={isFreeTrial} />
      <RemoveButton onClick={() => onRemoveSuburbClick(suburb)}>
        <RemoveSuburbCross />
        <RemoveText>Remove</RemoveText>
      </RemoveButton>
    </Container>
  );
};

export default SelectedSuburbCard;
