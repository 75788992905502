import { useSelector } from 'react-redux';
import { UserType } from '../graphql/generated';
import { RootState } from '../store';

/**
 * Tracks the last known user type to assist with initial rendering of the application before the me query has returned
 */
const useLastKnownUserType: () => UserType | null = () =>
  useSelector<RootState, UserType | null>(
    (state) => state.auth.lastKnownUserType,
  );

export default useLastKnownUserType;
