import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import {
  default as blueCheckmarkSuccessIconUrl,
  ReactComponent as BlueCheckmarkSuccessIcon,
} from '../assets/blue-checkmark-success.svg';
import closeIconUrl, {
  ReactComponent as CloseIcon,
} from '../assets/close-cross-small-opaque.svg';
import videoPlayButtonIconUrl, {
  ReactComponent as VideoPlayButtonIcon,
} from '../assets/video-play-button.svg';
import { UserMediaFieldValueState } from '../containers/UserMediaField';
import {
  UserMediaBaseFragment,
  UserMediaType,
  useUserMediaQuery,
} from '../graphql/generated';
import usePrecacheImages from '../hooks/usePrecacheImages';
import ASCaption from './ASCaption';
import VideoPreviewModal from './VideoPreviewModal';

const VideoOverlayBackdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(32, 53, 84, 0.4);
  border-radius: 10px;

  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const VideoPlayButton = styled.button`
  display: flex;
  z-index: 3;
  border: none;
  background: transparent;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
    color: transparent;
  }
`;

const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const VideoOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: #f4f5f6;
  border-radius: 10px;
`;

const usePrecacheForUserMediSquareVideoOverlay: () => void = () => {
  usePrecacheImages([videoPlayButtonIconUrl, blueCheckmarkSuccessIconUrl]);
};

export interface UserMediSquareVideoOverlayProps {
  className?: string;
  userMedia: UserMediaBaseFragment;
  videoModal: boolean;
}

export const UserMediSquareVideoOverlay: React.FC<UserMediSquareVideoOverlayProps> = (
  props,
) => {
  const { className, userMedia, videoModal } = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  const [showCustomPlayButton, setShowCustomPlayButton] = useState<boolean>(
    true,
  );

  const onClickVideo = () => {
    if (showCustomPlayButton) {
      setShowCustomPlayButton(false);
    }
  };

  useEffect(() => {
    if (showCustomPlayButton === false && !videoModal) {
      videoRef?.current?.play();
    }
  }, [showCustomPlayButton]);

  return (
    <VideoOverlayContainer>
      {showCustomPlayButton && (
        <VideoOverlayBackdrop className={className}>
          <VideoPlayButton onClick={onClickVideo}>
            <VideoPlayButtonIcon />
          </VideoPlayButton>
        </VideoOverlayBackdrop>
      )}

      <Video
        ref={videoRef}
        controls={!videoModal && !showCustomPlayButton}
        poster={userMedia.thumbnailUrl}>
        <source src={userMedia.url} />
      </Video>

      <VideoPreviewModal
        userMedia={userMedia}
        isOpen={videoModal && !showCustomPlayButton}
        onRequestClose={() => setShowCustomPlayButton(true)}
      />
    </VideoOverlayContainer>
  );
};

const GridItem = styled.li`
  list-style-type: none;
  width: 100px;
  height: 100px;
  border: 1px solid #e8eaed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: show;
  border-radius: 10px;
`;

const UploadSpinnerContainer = styled.div`
  background-color: #f4f5f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  overflow: hidden;
  gap: 8px;
`;

const ImageThumbnail = styled.img`
  background-color: #f4f5f6;

  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: -12px;
  right: -12px;

  border: none;
  background: none;
  margin: 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
  z-index: 2000;
`;

const CloseCross = styled(CloseIcon)`
  width: 23px;
  height: 23px;
`;

const LoadingText = styled(ASCaption)`
  margin: 0px;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.25px;
`;

export const usePrecacheUserMediaSquare: () => void = () => {
  usePrecacheImages([closeIconUrl]);

  usePrecacheForUserMediSquareVideoOverlay();
};

export interface UserMediaSquareProps {
  userMedia: UserMediaBaseFragment;
  hideRemoveButton?: boolean;
  onRemoveClick?: () => void;
  className?: string;
  videoOverlay?: ReactNode;
  videoModal?: boolean;
  mediaUploadingStatus?: UserMediaFieldValueState;
}

const UserMediaSquare: FC<UserMediaSquareProps> = (props) => {
  const {
    userMedia,
    onRemoveClick,
    hideRemoveButton = false,
    className,
    videoOverlay,
    videoModal = false,
    mediaUploadingStatus,
  } = props;

  const [isStillUploading, setIsStillUploading] = useState<boolean>(
    !userMedia.thumbnailReady || !userMedia.mainFileReady,
  );

  const { data: userMediaData } = useUserMediaQuery({
    variables: {
      id: userMedia.id,
    },
    pollInterval: 1_000,
    skip: !isStillUploading,
  });

  useEffect(() => {
    if (
      userMediaData?.userMedia &&
      userMediaData.userMedia.thumbnailReady &&
      userMediaData.userMedia.mainFileReady
    ) {
      setIsStillUploading(false);
    }
  }, [userMediaData]);

  return (
    <GridItem className={className}>
      {onRemoveClick && !hideRemoveButton && (
        <RemoveButton onClick={onRemoveClick}>
          <CloseCross />
        </RemoveButton>
      )}
      {!isStillUploading ? (
        <>
          {userMedia.type == UserMediaType.Video &&
            (videoOverlay ?? (
              <UserMediSquareVideoOverlay
                userMedia={userMedia}
                videoModal={videoModal}
              />
            ))}

          <ImageThumbnail src={userMedia?.thumbnailUrl ?? undefined} />
        </>
      ) : (
        <UploadSpinnerContainer>
          {mediaUploadingStatus == UserMediaFieldValueState.Ready ? (
            <>
              <BlueCheckmarkSuccessIcon />
              <LoadingText>Proccessing video...</LoadingText>
            </>
          ) : (
            <>
              <ClipLoader
                color="#1745B0"
                css="display: flex;"
                size={35}
                loading
              />
              <LoadingText>Uploading media...</LoadingText>
            </>
          )}
        </UploadSpinnerContainer>
      )}
    </GridItem>
  );
};

export default UserMediaSquare;
