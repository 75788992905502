import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 5px 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;

  :hover {
    text-decoration-line: none;
    color: initial;
  }
`;

const IconContainer = styled.div`
  width: 21px;
  height: 21px;
  margin: 0 25px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#58687e')};
  letter-spacing: 0.2px;
  flex-grow: 1;
`;

export interface ActionMenuItemProps {
  icon?: ReactNode;
  title: string;
  textColour?: string;
}

const ActionMenuItem: FC<ActionMenuItemProps> = (props) => {
  const { icon, title, textColour } = props;

  return (
    <Container>
      {icon && <IconContainer>{icon}</IconContainer>}

      <Label color={textColour}>{title}</Label>
    </Container>
  );
};

export default ActionMenuItem;
