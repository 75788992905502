import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import { PropertyAppraisalRequestBaseFragment } from '../../../graphql/generated';

const DateStampsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 25px 20px;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    padding: 0px 20px 20px;
  }
`;

const DateStampsLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  color: #203553;
  letter-spacing: 0.25px;
  margin-right: 20px;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.16px;

  span {
    font-family: Inter, Arial, sans-serif;
    font-weight: 400;
  }
`;

export interface AppraisalRequestDateStampsProps {
  appraisalRequest: PropertyAppraisalRequestBaseFragment;
}

const AppraisalRequestDateStamps: React.FC<AppraisalRequestDateStampsProps> = (
  props,
) => {
  const { appraisalRequest } = props;

  return (
    <DateStampsContainer>
      <DateStampsLabel>
        Appraisal received on:{' '}
        <span>{moment(appraisalRequest.requestedAt).format('D MMM YYYY')}</span>
      </DateStampsLabel>
      {appraisalRequest.propertyAppraisal?.sentAt && (
        <DateStampsLabel>
          Appraisal sent on:{' '}
          <span>
            {moment(appraisalRequest.propertyAppraisal.sentAt).format(
              'D MMM YYYY',
            )}
          </span>
        </DateStampsLabel>
      )}
    </DateStampsContainer>
  );
};

export default AppraisalRequestDateStamps;
