import React, { FC } from 'react';
import styled from 'styled-components';
import { AgentBaseFragment } from '../graphql/generated';

const ProfilePhotoContainer = styled.div`
  width: 75px;
  width: min(20vw, 75px);
  height: 75px;
  height: min(20vw, 75px);
  margin-right: 45px;
  display: flex;
  align-items: stretch;
  position: relative;
`;

const ProfilePhoto = styled.img`
  display: flex;
  background-color: #f4f5f6;
  border: 1px solid #e8eaed;
  position: center;
  object-fit: cover;
  flex: 1;
  max-width: 100%;
  aspect-ratio: 0.5;
  width: 75px;
  width: min(20vw, 75px);
  height: 75px;
  height: min(20vw, 75px);
`;

const AgencyLogo = styled.img<{
  $position:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right';
}>`
  position: absolute;
  top: ${(props) =>
    props.$position === 'top-left' || props.$position === 'top-right'
      ? '-1px'
      : props.$position === 'center-left' || props.$position === 'center-right'
      ? '50%'
      : undefined};
  bottom: ${(props) =>
    props.$position === 'bottom-left' || props.$position === 'bottom-right'
      ? '-1px'
      : undefined};
  left: ${(props) =>
    props.$position === 'bottom-left' || props.$position === 'top-left'
      ? '-1px'
      : props.$position === 'center-left'
      ? '-50px'
      : undefined};
  right: ${(props) =>
    props.$position === 'bottom-right' || props.$position === 'top-right'
      ? '-1px'
      : props.$position === 'center-right'
      ? '-45%'
      : undefined};
  transform: ${(props) =>
    props.$position === 'center-left' || props.$position === 'center-right'
      ? 'translateY(-50%)'
      : undefined};
  height: auto;
  width: 50%;
  background-color: white;
  border: 1px solid #ffffff;
  border-radius: 4px;
  object-fit: fill;
`;

export interface AgentProfilePhotoProps {
  agent: AgentBaseFragment;
  showAgencyLogo?: boolean;
  position?:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right';
}

const AgentProfilePhoto: FC<AgentProfilePhotoProps> = (props) => {
  const { agent, showAgencyLogo = false, position = 'center-right' } = props;

  return (
    <ProfilePhotoContainer>
      <ProfilePhoto
        src={agent.profilePicture?.thumbnailUrl ?? agent.profilePicture?.url}
      />
      {showAgencyLogo && (
        <AgencyLogo $position={position} src={agent.agencyLogo?.url} />
      )}
    </ProfilePhotoContainer>
  );
};

export default AgentProfilePhoto;
