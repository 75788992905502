import React from 'react';
import styled from 'styled-components';
import {
  AgentCommissionCommissionType,
  PropertyAppraisalRequestBaseFragment,
  PropertyAppraisalRequestState,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import renderPropertyPrice from '../../../src/utils/renderPropertyPrice';
import statBulletIconUrl, {
  ReactComponent as StatBulletIcon,
} from '../assets/property-details-stat-bullet.svg';
import AppraisalRequestDateStamps from './AppraisalRequestDatesStamp';
import AppraisalRequestStatusBadge from './AppraisalRequestStatusBadge';
import PropertyCardTemplate, {
  usePrecacheForPropertyCardTemplate,
} from './PropertyCardTemplate';

const NewBadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  background: #fc4e6f;
  border-radius: 0 10px 0 0;
  padding: 5px 11px;

  z-index: 3;

  font-family: Poppins, Arial, sans-serif;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: -0.14px;
  text-align: center;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0 0 0;
`;

const StatBullet = styled(StatBulletIcon)`
  margin: 0 6px;
  width: 4px;
  height: 4px;
`;

const StatLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
`;

const AppraisalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 8px 0 0 0;
  border-top: 1px solid rgba(32, 53, 83, 0.14);
  margin: 24px 0 0 0;
`;

const SellingRangeTitleLabel = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 24px 0 14px 0;
`;

const SellingRangeLabel = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.2px;
  margin: 0 0 9px 0;
`;

const FeesLabel = styled.div`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
`;
export const usePrecacheForAppraisalRequestCard: () => void = () => {
  usePrecacheForPropertyCardTemplate();

  usePrecacheImages([statBulletIconUrl]);
};

export interface AppraisalRequestCardProps {
  appraisalRequest: PropertyAppraisalRequestBaseFragment;
  showAppraisal: boolean;
}

const AppraisalRequestCard: React.FC<AppraisalRequestCardProps> = (props) => {
  const { appraisalRequest, showAppraisal } = props;

  return (
    <PropertyCardTemplate
      property={appraisalRequest.property}
      href={`/agent/appraisal-request/${appraisalRequest.id}`}
      StatusBadgeComponent={
        <AppraisalRequestStatusBadge appraisalRequest={appraisalRequest} />
      }
      menuButtonVisible={false}
      dateStampsComponent={
        <AppraisalRequestDateStamps appraisalRequest={appraisalRequest} />
      }
      bottomLabel={
        <>
          <StatsContainer>
            <StatLabel>
              {appraisalRequest.property.numberOfBedrooms ?? 0} Bed
            </StatLabel>
            <StatBullet />
            <StatLabel>
              {appraisalRequest.property.numberOfBathrooms ?? 0} Bath
            </StatLabel>
            <StatBullet />
            <StatLabel>
              {appraisalRequest.property.numberOfCarSpaces ?? 0} Car
            </StatLabel>
          </StatsContainer>

          {showAppraisal && appraisalRequest.propertyAppraisal && (
            <AppraisalContainer>
              <SellingRangeTitleLabel>Selling Range</SellingRangeTitleLabel>
              <SellingRangeLabel>
                {renderPropertyPrice(
                  appraisalRequest.propertyAppraisal.sellingRangeMin,
                )}
                {' - '}
                {renderPropertyPrice(
                  appraisalRequest.propertyAppraisal.sellingRangeMax,
                )}
              </SellingRangeLabel>
              <FeesLabel>
                $
                {new Intl.NumberFormat().format(
                  appraisalRequest.propertyAppraisal.marketingFee.costDollars,
                )}{' '}
                marketing fee +{' '}
                {appraisalRequest.propertyAppraisal.commission
                  .commissionType === AgentCommissionCommissionType.FlatFee
                  ? `$${new Intl.NumberFormat().format(
                      appraisalRequest.propertyAppraisal.commission
                        .flatFeeCostDollars ?? 0,
                    )}`
                  : `${appraisalRequest.propertyAppraisal.commission.percentageRate}%`}{' '}
                commission
              </FeesLabel>
            </AppraisalContainer>
          )}
        </>
      }>
      {!appraisalRequest.seenByAgent &&
        (appraisalRequest.state === PropertyAppraisalRequestState.Pending ||
          appraisalRequest.state === PropertyAppraisalRequestState.Expired) && (
          <NewBadgeContainer>New</NewBadgeContainer>
        )}
    </PropertyCardTemplate>
  );
};

export default AppraisalRequestCard;
