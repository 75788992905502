import { useApolloClient } from '@apollo/client';
import { Form, Formik } from 'formik';
import chunk from 'lodash.chunk';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASActionButton from '../../../components/ASActionButton';
import FixedFooter from '../../../components/FixedFooter';
import GooglePlacesSelectField from '../../../components/GooglePlacesSelectField';
import config from '../../../config';
import theme from '../../../constants/theme';
import {
  GetSuburbFromSearchWithNearbySuburbsDocument,
  GetSuburbFromSearchWithNearbySuburbsQuery,
  SuburbWithAgentStatsFragment,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
  useMySubscribedSuburbsQuery,
} from '../../../graphql/generated';
import GoogleAddressParser, {
  AddressComponent,
} from '../../../utils/googleAddressParser';
import SuburbSelectCard from '../components/SuburbSelectCard';
import useSuburbCart from '../hooks/useSuburbCart';

const Container = styled.div<{ $scroll: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: visible;
  overflow-y: ${(props) => (props.$scroll ? 'scroll' : 'visible')};
  height: ${(props) => (props.$scroll ? '100%' : 'auto')};
  margin: ${(props) => (props.$scroll ? '0 -0 0 0' : 'auto')};
  padding: ${(props) => (props.$scroll ? '0 20px 100px 20px' : '0 0 20px 0')};
`;

const NearbyTitle = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  line-height: 23px;
  margin: 16px 0 12px 0;
`;

const AddButton = styled(ASActionButton).attrs({ type: 'submit' })`
  margin-top: 13px;
  width: 100%;
  max-width: ${theme.layout.desktopBreakpoint};
`;

const InputAndResultsConainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const FormStyled = styled(Form)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SuburbsContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 884px;
  flex-direction: column;
  justify-content: stretch;
`;

const SuburbRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 15px 0;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 7.5px 0 0;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex: initial;
    margin: 0 0 15px 0;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 0 0 7.5px;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    flex: initial;
    margin: 0 0 0 0;
  }
`;

const SelectedCount = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #58687e;
  letter-spacing: 0.13px;
  line-height: 24px;
  margin: 13px 0 0 0;
`;

const validationSchema = Yup.object().shape({
  searchSuburb: Yup.object().required('Suburb is required'),
});

interface FormValues {
  searchSuburb?: google.maps.places.PlaceResult;
}

const initialValues: FormValues = {
  searchSuburb: undefined,
};

interface SuburbSelectFormProps {
  onAddClicked: (suburbs: Array<string>) => void;
  submitDisabled?: boolean;
  scroll?: boolean;
}

const SuburbSelectForm: FC<SuburbSelectFormProps> = ({
  onAddClicked,
  submitDisabled,
  scroll = false,
}) => {
  const client = useApolloClient();

  const { data: mySubscribedSuburbsData } = useMySubscribedSuburbsQuery();
  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [searchTerm, setSearchTerm] = useState('');
  const [nearbySuburbs, setNearbySuburbs] = useState<
    Array<SuburbWithAgentStatsFragment>
  >();

  const [cartSuburbIds, , setCartSuburbIds] = useSuburbCart();

  const onSearchSuburbOptionSelected = async (
    placeResult: google.maps.places.PlaceResult | null,
  ) => {
    const detailsParsed = new GoogleAddressParser(
      placeResult?.address_components as AddressComponent[],
    ).result();

    const {
      data,
    } = await client.query<GetSuburbFromSearchWithNearbySuburbsQuery>({
      query: GetSuburbFromSearchWithNearbySuburbsDocument,
      variables: {
        suburbs: [detailsParsed.city ?? '', detailsParsed.state ?? ''],
        state: detailsParsed.state,
        postcode: detailsParsed.postal_code,
      },
    });

    return [
      data.getSuburbFromSearch,
      ...(data.getSuburbFromSearch?.nearbySuburbs ?? []),
    ];
  };

  const onSubmit = useCallback(async () => {
    try {
      onAddClicked(cartSuburbIds);
    } catch (err) {
      console.log(err);
    }
  }, [cartSuburbIds]);

  const onSearchTermChanged = (text: string) => {
    setSearchTerm(text);
    setNearbySuburbs([]);
  };

  const onSuburbSelectClick = useCallback(
    (suburb: SuburbWithAgentStatsFragment) => {
      const suburbIndex = cartSuburbIds.indexOf(suburb.id);

      if (suburbIndex === -1) {
        if (cartSuburbIds.length < 5) {
          setCartSuburbIds([...cartSuburbIds, suburb.id]);
        }
      } else {
        setCartSuburbIds([
          ...cartSuburbIds.slice(0, suburbIndex),
          ...cartSuburbIds.slice(suburbIndex + 1),
        ]);
      }
    },
    [cartSuburbIds],
  );

  const chunkedNearbySuburbs = useMemo(
    () =>
      chunk(nearbySuburbs, 2) as (
        | [SuburbWithAgentStatsFragment, SuburbWithAgentStatsFragment]
        | [SuburbWithAgentStatsFragment]
      )[],
    [nearbySuburbs],
  );

  return (
    <>
      <Container $scroll={scroll}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ setFieldValue }) => {
            return (
              <FormStyled
                onSubmit={(e) => {
                  e.preventDefault();
                }}>
                <InputAndResultsConainer>
                  <GooglePlacesSelectField
                    fieldName="searchSuburb"
                    placeholder="Suburb"
                    searchTerm={searchTerm}
                    onOptionSelected={async (suburb) => {
                      setFieldValue('searchSuburb', suburb);
                      const nearbySuburbResults = await onSearchSuburbOptionSelected(
                        suburb,
                      );

                      setNearbySuburbs(nearbySuburbResults);
                    }}
                    onSearchTermChanged={onSearchTermChanged}
                    googleApiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                    types={['(cities)']}
                  />
                  {chunkedNearbySuburbs && chunkedNearbySuburbs.length > 0 && (
                    <>
                      <NearbyTitle>Nearby suburbs</NearbyTitle>
                      <SuburbsContainer>
                        {chunkedNearbySuburbs.map((suburbs, index) => {
                          const [leftSuburb, rightSuburb] = suburbs;
                          const leftSelected =
                            cartSuburbIds?.includes(leftSuburb.id) ?? false;
                          const rightSelected = rightSuburb
                            ? cartSuburbIds?.includes(rightSuburb.id) ?? false
                            : false;

                          // TODO: there is an error on the below causing the left side items towards the ends of the list to show as full price for some
                          // reason. At the moment all should be trials so just setting to true

                          const leftFreeTrial = true;
                          const rightFreeTrial = true;

                          // const leftFreeTrial =
                          //   (freeTrialData &&
                          //     freeTrialData
                          //       .myAgentSuburbSubscriptionFreeTrialState
                          //       .totalFreeTrialsAllowed -
                          //       freeTrialData
                          //         .myAgentSuburbSubscriptionFreeTrialState
                          //         .numberOfFreeTrialsClaimed -
                          //       index >
                          //       0) ??
                          //   false;
                          // const rightFreeTrial =
                          //   (freeTrialData &&
                          //     freeTrialData
                          //       .myAgentSuburbSubscriptionFreeTrialState
                          //       .totalFreeTrialsAllowed -
                          //       freeTrialData
                          //         .myAgentSuburbSubscriptionFreeTrialState
                          //         .numberOfFreeTrialsClaimed -
                          //       index +
                          //       1 >
                          //       0) ??
                          //   false;

                          return (
                            <SuburbRowContainer key={index}>
                              <LeftContainer>
                                <SuburbSelectCard
                                  suburb={leftSuburb}
                                  selected={leftSelected}
                                  visiblyDisabled={
                                    !leftSelected &&
                                    cartSuburbIds.length +
                                      (freeTrialData
                                        ?.myAgentSuburbSubscriptionFreeTrialState
                                        .numberOfFreeTrialsClaimed ?? 0) >=
                                      Number(config.MAX_AGENT_SUBURBS ?? 0)
                                  }
                                  isFreeTrial={leftFreeTrial}
                                  onClick={onSuburbSelectClick}
                                  suburbSubscription={mySubscribedSuburbsData?.mySubscribedSuburbs.find(
                                    (suburbSubscription) =>
                                      suburbSubscription.suburb.id ===
                                      leftSuburb.id,
                                  )}
                                />
                              </LeftContainer>
                              <RightContainer>
                                {rightSuburb && (
                                  <SuburbSelectCard
                                    suburb={rightSuburb}
                                    selected={rightSelected}
                                    visiblyDisabled={
                                      !rightSelected &&
                                      cartSuburbIds.length +
                                        (freeTrialData
                                          ?.myAgentSuburbSubscriptionFreeTrialState
                                          .numberOfFreeTrialsClaimed ?? 0) >=
                                        Number(config.MAX_AGENT_SUBURBS ?? 0)
                                    }
                                    isFreeTrial={rightFreeTrial}
                                    onClick={onSuburbSelectClick}
                                    suburbSubscription={mySubscribedSuburbsData?.mySubscribedSuburbs.find(
                                      (suburbSubscription) =>
                                        suburbSubscription.suburb.id ===
                                        rightSuburb.id,
                                    )}
                                  />
                                )}
                              </RightContainer>
                            </SuburbRowContainer>
                          );
                        })}
                      </SuburbsContainer>
                    </>
                  )}
                </InputAndResultsConainer>
              </FormStyled>
            );
          }}
        </Formik>
        {cartSuburbIds?.length > 0 && (
          <FixedFooter>
            <SelectedCount>
              {cartSuburbIds?.length +
                (freeTrialData?.myAgentSuburbSubscriptionFreeTrialState
                  .numberOfFreeTrialsClaimed ?? 0)}
              /5 free trials claimed
            </SelectedCount>
            <AddButton
              disabled={submitDisabled}
              loading={false}
              onClick={() => onAddClicked(cartSuburbIds)}>
              Confirm
            </AddButton>
          </FixedFooter>
        )}
      </Container>
    </>
  );
};

export default SuburbSelectForm;
