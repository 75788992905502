import React, { FC } from 'react';
import styled from 'styled-components';
import { AgentBadge } from '../graphql/generated';
import useAgentBadgeDetails, {
  AgentBadgeDetails,
} from '../hooks/useAgentBadgeDetails';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Description = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  line-height: 25px;
  margin-bottom: 30px;
`;

const BadgeRowContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const BadgeIcon = styled.img`
  width: 56px;
  height: 56px;
`;

const BadgeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 16px;
`;

const BadgeName = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.16px;
  line-height: 24px;
  margin: 0;
`;

const BadgeDescription = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.17px;
  margin: 0 0 5px 0;
`;

const BadgesContainer = styled.div`
  margin: 0 10px;
`;

interface BadgeRowProps {
  badgeDetails: AgentBadgeDetails;
}

const BadgeRow: FC<BadgeRowProps> = ({ badgeDetails }) => {
  const saleCountText = `${badgeDetails.saleCount}${
    badgeDetails.saleCount > 1 ? '+ properties sold' : ' property sold'
  }`;

  return (
    <BadgeRowContainer>
      <BadgeIcon src={badgeDetails.badgeUri} />
      <BadgeDetailsContainer>
        <BadgeName>{badgeDetails.name}</BadgeName>
        <BadgeDescription>{saleCountText}</BadgeDescription>
      </BadgeDetailsContainer>
    </BadgeRowContainer>
  );
};

interface MemberBadgeInfoProps {
  onRequestClose: () => void;
}

const MemberBadgeInfo: FC<MemberBadgeInfoProps> = () => {
  const verifiedBadgeDetails = useAgentBadgeDetails(AgentBadge.Verified);
  const bronzeBadgeDetails = useAgentBadgeDetails(AgentBadge.Bronze);
  const silverBadgeDetails = useAgentBadgeDetails(AgentBadge.Silver);
  const goldBadgeDetails = useAgentBadgeDetails(AgentBadge.Gold);
  const platinumBadgeDetails = useAgentBadgeDetails(AgentBadge.Platinum);
  const diamondBadgeDetails = useAgentBadgeDetails(AgentBadge.Diamond);

  return (
    <Container>
      <Description>
        Agents are awarded badges for reaching property sale milestones
      </Description>
      <BadgesContainer>
        {verifiedBadgeDetails && (
          <BadgeRow badgeDetails={verifiedBadgeDetails} />
        )}
        {bronzeBadgeDetails && <BadgeRow badgeDetails={bronzeBadgeDetails} />}
        {silverBadgeDetails && <BadgeRow badgeDetails={silverBadgeDetails} />}
        {goldBadgeDetails && <BadgeRow badgeDetails={goldBadgeDetails} />}
        {platinumBadgeDetails && (
          <BadgeRow badgeDetails={platinumBadgeDetails} />
        )}
        {diamondBadgeDetails && <BadgeRow badgeDetails={diamondBadgeDetails} />}
      </BadgesContainer>
    </Container>
  );
};

export default MemberBadgeInfo;
