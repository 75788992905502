import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import config from './config';
import NotFound from './containers/NotFound';
import AgentProfileSettings from './features/agent/containers/AgentProfileSettings';
import AgentSaleFinalized from './features/agent/containers/AgentSaleFinalized';
import AgentSignup from './features/agent/containers/AgentSignup';
import AgentSignupBasicDetails from './features/agent/containers/AgentSignupBasicDetails';
import AgentSignupComplete from './features/agent/containers/AgentSignupComplete';
import AgentSignupPayment from './features/agent/containers/AgentSignupPayment';
import AgentSignupProfileDetails from './features/agent/containers/AgentSignupProfileDetails';
import AgentSignupSubscriptionConfirmed from './features/agent/containers/AgentSignupSubscriptionConfirmed';
import AgentSignupSuburbs from './features/agent/containers/AgentSignupSuburbs';
import AgentSignupSummary from './features/agent/containers/AgentSignupSummary';
import ConfirmSale from './features/agent/containers/ConfirmSale';
import ConfirmSalePriceAndDate from './features/agent/containers/ConfirmSalePriceAndDate';
import ChangePassword from './features/auth/containers/ChangePassword';
import ChangePasswordSuccess from './features/auth/containers/ChangePasswordSuccess';
import ContinueSteps from './features/auth/containers/ContinueSteps';
import Login from './features/auth/containers/Login';
import Logout from './features/auth/containers/Logout';
import ResetPassword from './features/auth/containers/ResetPassword';
import ResetPasswordSuccess from './features/auth/containers/RestPasswordSuccess';
import TermsAndConditions from './features/auth/containers/TermsAndConditions';
import VerifyEmail from './features/auth/containers/VerifyEmail';
import {
  UpdateLastKnownIsGhostUser,
  UpdateLastKnownUserTypeAction,
  UPDATE_LAST_KNOWN_IS_GHOST_USER,
  UPDATE_LAST_KNOWN_USER_TYPE,
} from './features/auth/store/authTypes';
// import AboutPage from './features/landing/containers/About';
// import ForAgents from './features/landing/containers/ForAgents';
// import ForSellers from './features/landing/containers/ForSellers';
// import HowItWorks from './features/landing/containers/HowItWorks';
import GetStarted from './features/landing/containers/GetStarted';
// import Landing from './features/landing/containers/Landing';
import AdditionalInformation from './features/new-property/containers/Additionalnformation';
import Address from './features/new-property/containers/Address';
import ContinueSignup from './features/new-property/containers/ContinueSignup';
import Details from './features/new-property/containers/Details';
import DetailsComplete from './features/new-property/containers/DetailsComplete';
import Features from './features/new-property/containers/Features';
import NoAgents from './features/new-property/containers/NoAgents';
import Photos from './features/new-property/containers/Photos';
import RequestSent from './features/new-property/containers/RequestSent';
import SaleInformation from './features/new-property/containers/SaleInformation';
import SelectAgents from './features/new-property/containers/SelectAgents';
import SellerCreatePassword from './features/new-property/containers/SellerCreatePassword';
import SellerEmailVerification from './features/new-property/containers/SellerEmailVerification';
import SellerSignupBasicDetails from './features/new-property/containers/SellerSignupBasicDetails';
import AgentAppointmentRequests from './features/properties/containers/AgentAppointmentRequests';
import AgentDashboard from './features/properties/containers/AgentDashboard';
import AppointmentRequestComplete from './features/properties/containers/AppointmentRequestComplete';
import AppraisalRequestDetails from './features/properties/containers/AppraisalRequestDetails';
import BankDetails from './features/properties/containers/BankDetails';
import NewAppraisalAdditionalNotes from './features/properties/containers/NewAppraisalAdditionalNotes';
import NewAppraisalComparableProperties from './features/properties/containers/NewAppraisalComparableSales';
import NewAppraisalFees from './features/properties/containers/NewAppraisalFees';
import NewAppraisalSellingRange from './features/properties/containers/NewAppraisalSellingRange';
import NewAppraisalSubmitted from './features/properties/containers/NewAppraisalSubmitted';
import NewAppraisalSummary from './features/properties/containers/NewAppraisalSummary';
import NewAppraisalVideo from './features/properties/containers/NewAppraisalVideo';
import PropertyAppraisal from './features/properties/containers/PropertyAppraisalDetails';
import PropertyDetails from './features/properties/containers/PropertyDetails';
import PropertyNotSold from './features/properties/containers/PropertyNotSold';
import PropertySold from './features/properties/containers/PropertySold';
import RequestAppointment from './features/properties/containers/RequestAppointment';
import SaleStatus from './features/properties/containers/SaleStatus';
import SelectSaleAgent from './features/properties/containers/SelectSaleAgent';
import SellerDashboard from './features/properties/containers/SellerDashboard';
import SellerProfileSettings from './features/seller/containers/SellerProfileSettings';
import {
  useMeQuery,
  useMySubscribedSuburbsQuery,
  UserType,
} from './graphql/generated';
import useIsAuthenticated from './hooks/useIsAuthenticated';
import useLastKnownIsGhostUser from './hooks/useLastKnownIsGhostUser';
import useLastKnownUserType from './hooks/useLastKnowUserType';

interface PrivateRouteProps extends RouteProps {
  seller?: boolean;
  agent?: boolean;
  allowIncompletUser?: boolean;
  checkEmailVerification?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (routeProps) => {
  const history = useHistory();

  const {
    seller = false,
    agent = false,
    allowIncompletUser = false,
    checkEmailVerification,
    component: Component,
    ...rest
  } = routeProps;

  const isAuthenticated = useIsAuthenticated();
  const { data: meData, loading } = useMeQuery({
    skip: !isAuthenticated,
  });
  const lastKnownUserType = useLastKnownUserType();

  const userType = meData?.me?.userType ?? lastKnownUserType;
  const isCorrectUserType =
    (!seller && !agent) ||
    (seller && userType === UserType.Seller) ||
    (agent && userType === UserType.Agent);

  const validUserState = meData?.me?.profileComplete || allowIncompletUser;

  const isEmailVerified = meData?.me.isEmailVerified || !checkEmailVerification;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!Component) {
          return null;
        }

        // If the user has a token but auth is still loading then start rendering the page
        if (loading && isAuthenticated) {
          return <Component {...props} />;
        }

        if (!isAuthenticated) {
          // If the user is unauthenticated, redirect them to login
          return (
            <Redirect
              to={{
                pathname: '/login',
                search:
                  typeof routeProps.path == 'string'
                    ? `from=${encodeURIComponent(
                        `${history.location.pathname}?${
                          history.location.search
                            ? `?${history.location.search}`
                            : ''
                        }`,
                      )}`
                    : undefined,
              }}
            />
          );
        } else if (!isCorrectUserType) {
          // If the user is the incorrect user type show a 404 page
          return <NotFound />;
        } else if (!validUserState) {
          // If the user is the correct type but their profile isn't complete enough redirect them to their onboarding process
          return (
            <Redirect
              to={{
                pathname:
                  userType === UserType.Seller
                    ? '/new-property/'
                    : '/agent/signup/basic-details',
              }}
            />
          );
        } else if (!isEmailVerified) {
          // If the user email not verified, redirect them to email verification
          return (
            <Redirect
              to={{
                pathname: '/email-verification',
              }}
            />
          );
        } else {
          // If the user is fully authenticated then render the page
          return <Component {...props} />;
        }
      }}
    />
  );
};

/**
 * Resets scroll position to top of page on every route cahange.
 * Base on: https://stackoverflow.com/a/54343182
 */
const ScrollToTop: FC = (props) => {
  const { children } = props;
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
      document.body.scrollTo(0, 0); // Pages with modals may actualy have scrolling handled by body rather than window
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{children}</>;
};

const Routes: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { data: meData } = useMeQuery({
    skip: !isAuthenticated,
  });
  const { data: subscribedSuburbsData } = useMySubscribedSuburbsQuery({
    skip: !(meData?.me?.userType === UserType.Agent),
  });

  const lastKnownUserType = useLastKnownUserType();
  const lasKnownIsGhostUser = useLastKnownIsGhostUser();
  const dispatch = useDispatch();

  // Update last known user type when value changes
  useEffect(() => {
    if (
      isAuthenticated &&
      meData?.me &&
      meData.me.userType !== lastKnownUserType
    ) {
      dispatch({
        type: UPDATE_LAST_KNOWN_USER_TYPE,
        payload: { currentUserType: meData.me.userType },
      } as UpdateLastKnownUserTypeAction);
    }
  }, [isAuthenticated, lastKnownUserType, meData]);

  // Update last is ghost user type when value changes
  useEffect(() => {
    if (
      isAuthenticated &&
      meData?.me &&
      meData.me.isGhostUser !== lasKnownIsGhostUser
    ) {
      dispatch({
        type: UPDATE_LAST_KNOWN_IS_GHOST_USER,
        payload: { isGhostUser: meData.me.isGhostUser },
      } as UpdateLastKnownIsGhostUser);
    }
  }, [isAuthenticated, lasKnownIsGhostUser, meData]);

  const userType = meData?.me?.userType ?? lastKnownUserType;
  const isGhostUser = meData?.me?.isGhostUser ?? lasKnownIsGhostUser;

  const profileConfirmedIncomplete = meData?.me
    ? !meData?.me?.profileComplete
    : false;

  const agentSubscribedToSuburbs = subscribedSuburbsData
    ? subscribedSuburbsData.mySubscribedSuburbs.length > 0
    : undefined;

  return (
    <Router>
      <Route
        path=""
        render={({ location }) => {
          if (config.GOOGLE_ANALYTCS_TRACKING_ID) {
            ReactGA.pageview(location.pathname);
          }

          return null;
        }}
      />

      <ScrollToTop>
        <Switch>
          <Route exact path="/">
            {isAuthenticated && !isGhostUser ? (
              userType == UserType.Seller ? (
                meData &&
                (meData.me.profileComplete ? (
                  meData.me.isEmailVerified ? (
                    <SellerDashboard />
                  ) : (
                    <SellerEmailVerification />
                  )
                ) : (
                  meData.me.sellerProfile?.nextPage && (
                    <Redirect
                      to={{
                        pathname: meData.me.sellerProfile?.nextPage,
                      }}
                    />
                  )
                ))
              ) : userType == UserType.Agent ? (
                !profileConfirmedIncomplete ||
                agentSubscribedToSuburbs === undefined ? (
                  <AgentDashboard />
                ) : agentSubscribedToSuburbs === true ? (
                  <Redirect
                    to={{
                      pathname: '/agent/signup/profile-details',
                    }}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: '/agent/signup/basic-details',
                    }}
                  />
                )
              ) : (
                <Redirect to={{ pathname: '/login' }} />
              )
            ) : (
              <Redirect to={{ pathname: '/login' }} />
            )}
          </Route>

          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route exact path="/reset-password/success">
            <ResetPasswordSuccess />
          </Route>
          <Route exact path="/change-password/user/:userId/token/:token">
            <ChangePassword />
          </Route>
          <Route exact path="/change-password/success">
            <ChangePasswordSuccess />
          </Route>
          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/get-started">
            <GetStarted />
          </Route>
          <Route exact path="/terms-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/verify-email/:token">
            <VerifyEmail />
          </Route>
          <Route exact path="/continue/:token">
            <ContinueSteps />
          </Route>
          {/* <Route exact path="/about-the-company">
            <AboutPage />
          </Route>
          <Route exact path="/how-it-works">
            <HowItWorks />
          </Route>
          <Route exact path="/real-state-agent-info">
            <ForAgents />
          </Route>
          <Route exact path="/find-real-estate-agents">
            <ForSellers />
          </Route> */}

          <Route exact path="/continue-signup" component={ContinueSignup} />

          {/* Seller Routes */}

          {/* -- New Property Routes */}
          <Redirect exact path="/new-property/" to="/new-property/address" />

          {/* Address screen is not private as it promotes unauthenticated users to ghost users */}
          <Route exact path="/new-property/address" component={Address} />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            path="/new-property/details"
            component={Details}
          />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            path="/new-property/features"
            component={Features}
          />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            path="/new-property/sale-info"
            component={SaleInformation}
          />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            path="/new-property/photos"
            component={Photos}
          />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            path="/new-property/additional-info"
            component={AdditionalInformation}
          />
          <Route
            seller
            allowIncompletUser
            exact
            path="/new-property/account-details"
            component={SellerSignupBasicDetails}
          />
          <Route
            seller
            allowIncompletUser
            exact
            path="/new-property/account-password"
            component={SellerCreatePassword}
          />
          <PrivateRoute
            seller
            allowIncompletUser
            exact
            checkEmailVerification
            path="/new-property/details-complete"
            component={DetailsComplete}
          />
          <PrivateRoute
            seller
            exact
            checkEmailVerification
            path="/new-property/select-agents"
            component={SelectAgents}
          />
          <PrivateRoute
            seller
            exact
            checkEmailVerification
            path="/new-property/request-sent"
            component={RequestSent}
          />
          <PrivateRoute
            seller
            exact
            checkEmailVerification
            path="/new-property/no-agents"
            component={NoAgents}
          />
          <PrivateRoute
            exact
            path="/email-verification"
            component={SellerEmailVerification}
          />

          {/* -- Seller Dashboard Routes */}

          <PrivateRoute
            seller
            exact
            path="/property/:id"
            component={PropertyDetails}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:id/appraisal/:appraisalId"
            component={PropertyAppraisal}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/appraisal/:appraisalId/request-appointment"
            component={RequestAppointment}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/appraisal/:appraisalId/request-complete"
            component={AppointmentRequestComplete}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/sale-status"
            component={SaleStatus}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/sale-status/sold/agent"
            component={SelectSaleAgent}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/sale-status/sold/agent/:agentId/claim"
            component={BankDetails}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/sold"
            component={PropertySold}
          />

          <PrivateRoute
            seller
            exact
            path="/property/:propertyId/not-sold"
            component={PropertyNotSold}
          />

          <PrivateRoute
            seller
            exact
            path="/profile-settings"
            component={SellerProfileSettings}
          />

          {/* Agent Routes */}

          {/* -- Agent Signup */}

          <Route exact path="/agent/signup" component={AgentSignup} />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/basic-details"
            component={AgentSignupBasicDetails}
          />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/suburbs"
            component={AgentSignupSuburbs}
          />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/summary"
            component={AgentSignupSummary}
          />
          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/payment"
            component={AgentSignupPayment}
          />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/payment-processed"
            component={AgentSignupSubscriptionConfirmed}
          />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/profile-details"
            component={AgentSignupProfileDetails}
          />

          <PrivateRoute
            agent
            allowIncompletUser
            exact
            path="/agent/signup/complete"
            component={AgentSignupComplete}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id"
            component={AppraisalRequestDetails}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/profile-settings"
            component={AgentProfileSettings}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appointment-requests"
            component={AgentAppointmentRequests}
          />

          {/* -- New Appraisal Routes */}

          <Route
            exact
            path="/agent/appraisal-request/:id/appraise"
            render={({ match }) => {
              return (
                <Redirect
                  to={`/agent/appraisal-request/${match.params.id}/appraise/selling-range`}
                />
              );
            }}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/selling-range"
            component={NewAppraisalSellingRange}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/video"
            component={NewAppraisalVideo}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/fees"
            component={NewAppraisalFees}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/comparable-sales"
            component={NewAppraisalComparableProperties}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/additional-notes"
            component={NewAppraisalAdditionalNotes}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/summary"
            component={NewAppraisalSummary}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/appraisal-request/:id/appraise/submitted"
            component={NewAppraisalSubmitted}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/property-sale/:id/confirm"
            component={ConfirmSale}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/property-sale/:id/confirm/details"
            component={ConfirmSalePriceAndDate}
          />

          <PrivateRoute
            agent
            exact
            path="/agent/property-sale/:id/confirm/finalized"
            component={AgentSaleFinalized}
          />

          <Route component={NotFound} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default Routes;
