import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import completeCheckmarkUrl from '../../../assets/complete-checkmark.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepCompleteContent, {
  usePrecacheForStepCompleteContent,
} from '../../../components/StepCompleteContent';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForSellerDashboard } from '../../properties/containers/SellerDashboard';

export const usePrecacheForRequestsSent: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
  usePrecacheForNavigationHeader();
  usePrecacheForStepCompleteContent();
  usePrecacheForStepTemplate();
};

const RequestSent: FC = () => {
  const history = useHistory();
  usePrecacheForSellerDashboard();

  const onOkayClicked = () => {
    history.push('/');
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Request Sent | AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Request Sent!"
        caption="You should have an appraisal within the next 2 business days. This could be longer during holiday periods."
        nextButtonLabel="Okay"
        onNextButtonClick={onOkayClicked}
      />
    </StepPageTemplate.Container>
  );
};

export default RequestSent;
