import { Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { FC, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NumberInputField from '../../../components/NumberInputField';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  useMeQuery,
  useUpdateAgentProfileBasicDetailsMutation,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import { usePrecacheForAgentSignupProfileDetails } from './AgentSignupProfileDetails';

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

export const usePrecacheForAgentSignupBasicDetails: () => void = () => {
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface FormValues {
  name: string;
  mobileNumber: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}/, 'Phone number is invalid')
    .required('Phone number is required'),
});

const AgentSignupBasicDetails: FC = () => {
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  const { data: meData } = useMeQuery({ skip: !isAuthenticated });
  const [
    updateAgentProfileBasicDetails,
    { loading, error: updateDetailsError },
  ] = useUpdateAgentProfileBasicDetailsMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    const parsedNumber = parsePhoneNumberFromString(values.mobileNumber, 'AU');

    if (!parsedNumber) return; // This shouldn't happen thanks to our form validation

    await updateAgentProfileBasicDetails({
      variables: {
        ...values,
        mobileNumber: parsedNumber.number as string,
      },
    });

    history.push('/agent/signup/suburbs');
  }, []);

  const initialValues: FormValues = useMemo(
    () => ({
      mobileNumber:
        // Convert existing mobile number from internationalised version to australian
        (meData?.me.mobileNumber &&
          `0${
            parsePhoneNumberFromString(meData?.me.mobileNumber)?.nationalNumber
          }`) ??
        '',
      name: meData?.me.agentProfile?.name ?? '',
    }),
    [meData],
  );

  usePrecacheForAgentSignupProfileDetails();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container
      desktopHeader={<NavigationHeader minimal navigateToLogout />}>
      <Helmet>
        <title>Basic Details | AgentSpot</title>
      </Helmet>
      {meData?.me && (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}>
          {({ submitForm, errors, isValid, touched }) => {
            const error: string | undefined =
              (touched['name'] && errors.name) ||
              (touched['mobileNumber'] && errors.mobileNumber) ||
              (updateDetailsError && apolloErrorToString(updateDetailsError));

            return (
              <>
                <StepPageTemplate.ContentContainerForm>
                  <StepNavigationHeader
                    title="Sign up"
                    stepName="Basic details"
                    stepIndex={1}
                    stepCount={2}
                    stepDescription="Enter the following details about yourself"
                  />

                  <InputLabel>Full name</InputLabel>
                  <TextInputField
                    showErrorAfterTouch
                    name="name"
                    placeholder="Full name"
                    maxLength={196}
                  />

                  <InputLabel>Phone number</InputLabel>
                  <NumberInputField
                    showErrorAfterTouch
                    fieldName="mobileNumber"
                    placeholder="Phone number"
                    thousandSeparator={false}
                    allowLeadingZeros
                    isNumericString
                    useStringValue
                  />
                  {error && <ASErrorLabel>{error}</ASErrorLabel>}
                </StepPageTemplate.ContentContainerForm>
                <StepNavigationFooter
                  backHref={null}
                  onNextClick={submitForm}
                  nextDisabled={!isValid || loading}
                  hideBackButton
                  loading={loading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default AgentSignupBasicDetails;
