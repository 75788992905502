import { Loader } from '@googlemaps/js-api-loader';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

const usePlacesAutocomplete = (
  apiKey: string,
  text = '',
  types = ['address'],
  debounceMs = 200,
): {
  loading: boolean;
  predictions: Array<google.maps.places.AutocompletePrediction>;
} => {
  const [predictions, setPredictions] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [autocompleteService, setAutocompleteService] = useState<
    google.maps.places.AutocompleteService | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        await new Loader({ apiKey, ...{ libraries: ['places'] } }).load();
        setAutocompleteService(new google.maps.places.AutocompleteService());
      } catch (error) {
        console.log(error);
      }
    };

    if (apiKey) {
      init();
    }
  }, []);

  const getPredictions = useCallback(
    async (text: string) => {
      setLoading(true);
      autocompleteService?.getPlacePredictions(
        {
          input: text,
          types: types,
          componentRestrictions: { country: 'au' },
        },
        (predictions) => {
          setLoading(false);
          setPredictions(predictions ?? []);
        },
      );
    },
    [autocompleteService],
  );

  const debounceOnSearchTermChange: (
    searchTerm: string,
  ) => void = useCallback(debounce(getPredictions, debounceMs), [
    autocompleteService,
  ]);

  useEffect(() => {
    try {
      if (!text) {
        return;
      }

      debounceOnSearchTermChange(text);
    } catch (e) {
      setLoading(false);
    }
  }, [text, debounceMs]);

  return { predictions, loading };
};

export default usePlacesAutocomplete;
