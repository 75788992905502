import { Field, useField } from 'formik';
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 10px 21px 30px 20px;
`;

const Label = styled.div`
  flex-grow: 1;
  margin: 5px 0 8px;

  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.11px;
`;

const OptionContainer = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
`;

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    grid-template-columns: auto auto auto auto;
  }
`;

const OptionLabel = styled.div<{ checked: boolean }>`
  ${(props) =>
    props.checked
      ? `background-color: #1745B0; color: #ffffff`
      : `color: #58687e;`};
  flex-grow: 1;
  margin: 0;
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 15px;
  width: 122px;
  height: 70px;
  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.14px;
  border: 1px solid #58687e;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    width: 96px;
    height: 70px;
    padding: 10px;
  }
`;

const HiddenField = styled(Field).attrs({
  type: 'checkbox',
})`
  display: none;
`;

interface OptionField {
  fieldName: string;
  value: string | undefined;
  label: string;
}

const OptionField: FC<OptionField> = (props) => {
  const { fieldName: name, value, label } = props;

  const [{ checked }] = useField({
    name,
    value: value ?? undefined,
    type: 'checkbox',
  });

  // This is a bit of a hack to force the checkbox buttons into having an undefined value
  const showAsChecked = checked || !value;

  return (
    <OptionContainer>
      <HiddenField name={name} value={value} />
      <OptionLabel checked={showAsChecked ?? false}>{label}</OptionLabel>
    </OptionContainer>
  );
};

export interface PropertyQuestionCheckBoxRowFieldProps<
  T extends string | undefined
> {
  fieldName: string;
  label: string;
  options: Array<{ value: T; label: string }>;
}
const PropertyQuestionCheckBoxRowField = <T extends string | undefined>(
  props: PropertyQuestionCheckBoxRowFieldProps<T>,
): ReactElement => {
  const { label, fieldName, options } = props;

  return (
    <Container>
      <Label>{label}</Label>

      <OptionsContainer>
        {options.map(({ value, label }, i) => (
          <OptionField
            fieldName={fieldName}
            value={value}
            label={label}
            key={i}
          />
        ))}
      </OptionsContainer>
    </Container>
  );
};

export default PropertyQuestionCheckBoxRowField;
