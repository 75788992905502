import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import appraisalVideoPlayButtonUri, {
  ReactComponent as AppraisalVideoPlayButtonIcon,
} from '../assets/appraisal-video-play-button.svg';

const VideoContainer = styled.div`
  height: auto;
  background-color: #444444;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    border-radius: 10px;
    overflow: hidden;
    margin: 0 20px;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  z-index: 1;
  background: transparent url(${(props) => props.poster}) no-repeat center;
`;

const AppraisalVideoPlayButton = styled(AppraisalVideoPlayButtonIcon)`
  z-index: 2;
  align-self: flex-start;
  justify-self: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const PosterImage = styled.img`
  z-index: 2;
  height: 100%;
  width: auto;
  position: absolute;
  object-fit: contain;
`;

export const usePrecacheForAppraisalVideo: () => void = () => {
  usePrecacheImages([appraisalVideoPlayButtonUri]);
};

export interface AppraisalVideoProps {
  videoUrl: string;
  videoThumbnail: string;
}

const AppraisalVideo: FC<AppraisalVideoProps> = ({
  videoUrl,
  videoThumbnail,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [showOverlay, setShowOverlay] = useState<boolean>(true);

  const onClickVideo = () => {
    if (showOverlay) {
      setShowOverlay(false);
    }
  };

  useEffect(() => {
    if (showOverlay === false) {
      videoRef?.current?.play();
    }
  }, [showOverlay]);

  return (
    <VideoContainer onClick={onClickVideo}>
      {showOverlay && (
        <>
          <AppraisalVideoPlayButton />
          <PosterImage src={videoThumbnail} />
        </>
      )}
      <Video ref={videoRef} controls={!showOverlay}>
        <source src={videoUrl} />
      </Video>
    </VideoContainer>
  );
};

export default AppraisalVideo;
