import React, { FC } from 'react';
import styled from 'styled-components';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../components/NavigationHeader';
import NotFoundPlaceholder, {
  usePrecacheForNotFoundPlaceholder,
} from '../components/NotFoundPlaceholder';
import UnauthenticatedMenuActions from '../components/UnauthenticatedMenuActions';
import LandingFooter, {
  usePrecacheForLandingFooter,
} from '../features/landing/components/LandingFooter';
import useIsAuthenticated from '../hooks/useIsAuthenticated';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top: 1px solid #e8eaed;
  z-index: 2;
`;

export const usePrecacheForNotFound: () => void = () => {
  usePrecacheForNotFoundPlaceholder();
};

const NotFound: FC = () => {
  const isAuthenticated = useIsAuthenticated();

  usePrecacheForNavigationHeader();
  usePrecacheForLandingFooter();

  return (
    <Container>
      <NotFoundPlaceholder>
        <NavigationHeader
          rightSideComponent={
            !isAuthenticated && <UnauthenticatedMenuActions />
          }
        />
      </NotFoundPlaceholder>
      <FooterContainer>
        <LandingFooter />
      </FooterContainer>
    </Container>
  );
};

export default NotFound;
