import React, { FC, useMemo } from 'react';
import StatusBadge from '../../../components/StatusBadge';
import {
  PropertyCompleteType,
  PropertyState,
  PropertyWithAppraisalsFragment,
} from '../../../graphql/generated';
import PropertyCardTemplate, {
  usePrecacheForPropertyCardTemplate,
} from './PropertyCardTemplate';
import { usePrecacheForPropertyActionMenu } from './UniversalPropertyActionMenu';

export const usePrecacheForPropertyCard: () => void = () => {
  usePrecacheForPropertyCardTemplate();
  usePrecacheForPropertyActionMenu();
};

export interface PropertyCardProps {
  property: PropertyWithAppraisalsFragment;
}

const PropertyCard: FC<PropertyCardProps> = (props) => {
  const { property } = props;

  const statusBadgeMessage = useMemo(() => {
    if (
      property.state === PropertyState.Complete &&
      property.completeType === PropertyCompleteType.SoldUnconditionally
    ) {
      return 'Sold';
    } else if (
      (property.state === PropertyState.Complete &&
        property.completeType === PropertyCompleteType.DecidedNotToSell) ||
      property.completeType === PropertyCompleteType.DecidedNotToUseAgentspot
    ) {
      return 'Off market';
    } else if (
      property.state === PropertyState.Active &&
      property.propertyAppraisals.length > 0
    ) {
      return property.propertyAppraisals.length > 1
        ? `${property.propertyAppraisals.length} Appraisals`
        : '1 Appraisal';
    } else if (property.state === PropertyState.Active) {
      return 'Waiting for appraisals';
    } else {
      return undefined;
    }
  }, [property]);

  return (
    <>
      <PropertyCardTemplate
        property={property}
        href={`/property/${property.id}`}
        StatusBadgeComponent={<StatusBadge> {statusBadgeMessage}</StatusBadge>}
        menuButtonVisible={
          !(
            property.state === PropertyState.Complete &&
            property.completeType === PropertyCompleteType.SoldUnconditionally
          )
        }
      />
    </>
  );
};

export default PropertyCard;
