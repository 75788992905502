import React, { FC } from 'react';
import styled from 'styled-components';
import completeCheckmarkUrl, {
  ReactComponent as CompleteCheckmark,
} from '../assets/complete-checkmark.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';
import ASBlockButton from './ASBlockButton';
import ASCaption from './ASCaption';
import ASHeader from './ASHeader';
import ASParagraph from './ASParagraph';
import StepPageTemplate from './StepPageTemplate';

type HeaderProps = {
  fontSize?: string;
};

const Header = styled(ASHeader)<HeaderProps>`
  text-align: center;
  margin-bottom: 25px;
  font-size: ${(props) => props.fontSize ?? '25px'};
`;

const PageContentContainer = styled(StepPageTemplate.ContentContainer)`
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`;

const ContinueButton = styled(ASBlockButton)`
  min-width: 80%;
  min-width: min(227px, 80%);
  margin: 26px 0 0 0;
`;

export const usePrecacheForStepCompleteContent: () => void = () => {
  usePrecacheImages([completeCheckmarkUrl]);
};

interface StepCompleteContentProps {
  title: string;
  body?: string;
  caption?: string;
  nextButtonLabel: string;
  onNextButtonClick: () => void;
  hideCheckmark?: boolean;
  headerFontSize?: string;
}

const StepCompleteContent: FC<StepCompleteContentProps> = (props) => {
  const {
    title,
    body,
    caption,
    nextButtonLabel,
    onNextButtonClick,
    hideCheckmark = false,
    headerFontSize,
  } = props;

  return (
    <PageContentContainer>
      {!hideCheckmark && <CompleteCheckmark />}
      <Header fontSize={headerFontSize}>{title}</Header>
      {body && <ASParagraph>{body}</ASParagraph>}
      {caption && <ASCaption>{caption}</ASCaption>}

      <ContinueButton onClick={onNextButtonClick}>
        {nextButtonLabel}
      </ContinueButton>
    </PageContentContainer>
  );
};

export default StepCompleteContent;
