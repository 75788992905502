import { useField } from 'formik';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import ASModal, { usePrecacheForASModal } from '../../../components/ASModal';
import { ReactComponent as DownArrow } from '../assets/option-select-down-arrow.svg';
import QuestionOptionSelect, {
  usePrecacheForQuestionOptionSelect,
} from './QuestionOptionSelect';

const InputShape = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid #282828;
  border-radius: 0px;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.2px;
  padding: 0 20px;
  background-color: #ffffff;
`;

const ValueLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  border: none;
  flex-grow: 1;
  padding: 18px 0;
  text-align: left;
`;

const PlaceholderLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  border: none;
  flex-grow: 1;
  padding: 18px 0;
  text-align: left;
`;

export const usePrecacheForOptionSelectInputField: () => void = () => {
  usePrecacheForASModal();
  usePrecacheForQuestionOptionSelect();
};
export interface OptionSelectInputFieldProps<T> {
  fieldName: string;
  placeholder: string;
  options: Array<{ value: T; label: string }>;
  modalTitle: string;
}

const OptionSelectInputField = <T extends string | undefined>(
  props: OptionSelectInputFieldProps<T>,
): ReactElement => {
  const { fieldName, placeholder, options, modalTitle } = props;

  const [{ value }, , { setValue }] = useField({ name: fieldName });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const selectedOption = useMemo(
    () => options.find((option) => option?.value === value),
    [options, value],
  );

  const onClick = useCallback(() => {
    setIsModalOpen(true);
  }, [isModalOpen]);

  return (
    <>
      <InputShape onClick={onClick} type="button">
        {value ? (
          <ValueLabel>{selectedOption?.label ?? value}</ValueLabel>
        ) : (
          <PlaceholderLabel>{placeholder}</PlaceholderLabel>
        )}
        <DownArrow />
      </InputShape>
      <ASModal
        isOpen={isModalOpen}
        title={modalTitle}
        onRequestClose={() => setIsModalOpen(false)}>
        <QuestionOptionSelect<T>
          initialValue={value}
          options={options}
          label=""
          onDone={(value) => {
            setValue(value);
            setIsModalOpen(false);
          }}
        />
      </ASModal>
    </>
  );
};

export default OptionSelectInputField;
