import { useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';
import NumberInputField from '../../../components/NumberInputField';
import TextInputField from '../../../components/TextInputField';
import theme from '../../../constants/theme';
import { PropertyAppraisalComparableSaleInput } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import removeCrossIconUrl, {
  ReactComponent as RemoveCrossIcon,
} from '../../agent/assets/remove-suburb-cross.svg';

const Container = styled.div`
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  padding: 22px 20px 25px 20px;
  margin: 0 0 15px 0;
`;

const FieldTitleLabel = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  margin: 0 0 20px 0;
`;

const FieldCaptionLabel = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 0 0 10px 0;
`;

const ViewLink = styled.a`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.3px;
  text-align: right;

  margin: 0 20px 0 40px;

  :hover {
    color: #1745b0;
    text-decoration-color: #1745b0;
  }
`;

const LinkPlaceholder = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #a6aeba;
  letter-spacing: 0.3px;
  text-align: right;

  margin: 0 20px 0 40px;
`;

const PrefixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
`;

const NumberInput = styled(NumberInputField)`
  margin-bottom: 10px;
`;

const RemoveButton = styled.button`
  border: none;
  padding: 0;
  margin: 20px 0 0 0;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const RemoveCross = styled(RemoveCrossIcon)`
  width: 14px;
  height: 14px;
  margin: 0 8px 0 0;
`;

const RemoveText = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  margin: 0;
`;

export interface ComparableSaleInputFieldProps {
  fieldName: string;
  index: number;
  onRemovePress: () => void;
}

export const usePrecacheForComparableSaleInputField: () => void = () => {
  usePrecacheImages([removeCrossIconUrl]);
};

const ComparableSaleInputField: FC<ComparableSaleInputFieldProps> = (props) => {
  const { fieldName, index, onRemovePress } = props;

  const [{ value }, { error }] = useField<PropertyAppraisalComparableSaleInput>(
    {
      name: fieldName,
    },
  );

  const url =
    !error && !!value?.url
      ? !/^https?:\/\//i.test(value.url)
        ? `https://${value.url.trim()}`
        : value.url
      : undefined;

  return (
    <Container>
      <FieldTitleLabel>Comparable sale {index + 1}</FieldTitleLabel>
      <FieldCaptionLabel>Address</FieldCaptionLabel>
      <TextInputField
        name={`${fieldName}.address`}
        placeholder=""
        showErrorAfterTouch
        maxLength={255}
      />
      <FieldCaptionLabel>Sale Price</FieldCaptionLabel>
      <NumberInput
        fieldName={`${fieldName}.salePriceDollars`}
        allowNegative={false}
        placeholder=""
        renderPrefix={() => <PrefixLabel>$</PrefixLabel>}
      />
      <FieldCaptionLabel>Listing URL (optional)</FieldCaptionLabel>
      <TextInputField
        name={`${fieldName}.url`}
        placeholder=""
        showErrorAfterTouch
        maxLength={255}
        renderSuffix={() =>
          !error && !!url ? (
            <ViewLink href={url} target="_blank">
              View
            </ViewLink>
          ) : (
            <LinkPlaceholder>View</LinkPlaceholder>
          )
        }
      />

      <RemoveButton onClick={onRemovePress}>
        <RemoveCross />
        <RemoveText>Remove</RemoveText>
      </RemoveButton>
    </Container>
  );
};

export default ComparableSaleInputField;
