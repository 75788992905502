import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import StepCompleteContent from '../../../components/StepCompleteContent';
import { pxToRem } from '../../../utils/pxToRem';
import { usePrecacheForAgentDashboard } from '../../properties/containers/AgentDashboard';

export const usePrecacheForAgentSaleFinalized: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};

const AgentSaleFinalized: FC = () => {
  const history = useHistory();

  const onNextClicked = useCallback(() => {
    history.push('/');
  }, []);

  usePrecacheForAgentDashboard();

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Confirm Sale | AgentSpot</title>
      </Helmet>
      <StepCompleteContent
        title="Sale has been finalized"
        body="Congratulations, your sale has been finalised. You will be charged the AgentSpot commission fee for the successful sale of this property."
        nextButtonLabel="Back to home"
        onNextButtonClick={onNextClicked}
        headerFontSize={pxToRem(40)}
      />
    </SideBySidePageTemplate>
  );
};

export default AgentSaleFinalized;
