import React, { FC } from 'react';
import styled from 'styled-components';
import createListingMenuPlusBlockIconUrl, {
  ReactComponent as CreateListingMenuPlusBlockIcon,
} from '../assets/create-listing-menu-plus-block.svg';
import myPropertiesHouseIconUrl, {
  ReactComponent as MyPropertiesHouseIcon,
} from '../assets/my-properties-house.svg';
import profileSettingsSillhouetteIconUrl, {
  ReactComponent as ProfileSettingsSillhouetteIcon,
} from '../assets/profile-settings-silhouette.svg';
import { usePrecacheForAddress } from '../features/new-property/containers/Address';
import { usePrecacheForSellerDashboard } from '../features/properties/containers/SellerDashboard';
import { usePrecacheForSellerProfileSettings } from '../features/seller/containers/SellerProfileSettings';
import { useMeQuery } from '../graphql/generated';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import usePrecacheImages from '../hooks/usePrecacheImages';
import MenuLink from './MenuLink';
import MenuTemplate from './MenuTemplate';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 50px 0;
  min-height: 124px;
`;

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(135deg, #007ce9 0%, #0b1a9a 100%);
  width: 75px;
  height: 75px;
  border-radius: 38px;

  margin: 0 0 18px 0;
`;

const AvatarLatter = styled.div`
  display: flex;
  font-family: Inter, Arial, sans-serif;
  font-weight: 800;
  font-size: 40px;
  color: #ffffff;
  letter-spacing: -0.51px;

  :hover {
    cursor: default;
  }
`;

const NameLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: #203553;
  letter-spacing: 0.15px;
`;

const ProfileSettingsSillhouette = styled(ProfileSettingsSillhouetteIcon)`
  width: 17px;
  height: 19px;
`;

const MyPropertiesHouse = styled(MyPropertiesHouseIcon)`
  width: 17px;
  height: 18px;
`;

const CreateListingMenuPlusBlock = styled(CreateListingMenuPlusBlockIcon)`
  width: 17px;
  height: 17px;
`;

/**
 * WARNING: Using this inside the precache hook of one of the linked pages will cause an infinite loop!
 *
 * Use inside render of any page showing this menu but do not include it in their precache hook.
 */
export const usePrecacheForSellerMenu: () => void = () => {
  const isAuthenticated = useIsAuthenticated();

  useMeQuery({
    skip: !isAuthenticated,
  });

  usePrecacheImages([
    profileSettingsSillhouetteIconUrl,
    myPropertiesHouseIconUrl,
    createListingMenuPlusBlockIconUrl,
  ]);

  usePrecacheForAddress();
  usePrecacheForSellerDashboard();
  usePrecacheForSellerProfileSettings();
};

const SellerMenu: FC = () => {
  const { data: meData } = useMeQuery();

  const sellerProfile = meData?.me.sellerProfile;

  return (
    <MenuTemplate
      profileSection={
        <ProfileContainer>
          {sellerProfile && (
            <>
              <Avatar>
                <AvatarLatter>
                  {sellerProfile?.name?.slice(0, 1) ?? 'A'}
                </AvatarLatter>
              </Avatar>
              <NameLabel>{sellerProfile?.name ?? 'Anonymous User'}</NameLabel>
            </>
          )}
        </ProfileContainer>
      }
      links={
        <>
          <MenuLink
            label="My Properties"
            icon={<MyPropertiesHouse />}
            href="/"
          />
          <MenuLink
            label="Create Listing"
            icon={<CreateListingMenuPlusBlock />}
            href="/new-property"
          />

          {meData?.me.profileComplete && (
            <MenuLink
              label="Profile Settings"
              icon={<ProfileSettingsSillhouette />}
              href="/profile-settings"
            />
          )}
        </>
      }
    />
  );
};

export default SellerMenu;
