import { useCallback, useEffect, useState } from 'react';
import bronzeBadgeUrl from '../assets/agent-badge-bronze.svg';
import diamondBadgeUrl from '../assets/agent-badge-diamond.svg';
import goldBadgeUrl from '../assets/agent-badge-gold.svg';
import platinumBadgeUrl from '../assets/agent-badge-platinum.svg';
import silverBadgeUrl from '../assets/agent-badge-silver.svg';
import verifiedBadgeUrl from '../assets/agent-badge-verified.svg';
import { badgeTypeToSaleCount } from '../constants/EnumMappings';
import { AgentBadge } from '../graphql/generated';

export interface AgentBadgeDetails {
  name?: string;
  saleCount: number;
  badgeUri?: string;
}

const useAgentBadgeDetails = (
  badge: AgentBadge | undefined | null,
): AgentBadgeDetails | undefined | null => {
  const [badgeDetails, setBadgeDetails] = useState<AgentBadgeDetails>();

  useEffect(() => {
    const badgeDetails = getBadgeDetails();
    setBadgeDetails(badgeDetails);
  }, [badge]);

  const getBadgeDetails = useCallback(() => {
    if (!badge) {
      return;
    }

    const saleCount = badgeTypeToSaleCount[badge];
    let name = '';
    let badgeUri = '';

    switch (badge) {
      case AgentBadge.Diamond:
        badgeUri = diamondBadgeUrl;
        name = 'Diamond';
        break;
      case AgentBadge.Platinum:
        badgeUri = platinumBadgeUrl;
        name = 'Platinum';
        break;
      case AgentBadge.Gold:
        badgeUri = goldBadgeUrl;
        name = 'Gold';
        break;
      case AgentBadge.Silver:
        badgeUri = silverBadgeUrl;
        name = 'Silver';
        break;
      case AgentBadge.Bronze:
        badgeUri = bronzeBadgeUrl;
        name = 'Bronze';
        break;
      case AgentBadge.Verified:
        badgeUri = verifiedBadgeUrl;
        name = 'Verified';
        break;
      default:
        break;
    }

    return {
      saleCount,
      name,
      badgeUri,
    };
  }, [badge]);

  return badgeDetails;
};

export default useAgentBadgeDetails;
