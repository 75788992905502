import { useMemo } from 'react';
import theme from '..//constants/theme';
import useViewport from './useViewport';

const useIsDesktop: () => boolean = () => {
  const windowSize = useViewport();
  const isDesktop = useMemo(() => {
    return (
      !!windowSize?.width &&
      windowSize.width >
        Number(theme.layout.desktopBreakpoint.replace('px', ''))
    );
  }, [windowSize]);

  return isDesktop;
};

export default useIsDesktop;
