import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';
import sellPriceIconUri, {
  ReactComponent as SellPriceIcon,
} from '../assets/sell-price-ic.svg';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;
  padding: 30px;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 15px;

  @media print {
    page-break-inside: avoid;
    padding: 30px 40px;
    margin-bottom: 40px;
  }
`;

const Title = styled.h4`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: ${pxToRem(13)};
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;

  @media print {
    text-align: start;
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: ${pxToRem(18)};
    font-weight: 800;
    color: #203553;
    letter-spacing: 0.19px;
    line-height: 26px;
    margin-bottom: 20px;
  }
`;

const PriceRange = styled.h2`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(18)};
  color: #203553;
  letter-spacing: 0.14px;
  text-align: center;

  @media print {
    text-align: left;
  }
`;

const SellPriceIconStyled = styled(SellPriceIcon)`
  align-self: center;
  margin-bottom: 18px;

  @media print {
    align-self: flex-start;
  }
`;

export const usePrecacheForSellPriceBox: () => void = () => {
  usePrecacheImages([sellPriceIconUri]);
};

export interface AppraisalPriceRangeBoxProps {
  sellingRangeMin?: number;
  sellingRangeMax?: number;
}

const AppraisalPriceRangeBox: FC<AppraisalPriceRangeBoxProps> = ({
  sellingRangeMin = 0,
  sellingRangeMax = 0,
}) => {
  return (
    <Container>
      <SellPriceIconStyled />
      <Title>Estimated Selling Price</Title>
      <PriceRange>{`$${new Intl.NumberFormat().format(
        sellingRangeMin,
      )} - $${new Intl.NumberFormat().format(sellingRangeMax)}`}</PriceRange>
    </Container>
  );
};

export default AppraisalPriceRangeBox;
