import React, { FC } from 'react';
import styled from 'styled-components';
import sideBySideBackgroundUrl from '..//assets/side-by-side-background.png';
import agentSpotLogoIconUrl, {
  ReactComponent as AgentSpotLogoIcon,
} from '../assets/agentspot-logo-white.svg';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../components/NavigationHeader';
import config from '../config';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: row;
  }
`;

const DecorativeSidePanel = styled.div`
  display: none;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    background-color: #07134f;
    background-image: url(${sideBySideBackgroundUrl});
    background-size: cover;
    background-position: center;
  }
`;

const AgentSpotLogo = styled(AgentSpotLogoIcon)`
  display: flex;
  align-self: center;
`;

const ContentSideContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    max-width: 510px;
    background-color: #ffffff;
    overflow: scroll;
  }
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: stretch;
  width: 100%;
  max-width: 450px;

  padding: 16px 20px;
  margin-bottom: 40px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    margin-bottom: 0;
  }
`;

const NavigationHeaderContainer = styled.div`
  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: none;
  }
`;
export const usePrecacheForSideBySidePageTemplate: () => void = () => {
  usePrecacheImages([agentSpotLogoIconUrl, sideBySideBackgroundUrl]);

  usePrecacheForNavigationHeader();
};

const SideBySidePageTemplate: FC = (props) => {
  const { children } = props;

  return (
    <Container>
      <NavigationHeaderContainer>
        <NavigationHeader />
      </NavigationHeaderContainer>

      <DecorativeSidePanel>
        <a href={config.NEXT_APP_URI}>
          {/* TODO: replace logo with white inverted logo to be supplied by ned */}
          <AgentSpotLogo title="AgentSpot" />
        </a>
      </DecorativeSidePanel>

      <ContentSideContainer>
        <PageContentContainer>{children}</PageContentContainer>
      </ContentSideContainer>
    </Container>
  );
};

export default SideBySidePageTemplate;
