import React, { FC } from 'react';
import styled from 'styled-components';

const Text = styled.p`
  flex: 1;
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 0.2px;
  padding-top: 15px;
  color: #203553;
`;

const ASSellerSignUpNote: FC = () => {
  return (
    <Text>
      {/* <b>
        Receive personalised proposals without any obligation to speak with an
        agent. Your contact information will only be shared with the agent you
        choose, after you’ve compared their proposals.
      </b> */}
      <li>Free appraisal without needing an agent to come to your home.</li>
      <li>
        Your contact information remains private, only hear from the agent you
        choose.
      </li>
      <li>
        Plus, earn a $500 bonus after successfully selling your home with an
        AgentSpot agent.
      </li>
    </Text>
  );
};

export default ASSellerSignUpNote;
