import React, { FC } from 'react';
import styled from 'styled-components';
import goldCoinUrl, {
  ReactComponent as GoldCoin,
} from '../../../assets/gold-coin.svg';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 20px 5px 20px;
`;

const Title = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: ${pxToRem(13)};
  color: #203553;
  align-self: center;
  line-height: 23px;
  letter-spacing: 0.19;
  margin: 0;
`;

const Description = styled.span`
  font-family: Inter, Arial, sans-serif;
  font-weight: 400;
  font-size: ${pxToRem(13)};
  color: #57667b;
  letter-spacing: 0.19px;
  line-height: 23px;
`;

const GoldCoinIcon = styled(GoldCoin)`
  width: 40px;
  height: 40px;
  margin-right: 15px;
`;

const TextContainer = styled.div`
  display: flex;
  flex: 1;
`;

export interface CashbackSignupBoxProps {
  cashbackAmount: number | undefined;
}

export const usePrecacheForCashbackSignupBox: () => void = () => {
  usePrecacheImages([goldCoinUrl]);
};

const CashbackSignupBox: FC<CashbackSignupBoxProps> = ({ cashbackAmount }) => {
  return (
    <Container>
      <GoldCoinIcon />
      <TextContainer>
        <Title>
          {`Get a $${cashbackAmount} bonus `}
          <Description>
            when you sell your property with an AgentSpot agent
          </Description>
        </Title>
      </TextContainer>
    </Container>
  );
};

export default CashbackSignupBox;
