import moment, { Moment } from 'moment';

/*
    Get all time intervals between two times
*/

export const intervalsBetweenTimes = (
  startTime: Moment,
  endTime: Moment,
  intervalMinutes: number,
): Moment[] => {
  const intervals = [];
  let curTime = moment(startTime);

  while (moment(curTime).isSameOrBefore(moment(endTime))) {
    intervals.push(moment(curTime));
    curTime = moment(curTime).add(intervalMinutes, 'minutes');
  }

  return intervals;
};
