import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import blockDirectionLeftIconUrl, {
  ReactComponent as BlockDirectionLeftIcon,
} from '../../../assets/block-direction-left.svg';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { pxToRem } from '../../../utils/pxToRem';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 27px 0 14px 0;
`;

const BackLink = styled(Link)`
  margin: 0;
`;

const PageTitleLabel = styled.h1`
  font-family: 'neuzeit-grotesk', Arial, sans-serif;
  font-size: ${pxToRem(20)};
  color: #203553;
  text-align: center;
  text-align: center;
  flex-grow: 1;
  margin: 0 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BackLinkCounterWeight = styled.div`
  width: 16px;
`;

const BlockDirectionLeft = styled(BlockDirectionLeftIcon)`
  width: 25px;
  height: 25px;
`;

export interface SaleStatusNavigationHeaderProps {
  backHref: string;
  pageTitle: string;
  rightSideComponent?: ReactNode;
}

export const usePrecacheForBackNavButton: () => void = () => {
  usePrecacheImages([blockDirectionLeftIconUrl]);
};

const SaleStatusNavigationHeader: FC<SaleStatusNavigationHeaderProps> = (
  props,
) => {
  const { backHref, pageTitle, rightSideComponent } = props;

  return (
    <Container>
      <BackLink to={backHref}>
        <BlockDirectionLeft />
      </BackLink>
      <TitleContainer>
        <PageTitleLabel>{pageTitle}</PageTitleLabel>
      </TitleContainer>

      {rightSideComponent ? rightSideComponent : <BackLinkCounterWeight />}
    </Container>
  );
};

export default SaleStatusNavigationHeader;
