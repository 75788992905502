import { ParsedQuery } from 'query-string';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ASHeader from '../../../components/ASHeader';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import TabBar from '../../../components/TabBar';
import useQueryParams from '../../../hooks/useQueryParams';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100vh;
  margin-bottom: 40px;
`;

const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: center;
  width: 100%;
  max-width: 770px;

  padding: 16px 20px;
`;

const Header = styled(ASHeader)`
  text-align: left;
`;

const TabsContainer = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
`;

// const LegalText = styled.p`
//   font-family: Inter, Arial, Helvetica, sans-serif;
//   font-size: 13px;
//   color: #8f9aa9;
//   letter-spacing: 0.14px;
//   line-height: 22px;
// `;

const LegalLink = styled.a`
  font-family: Inter, Arial, sans-serif;
  font-size: 1rem;
  color: #8f9aa9;
  margin: 0 0 12px 0;
  text-decoration: underline;
  text-decoration-color: #8f9aa9;

  :hover & {
    color: #58687e;
    text-decoration: underline;
    text-decoration-color: #58687e;
  }
`;

const TERMS_TAB_INDEX = 0;
const PRIVACY_TAB_INDEX = 1;

export const usePrecacheForTermsAndConditions: () => void = () => {
  usePrecacheForNavigationHeader();
};

interface ExpectedQueryParams extends ParsedQuery {
  tab: string | string[] | null;
}

const TermsAndConditions: FC = () => {
  const { tab } = useQueryParams<ExpectedQueryParams>();

  const [activeTabIndex, setActiveTabIndex] = useState(
    tab === 'privacy' ? PRIVACY_TAB_INDEX : TERMS_TAB_INDEX,
  );

  const onTabClick = (tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  return (
    <Container>
      <Helmet>
        <title>Terms &amps; Conditions | AgentSpot</title>
      </Helmet>
      <NavigationHeader />

      <PageContentContainer>
        <Header>Legal Information</Header>
        <TabBar
          tabs={[
            {
              href: '/terms-and-conditions/?tab=terms',
              label: 'Terms & Conditions',
            },
            {
              href: '/terms-and-conditions/?tab=privacy',
              label: 'Privacy Policy',
            },
          ]}
          selectedTabIndex={activeTabIndex}
          onTabClick={onTabClick}
        />
        <TabsContainer>
          {activeTabIndex === TERMS_TAB_INDEX && (
            <>
              <LegalLink
                target="_blank"
                href="/assets/legals/agent-terms-of-service.pdf">
                Agent - Terms of Service [PDF]
              </LegalLink>
              <LegalLink
                target="_blank"
                href="/assets/legals/seller-terms-of-service.pdf">
                Seller - Terms of Service [PDF]
              </LegalLink>
            </>
          )}
          {activeTabIndex === PRIVACY_TAB_INDEX && (
            <>
              <LegalLink
                target="_blank"
                href="/assets/legals/agentspot-privacy-policy.pdf">
                Privacy Policy [PDF]
              </LegalLink>
            </>
          )}
        </TabsContainer>
      </PageContentContainer>
    </Container>
  );
};

export default TermsAndConditions;
