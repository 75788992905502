import React, { FC, useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import BlockDirectionRightDisabled from '../assets/block-direction-right-disabled.svg';
import BlockDirectionRightEnabledEmpty from '../assets/block-direction-right-enabled-empty.svg';
import BlockDirectionRightEnabled from '../assets/block-direction-right-enabled.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';
import ASActionButton from './ASActionButton';

interface NavButtonProps {
  $disabled: boolean;
}

const NavButton = styled.button<NavButtonProps>`
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  font-family: Inter, Arial, sans-serif;
  font-size: 15px;
  letter-spacing: 0.2px;
  background-color: transparent;

  :hover {
    text-decoration-line: none;
  }
`;

const NextLabel = styled.p<{ $disabled: boolean }>`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  margin: 0;
  color: ${(props) => (props.$disabled ? '#282828' : '#1745B0')};
  padding-right: 13px;
`;

const BlockDirectionRight = styled.div<{ src: string }>`
  width: 25px;
  height: 25px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-image: url(${(props) => props.src});
`;

const DesktopButton = styled(ASActionButton).attrs({ type: 'submit' })`
  /* min-width: 174px; */
`;

export const usePrecacheForNextNavButton: () => void = () => {
  usePrecacheImages([BlockDirectionRightDisabled, BlockDirectionRightEnabled]);
};

export interface NextNavButtonProps {
  label?: string;
  onNextClick: () => void;
  nextDisabled: boolean;
  loading?: boolean;
  widthForDesktop?: string;
}

const NextNavButton: FC<NextNavButtonProps> = (props) => {
  const {
    label = 'Next',
    nextDisabled,
    onNextClick,
    loading = false,
    widthForDesktop,
  } = props;

  const [isDesktop, setDesktop] = useState(
    window.innerWidth >
      Number(theme.layout.desktopBreakpoint.replace('px', '')),
  );

  const updateMedia = () => {
    setDesktop(
      window.innerWidth >
        Number(theme.layout.desktopBreakpoint.replace('px', '')),
    );
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return isDesktop ? (
    <DesktopButton
      onClick={nextDisabled || loading ? undefined : onNextClick}
      visiblyDisabled={nextDisabled && !loading}
      loading={loading}
      widthForDesktop={widthForDesktop}>
      {label}
    </DesktopButton>
  ) : (
    <NavButton
      $disabled={nextDisabled || loading}
      onClick={nextDisabled || loading ? undefined : onNextClick}>
      <NextLabel $disabled={nextDisabled && !loading}>{label}</NextLabel>
      <BlockDirectionRight
        src={
          !loading
            ? nextDisabled
              ? BlockDirectionRightDisabled
              : BlockDirectionRightEnabled
            : BlockDirectionRightEnabledEmpty
        }>
        <BeatLoader
          color="#ffffff"
          css="display: flex;"
          size={3}
          margin={1}
          loading={loading}
        />
      </BlockDirectionRight>
    </NavButton>
  );
};

export default NextNavButton;
