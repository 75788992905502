import React, { FC } from 'react';
import styled from 'styled-components';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import chevronDownUrl, {
  ReactComponent as ChevronDown,
} from '../assets/chevron-down-blue.svg';
import chevronUpUrl, {
  ReactComponent as ChevronUp,
} from '../assets/chevron-up-blue.svg';

const Container = styled.div`
  margin: 0 20px;
`;

const DetailsExpansionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 20px 0 20px 0;
  :hover {
    cursor: pointer;
  }
`;

const DetailsExpansionLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.2px;
  margin: 0 6px 0 0;
`;

interface Props {
  onToggle: () => void;
  expanded: boolean;
}

export const usePrecacheForPropertyDetailsToggle: () => void = () => {
  usePrecacheImages([chevronUpUrl, chevronDownUrl]);
};

const PropertyDetailsToggle: FC<Props> = ({ onToggle, expanded }) => {
  return (
    <Container>
      <DetailsExpansionRow onClick={onToggle}>
        <DetailsExpansionLabel>{`${
          expanded ? 'Hide' : 'View'
        } Property Details`}</DetailsExpansionLabel>
        {expanded ? <ChevronUp /> : <ChevronDown />}
      </DetailsExpansionRow>
    </Container>
  );
};

export default PropertyDetailsToggle;
