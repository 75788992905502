import React, { FC } from 'react';
import styled from 'styled-components';
import addBluePlusBlockIconUrl, {
  ReactComponent as AddBluePlusBlock,
} from '../assets/add-blue-plus-block.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';

export const RowSmallButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: #ffffff;

  border: 1px solid #e8eaed;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  :hover::enabled {
    text-decoration-line: none;
    box-shadow: 0px 2px 4px 1px #d1d1d1;
    color: #1745b0;
  }

  padding: 25px 20px;
`;

const Label = styled.label<{ disabled: boolean }>`
  display: flex;
  flex-grow: 1;
  margin: 0 0 0 15px;

  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.3px;

  :hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const Description = styled.label`
  display: flex;
  flex-grow: 1;
  margin: 6px 0 0 15px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #58687e;
  letter-spacing: 0.13px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const usePrecacheFoAddItemRowSmallButton: () => void = () => {
  usePrecacheImages([addBluePlusBlockIconUrl]);
};

export interface AddItemRowSmallButtonProps {
  label: string;
  description?: string;
  onClick: () => void;
  disabled?: boolean;
}

const AddItemRowSmallButton: FC<AddItemRowSmallButtonProps> = (props) => {
  const { label, description, onClick, disabled = false } = props;

  return (
    <RowSmallButton disabled={disabled} onClick={onClick}>
      <AddBluePlusBlock />
      <TextContainer>
        <Label disabled={disabled}>{label}</Label>
        {description && <Description>{description}</Description>}
      </TextContainer>
    </RowSmallButton>
  );
};

export default AddItemRowSmallButton;
