import React, { FC } from 'react';
import styled from 'styled-components';
import ASBlockLink from '../../../components/ASBlockLink';
import theme from '../../../constants/theme';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import { usePrecacheForAddress } from '../../new-property/containers/Address';
import createListingPlusBlockSvgUrl, {
  ReactComponent as CreateListingPlusBlockSvg,
} from '../assets/create-listing-plus-block.svg';

const BlockLink = styled(ASBlockLink)`
  display: none;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: initial;
    height: 42px;
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    align-items: center;
    background-image: linear-gradient(90deg, #007ce9 0%, #0b1a9a 100%);

    :hover {
      text-decoration-line: none;
      box-shadow: 0px 2px 4px 1px #d1d1d1;
      color: #ffffff;
    }
  }
`;

const CreateListingPlusBlock = styled(CreateListingPlusBlockSvg)`
  margin: 0 9px 0 -2px;
`;

export const usePrecacheForCreateListingButton: () => void = () => {
  usePrecacheImages([createListingPlusBlockSvgUrl]);

  usePrecacheForAddress();
};

const CreateListingButton: FC = () => {
  return (
    <BlockLink to="/new-property/">
      <CreateListingPlusBlock />
      Create Listing
    </BlockLink>
  );
};

export default CreateListingButton;
