import React, { FC } from 'react';
import styled from 'styled-components';
import {
  default as addMediaIconLightUrl,
  ReactComponent as AddMediaIconLight,
} from '../assets/add-media-icon-light.svg';
import {
  default as addMediaIconUrl,
  ReactComponent as AddMediaIcon,
} from '../assets/add-media-icon.svg';
import usePrecacheImages from '../hooks/usePrecacheImages';

const GridItem = styled.li`
  list-style-type: none;
  width: 100px;
  height: 100px;
  border: 1px solid #e8eaed;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 0px;
`;

const ItemContainer = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

const AddItemTitle = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #1745b0;
  margin: 12px 0 0 0;
  letter-spacing: 0.15px;
  display: flex;
`;

const HiddenFileInputField = styled.input`
  display: none;
`;

export const usePrecacheForUserMediaAddButton: () => void = () => {
  usePrecacheImages([addMediaIconUrl, addMediaIconLightUrl]);
};

export interface UserMediaAddButtonProps {
  title?: string;
  accept?: string;
  onFileSelected: (file: File) => void;
  className?: string;
  disabled?: boolean;
  lightIcon?: boolean;
}

const UserMediaAddButton: FC<UserMediaAddButtonProps> = (props) => {
  const {
    title,
    accept,
    onFileSelected,
    className,
    disabled,
    lightIcon = false,
  } = props;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    if (file) {
      onFileSelected(file);
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <GridItem className={className}>
      <ItemContainer onClick={handleClick} disabled={disabled}>
        {!lightIcon ? (
          <AddMediaIcon style={{ display: 'flex' }} />
        ) : (
          <AddMediaIconLight style={{ display: 'flex' }} />
        )}
        <HiddenFileInputField
          type="file"
          accept={accept}
          ref={hiddenFileInput}
          onChange={changeHandler}
        />
        {title && <AddItemTitle>{title}</AddItemTitle>}
      </ItemContainer>
    </GridItem>
  );
};

export default UserMediaAddButton;
