import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { ReactComponent as VideoPlayButtonIcon } from '../../../assets/video-play-button.svg';
import theme from '../../../constants/theme';
import {
  PropertyBaseFragment,
  PropertyMedia,
} from '../../../graphql/generated';
import useIsDesktop from '../../../hooks/useIsDekstop';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import defaultPropertyImageUrl from '../assets/default-property-image.svg';
import floorplanBadgeSheetIconUrl, {
  ReactComponent as FloorplanBadgeSheetIcon,
} from '../assets/floorplan-badge-sheet.svg';
import imageBadgeLandscapeIconUrl, {
  ReactComponent as ImageBadgeLandscapeIcon,
} from '../assets/image-badge-landscape.svg';
import PropertyPhotoSwiperModal from './PropertyPhotoSwiperModal';
import './Swiper.css'; // CSS file used given the library wasn't fully customizable using Styled Components
SwiperCore.use([Navigation]);

const Container = styled.div`
  padding-top: 66%; // Effectively sets height the 52% of elements own width
  height: 0;
  max-height: 420px;
  position: relative;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    border-radius: 10px;
    overflow: hidden;
    margin: 0 20px;
  }
`;

const PhotosContainerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #f4f5f6;
  overflow: hidden;
`;

const PhotosSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Photo = styled.img`
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #444444;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const VideoPoster = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  object-fit: cover;
  object-position: center center;
`;

const VideoPlayButton = styled(VideoPlayButtonIcon)`
  z-index: 200;
`;

const BottomLeftStatusBadgeContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
`;

const BadgesContainer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;

  display: flex;
  flex-direction: row;
  z-index: 2;
`;

const PhotoBadge = styled.div`
  padding: 7px 11px 8px 11px;

  border-radius: 7px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 11px;
  color: #ffffff;
  letter-spacing: 0.17px;
  margin: 0 5px 0 0;
  background-color: rgba(32, 53, 83, 0.8);

  :hover {
    cursor: pointer;
  }
`;

const FloorplanBadgeSheet = styled(FloorplanBadgeSheetIcon)`
  margin: 0 7px 0 0;
`;

const ImageBadgeLandscape = styled(ImageBadgeLandscapeIcon)`
  margin: 0 7px 0 0;
`;

export const usePrecacheForPropertyPhotoSwiper: () => void = () => {
  usePrecacheImages([
    floorplanBadgeSheetIconUrl,
    imageBadgeLandscapeIconUrl,
    defaultPropertyImageUrl,
  ]);
};

export interface SlideProps {
  propertyMedia: PropertyMedia;
  position: number;
  openModalAtPosition: (position: number) => void;
}

const PhotoSlide: FC<SlideProps> = (props) => {
  const { propertyMedia, position, openModalAtPosition } = props;

  const onClick = useCallback(() => {
    openModalAtPosition(position);
  }, [openModalAtPosition, position]);

  return (
    <Photo src={propertyMedia?.userMedia?.thumbnailUrl} onClick={onClick} />
  );
};

const VideoSlide: FC<SlideProps> = (props) => {
  const { propertyMedia, position, openModalAtPosition } = props;

  const onClick = useCallback(() => {
    openModalAtPosition(position);
  }, [openModalAtPosition, position]);

  return (
    <VideoContainer onClick={onClick}>
      <VideoPlayButton />
      <VideoPoster src={propertyMedia?.userMedia?.thumbnailUrl} />
    </VideoContainer>
  );
};

interface PropertyPhotoSwiperProps {
  property: PropertyBaseFragment;
  StatusBadgeComponent?: ReactNode;
}

interface PartialSwiperClass {
  // The actual SwiperClass is not exported from the swiper package despite being part of all thier functions :(
  allowSlideNext: boolean;
  allowSlidePrev: boolean;
}

const PropertyPhotoSwiper: FC<PropertyPhotoSwiperProps> = (props) => {
  const { property, StatusBadgeComponent } = props;
  const isDesktop = useIsDesktop();

  const swiperRef = useRef<PartialSwiperClass | null>(null);
  const onSwiper = useCallback((swiper) => (swiperRef.current = swiper), []);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalSwiperPosition, setModalSwiperPosition] = useState<number>(0);

  const openModalAtPosition = useCallback((position: number) => {
    setModalSwiperPosition(position);
    setIsModalOpen(true);

    // Disable keybaord navigation whilst modal is open (otherwise this swiper will move behind the overlay when using the modal)
    if (swiperRef.current) {
      swiperRef.current.allowSlideNext = false;
      swiperRef.current.allowSlidePrev = false;
    }
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);

    // Re-enable keyboard navigation once mondal is closed
    if (swiperRef.current) {
      swiperRef.current.allowSlideNext = true;
      swiperRef.current.allowSlidePrev = true;
    }
  }, []);

  const onPhotosBadgeClick = useCallback(() => {
    openModalAtPosition(0);
  }, []);

  const onFloorplanBadgeClick = useCallback(() => {
    openModalAtPosition(property.photos.length);
  }, [property]);

  const onVideosBadgeClick = useCallback(() => {
    openModalAtPosition(property.photos.length + property.floorplans.length);
  }, [property]);

  return (
    <Container>
      {isModalOpen && (
        <PropertyPhotoSwiperModal
          property={property}
          isOpen={isModalOpen}
          position={modalSwiperPosition}
          onPositionChange={setModalSwiperPosition}
          onRequestClose={closeModal}
        />
      )}

      <PhotosContainerContent>
        <PhotosSwiper keyboard navigation={isDesktop} onSwiper={onSwiper}>
          {[...property.photos, ...property.floorplans, ...property.videos]
            .length == 0 && (
            <SwiperSlide>
              <Photo src={defaultPropertyImageUrl} />
            </SwiperSlide>
          )}
          {[...property.photos, ...property.floorplans].map(
            (propertyMedia, i) => (
              <SwiperSlide key={propertyMedia.id}>
                <PhotoSlide
                  propertyMedia={propertyMedia}
                  position={i}
                  openModalAtPosition={openModalAtPosition}
                />
              </SwiperSlide>
            ),
          )}

          {[...property.videos].map((propertyMedia, i) => (
            <SwiperSlide key={propertyMedia.id}>
              <VideoSlide
                propertyMedia={propertyMedia}
                position={
                  property.photos.length + property.floorplans.length + i
                }
                openModalAtPosition={openModalAtPosition}
              />
            </SwiperSlide>
          ))}
        </PhotosSwiper>
        {StatusBadgeComponent && (
          <BottomLeftStatusBadgeContainer>
            {StatusBadgeComponent}
          </BottomLeftStatusBadgeContainer>
        )}
        <BadgesContainer>
          {property.photos.length > 0 && (
            <PhotoBadge onClick={onPhotosBadgeClick}>
              <ImageBadgeLandscape />
              {property.photos.length} Photo{property.photos.length > 1 && 's'}
            </PhotoBadge>
          )}

          {property.floorplans.length > 0 && (
            <PhotoBadge onClick={onFloorplanBadgeClick}>
              <FloorplanBadgeSheet />
              {property.floorplans.length} Floorplan
              {property.floorplans.length > 1 && 's'}
            </PhotoBadge>
          )}

          {property.videos.length > 0 && (
            <PhotoBadge onClick={onVideosBadgeClick}>
              <ImageBadgeLandscape />
              {property.videos.length} Video{property.videos.length > 1 && 's'}
            </PhotoBadge>
          )}
        </BadgesContainer>
      </PhotosContainerContent>
    </Container>
  );
};

export default PropertyPhotoSwiper;
