import React, { FC } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import actionButtonArrowDisabledIconUrl, {
  ReactComponent as ActionButtonArrowDisabledIcon,
} from '../assets/action-button-arrow-disabled.svg';
import actionButtonArrowIconUrl, {
  ReactComponent as ActionButtonArrowIcon,
} from '../assets/action-button-arrow.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Button = styled.button<{ $widthForDesktop: string | undefined }>`
  border: none;
  background-image: linear-gradient(90deg, #1745b0 0%, #1745b0 100%);
  border-radius: 0px;
  padding: 10px 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  color: #ffffff;

  :hover {
    text-decoration-line: none;
    box-shadow: 0px 2px 4px 1px #d1d1d1;
    color: #ffffff;
  }

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    width: ${(props) =>
      props.$widthForDesktop ? props.$widthForDesktop : '225px'};
  }
`;

const DisabledButton = styled(Button)`
  background: #e8eaed;
  color: #282828;
  :hover {
    box-shadow: none;
    color: #282828;
  }
`;

const Label = styled.label`
  display: flex;
  flex-grow: 1;

  font-family: Inter, Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.3px;
  margin: 0;

  :hover {
    cursor: pointer;
  }
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 6px;
  background: #ffffff;
`;

export const usePrecacheForASActionButton: () => void = () => {
  usePrecacheImages([
    actionButtonArrowIconUrl,
    actionButtonArrowDisabledIconUrl,
  ]);
};

export interface ASActionButtonProps {
  visiblyDisabled?: boolean;
  disabled?: boolean;
  loading?: boolean;
  widthForDesktop?: string;
}

const ASActionButton: FC<
  ASActionButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  const {
    visiblyDisabled = false,
    disabled = false,
    loading = false,
    widthForDesktop,
    children,
    ...rest
  } = props;

  return !visiblyDisabled ? (
    <Button
      disabled={disabled || loading}
      $widthForDesktop={widthForDesktop}
      type={'button'}
      {...rest}>
      <Label>{children}</Label>

      {!loading ? (
        <ActionButtonArrowIcon />
      ) : (
        <LoadingSpinnerContainer>
          <BeatLoader
            color="#0e26d9"
            css="display: flex;"
            size={4}
            margin={1}
            loading={loading}
          />
        </LoadingSpinnerContainer>
      )}
    </Button>
  ) : (
    <DisabledButton disabled $widthForDesktop={widthForDesktop} {...rest}>
      <Label>{children}</Label>

      <ActionButtonArrowDisabledIcon />
    </DisabledButton>
  );
};

export default ASActionButton;
