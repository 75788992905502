import styled from 'styled-components';
import theme from '../constants/theme';

const FixedFooter = styled.div<{ $sticky?: boolean }>`
  position: ${(props) => (props.$sticky ? 'sticky' : 'fixed')};
  width: ${(props) => (props.$sticky ? '100%' : 'auto')};
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10;
  background-color: #ffffff;
  border-top: 1px solid #e8eaed;
  z-index: 2;
  padding: 0 ${theme.spacing.pageInset} 18px ${theme.spacing.pageInset};
`;

export default FixedFooter;
