import React, { FC, MouseEventHandler, useCallback } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { ReactComponent as CloseModalIcon } from '../../../assets/close-cross-white.svg';
import { PropertyBaseFragment } from '../../../graphql/generated';
import withClassNameShim from '../../../hooks/stylePropHelpers';

const ContainerModal = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: column;
  position: relative;
`;

const ContainerModalWithOverlay = styled(
  withClassNameShim<Modal.Props>('overlayClassName')(ContainerModal),
)`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const CloseModalButton = styled.button`
  padding: 20px;
  position: absolute;
  top: 70px;
  left: 10px;
  z-index: 2;
  padding: 20px;
  margin: 0;
  background: transparent;
  border: none;

  :hover {
    background: transparent;
    border: none;
    box-shadow: none;
    cursor: pointer;
  }
`;
const PhotosSwiper = styled(Swiper)`
  z-index: 1;
`;

const PhotoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Photo = styled.img`
  object-fit: contain;
  object-position: center center;
  width: 100%;

  :hover {
    cursor: grab;
  }
`;

// TODO: fix inabality to click overlay on the left or right of image (can only clikc out above or below) (this is because the swiper slide itself doesn't grow to full width by default)

const Video = styled.video`
  max-height: 100%;
  max-width: 100%;
  background-color: #444444;

  :focus {
    outline: none;
  }
`;

export interface PropertyPhotoSwiperModalProps {
  property: PropertyBaseFragment;
  position: number;
  onPositionChange: (position: number) => void;
  isOpen: boolean;
  onRequestClose: () => void;
}

const PropertyPhotoSwiperModal: FC<PropertyPhotoSwiperModalProps> = (props) => {
  const {
    property,
    isOpen,
    onRequestClose,
    position,
    onPositionChange,
  } = props;

  const onSlideChange = useCallback(
    (swiper: { activeIndex: number }) => {
      onPositionChange(swiper.activeIndex);
    },
    [onPositionChange],
  );

  const onClickOutsideOfPhoto: MouseEventHandler<HTMLElement> = useCallback(() => {
    onRequestClose();
  }, []);

  const onClickInsideOfPhoto: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      // Prevents onClickOutsideOfPhoto being called if the photo is clicked directly
      e.stopPropagation();
    },
    [],
  );

  return (
    <ContainerModalWithOverlay
      shouldCloseOnEsc
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick>
      <CloseModalButton onClick={onRequestClose}>
        <CloseModalIcon stroke="white" />
      </CloseModalButton>
      <PhotosSwiper
        spaceBetween={20}
        onSlideChange={onSlideChange}
        keyboard
        zoom
        initialSlide={position}>
        {[...property.photos, ...property.floorplans].map((propertyMedia) => (
          <SwiperSlide key={propertyMedia.id}>
            <PhotoContainer onClick={onClickOutsideOfPhoto}>
              <div className="swiper-zoom-container">
                <Photo
                  src={propertyMedia.userMedia.url}
                  onClick={onClickInsideOfPhoto}
                />
              </div>
            </PhotoContainer>
          </SwiperSlide>
        ))}

        {[...property.videos].map((propertyMedia) => (
          <SwiperSlide key={propertyMedia.id}>
            <PhotoContainer onClick={onClickOutsideOfPhoto}>
              {/* <div className="swiper-zoom-container"> */}
              <Video
                controls
                preload="auto"
                onClick={onClickInsideOfPhoto}
                poster={propertyMedia.userMedia.thumbnailUrl}>
                <source
                  src={propertyMedia.userMedia.url}
                  type={propertyMedia.userMedia.mimeType}
                />
              </Video>
              {/* </div> */}
            </PhotoContainer>
          </SwiperSlide>
        ))}
      </PhotosSwiper>
    </ContainerModalWithOverlay>
  );
};

export default PropertyPhotoSwiperModal;
