import { from } from '@apollo/client';
import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASCaption from '../../../components/ASCaption';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import { usePrecacheForNavigationHeader } from '../../../components/NavigationHeader';
import PasswordInputField from '../../../components/PasswordInputField';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import { useCreateAgentUserMutation } from '../../../graphql/generated';
import useFetchMeToCompleteLogin from '../../../hooks/useFetchMeToCompleteLogin';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import { usePrecacheForLogin } from '../../auth/containers/Login';
import useIsGhostUser from '../../auth/hooks/useIsGhostUser';
import useLogoutGhostUser from '../../auth/hooks/useLogoutGhostUser';
import { LOGIN } from '../../auth/store/authTypes';
import { usePrecacheForAgentSignupBasicDetails } from './AgentSignupBasicDetails';
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(ASHeader)`
  margin-bottom: 21px;
`;

const Caption = styled(ASCaption)`
  text-align: left;
`;

const AnchorLink = styled(Link)`
  color: #1745b0;
`;

const SignupButton = styled(ASBlockButton)`
  margin: 26px 0 21px 0;
  width: 100%;
`;

const AlreadyHaveAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 22px;
  margin-top: 12px;

  background: #f8f9fa;
  border-radius: 11px;
`;

const AlreadyHaveAccountLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;
  margin-right: 8px;
`;

const AlreadyHaveAccountLink = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15px;
  text-align: center;
  line-height: 25px;

  color: #1745b0;
  :hover {
    color: #1745b0;
  }
`;

export const usePrecacheForAgentSignup: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForSideBySidePageTemplate();
};

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
  password: Yup.string()
    .min(9, 'Password is too short')
    .required('Password is required'),
});

const AgentSignup: FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const isGhostUser = useIsGhostUser();
  const [isAuthenticatedAtFirstRender] = useState(isAuthenticated);

  const history = useHistory();
  const dispatch = useDispatch();

  const [
    createAgentUser,
    { loading, error: createAgentError },
  ] = useCreateAgentUserMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { data } = await createAgentUser({ variables: values });

      if (data?.createAgentUser) {
        dispatch({
          type: LOGIN,
          payload: {
            authToken: data?.createAgentUser?.token,
          },
        });
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  useFetchMeToCompleteLogin(() => {
    history.push(
      typeof from == 'string' ? from : '/agent/signup/basic-details',
    );
  });

  usePrecacheForAgentSignupBasicDetails();
  usePrecacheForLogin();

  useLogoutGhostUser();

  if (isAuthenticated && isAuthenticatedAtFirstRender && !isGhostUser) {
    return <Redirect to="/" />;
  }

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Agent Sign Up | AgentSpot</title>
      </Helmet>
      <Formik<FormValues>
        initialValues={initialValues!}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount }) => {
          const error: string | undefined =
            (submitCount > 0 && errors && Object.values(errors)[0]) ||
            (createAgentError && apolloErrorToString(createAgentError));

          return (
            <Form>
              <Header>Sign up to AgentSpot</Header>
              <Caption>
                By signing up to AgentSpot, I agree to the{' '}
                <AnchorLink to="/terms-and-conditions">
                  Conditions of use and Privacy policy
                </AnchorLink>
              </Caption>

              <TextInputField
                name="email"
                type="email"
                placeholder="Email address"
                maxLength={196}
              />
              <PasswordInputField name="password" placeholder="Password" />

              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <SignupButton loading={loading} onClick={submitForm}>
                Sign up
              </SignupButton>
              <AlreadyHaveAccountContainer>
                <AlreadyHaveAccountLabel>
                  Already have an account?
                </AlreadyHaveAccountLabel>
                <AlreadyHaveAccountLink to="/login">
                  Log in
                </AlreadyHaveAccountLink>
              </AlreadyHaveAccountContainer>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default AgentSignup;
