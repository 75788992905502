import { FieldArray, Form, Formik, useField } from 'formik';
import { default as React, ReactElement, useCallback, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import clearCrossIconUrl, {
  ReactComponent as ClearCrossIcon,
} from '../../../assets/clear-cross-button.svg';
import AddItemRowLargeButton, {
  usePrecacheFoAddItemRowLargeButton,
} from '../../../components/AddItemRowLargeButton';
import ASBlockButton from '../../../components/ASBlockButton';
import ASModal, { usePrecacheForASModal } from '../../../components/ASModal';
import NumberInputField from '../../../components/NumberInputField';
import TextInputField from '../../../components/TextInputField';
import { useUpdateDraftPropertyAppraisalMarketingFeeMutation } from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import AgentMarketingFee from './AgentMarketingFee';

const Container = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  margin: 0;
`;

const InputLabel = styled.div`
  font-family: Inter;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;

  margin: 0 0 10px 0;
`;

const PrefixLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 14px;
  color: #282828;
  letter-spacing: 0.2px;
  text-align: right;
  line-height: 23px;
`;

const MarketingIclusionsLabel = styled.h5`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  margin: 25px 0 20px 0;
`;

const MarketingInclisionInputContainer = styled.div`
  margin: 0 0 0 0;
`;

const RemoveMarketingInclusionsButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: #ffffff;
  margin: 0;
  padding: 0;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const ClearCross = styled(ClearCrossIcon)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const AddMarketingIclusionsButton = styled.button.attrs({ type: 'button' })`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.2px;
  text-align: left;

  border: none;
  background-color: #ffffff;
  margin: 10px 20px 0 0;

  :hover {
    text-decoration-line: underline;
    text-decoration-color: #1745b0;
  }
`;

const DoneButton = styled(ASBlockButton).attrs({
  type: 'button',
})`
  margin-top: 25px;
`;

export const usePrecacheForAgentMarketingFeeInputModalField: () => void = () => {
  usePrecacheImages([clearCrossIconUrl]);

  usePrecacheForASModal();
  usePrecacheFoAddItemRowLargeButton();
};

interface FormValues {
  costDollars?: number;
  marketingInclusions: string[];
}

const validationSchema = Yup.object().shape({
  costDollars: Yup.number().positive().required(),
  marketingInclusions: Yup.array().of(Yup.string().required()).required(),
});

export interface AgentMarketingFeeInputModalFieldProps {
  propertyAppraisalId: string;
  fieldName: string;
}

const AgentMarketingFeeInputModalField = (
  props: AgentMarketingFeeInputModalFieldProps,
): ReactElement => {
  const { propertyAppraisalId, fieldName } = props;

  const [{ value }, , { setValue }] = useField({ name: fieldName });

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [
    updateDraftPropertyAppraisalMarketingFee,
  ] = useUpdateDraftPropertyAppraisalMarketingFeeMutation();

  const onAddMarketingFeeClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onSubmit = useCallback(async (values: FormValues) => {
    const { data } = await updateDraftPropertyAppraisalMarketingFee({
      variables: {
        propertyAppraisalId,
        costDollars: values.costDollars!,
        marketingInclusions: values.marketingInclusions!,
      },
    });

    setValue(data?.updateDraftPropertyAppraisalMarketingFee.marketingFee);
    setIsModalOpen(false);
  }, []);

  const initalValues =
    value ??
    ({
      costDollars: undefined,
      marketingInclusions: [''],
    } as FormValues);

  return (
    <>
      {value ? (
        <AgentMarketingFee
          agentMarketingFee={value}
          onEditClick={onAddMarketingFeeClick}
          editLabel="Edit fees"
        />
      ) : (
        <AddItemRowLargeButton
          label="Add your marketing fees"
          onClick={onAddMarketingFeeClick}
        />
      )}

      <ASModal
        isOpen={isModalOpen}
        title="Marketing Fees"
        onRequestClose={() => setIsModalOpen(false)}>
        <Formik
          initialValues={initalValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount>
          {({ submitForm, values }) => {
            return (
              <Container>
                <InputLabel>Marketing fees cost</InputLabel>
                <NumberInputField
                  fieldName="costDollars"
                  placeholder="Cost"
                  decimalScale={2}
                  showErrorIfValueSet
                  renderPrefix={() => <PrefixLabel>$</PrefixLabel>}
                />

                <MarketingIclusionsLabel>
                  Marketing inclusions
                </MarketingIclusionsLabel>
                <FieldArray name="marketingInclusions">
                  {(arrayHelpers) => (
                    <>
                      {values.marketingInclusions?.map((_, i) => (
                        <MarketingInclisionInputContainer key={i}>
                          <TextInputField
                            name={`marketingInclusions[${i}]`}
                            maxLength={50}
                            placeholder={'Marketing inclusion'}
                            showErrorAfterTouch
                            renderSuffix={() => (
                              <RemoveMarketingInclusionsButton
                                onClick={() => arrayHelpers.remove(i)}>
                                <ClearCross />
                              </RemoveMarketingInclusionsButton>
                            )}
                          />
                        </MarketingInclisionInputContainer>
                      ))}

                      <AddMarketingIclusionsButton
                        onClick={() => arrayHelpers.push('')}>
                        Add another
                      </AddMarketingIclusionsButton>
                    </>
                  )}
                </FieldArray>

                <DoneButton onClick={submitForm}>Done</DoneButton>
              </Container>
            );
          }}
        </Formik>
      </ASModal>
    </>
  );
};

export default AgentMarketingFeeInputModalField;
