import { UserType } from '../../../graphql/generated';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN = 'LOGIN';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const LOGOUT_BEGIN = 'LOGOUT_BEGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_LAST_KNOWN_USER_TYPE = 'UPDATE_LAST_KNOWN_USER_TYPE';
export const UPDATE_LAST_KNOWN_IS_GHOST_USER =
  'UPDATE_LAST_KNOWN_IS_GHOST_USER';

export interface AuthState {
  authToken: string | null;

  /**
   * Indicates whether the user is in the process of being logged out (this was done to
   * prevent a bug where the me query would be refetched during logout before the authtoken
   *  was removed from the redux state)
   */
  logoutInProgress: boolean;

  /**
   * Indicates whether the user is in the process of being logged out (this was done to
   * prevent a bug where the me query would be return stale data briefly betw)
   */
  loginInProgress: boolean;

  /**
   * Tracks the last know user type to assist with initial rendering of the application before
   * the me query has returned
   */
  lastKnownUserType: UserType | null;
  lastKnownIsGhostUser: boolean | null;
}

export interface LoginBeginAction {
  type: typeof LOGIN_BEGIN;
  payload: null;
}
export interface LoginAction {
  type: typeof LOGIN;
  payload: {
    authToken: string;
    isGhostUser: boolean;
  };
}
export interface LoginCompleteAction {
  type: typeof LOGIN_COMPLETE;
  payload: {
    userType: UserType;
    isGhostUser: boolean;
  };
}

export interface LogoutBeginAction {
  type: typeof LOGOUT_BEGIN;
  payload: null;
}
export interface LogoutAction {
  type: typeof LOGOUT;
  payload: null;
}
export interface UpdateLastKnownUserTypeAction {
  type: typeof UPDATE_LAST_KNOWN_USER_TYPE;
  payload: {
    currentUserType: UserType;
  };
}

export interface UpdateLastKnownIsGhostUser {
  type: typeof UPDATE_LAST_KNOWN_IS_GHOST_USER;
  payload: {
    isGhostUser: boolean;
  };
}

export type AuthActions =
  | LoginBeginAction
  | LoginAction
  | LoginCompleteAction
  | LogoutBeginAction
  | LogoutAction
  | UpdateLastKnownUserTypeAction
  | UpdateLastKnownIsGhostUser;
