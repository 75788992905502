import { Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { FC, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASCaption from '../../../components/ASCaption';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import ASSellerSignUpNote from '../../../components/ASSellerSignUpNote';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NumberInputField from '../../../components/NumberInputField';
import PasswordInputField from '../../../components/PasswordInputField';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  useMeQuery,
  useStaticCashbackAmountQuery,
  useUpdateUserDetailsMutation,
  useUpdateUserPasswordMutation,
} from '../../../graphql/generated';
import CashbackReminder from '../components/CashbackReminder';

const InputLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
  margin: 11px 0 10px 0;
`;

const Header = styled(ASHeader)<{ description: boolean }>`
  margin-bottom: ${(props) => (props.description ? '7px' : '23px')};
  text-align: center;
`;

const StepDescription = styled(ASCaption)`
  margin: 0 20px 23px 20px;
`;

const CashbackContainerReminder = styled.div`
  margin: 20px 0 0 0;
`;

const PrivacyAlert = styled.div`
  width: 100%;
  background-color: #1745b0;
  color: white;
  padding: 16px 60px;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -32px;
`;

const PrivacyInfo = styled.div`
  margin: 0 20px 23px 20px;
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #8f9aa9;
  letter-spacing: 0.14px;
  text-align: center;
  line-height: 24px;
`;

export const usePrecacheForSellerSignupBasicDetails: () => void = () => {
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

interface FormValues {
  mobileNumber: string;
  password: string;
  passwordConfirmation: string;
}

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, 'Password must be at least 9 characters')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(9)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}/, 'Phone number is invalid')
    .required('Phone number is required'),
});

const SellerCreatePassword: FC = () => {
  const history = useHistory();

  const { data: meData } = useMeQuery();

  const [updateUserDetails] = useUpdateUserDetailsMutation();
  const { data: cashbackAmount } = useStaticCashbackAmountQuery();

  const [
    updateUserPassword,
    { loading, error: updateDetailsError },
  ] = useUpdateUserPasswordMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const parsedNumber = parsePhoneNumberFromString(
        values.mobileNumber,
        'AU',
      );

      if (!parsedNumber) return;

      await updateUserPassword({
        variables: {
          password: values.password,
          mobileNumber: parsedNumber.number as string,
        },
      });

      const nextPage = '/new-property/details-complete';

      updateUserDetails({
        variables: {
          nextPage: '',
        },
      });

      history.push(nextPage);

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  const initialValues: FormValues = useMemo(
    () => ({
      mobileNumber: '',
      password: '',
      passwordConfirmation: '',
    }),
    [],
  );

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Create Account | AgentSpot</title>
      </Helmet>
      {meData?.me && (
        <>
          <Formik<FormValues>
            initialValues={initialValues!}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount={true}>
            {({ submitForm, errors, isValid, touched }) => {
              const error: string | undefined =
                (touched['password'] && errors.password) ||
                (touched['passwordConfirmation'] &&
                  errors.passwordConfirmation) ||
                (touched['mobileNumber'] && errors.mobileNumber) ||
                (updateDetailsError && apolloErrorToString(updateDetailsError));

              return (
                <>
                  <StepPageTemplate.ContentContainer>
                    <Header description={true}>Create your Account</Header>
                    <StepDescription>
                      To save your property details
                    </StepDescription>
                  </StepPageTemplate.ContentContainer>
                  <PrivacyAlert>
                    Your contact details remain private
                  </PrivacyAlert>
                  <StepPageTemplate.ContentContainer>
                    <PrivacyInfo>
                      We don&apos;t share your contact information with agents
                      until you&apos;re ready. You let them know if and when you
                      want to meet them.
                    </PrivacyInfo>
                    <NumberInputField
                      showErrorAfterTouch
                      fieldName="mobileNumber"
                      placeholder="Phone number"
                      thousandSeparator={false}
                      allowLeadingZeros
                      isNumericString
                      useStringValue
                    />

                    <InputLabel></InputLabel>
                    <PasswordInputField
                      name="password"
                      placeholder="Create Password"
                    />

                    <InputLabel></InputLabel>
                    <PasswordInputField
                      name="passwordConfirmation"
                      placeholder="Confirm Password"
                    />
                    {error && <ASErrorLabel>{error}</ASErrorLabel>}
                    <ASSellerSignUpNote />
                  </StepPageTemplate.ContentContainer>
                  <StepNavigationFooter
                    backHref="/new-property/additional-info"
                    onNextClick={submitForm}
                    nextDisabled={!isValid || loading}
                    loading={loading}
                    nextButtonLabel="Done"
                  />
                  <CashbackContainerReminder>
                    <CashbackReminder
                      cashbackAmount={cashbackAmount?.staticCashbackAmount}
                    />
                  </CashbackContainerReminder>
                </>
              );
            }}
          </Formik>
        </>
      )}
    </StepPageTemplate.Container>
  );
};

export default SellerCreatePassword;
