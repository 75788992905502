import { ParsedQuery } from 'query-string';
import React, { FC, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import AgentMenu, {
  usePrecacheForAgentMenu,
} from '../../../components/AgentMenu';
import ASHeader from '../../../components/ASHeader';
import SplitDashboardPageTemplate, {
  usePrecacheForSplitDashboardPageTemplate,
} from '../../../components/SplitDashboardPageTemplate';
import TabBar from '../../../components/TabBar';
import config from '../../../config';
import {
  useMeQuery,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
  useMyCompleteAppraisalRequestsQuery,
  useMyPendingAppraisalRequestsQuery,
  // useMySubscribedSuburbsQuery,
  UserType,
} from '../../../graphql/generated';
import useIsAuthenticated from '../../../hooks/useIsAuthenticated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import useQueryParams from '../../../hooks/useQueryParams';
import AddSuburbBanner from '../components/AddSuburbBanner';
import { usePrecacheForAppraisalRequestCard } from '../components/AppraisalRequestCard';
import AppraisalRequestList from '../components/AppraisalRequestList';
import NoCompleteAppraisalsPlaceholder, {
  usePrecacheForNoCompleteAppraisalsPlaceholder,
} from '../components/NoCompleteAppraisalsPlaceholder';
import NoPendingRequestsPlaceholder, {
  usePrecacheForNoPendingRequestsPlaceholder,
} from '../components/NoPendingRequestsPlaceholder';
// import UpdateSubscriptionBanner, {
//   usePrecacheForUpdateSubscriptionBanner,
// } from '../components/UpdateSubscriptionBanner';
import { usePrecacheForAppraisalRequestDetails } from './AppraisalRequestDetails';

const Header = styled(ASHeader)`
  text-align: left;
`;

export const usePrecacheForAgentDashboard: () => void = () => {
  const isAuthenticated = useIsAuthenticated(UserType.Agent);

  const { data: pendingAppraisalsData } = useMyPendingAppraisalRequestsQuery({
    variables: { first: 10 },
    skip: !isAuthenticated,
  });

  const { data: completeAppraisalsData } = useMyCompleteAppraisalRequestsQuery({
    variables: { first: 10 },
    skip: !isAuthenticated,
  });

  usePrecacheImages([
    ...((pendingAppraisalsData?.myPendingAppraisalRequests?.edges
      ?.map((edge) => edge?.node?.property.photos[0]?.userMedia.thumbnailUrl)
      ?.filter((url) => url != undefined) as string[]) ?? []),
    ...((completeAppraisalsData?.myCompleteAppraisalRequests?.edges
      ?.map((edge) => edge?.node?.property.photos[0]?.userMedia.thumbnailUrl)
      ?.filter((url) => url != undefined) as string[]) ?? []),
  ]);

  usePrecacheForSplitDashboardPageTemplate();
  usePrecacheForAppraisalRequestCard();
  usePrecacheForNoPendingRequestsPlaceholder();
  usePrecacheForNoCompleteAppraisalsPlaceholder();
};

interface ExpectedQueryParams extends ParsedQuery {
  tab: string | string[] | null;
  collect_payment_details_for_property_appraisal_request:
    | string
    | string[]
    | null;
}

const AgentDashboard: FC = () => {
  const { tab } = useQueryParams<ExpectedQueryParams>();

  const activeTab = tab === 'complete' ? 'complete' : 'pending';

  const {
    data: pendingAppraisalsData,
    fetchMore: fetchMorePendingAppraisals,
    refetch: refetchPendingAppraisals,
  } = useMyPendingAppraisalRequestsQuery({
    variables: { first: 10 },
  });

  const {
    data: completeAppraisalsData,
    fetchMore: fetchMoreCompleteAppraisals,
    refetch: refetchCompleteAppraisals,
  } = useMyCompleteAppraisalRequestsQuery({
    variables: { first: 10 },
  });

  // const {
  //   data: mySubscribedSuburbsData,
  //   loading: subscribedSuburbsLoading,
  // } = useMySubscribedSuburbsQuery();

  const { data: meData } = useMeQuery();

  const onPendingAppraisalsScrollEndReached = useCallback(() => {
    if (pendingAppraisalsData?.myPendingAppraisalRequests) {
      fetchMorePendingAppraisals({
        variables: {
          first: 10,
          after:
            pendingAppraisalsData?.myPendingAppraisalRequests.pageInfo
              .endCursor,
        },
      });
    }
  }, [pendingAppraisalsData, fetchMorePendingAppraisals]);

  const onCompleteAppraisalsScrollEndReached = useCallback(() => {
    if (completeAppraisalsData?.myCompleteAppraisalRequests) {
      fetchMoreCompleteAppraisals({
        variables: {
          first: 10,
          after:
            completeAppraisalsData?.myCompleteAppraisalRequests.pageInfo
              .endCursor,
        },
      });
    }
  }, [completeAppraisalsData, fetchMoreCompleteAppraisals]);

  const onTabClick = useCallback((tabIndex) => {
    switch (tabIndex) {
      case 0: {
        refetchPendingAppraisals({
          first: 10,
        });
        return;
      }
      case 1: {
        refetchCompleteAppraisals({
          first: 10,
        });
        return;
      }
      default:
        return;
    }
  }, []);

  const unseenAppraisalsCount = useMemo(
    () =>
      pendingAppraisalsData?.myPendingAppraisalRequests?.edges?.filter(
        (edge) => edge?.node && !edge.node.seenByAgent,
      )?.length ?? undefined,
    [pendingAppraisalsData],
  );

  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  usePrecacheForAppraisalRequestDetails();
  usePrecacheForAgentMenu();
  // usePrecacheForUpdateSubscriptionBanner();

  return (
    <SplitDashboardPageTemplate menu={<AgentMenu />}>
      <>
        <Helmet>
          <title>Properties | AgentSpot</title>
        </Helmet>
        {/* {!subscribedSuburbsLoading &&
          mySubscribedSuburbsData?.mySubscribedSuburbs.length === 0 && (
            <UpdateSubscriptionBanner />
          )} */}
        <Header>Properties</Header>

        {freeTrialData &&
          freeTrialData.myAgentSuburbSubscriptionFreeTrialState
            .numberOfFreeTrialsClaimed < Number(config.MAX_AGENT_SUBURBS) && (
            <AddSuburbBanner
              description="Subscribe to suburbs to receive appraisal requests in those areas"
              actionDescription="Add Suburbs"
            />
          )}

        {meData?.me.isEmailVerified && (
          <>
            <TabBar
              tabs={[
                {
                  href: '/?tab=pending',
                  label: 'Appraisal Requests',
                  alertCount: unseenAppraisalsCount,
                },
                {
                  href: '/?tab=complete',
                  label: 'Appraised Properties',
                },
              ]}
              selectedTabIndex={activeTab === 'complete' ? 1 : 0}
              onTabClick={onTabClick}
            />
            {activeTab === 'pending' && (
              <AppraisalRequestList
                appraisalRequestEdges={
                  pendingAppraisalsData?.myPendingAppraisalRequests?.edges ??
                  undefined
                }
                onScrollEndReached={onPendingAppraisalsScrollEndReached}
                hasMore={
                  pendingAppraisalsData?.myPendingAppraisalRequests?.pageInfo
                    .hasNextPage ?? false
                }
                emptyPlaceholder={<NoPendingRequestsPlaceholder />}
              />
            )}
            {activeTab === 'complete' && (
              <AppraisalRequestList
                appraisalRequestEdges={
                  completeAppraisalsData?.myCompleteAppraisalRequests?.edges ??
                  undefined
                }
                onScrollEndReached={onCompleteAppraisalsScrollEndReached}
                hasMore={
                  completeAppraisalsData?.myCompleteAppraisalRequests?.pageInfo
                    .hasNextPage ?? false
                }
                showAppraisal
                emptyPlaceholder={<NoCompleteAppraisalsPlaceholder />}
              />
            )}
          </>
        )}
      </>
    </SplitDashboardPageTemplate>
  );
};

export default AgentDashboard;
