import React from 'react';

const withClassNameShim = <P extends { className?: string | unknown }>(
  targetProp: string,
) => (WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
  class WithClassNameShim extends React.Component<P> {
    static displayName: string;
    render() {
      const { className, children, ...rest } = this.props;

      return (
        /* eslint-disable-next-line */
        // @ts-ignore // As best as I can tell there is no dynamic way asserting P has a string key matching the targetProp string value
        <WrappedComponent {...rest} {...{ [targetProp]: className }}>
          {children}
        </WrappedComponent>
      );
    }
  }

  WithClassNameShim.displayName = `WithClassNameShim(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithClassNameShim;
};

export default withClassNameShim;

/** Taken from React HOCs best practics: https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging */

function getDisplayName(
  /* eslint-disable-next-line */
  // @ts-ignore
  WrappedComponent,
) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
