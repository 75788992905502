import React, { FC, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import UserMediaAddButton, {
  usePrecacheForUserMediaAddButton,
} from '../../../components/UserMediaAddButton';
import UserMediaSquare, {
  usePrecacheUserMediaSquare,
} from '../../../components/UserMediaSquare';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import {
  PropertyMediaBaseFragment,
  PropertyMediaType,
  useAttachUserMediaToDraftPropertyMutation,
  useCreateUserMediaMutation,
  useRemovePropertyMediaFromDraftPropertyMutation,
  UserMediaBaseFragment,
  UserMediaType,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForAdditionalInformation } from './Additionalnformation';

const SectionLabelText = styled.label`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;

  color: #58687e;
  letter-spacing: 0.2px;
  line-height: 23px;
  margin: 10px 0;
`;

const MediaGrid = styled.ul`
  width: 100%;
  margin: 10px 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  grid-gap: 10px;
  padding: 0;
`;

const PageContentsContainer = styled(StepPageTemplate.ContentContainer)`
  max-width: 600px;
  padding-bottom: 40px;
`;

export const usePrecacheForPhotos: () => void = () => {
  usePrecacheUserMediaSquare();
  usePrecacheForUserMediaAddButton();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForStepTemplate();
};

const Photos: FC = () => {
  const history = useHistory();

  const { draftProperty } = useCurrentDraftProperty();

  const [
    createUserMedia,
    { error: createUserMediaError },
  ] = useCreateUserMediaMutation();
  const [
    attachUserMediaToDraftProperty,
    { error: attachUserMediaToDraftPropertyError },
  ] = useAttachUserMediaToDraftPropertyMutation();
  const [
    removePropertyMediaFromDraftProperty,
    { error: removePropertyMediaFromDraftPropertyError },
  ] = useRemovePropertyMediaFromDraftPropertyMutation();

  const [updateUserDetails] = useUpdateUserDetailsMutation();

  useCurrentDraftProperty();

  const [loadingUserMedia, setLoadingUserMedia] = useState<
    UserMediaBaseFragment[]
  >([]);

  const [uploadError, setUploadError] = useState<Error | null>(null);

  const removeLoadingUserMedia = (userMediaId: string) => {
    setLoadingUserMedia((loadingUserMedia) =>
      loadingUserMedia.filter((um) => um.id !== userMediaId),
    );
  };

  const onFileSelectedFactory = useCallback(
    (propertyMediaType: PropertyMediaType) => async (file: File) => {
      if (!draftProperty) return;

      const fileExtension = file.name.split('.').pop();

      if (!fileExtension) return;

      setUploadError(null);

      const { data: createUserMediaData } = await createUserMedia({
        variables: {
          mimeType: file.type,
          fileExtension,
        },
      });

      if (createUserMediaData) {
        const userMedia = createUserMediaData.createUserMedia.userMedia;

        try {
          setLoadingUserMedia((loadingUserMedia) => [
            ...loadingUserMedia,
            userMedia,
          ]);

          const uploadResult = await fetch(
            createUserMediaData.createUserMedia.uploadUrl,
            {
              method: 'PUT',
              body: file,
            },
          );

          if (uploadResult.status == 200) {
            await attachUserMediaToDraftProperty({
              variables: {
                propertyId: draftProperty.id,
                propertyMediaType,
                userMediaId: userMedia.id,
              },
            });
          } else {
            setUploadError(
              new Error(`Upload PUT returned status: ${uploadResult.status}`),
            );
          }

          removeLoadingUserMedia(userMedia.id);
        } catch (e) {
          console.error(e);

          if (!('graphQlErrors' in e)) {
            setUploadError(e);
          }

          removeLoadingUserMedia(userMedia.id);
        }
      }
    },
    [draftProperty],
  );

  const onPhotoSelected = useCallback(
    onFileSelectedFactory(PropertyMediaType.Photo),
    [onFileSelectedFactory],
  );
  const onFloorplanSelected = useCallback(
    onFileSelectedFactory(PropertyMediaType.FloorPlan),
    [onFileSelectedFactory],
  );
  const onVideoSelected = useCallback(
    onFileSelectedFactory(PropertyMediaType.Video),
    [onFileSelectedFactory],
  );

  const onRemoveMediaClick = useCallback(
    (propertyMedia: PropertyMediaBaseFragment) => {
      removePropertyMediaFromDraftProperty({
        variables: { propertyMediaId: propertyMedia.id },
      });
    },
    [],
  );

  const onNextClick = useCallback((skipToEnd: boolean) => {
    const nextPage =
      '/new-property/' + (skipToEnd ? 'account-password' : 'additional-info');

    updateUserDetails({
      variables: {
        nextPage: nextPage,
      },
    });

    history.push(nextPage);
  }, []);

  const apolloError =
    createUserMediaError ||
    attachUserMediaToDraftPropertyError ||
    removePropertyMediaFromDraftPropertyError;

  const someMediaStillUploading = loadingUserMedia.length !== 0;

  const disableNextButton = someMediaStillUploading;

  usePrecacheForAdditionalInformation();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Photos & Video | AgentSpot</title>
      </Helmet>
      {!draftProperty ? null : (
        <PageContentsContainer>
          <StepNavigationHeader
            title="Property Details"
            stepCount={6}
            stepIndex={5}
            stepName="Property Photos &amp; Video"
            stepDescription="(Optional)"
          />
          <SectionLabelText>
            Upload Images/video and floorplans (Max 20)
          </SectionLabelText>

          {apolloError && (
            <ASErrorLabel>{apolloErrorToString(apolloError)}</ASErrorLabel>
          )}
          {uploadError && (
            <ASErrorLabel>
              An error occured whilst uploading the media
            </ASErrorLabel>
          )}

          <MediaGrid>
            {draftProperty.photos.length + draftProperty.videos.length < 20 && (
              <>
                <UserMediaAddButton
                  title={'Images'}
                  accept={'image/*'}
                  onFileSelected={onPhotoSelected}
                />
                <UserMediaAddButton
                  title={'Floorplans'}
                  accept={'image/*'}
                  onFileSelected={onFloorplanSelected}
                />
              </>
            )}

            {draftProperty.photos.map((propertyMedia) => (
              <UserMediaSquare
                userMedia={propertyMedia.userMedia}
                onRemoveClick={() => onRemoveMediaClick(propertyMedia)}
                key={`photo_${propertyMedia.userMedia.id}`}
              />
            ))}

            {draftProperty.floorplans.map((propertyMedia) => (
              <UserMediaSquare
                userMedia={propertyMedia.userMedia}
                onRemoveClick={() => onRemoveMediaClick(propertyMedia)}
                key={`floorplan_${propertyMedia.userMedia.id}`}
              />
            ))}

            {loadingUserMedia
              .filter((userMedia) => userMedia.type == UserMediaType.Image)
              .map((userMedia) => (
                <UserMediaSquare
                  userMedia={userMedia}
                  key={`loading_${userMedia.id}`}
                />
              ))}
          </MediaGrid>

          <SectionLabelText>{'Upload Video'}</SectionLabelText>
          <MediaGrid>
            {draftProperty.videos.length < 5 && (
              <UserMediaAddButton
                title={'Add Video'}
                accept={'video/*'}
                onFileSelected={onVideoSelected}
              />
            )}

            {draftProperty.videos.map((propertyMedia) => (
              <UserMediaSquare
                userMedia={propertyMedia.userMedia}
                onRemoveClick={() => onRemoveMediaClick(propertyMedia)}
                key={`video_${propertyMedia.userMedia.id}`}
                videoModal
              />
            ))}

            {loadingUserMedia
              .filter((userMedia) => userMedia.type == UserMediaType.Video)
              .map((userMedia) => (
                <UserMediaSquare
                  userMedia={userMedia}
                  key={`loading_${userMedia.id}`}
                />
              ))}
          </MediaGrid>
        </PageContentsContainer>
      )}

      <StepNavigationFooter
        backHref="/new-property/sale-info"
        onNextClick={() => onNextClick(false)}
        onSkipClick={() => onNextClick(true)}
        nextDisabled={disableNextButton}
      />
    </StepPageTemplate.Container>
  );
};

export default Photos;
