import styled from 'styled-components';

const ASParagraph = styled.p`
  font-family: Inter, Arial, sans-serif;
  font-size: 16px;
  color: #8f9aa9;
  letter-spacing: 0.17px;
  text-align: center;
  line-height: 28px;
`;

export default ASParagraph;
