import React from 'react';
import styled from 'styled-components';
import ASCaption from '../../../components/ASCaption';
import MultiLineTextField from '../../../components/MultiLineTextField';
import { usePrecacheForNavigationHeader } from '../../../components/NavigationHeader';
import { usePrecacheForSearchSelectField } from '../../../components/SearchSelectField';
import { usePrecacheForStepNavigationFooter } from '../../../components/StepNavigationFooter';
import TextInputField from '../../../components/TextInputField';
import { usePrecacheForUserMediaAddButton } from '../../../components/UserMediaAddButton';
import { usePrecacheUserMediaSquare } from '../../../components/UserMediaSquare';
import theme from '../../../constants/theme';
import UserMediaField, {
  UserMediaFieldValue,
} from '../../../containers/UserMediaField';
import EditUserMediaLabelButton from '../components/EditUserMediaLabelButton';
import { usePrecacheForAgentSignupSuburbs } from './AgentSignupSuburbs';

const InputLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #58687e;
  letter-spacing: 0.2px;
  margin: 10px 0 0 0;
`;

const ProfilePhotoField = styled(UserMediaField)`
  width: 106px;
  height: 106px;
`;

const BioCaptionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AgencyLogoUserMediaFieldContainer = styled.div`
  margin: 0 0 0 0 !important;
`;

const AgencyLogoUserMediaField = styled(UserMediaField)`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  overflow: hidden;
`;

const AgencyLogoUserMediaFieldEditButton = styled(EditUserMediaLabelButton)`
  margin: 0 16px 0 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${theme.layout.desktopContainedContentWidth};
  overflow: visible;
`;

const BioInput = styled(MultiLineTextField)`
  margin-bottom: 10px;
`;

export const usePrecacheForAgentProfileDetailsForm: () => void = () => {
  usePrecacheUserMediaSquare();
  usePrecacheForUserMediaAddButton();
  usePrecacheForStepNavigationFooter();
  usePrecacheForNavigationHeader();
  usePrecacheForSearchSelectField();
};

export interface ProfileDetailsFormValues {
  profilePictureUserMedia?: UserMediaFieldValue;
  bio: string;
  agencyLogoUserMedia?: UserMediaFieldValue;
  agencyName: string;
  agencyBio: string;
}

export interface AgentProfileDetailsFormProps {
  editEnabled?: boolean;
  bioLength?: number;
}

const AgentProfileDetailsForm: React.FC<AgentProfileDetailsFormProps> = ({
  editEnabled = true,
  bioLength = 0,
}) => {
  usePrecacheForAgentSignupSuburbs();

  return (
    <Container>
      <ProfilePhotoField
        fieldName="profilePictureUserMedia"
        renderBottomDetail={({ isUploading, value, onFileSelected }) => (
          <>
            {editEnabled && (
              <EditUserMediaLabelButton
                label={value ? 'Change photo' : 'Choose photo'}
                disabled={isUploading}
                onFileSelected={onFileSelected}
                accept="image/*"
              />
            )}
          </>
        )}
        accept="image/*"
        disabled={!editEnabled}
      />

      <InputLabel>Bio</InputLabel>
      <BioCaptionRow>
        <ASCaption>Give a brief description of yourself</ASCaption>
        <ASCaption>{500 - bioLength}</ASCaption>
      </BioCaptionRow>

      <BioInput
        showErrorAfterTouch
        fieldName="bio"
        placeholder="Write your bio..."
        maxLength={500}
        disabled={!editEnabled}
      />

      <InputLabel>Agency</InputLabel>
      <BioCaptionRow>
        <ASCaption>Enter the agency you represent</ASCaption>
      </BioCaptionRow>
      <AgencyLogoUserMediaFieldContainer>
        <AgencyLogoUserMediaField
          fieldName="agencyLogoUserMedia"
          lightIcon
          horizontal
          renderBottomDetail={({ isUploading, value, onFileSelected }) => (
            <>
              <AgencyLogoUserMediaFieldEditButton
                label={value ? 'Upload new agency logo' : 'Upload agency logo'}
                disabled={isUploading}
                onFileSelected={onFileSelected}
                accept="image/*"
              />
            </>
          )}
          accept="image/*"
        />
      </AgencyLogoUserMediaFieldContainer>
      <TextInputField
        showErrorAfterTouch
        name="agencyName"
        placeholder="Agency name"
        maxLength={196}
      />

      <BioCaptionRow>
        <ASCaption>Give a brief description of your agency</ASCaption>
        <ASCaption>{500 - bioLength}</ASCaption>
      </BioCaptionRow>

      <BioInput
        showErrorAfterTouch
        fieldName="agencyBio"
        placeholder="Write your agency bio"
        maxLength={500}
        disabled={!editEnabled}
      />
    </Container>
  );
};

export default AgentProfileDetailsForm;
