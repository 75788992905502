import React, { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import passwordEyeIconUrl, {
  ReactComponent as PasswordFieldEyeIcon,
} from '../assets/password-field-eye.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';
import TextInputField, { TextInputFieldProps } from './TextInputField';

const PasswordFieldEyeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  padding: 0;
  margin: 0 20px 0 0;

  width: 23px;

  @media only screen and (max-width: ${theme.layout.desktopBreakpoint}) {
    /* This is a lazy work around for  this button not working well on iOS  */
    display: none;
  }
`;

const PasswordFieldEyeButton = styled.button.attrs({
  type: 'button',
})`
  width: 23px;
  height: 18px;
  padding: 0;
  margin: 0;

  border: none;
  background-color: transparent;
`;

export type PasswordInputFieldProps = TextInputFieldProps;

export const usePrecacheForPasswordInputField: () => void = () => {
  usePrecacheImages([passwordEyeIconUrl]);
};

const PasswordInputField: FC<PasswordInputFieldProps> = (props) => {
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const toggleHidePassword = useCallback(() => {
    setHidePassword((hidePassword) => !hidePassword);
  }, []);

  return (
    <TextInputField
      disabled={props.disabled}
      name="password"
      type={hidePassword ? 'password' : 'text'}
      renderSuffix={() => (
        <>
          {!props.disabled && (
            <PasswordFieldEyeContainer>
              <PasswordFieldEyeButton onClick={toggleHidePassword}>
                <PasswordFieldEyeIcon />
              </PasswordFieldEyeButton>
            </PasswordFieldEyeContainer>
          )}
        </>
      )}
      suffixPadding={53}
      {...props}
    />
  );
};

export default PasswordInputField;
