import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASErrorLabel from '../../../components/ASErrorLabel';
import ASHeader from '../../../components/ASHeader';
import PasswordInputField from '../../../components/PasswordInputField';
import SideBySidePageTemplate, {
  usePrecacheForSideBySidePageTemplate,
} from '../../../components/SideBySidePageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import { useUpdateUserPasswordWithResetTokenMutation } from '../../../graphql/generated';
import { usePrecacheForChangePasswordSuccess } from './ChangePasswordSuccess';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(ASHeader)`
  margin-bottom: 21px;
`;

const Expalanation = styled.p`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.16px;
  line-height: 22px;
  margin: 0 0 40px 0;
`;

const SendLinkButton = styled(ASBlockButton)`
  margin: 26px 0 21px 0;
  width: 100%;
`;

export const usePrecacheForChangePassword: () => void = () => {
  usePrecacheForSideBySidePageTemplate();
};

interface FormValues {
  password: string;
  passwordConfirmation: string;
}

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(9, 'Password must be at least 9 characters')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .min(9)
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

interface ExpectedPathParams {
  userId?: string;
  token?: string;
}
const ChangePassword: FC = () => {
  const history = useHistory();

  const { userId, token } = useParams<ExpectedPathParams>();

  const [
    updateUserPasswordWithResetToken,
    { loading, error: updateUserPasswordWithResetTokenError },
  ] = useUpdateUserPasswordWithResetTokenMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      if (!userId || !token) return;

      const { data } = await updateUserPasswordWithResetToken({
        variables: {
          userId,
          passwordResetToken: token,
          password: values.password,
        },
      });

      if (data) {
        history.push('/change-password/success');
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  usePrecacheForChangePasswordSuccess();

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Reset Password | AgentSpot</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm, errors, submitCount }) => {
          const error: string | undefined =
            (submitCount > 0 && errors && Object.values(errors)[0]) ||
            (updateUserPasswordWithResetTokenError &&
              (updateUserPasswordWithResetTokenError.message == 'not_found'
                ? 'Password reset link no longer valid'
                : apolloErrorToString(updateUserPasswordWithResetTokenError)));

          return (
            <Form>
              <Header>Enter your new password</Header>

              <Expalanation>Enter and confirm your new password</Expalanation>

              <PasswordInputField name="password" placeholder="Password" />
              <PasswordInputField
                name="passwordConfirmation"
                placeholder="Confirm Password"
              />
              {submitCount > 0 && error && <ASErrorLabel>{error}</ASErrorLabel>}

              <SendLinkButton loading={loading} onClick={submitForm}>
                Reset Password
              </SendLinkButton>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default ChangePassword;
