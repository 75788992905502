const renderPropertyPrice: (price?: number | null) => string = (price) => {
  if (!price && price !== 0) return '?';

  if (price >= 1_000_000) {
    return `$${(Math.floor(price / 100_000) * 100_000) / 1_000_000}m`;
  } else {
    return `$${Math.floor(price / 1_000)}k`;
  }
};

export default renderPropertyPrice;
