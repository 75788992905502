import React, { FC } from 'react';
import styled from 'styled-components';
import ASBlockLink from '../../../components/ASBlockLink';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import createListingPlusBlockSvgUrl, {
  ReactComponent as CreateListingPlusBlockSvg,
} from '../assets/create-listing-plus-block.svg';
import noPropertiesPlaceholderIconUrl, {
  ReactComponent as NoPropertiesPlaceholderIcon,
} from '../assets/no-properties-placeholder.svg';
import EmptyPlaceholder from './EmptyPlaceholder';

const CreateListingPlusBlock = styled(CreateListingPlusBlockSvg)`
  margin: 0 14px 0 -2px;
`;

const NoPropertiesPlaceholderImage = styled(NoPropertiesPlaceholderIcon)`
  /* TODO: width */
`;

export const usePrecacheForNoPropertiesPlaceholder: () => void = () => {
  usePrecacheImages([
    createListingPlusBlockSvgUrl,
    noPropertiesPlaceholderIconUrl,
  ]);
};

const NoPropertiesPlaceholder: FC = () => {
  return (
    <EmptyPlaceholder
      image={<NoPropertiesPlaceholderImage />}
      title="Create a listing"
      explanation="Listing your property on AgentSpot so you can receive appraisals and
      find the right agent to sell your home">
      <ASBlockLink to="/new-property/">
        <CreateListingPlusBlock />
        Create Listing
      </ASBlockLink>
    </EmptyPlaceholder>
  );
};

export default NoPropertiesPlaceholder;
