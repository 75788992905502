import React, { FC } from 'react';
import styled from 'styled-components';
import { PropertyAppraisalComparableSaleBaseFragment } from '../../../graphql/generated';

const Container = styled.div`
  background: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 17px 0 0 0;
`;

const ItemTitle = styled.h5`
  margin: 0 0 13px 0;
  font-family: Inter, Arial, sans-serif;
  font-size: 13px;
  color: #8f9aa9;
  letter-spacing: 0.15px;
`;

const AddressLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  letter-spacing: 0.2px;
`;

const SalePriceLabel = styled.div`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #203553;
  opacity: 0.7;
  margin-bottom: 16px;
`;

const UrlLabel = styled.a`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #1745b0;
  letter-spacing: 0.2px;

  :hover {
    color: #1745b0;
    text-decoration-color: #1745b0;
  }
`;

export interface AppraisalSummaryComparableSaleRowProps {
  title: string;
  value: PropertyAppraisalComparableSaleBaseFragment;
}

const AppraisalSummaryComparableSaleRow: FC<AppraisalSummaryComparableSaleRowProps> = (
  props,
) => {
  const { title, value } = props;

  const url = value?.url
    ? !/^https?:\/\//i.test(value.url)
      ? `https://${value.url.trim()}`
      : value.url
    : undefined;

  return (
    <Container>
      <ItemTitle>{title}</ItemTitle>
      <AddressLabel>{value.address}</AddressLabel>
      <SalePriceLabel>
        ${new Intl.NumberFormat().format(value.salePriceDollars)}
      </SalePriceLabel>
      {!!url && (
        <UrlLabel href={url} target="_blank">
          {url}
        </UrlLabel>
      )}
    </Container>
  );
};

export default AppraisalSummaryComparableSaleRow;
