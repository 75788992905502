import { useSelector } from 'react-redux';
import { UserType } from '../graphql/generated';
import { RootState } from '../store';

/**
 *
 * @returns false if no auth token set, login in progress, logout in progress; True otherwise.
 */
const useIsAuthenticated: (userType?: UserType) => boolean = (userType) =>
  useSelector<RootState, boolean>(
    (state) =>
      !!state.auth.authToken &&
      !state.auth.logoutInProgress &&
      !state.auth.loginInProgress &&
      (!userType || state.auth.lastKnownUserType === userType),
  );

export default useIsAuthenticated;
