import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import directionLeftArrowUrl, {
  ReactComponent as DirectionLeftArrow,
} from '../assets/direction-left-arrow.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 960px;
  padding: 14px 20px 14px 20px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 27px 20px 14px 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    align-self: stretch;
  }
`;

const BackLink = styled(Link)`
  margin: 0;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    margin: 0 0 35px;
  }
`;

const PageTitleLabel = styled.h1`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #8f9aa9;
  letter-spacing: 0.2px;
  text-align: center;
  flex-grow: 1;
  margin: 0 20px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    font-family: 'neuzeit-grotesk', Arial, sans-serif;
    font-weight: bold;

    font-size: 25px;
    color: #203553;
    text-align: left;
    margin: 0;
  }
`;

const BackLinkCounterWeight = styled.div`
  width: 16px;
`;

export const usePrecachForInnerNavigationHeader: () => void = () => {
  usePrecacheImages([directionLeftArrowUrl]);
};

export interface InnerNavigationHeaderProps {
  backHref: string;
  pageTitle: string;
  rightSideComponent?: ReactNode;
}

const InnerNavigationHeader: FC<InnerNavigationHeaderProps> = (props) => {
  const { backHref, pageTitle, rightSideComponent } = props;

  return (
    <Container>
      <BackLink to={backHref}>
        <DirectionLeftArrow />
      </BackLink>
      <ContentContainer>
        <PageTitleLabel>{pageTitle}</PageTitleLabel>

        {rightSideComponent ? rightSideComponent : <BackLinkCounterWeight />}
      </ContentContainer>
    </Container>
  );
};

export default InnerNavigationHeader;
