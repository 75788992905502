import { Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import ASErrorLabel from '../../../components/ASErrorLabel';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { apolloErrorToString } from '../../../constants/ErrorCodes';
import UserMediaField, {
  UserMediaFieldValue,
  UserMediaFieldValueState,
} from '../../../containers/UserMediaField';
import {
  UserMediaBaseFragment,
  useUpdateDraftPropertyAppraisalVideoMutation,
} from '../../../graphql/generated';
import usePrecacheImages from '../../../hooks/usePrecacheImages';
import removeIconUrl, {
  ReactComponent as RemoveIcon,
} from '../assets/close-cross-small-dark.svg';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalFees } from './NewAppraisalFees';

export const usePrecacheForNewAppraisalVideo: () => void = () => {
  usePrecacheImages([removeIconUrl]);

  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForStepTemplate();
};

const UploadVideoField = styled(UserMediaField)`
  width: 100%;
  min-height: 170px;
`;

const RemoveVideoButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 27px 0 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const RemoveVideoCross = styled(RemoveIcon)`
  width: 19px;
  height: 19px;
`;

const RemoveVideoLabel = styled.div`
  margin: 0 0 0 9px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #203553;
  opacity: 0.5;
  letter-spacing: 0.15px;
`;

const UploadingMessage = styled.div`
  margin: 25px 0 0 9px;
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #203553;
  opacity: 0.5;
  letter-spacing: 0.15px;
`;

interface ExpectedPathParams {
  id?: string;
}

interface FormValues {
  appraisalVideoUserMedia?: UserMediaFieldValue;
}

const validationSchema = Yup.object().shape({
  appraisalVideoUserMedia: Yup.object(),
});

const NewAppraisalVideo: React.FC = () => {
  const [loadingUserMedia, setLoadingUserMedia] = useState<
    UserMediaBaseFragment[]
  >([]);

  useEffect(() => {
    console.log(loadingUserMedia);
  }, [loadingUserMedia]);

  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const [
    updateDraftPropertyAppraisalVideo,
    {
      error: updateDraftPropertyAppraisalVideoError,
      loading: updateDraftPropertyAppraisalVideoLoading,
    },
  ] = useUpdateDraftPropertyAppraisalVideoMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftPropertyAppraisal) return;

      if (values.appraisalVideoUserMedia) {
        await updateDraftPropertyAppraisalVideo({
          variables: {
            propertyAppraisalId: draftPropertyAppraisal.id,
            videoUserMediaId: values.appraisalVideoUserMedia.userMedia.id,
          },
        });
      } else {
        await updateDraftPropertyAppraisalVideo({
          variables: {
            propertyAppraisalId: draftPropertyAppraisal.id,
            videoUserMediaId: null,
          },
        });
      }

      history.push(
        `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/additional-notes`,
      );
    },
    [draftPropertyAppraisal],
  );

  const initialValues: FormValues = {
    appraisalVideoUserMedia: draftPropertyAppraisal?.appraisalVideo
      ? {
          userMedia: draftPropertyAppraisal?.appraisalVideo,
          state: UserMediaFieldValueState.Ready,
        }
      : undefined,
  };

  usePrecacheForNewAppraisalFees();
  usePrecacheForAgentMenu();

  const someMediaStillUploading = loadingUserMedia.length !== 0;
  const disableNextButton = someMediaStillUploading;

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Add Video | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {draftPropertyAppraisal && (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount={true}>
          {({ submitForm, isValid }) => {
            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <StepNavigationHeader
                    title="Appraise property"
                    stepName="Add a personal video message"
                    stepIndex={4}
                    stepCount={5}
                    stepDescription={
                      'You can choose to add a video to help explain and break down your appraisal'
                    }
                  />

                  <UploadVideoField
                    fieldName="appraisalVideoUserMedia"
                    accept="video/*"
                    addButtonTitle="Upload Video"
                    setLoadingUserMedia={setLoadingUserMedia}
                    renderBottomDetail={({ isUploading, value, setValue }) =>
                      value &&
                      !isUploading && (
                        <>
                          <RemoveVideoButton
                            onClick={() => setValue(undefined)}>
                            <RemoveVideoCross />
                            <RemoveVideoLabel>Remove Video</RemoveVideoLabel>
                          </RemoveVideoButton>
                          <UploadingMessage>
                            You can continue to appraise the property whilest we
                            are proccessing you video
                          </UploadingMessage>
                        </>
                      )
                    }
                  />

                  {updateDraftPropertyAppraisalVideoError && (
                    <ASErrorLabel>
                      {apolloErrorToString(
                        updateDraftPropertyAppraisalVideoError,
                      )}
                    </ASErrorLabel>
                  )}
                </StepPageTemplate.ContentContainer>
                <StepNavigationFooter
                  backHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/comparable-sales`}
                  onNextClick={submitForm}
                  nextDisabled={
                    updateDraftPropertyAppraisalVideoLoading ||
                    !isValid ||
                    disableNextButton
                  }
                  loading={updateDraftPropertyAppraisalVideoLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalVideo;
