import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { usePrecacheForAgentMenu } from '../../../components/AgentMenu';
import MultiLineTextField from '../../../components/MultiLineTextField';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import NotFoundPlaceholder from '../../../components/NotFoundPlaceholder';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import { useUpdateDraftPropertyAppraisalAdditionalNotesMutation } from '../../../graphql/generated';
import useDraftPropertyAppraisal from '../hooks/useDraftPropertyAppraisal';
import { usePrecacheForNewAppraisalSummary } from './NewAppraisalSummary';

export const usePrecacheForNewAppraisalAdditionalNotes: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForStepTemplate();
};

interface ExpectedPathParams {
  id: string;
}
interface FormValues {
  additionalNotes?: string;
}

const validationSchema = Yup.object().shape({
  additionalNotes: Yup.string()
    .required('Additional notes are required')
    .trim()
    .min(1, 'Additional notes are required'),
});

const NewAppraisalAdditionalNotes: FC = () => {
  const { id: propertyAppraisalRequestId } = useParams<ExpectedPathParams>();
  const history = useHistory();

  const { draftPropertyAppraisal, notFound } = useDraftPropertyAppraisal(
    propertyAppraisalRequestId,
  );

  const [
    updateDraftPropertyAppraisalAdditionalNotes,
    { loading: updateDraftPropertyAppraisalAdditionalNotesLoading },
  ] = useUpdateDraftPropertyAppraisalAdditionalNotesMutation();

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!draftPropertyAppraisal) return;

      await updateDraftPropertyAppraisalAdditionalNotes({
        variables: {
          propertyAppraisalId: draftPropertyAppraisal.id,
          additionalNotes: values.additionalNotes!,
        },
      });

      history.push(
        `/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/summary`,
      );
    },
    [draftPropertyAppraisal],
  );

  const initialValues = !draftPropertyAppraisal
    ? null
    : ({
        additionalNotes: draftPropertyAppraisal.additionalNotes || undefined,
      } as FormValues);

  usePrecacheForNewAppraisalSummary();
  usePrecacheForAgentMenu();

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Additional notes | AgentSpot</title>
      </Helmet>

      {!draftPropertyAppraisal && notFound && <NotFoundPlaceholder />}
      {!draftPropertyAppraisal ? null : (
        <Formik<FormValues>
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount>
          {({ submitForm, isValid }) => {
            return (
              <>
                <StepPageTemplate.ContentContainer>
                  <StepNavigationHeader
                    title="Appraise property"
                    stepName="Additional Notes"
                    stepIndex={5}
                    stepCount={5}
                    stepDescription="Add any additional notes if you have them"
                  />
                  <MultiLineTextField
                    fieldName="additionalNotes"
                    placeholder="Write notes..."
                    maxLength={3500}
                  />
                </StepPageTemplate.ContentContainer>
                <StepNavigationFooter
                  backHref={`/agent/appraisal-request/${propertyAppraisalRequestId}/appraise/video`}
                  onNextClick={submitForm}
                  nextDisabled={
                    !isValid ||
                    updateDraftPropertyAppraisalAdditionalNotesLoading
                  }
                  loading={updateDraftPropertyAppraisalAdditionalNotesLoading}
                />
              </>
            );
          }}
        </Formik>
      )}
    </StepPageTemplate.Container>
  );
};

export default NewAppraisalAdditionalNotes;
