import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { ParsedQuery } from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASModal from '../../../components/ASModal';
import GooglePlacesSelectField from '../../../components/GooglePlacesSelectField';
import NavigationHeader, {
  usePrecacheForNavigationHeader,
} from '../../../components/NavigationHeader';
import StepNavigationFooter, {
  usePrecacheForStepNavigationFooter,
} from '../../../components/StepNavigationFooter';
import StepNavigationHeader from '../../../components/StepNavigationHeader';
import StepPageTemplate, {
  usePrecacheForStepTemplate,
} from '../../../components/StepPageTemplate';
import TextInputField from '../../../components/TextInputField';
import { DECRYPT_KEY } from '../../../constants/EnumMappings';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import {
  AgentsForPropertyDocument,
  CurrentDraftPropertyDocument,
  GetSuburbFromSearchDocument,
  GetSuburbFromSearchQuery,
  SuburbBaseFragment,
  UserType,
  useStartNewDraftPropertyMutation,
  useStaticCashbackAmountQuery,
  useUpdatePropertyDetailsMutation,
  useUpdateUserDetailsMutation,
} from '../../../graphql/generated';
import usePrevious from '../../../hooks/usePrevious';
import useQueryParams from '../../../hooks/useQueryParams';
import SocialSignUp from '../../../SocialSignUp/SocialSignUp';
import GoogleAddressParser, {
  AddressComponent,
} from '../../../utils/googleAddressParser';
import PrivacyPolicyCheckField from '../../properties/components/PrivacyPolicyCheckField';
import martyIcon from '../assets/marty.png';
import CashbackReminder from '../components/CashbackReminder';
import useCreateGhostUserIfNecessary from '../hooks/useCreateGhostUserIfNecessary';
import useCurrentDraftProperty from '../hooks/useCurrentDraftProperty';
import { usePrecacheForDetails } from './Details';
import ManualSuburbForm from './ManualSuburbForm';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { decryptQueryParams } = require('query-string-hash');

const ManualEntryButton = styled.button`
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #1745b0;
  letter-spacing: 0.15px;
  border: none;
  background: none;
  margin: 10px 0 0 0;
  padding: 0;
  text-align: flex-start;
  align-self: flex-start;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 18px 0px;
`;

const UnitNumberInputContainer = styled.div`
  margin: 0 0 0 0;
`;

const CashbackContainerReminder = styled.div`
  margin: 20px 0 0 0;
`;

const UserDetailsContainer = styled.div`
  margin: 11px 0 10px 0;
`;

export const LoadingSpinnerContainer = styled.div`
  margin-top: 50px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  display: flex;
  flex-grow: 1;
  margin: 20px 0 0 0;

  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 28px;
  color: #1745b0;
  letter-spacing: 0.3px;
  align-self: center;
  text-align: center;
  width: 50%;
`;

export const usePrecacheForAddress: () => void = () => {
  usePrecacheForNavigationHeader();
  usePrecacheForStepNavigationFooter();
  usePrecacheForStepTemplate();
};

export interface Address {
  streetName: string;
  suburb: SuburbBaseFragment;
}

interface ExpectedQueryParams extends ParsedQuery {
  hash: string | string[] | null;
}

interface QueryParams {
  address?: Address;
  consent: string;
  name: string;
  email: string;
}

interface FormValues {
  address?: Address;
  unitNumber: string;
  isPrivacyPolicyAccepted: boolean;
  name: string;
  email: string;
  socialEmail?: string;
  isGhostUser: boolean;
}

const validationSchema = Yup.object().shape({
  address: Yup.object().required('Address is required'),
  unitNumber: Yup.string().max(50, 'Unit number is too long'),
  isPrivacyPolicyAccepted: Yup.boolean().when(['isGhostUser'], {
    is: (isGhostUser: boolean) => isGhostUser === true,
    then: Yup.boolean().oneOf(
      [true],
      'You must agree to the terms & conditions',
    ),
  }),
  name: Yup.string().when(['isGhostUser'], {
    is: (isGhostUser: boolean) => isGhostUser === true,
    then: Yup.string().required('Name is required'),
  }),
  email: Yup.string().when(['isGhostUser'], {
    is: (isGhostUser: boolean) => isGhostUser === true,
    then: Yup.string()
      .email('Email address is invalid')
      .required('Email address is required'),
  }),
});

const Address: FC = () => {
  usePrecacheForDetails();
  const history = useHistory();
  const { hash } = useQueryParams<ExpectedQueryParams>();
  const client = useApolloClient();
  const [suburbEntryModalOpen, setSuburbEntryModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [queryParams, setQueryParams] = useState<QueryParams>();
  const { isAuthenticated, user } = useCreateGhostUserIfNecessary();
  const [isHashedDataLoading, setIsHashedDataLoading] = useState(false);
  const { data: cashbackAmount } = useStaticCashbackAmountQuery();
  const {
    draftProperty,
    loading: loadingDraftProperty,
  } = useCurrentDraftProperty(false);
  const wasLoadingDraftProperty = usePrevious(loadingDraftProperty);
  const [
    createDraftProperty,
    { loading: createDraftPropertyLoading },
  ] = useStartNewDraftPropertyMutation({
    refetchQueries: [{ query: CurrentDraftPropertyDocument }],
    awaitRefetchQueries: true,
  });
  const [
    updateUserDetails,
    { loading: updateUserDetailsLoading },
  ] = useUpdateUserDetailsMutation();
  const [
    updatePropertyDetails,
    { loading: updatePropertyDetailsLoading },
  ] = useUpdatePropertyDetailsMutation({
    refetchQueries: draftProperty
      ? [
          {
            query: AgentsForPropertyDocument,
            variables: { propertyId: draftProperty.id },
          },
        ]
      : undefined,
  });
  const getSuburbDetails = async (queryParams: QueryParams) => {
    const { data } = await client.query<GetSuburbFromSearchQuery>({
      query: GetSuburbFromSearchDocument,
      variables: {
        suburbs: [
          queryParams?.address?.suburb?.name ?? '',
          queryParams?.address?.suburb.state ?? '',
        ],
        state: queryParams?.address?.suburb?.state,
        postcode: queryParams?.address?.suburb?.postcode,
      },
    });

    return data.getSuburbFromSearch;
  };

  /*
    Construct the search term from the draft property
    to match Google Places formatted_address
  */
  useEffect(() => {
    if (hash && !loadingDraftProperty) {
      const queryParams = decryptQueryParams(hash, DECRYPT_KEY);

      if (queryParams?.address) {
        queryParams.address = JSON.parse(queryParams?.address);
        getSuburbDetails(queryParams).then((data) => {
          queryParams.address.suburb = data;
          setQueryParams(queryParams);
          setSearchTerm(
            `${queryParams?.address?.streetName}, ${queryParams?.address?.suburb?.name} ${queryParams?.address?.suburb?.state} ${queryParams?.address?.suburb?.postcode}, Australia`,
          );
          const values = {
            address: {
              streetName: queryParams?.address?.streetName,
              suburb: data,
            },
            unitNumber: '',
            isPrivacyPolicyAccepted:
              queryParams?.consent === 'true' ? true : false,
            name: queryParams?.name || '',
            email: queryParams?.email || '',
            socialEmail: '',
            isGhostUser: user?.isGhostUser || false,
          };

          if (user && values) {
            setIsHashedDataLoading(true);
            onSubmit(values);
          }
        });
      }
    } else if (
      wasLoadingDraftProperty &&
      !loadingDraftProperty &&
      draftProperty
    ) {
      setSearchTerm(
        `${draftProperty.streetName}, ${draftProperty.suburb.name} ${draftProperty.suburb.state} ${draftProperty.suburb.postcode}, Australia`,
      );
    }
  }, [loadingDraftProperty]);

  const initialValues: FormValues =
    hash && queryParams && queryParams?.address
      ? {
          address: {
            streetName: queryParams?.address?.streetName,
            suburb: queryParams?.address?.suburb,
          },
          unitNumber: '',
          isPrivacyPolicyAccepted:
            queryParams?.consent === 'true' ? true : false,
          name: queryParams?.name || '',
          email: queryParams?.email || '',
          socialEmail: '',
          isGhostUser: user?.isGhostUser || false,
        }
      : !draftProperty?.streetName || !draftProperty.suburb
      ? {
          address: undefined,
          unitNumber: '',
          isPrivacyPolicyAccepted:
            user?.sellerProfile?.isPrivacyPolicyAccepted ||
            (user?.isGhostUser ? false : true),
          name: user?.sellerProfile?.name || '',
          email: user?.email || '',
          socialEmail: '',
          isGhostUser: user?.isGhostUser || false,
        }
      : {
          address: {
            streetName: draftProperty.streetName,
            suburb: draftProperty.suburb,
          },
          unitNumber: '',
          isPrivacyPolicyAccepted:
            user?.sellerProfile?.isPrivacyPolicyAccepted ||
            (user?.isGhostUser ? false : true),
          name: user?.sellerProfile?.name || '',
          email: user?.email || '',
          socialEmail: '',
          isGhostUser: user?.isGhostUser || false,
        };

  const updateUserDetailsCall = useCallback(async (values: FormValues) => {
    try {
      await updateUserDetails({
        variables: {
          email: values.email,
          name: values.name,
          socialEmail: values.socialEmail,
          isPrivacyPolicyAccepted: values.isPrivacyPolicyAccepted,
        },
      });

      return true;
    } catch (e) {
      if (e.message === ErrorCodes.EmailIsAlreadyInUse) {
        toast('Email address is already in use', {
          toastId: 'email_already_used',
        });
      } else if (e.message === ErrorCodes.EmailInvalid) {
        toast('Email address is invalid', { toastId: 'email_invalid' });
      } else {
        toast('Something went wrong', { toastId: 'something_went_wrong' });
      }

      setIsHashedDataLoading(false);

      return false;
    }
  }, []);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      let isSuccess = null;
      if (!user?.isEmailVerified) {
        isSuccess = await updateUserDetailsCall(values);
      }

      const streetName = values.unitNumber
        ? `${values.unitNumber} ${values.address!.streetName}`
        : values.address!.streetName;

      if (!draftProperty) {
        await createDraftProperty({
          variables: {
            suburbId: values.address!.suburb.id,
            streetName,
          },
        });
      } else {
        await updatePropertyDetails({
          variables: {
            propertyId: draftProperty.id,
            propertyDetails: {
              suburbId: values.address!.suburb.id,
              streetName,
            },
          },
        });
      }

      if (isSuccess) {
        updateUserDetails({
          variables: {
            nextPage: '/new-property/details',
          },
        });
        history.push('/new-property/details');
      }
    },
    [draftProperty],
  );

  if (user?.userType && user.userType != UserType.Seller) {
    return <Redirect to="/" />;
  }

  const onAddressOptionSelected = async (
    placeResult: google.maps.places.PlaceResult | null,
  ) => {
    const detailsParsed = new GoogleAddressParser(
      placeResult?.address_components as AddressComponent[],
    ).result();

    const { data } = await client.query<GetSuburbFromSearchQuery>({
      query: GetSuburbFromSearchDocument,
      variables: {
        suburbs: [detailsParsed.city ?? '', detailsParsed.state ?? ''],
        state: detailsParsed.state,
        postcode: detailsParsed.postal_code,
      },
    });

    return data.getSuburbFromSearch;
  };

  return (
    <StepPageTemplate.Container desktopHeader={<NavigationHeader minimal />}>
      <Helmet>
        <title>Address | AgentSpot</title>
      </Helmet>
      {isHashedDataLoading ? (
        <LoadingSpinnerContainer>
          <BeatLoader
            color="#0e26d9"
            css="display: flex;
            justify-content: center;
            align-items: center;"
            size={50}
            margin={10}
            loading={isHashedDataLoading}
          />
        </LoadingSpinnerContainer>
      ) : (
        !loadingDraftProperty && (
          <Formik<FormValues>
            initialValues={initialValues!}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnMount={false}>
            {({ submitForm, isValid, setFieldValue }) => {
              const canGoForward =
                isValid &&
                isAuthenticated &&
                !createDraftPropertyLoading &&
                !updateUserDetailsLoading &&
                !updatePropertyDetailsLoading;

              return (
                <>
                  <TextContainer>
                    <img
                      style={{
                        alignSelf: 'center',
                        margin: '20px 0 0 0',
                      }}
                      src={martyIcon}
                      height={'121px'}
                      width={'283px'}
                    />
                    <Label>
                      Find a real estate agent that&apos;s right for you
                    </Label>
                  </TextContainer>
                  <StepPageTemplate.ContentContainer>
                    <StepNavigationHeader
                      title="Property Details"
                      stepCount={6}
                      stepIndex={1}
                      stepName="Enter your address"
                    />
                    <DetailsContainer>
                      <GooglePlacesSelectField
                        fieldName="address"
                        placeholder="Search for an address"
                        searchTerm={searchTerm}
                        onSearchTermChanged={setSearchTerm}
                        onOptionSelected={async (address) => {
                          const detailsParsed = new GoogleAddressParser(
                            address?.address_components as AddressComponent[],
                          ).result();
                          const suburb = await onAddressOptionSelected(address);

                          setSearchTerm(address.formatted_address ?? '');
                          setFieldValue('address', {
                            streetName: detailsParsed.street_number
                              ? `${detailsParsed.street_number} ${detailsParsed.street_name}`
                              : detailsParsed.street_name,
                            suburb: suburb,
                          } as Address);
                        }}
                        googleApiKey={
                          process.env.REACT_APP_GOOGLE_PLACES_API_KEY
                        }
                      />
                      <UnitNumberInputContainer>
                        <TextInputField
                          name="unitNumber"
                          maxLength={50}
                          placeholder={'Apartment Number, Level etc. (if any)'}
                        />
                      </UnitNumberInputContainer>
                      <ASModal
                        isOpen={suburbEntryModalOpen}
                        title={'Address & Suburb'}
                        onRequestClose={() => setSuburbEntryModalOpen(false)}>
                        <ManualSuburbForm
                          onAddressSubmitted={async (
                            formattedAddress: string,
                            suburbDetails: google.maps.places.PlaceResult,
                          ) => {
                            const suburb = await onAddressOptionSelected(
                              suburbDetails,
                            );

                            setSearchTerm(
                              `${formattedAddress}, ${suburbDetails.formatted_address}`,
                            );

                            setFieldValue('address', {
                              streetName: `${formattedAddress}`,
                              suburb: suburb,
                            } as Address);

                            setSuburbEntryModalOpen(false);
                          }}
                        />
                      </ASModal>
                      <ManualEntryButton
                        onClick={() => setSuburbEntryModalOpen(true)}>
                        {"Can't find your address? Enter suburb"}
                      </ManualEntryButton>

                      {!user?.isEmailVerified && (
                        <UserDetailsContainer>
                          <TextInputField
                            showErrorAfterTouch
                            name="name"
                            placeholder="Enter full name"
                            maxLength={196}
                          />

                          <TextInputField
                            showErrorAfterTouch
                            name="email"
                            type="email"
                            placeholder="Enter email address"
                            maxLength={196}
                          />
                          <PrivacyPolicyCheckField name="isPrivacyPolicyAccepted" />
                        </UserDetailsContainer>
                      )}
                    </DetailsContainer>
                  </StepPageTemplate.ContentContainer>
                  {!user?.profileComplete && (
                    <SocialSignUp setFieldValue={setFieldValue} />
                  )}
                  <CashbackContainerReminder>
                    <CashbackReminder
                      cashbackAmount={cashbackAmount?.staticCashbackAmount}
                    />
                  </CashbackContainerReminder>
                  <StepNavigationFooter
                    backHref={
                      isAuthenticated && !user?.isGhostUser
                        ? '/'
                        : '/get-started'
                    }
                    onNextClick={submitForm}
                    nextDisabled={!canGoForward}
                    loading={
                      createDraftPropertyLoading ||
                      updatePropertyDetailsLoading ||
                      updateUserDetailsLoading
                    }
                  />
                </>
              );
            }}
          </Formik>
        )
      )}
    </StepPageTemplate.Container>
  );
};

export default Address;
