import Drawer from '@material-ui/core/Drawer';
import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import agentSpotLogoIconUrl, {
  ReactComponent as AgentSpotLogoIcon,
} from '../assets/agentspot-logo.svg';
import menuOpenHamburgerIconUrl, {
  ReactComponent as MenuOpenHamburgerIcon,
} from '../assets/menu-open-hamburger.svg';
import theme from '../constants/theme';
import usePrecacheImages from '../hooks/usePrecacheImages';

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #e8eaed;
  background-color: white;

  z-index: 100;
`;

const HeightFiller = styled.div`
  height: 57px;
  width: 100%;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    height: 70px;
  }
`;

const ContentsContainer = styled.header<{ $extraWide?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: ${(props) => (props.$extraWide ? '1240px' : '1110px')};
  padding: 14px;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    padding: 14px 20px;
  }
`;

const MinimalContentsContainer = styled(ContentsContainer)`
  justify-content: center;
`;

const MenuOpenHamburgerButton = styled.button`
  border: none;
  padding: 0;
  margin: 0 20px 0 0;
  background: none;

  :hover {
    text-decoration-line: none;
    box-shadow: none;
  }

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: none;
  }
`;

const MenuOpenHamburger = styled(MenuOpenHamburgerIcon)`
  width: 24px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const NavbarBranding = styled.div`
  flex-grow: 1;
  flex-direction: row;

  cursor: pointer;

  @media only screen and (min-width: ${theme.layout.desktopBreakpoint}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 42px;
  }
`;

const LinkStyled = styled(Link)`
  /* Without background-color the link doesn't work on mobile devices */
  background-color: #fff;
`;

const AgentSpotLogo = styled(AgentSpotLogoIcon)`
  width: 121px;
  height: 29px;
  background-color: #fff;
`;

export const usePrecacheForNavigationHeader: () => void = () => {
  usePrecacheImages([agentSpotLogoIconUrl, menuOpenHamburgerIconUrl]);
};

export interface NavigationHeaderProps {
  rightSideComponent?: ReactNode;
  menuButton?: boolean;
  menu?: ReactNode;
  extraWide?: boolean;
  minimal?: boolean;
  navigateToLogout?: boolean;
}

const NavigationHeader: FC<NavigationHeaderProps> = (props) => {
  const {
    rightSideComponent,
    menuButton = false,
    menu: Menu,
    extraWide = false,
    minimal = false,
    navigateToLogout = false,
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpenClick = useCallback(() => {
    setIsMenuOpen(true);
  }, [isMenuOpen]);

  const onMenuRequestClose = useCallback(() => {
    setIsMenuOpen(false);
  }, [isMenuOpen]);

  // TODO: layout effect to set height filler height... (will help prevent issues on devices with scaled fonts)

  const navHref = !navigateToLogout ? '/' : '/logout';

  return (
    <>
      <Container>
        {minimal ? (
          <MinimalContentsContainer>
            <LinkStyled to={navHref}>
              <AgentSpotLogo />
            </LinkStyled>
          </MinimalContentsContainer>
        ) : (
          <>
            <ContentsContainer $extraWide={extraWide}>
              {menuButton && (
                <MenuOpenHamburgerButton onClick={onMenuOpenClick}>
                  <MenuOpenHamburger />
                </MenuOpenHamburgerButton>
              )}
              <NavbarBranding>
                <LinkStyled to={navHref}>
                  <AgentSpotLogo />
                </LinkStyled>
              </NavbarBranding>
              {rightSideComponent && rightSideComponent}
            </ContentsContainer>

            <Drawer
              open={isMenuOpen}
              onClose={onMenuRequestClose}
              variant={'temporary'}>
              {Menu && Menu}
            </Drawer>
          </>
        )}
      </Container>

      <HeightFiller />
    </>
  );
};

export default NavigationHeader;
