import { ParsedQuery } from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import AddItemRowSmallButton from '../../../components/AddItemRowSmallButton';
import ASPromptModal, {
  PromptModalButtonTypes,
} from '../../../components/ASPromptModal';
import config from '../../../config';
// import { apolloErrorToString } from '../../../constants/ErrorCodes';
import theme from '../../../constants/theme';
import {
  SubscribedSuburbBaseFragment,
  // useCancelSuburbSubscriptionMutation,
  // useRenewSuburbSubscriptionMutation,
  useMyAgentSuburbSubscriptionFreeTrialStateQuery,
  useMySubscribedSuburbsQuery,
} from '../../../graphql/generated';
import useQueryParams from '../../../hooks/useQueryParams';
import ActiveSuburbCard from '../components/ActiveSuburbCard';
import AgentUpdateSuburbController, {
  usePrecacheForAgentUpdateSuburbController,
} from './AgentUpdateSuburbController';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${theme.layout.desktopContainedContentWidth};
  overflow: visible;
`;

const SuburbsContainer = styled.div`
  margin: 16px 0 0 0;
`;

interface ExpectedQueryParams extends ParsedQuery {
  tab: string | string[] | null;
  isModalOpen: string | null;
}

const AgentEditSuburbs: FC = () => {
  const { isModalOpen } = useQueryParams<ExpectedQueryParams>();

  useEffect(() => {
    if (isModalOpen) setAddSuburbModalOpen(true);
  }, [isModalOpen]);

  const { data: mySubscribedSuburbsData } = useMySubscribedSuburbsQuery();

  // const [
  //   cancelSuburbSubscription,
  //   {
  //     loading: cancelSuburbSubscriptionLoading,
  //     error: cancelSuburbSubscriptionError,
  //   },
  // ] = useCancelSuburbSubscriptionMutation();
  // const [
  //   renewSuburbSubscription,
  //   {
  //     loading: renewSuburbSubscriptionLoading,
  //     error: renewSuburbSubscriptionError,
  //   },
  // ] = useRenewSuburbSubscriptionMutation();

  const {
    data: freeTrialData,
  } = useMyAgentSuburbSubscriptionFreeTrialStateQuery();

  const [addSuburbModalOpen, setAddSuburbModalOpen] = useState(false);

  const onAddSuburbsClick = () => {
    setAddSuburbModalOpen(true);
  };

  // const [showErrorsInModal, setShowErrorsInModal] = useState(false);

  const [
    cancelModalOpenForSubscribedSuburb,
    setCancelModalOpenForSubscribedSuburb,
  ] = useState<SubscribedSuburbBaseFragment | null>(null);

  const onCancelSuburbClick = useCallback(
    (subsribedSuburb: SubscribedSuburbBaseFragment) => {
      setCancelModalOpenForSubscribedSuburb(subsribedSuburb);
    },
    [],
  );

  const closeCancelSuburbModal = useCallback(() => {
    setCancelModalOpenForSubscribedSuburb(null);
    // setShowErrorsInModal(false);
  }, []);

  // const onConfirmCancelSuburbClick = useCallback(async () => {
  //   try {
  //     if (!cancelModalOpenForSubscribedSuburb) return;

  //     const {
  //       data: cancelSuburbSubscriptionData,
  //     } = await cancelSuburbSubscription({
  //       variables: {
  //         suburbSubscriptionId: cancelModalOpenForSubscribedSuburb.id,
  //       },
  //     });

  //     if (cancelSuburbSubscriptionData) {
  //       setCancelModalOpenForSubscribedSuburb(null);
  //     } else {
  //       setShowErrorsInModal(true);
  //     }
  //   } catch {
  //     setShowErrorsInModal(true);
  //   }
  // }, [cancelModalOpenForSubscribedSuburb]);

  // const onRenewSuburbClick = useCallback(
  //   (subsribedSuburb: SubscribedSuburbBaseFragment) => {
  //     setRenewModalOpenForSubscribedSuburb(subsribedSuburb);
  //   },
  //   [],
  // );

  // const [
  //   renewModalOpenForSubscribedSuburb,
  //   setRenewModalOpenForSubscribedSuburb,
  // ] = useState<SubscribedSuburbBaseFragment | null>(null);

  // const closeRenewSuburbModal = useCallback(() => {
  //   setRenewModalOpenForSubscribedSuburb(null);
  //   setShowErrorsInModal(false);
  // }, []);

  // const onConfirmRenewSuburbClick = useCallback(async () => {
  //   try {
  //     if (!renewModalOpenForSubscribedSuburb) return;

  //     // const {
  //     //   data: renewSuburbSubscriptionData,
  //     // } = await renewSuburbSubscription({
  //     //   variables: {
  //     //     suburbSubscriptionId: renewModalOpenForSubscribedSuburb.id,
  //     //   },
  //     // });

  //     if (renewSuburbSubscriptionData) {
  //       setRenewModalOpenForSubscribedSuburb(null);
  //     } else {
  //       setShowErrorsInModal(true);
  //     }
  //   } catch {
  //     setShowErrorsInModal(true);
  //   }
  // }, [renewModalOpenForSubscribedSuburb]);

  usePrecacheForAgentUpdateSuburbController();

  return (
    <Container>
      {freeTrialData &&
        freeTrialData.myAgentSuburbSubscriptionFreeTrialState
          .numberOfFreeTrialsClaimed < Number(config.MAX_AGENT_SUBURBS) && (
          <AddItemRowSmallButton
            label="Add Suburbs"
            onClick={onAddSuburbsClick}
          />
        )}
      <SuburbsContainer></SuburbsContainer>
      <AgentUpdateSuburbController
        isOpen={addSuburbModalOpen}
        onRequestClose={() => setAddSuburbModalOpen(false)}
      />
      <ASPromptModal
        isOpen={cancelModalOpenForSubscribedSuburb !== null}
        title="Cancel service suburb"
        body={[
          `To cancel or change a suburb please contact us at`,
          `support@agentspot.com.au`,
        ]}
        buttons={[
          {
            type: PromptModalButtonTypes.Neutral,
            title: `Close`,
            onClick: closeCancelSuburbModal,
          },
          // {
          //   type: PromptModalButtonTypes.Affirmative,
          //   title: `Cancel service`,
          //   onClick: onConfirmCancelSuburbClick,
          //   loading: cancelSuburbSubscriptionLoading,
          // },
        ]}
        onRequestClose={closeCancelSuburbModal}
        // error={
        //   showErrorsInModal
        //     ? cancelSuburbSubscriptionError &&
        //       apolloErrorToString(cancelSuburbSubscriptionError)
        //     : undefined
        // }
      />
      {/* <ASPromptModal
        isOpen={renewModalOpenForSubscribedSuburb !== null}
        title="Renew service suburb"
        body={[
          `Are you sure you want to renew your services in this suburb?`,
          `If renewed, you will autmoaticly be charged for the suburb at the end of its billing cycle.`,
        ]}
        buttons={[
          {
            type: PromptModalButtonTypes.Neutral,
            title: `Don't renew`,
            onClick: closeRenewSuburbModal,
          },
          {
            type: PromptModalButtonTypes.Affirmative,
            title: `Renew service`,
            onClick: onConfirmRenewSuburbClick,
            loading: renewSuburbSubscriptionLoading,
          },
        ]}
        onRequestClose={closeRenewSuburbModal}
        error={
          showErrorsInModal
            ? renewSuburbSubscriptionError &&
              apolloErrorToString(renewSuburbSubscriptionError)
            : undefined
        }
      /> */}

      {mySubscribedSuburbsData?.mySubscribedSuburbs.map((subscribedSuburb) => (
        <ActiveSuburbCard
          subsribedSuburb={subscribedSuburb}
          onCancelSuburb={onCancelSuburbClick}
          onRenewSuburb={() => {
            console.log('renew');
          }}
          key={subscribedSuburb.id}
        />
      ))}
    </Container>
  );
};

export default AgentEditSuburbs;
