import React, { FC } from 'react';
import styled from 'styled-components';
import { SuburbBaseFragment } from '../../../graphql/generated';
import SuburbHeader from './SuburbHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 10px 0;
`;

export interface SuburbCheckoutRowProps {
  suburb: SuburbBaseFragment;
  isFreeTrial: boolean;
  showPricing?: boolean;
  showCostAfterTrial?: boolean;
  className?: string;
}

const SuburbCheckoutRow: FC<SuburbCheckoutRowProps> = (props) => {
  const {
    suburb,
    className,
    isFreeTrial,
    showPricing,
    showCostAfterTrial,
  } = props;

  return (
    <Container className={className}>
      <SuburbHeader
        suburb={suburb}
        isFreeTrial={isFreeTrial}
        showPricing={showPricing}
        showCostAfterTrial={showCostAfterTrial}
      />
    </Container>
  );
};

export default SuburbCheckoutRow;
