import React, { FC, useEffect } from 'react';
import useIsAuthenticated from '../hooks/useIsAuthenticated';
import Cookies from 'js-cookie';

interface LoginCookieSyncProps {
  children: React.ReactNode;
}

export const LoginCookieSync: FC<LoginCookieSyncProps> = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const cookieStatus = isAuthenticated ? 'loggedIn' : 'loggedOut';
    Cookies.set('loginStatus', cookieStatus, { domain: '.agentspot.com.au' });
  }, [isAuthenticated]);

  return <>{children}</>;
};
