import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import theme from '../constants/theme';

const Container = styled.div`
  background: #ffffff;
  border: ${theme.style.border};
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 25px 20px;
  margin: 0 0 10px 0;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 3px;
`;

const Title = styled.h4`
  font-family: Inter, Arial, sans-serif;
  font-weight: 500;
  font-size: 14px;
  flex-grow: 1;
  color: #58687e;
  letter-spacing: 0.2px;
  margin: 0;
`;

const EditButton = styled(Link)`
  font-family: Inter, Arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #1745b0;
  letter-spacing: 0.3px;
  text-align: right;
  margin: 0;
`;

export interface SummarySectionProps {
  title: string;
  editHref?: string;
  onEditClick?: () => void;
  editLabel?: string;
  className?: string;
  hideEdit?: boolean;
}

const SummarySection: FC<SummarySectionProps> = (props) => {
  const {
    title,
    editHref,
    onEditClick,
    editLabel = 'Edit',
    className,
    children,
    hideEdit = false,
  } = props;

  return (
    <Container className={className}>
      <Header>
        <Title>{title}</Title>
        {!hideEdit && (
          /* eslint-disable-next-line */
          // @ts-ignore
          <EditButton to={editHref} onClick={onEditClick}>
            {editLabel}
          </EditButton>
        )}
      </Header>
      {children}
    </Container>
  );
};

export default SummarySection;
