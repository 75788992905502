import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SuburbBaseFragment,
  SuburbBaseFragmentDoc,
  useSuburbsQuery,
} from '../../../graphql/generated';
import { RootState } from '../../../store';
import { UpdateSuburbCartAction, UPDATE_SUBURB_CART } from '../store/cartTypes';

const useSuburbCart: () => [
  string[],
  SuburbBaseFragment[],
  (suburbIds: string[]) => void,
] = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const suburbIds = useSelector<RootState, string[]>(
    (state) => state.cart.suburbCart,
  );

  const setSuburbIds = useCallback(
    (suburbIds: string[]) => {
      dispatch({
        type: UPDATE_SUBURB_CART,
        payload: {
          suburbIds,
        },
      } as UpdateSuburbCartAction);
    },
    [dispatch],
  );

  const cachedSuburbs: SuburbBaseFragment[] = suburbIds
    .map(
      (suburbId) =>
        client.readFragment<SuburbBaseFragment>({
          id: `Suburb:${suburbId}`,
          fragment: SuburbBaseFragmentDoc,
          fragmentName: 'SuburbBase',
        }) ?? undefined,
    )
    .filter((suburb) => suburb !== undefined) as SuburbBaseFragment[];

  const { data: suburbsData } = useSuburbsQuery({
    variables: { ids: suburbIds },
  });

  return [suburbIds, suburbsData?.suburbs ?? cachedSuburbs, setSuburbIds];
};

export default useSuburbCart;
