import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import ASBlockButton from '../../../components/ASBlockButton';
import ASHeader from '../../../components/ASHeader';
import SideBySidePageTemplate from '../../../components/SideBySidePageTemplate';
import TextInputField from '../../../components/TextInputField';
import { useSendContinueEmailMutation } from '../../../graphql/generated';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(ASHeader)`
  margin-bottom: 21px;
`;

const SignupButton = styled(ASBlockButton)`
  margin: 26px 0 21px 0;
  width: 100%;
`;

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email address is invalid')
    .required('Email address is required'),
});

const ContinueSignup: FC = () => {
  const history = useHistory();

  const [continueEmailMutation, { loading }] = useSendContinueEmailMutation();

  const onSubmit = useCallback(async (values: FormValues) => {
    try {
      const { data } = await continueEmailMutation({
        variables: {
          email: values.email,
        },
      });

      if (data?.sendContinueEmail) {
        toast.info(
          'Please, check your email for link to complete registration',
        );
        history.push('/login');
      }

      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    } catch {
      toast.error('User is invalid or already verified');

      // (error handled by error from useMutation hook, empty catch added to prevent unhandled error excpetion)
    }
  }, []);

  return (
    <SideBySidePageTemplate>
      <Helmet>
        <title>Log In | AgentSpot</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ submitForm }) => {
          return (
            <Form>
              <Header>Welcome back</Header>
              <p>
                Please provide your email to continue entering your home details
              </p>
              <TextInputField
                name="email"
                placeholder="Email address"
                type="email"
              />
              <SignupButton loading={loading} onClick={submitForm}>
                Send Link
              </SignupButton>
            </Form>
          );
        }}
      </Formik>
    </SideBySidePageTemplate>
  );
};

export default ContinueSignup;
