import { useField, useFormikContext } from 'formik';
import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import clearCrossIconUrl from '../assets/clear-cross-button.svg';
import searchPrefixIconUrl from '../assets/search-magnifying-glass.svg';
import usePlacesAutocomplete from '../hooks/usePlacesAutocomplete';
import usePrecacheImages from '../hooks/usePrecacheImages';
import GoogleAddressParser, {
  AddressComponent,
} from '../utils/googleAddressParser';
import {
  ClearButton,
  ClearCross,
  InputField,
  InputShape,
  LoadingSpinnerContainer,
  NoResultsPlaceholder,
  Option,
  OptionsContainer,
  SearchPrefix,
} from './SearchSelectField';

export interface GooglePlacesSelectFieldOption {
  value: string;
  label: string;
}

export const usePrecacheForGooglePlacesSelectField: () => void = () => {
  usePrecacheImages([searchPrefixIconUrl, clearCrossIconUrl]);
};
export interface GooglePlacesSelectFieldProps {
  searchTerm: string;
  onSearchTermChanged: (searchTerm: string) => void;
  onOptionSelected?: (address: google.maps.places.PlaceResult) => void;
  fieldName: string;
  placeholder: string;
  showErrorAfterTouch?: boolean;
  showErrorAfterFormSubmit?: boolean;
  googleApiKey?: string;
  types?: Array<string>;
}

const GooglePlacesSelectField: FC<GooglePlacesSelectFieldProps> = (props) => {
  const {
    searchTerm,
    onSearchTermChanged,
    onOptionSelected,
    fieldName,
    showErrorAfterFormSubmit,
    showErrorAfterTouch,
    placeholder,
    googleApiKey,
    types = ['address'],
  } = props;

  const [{ value }, { error, touched }, { setValue }] = useField({
    name: fieldName,
  });

  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false);

  const { predictions, loading: predictionsLoading } = usePlacesAutocomplete(
    googleApiKey ?? '',
    searchTerm,
    types,
  );

  const options: GooglePlacesSelectFieldOption[] = useMemo(() => {
    return (
      predictions
        .map((prediction) => ({
          value: prediction.place_id,
          label: prediction.description,
        }))
        .slice(0, 5) ?? []
    );
  }, [predictions]);

  const onSearchChanged = useCallback((e: FormEvent<HTMLInputElement>) => {
    const newSearchTerm = e.currentTarget.value;

    setValue(undefined); // Changing search term deselects the current value
    onSearchTermChanged(newSearchTerm);
    setOptionsExpanded(newSearchTerm.length >= 3);
  }, []);

  const onClearClick = useCallback(() => {
    setValue(undefined);
    onSearchTermChanged('');
    setOptionsExpanded(false);
  }, []);

  const onOptionClick: (
    options: GooglePlacesSelectFieldOption,
  ) => void = useCallback((selectedOption) => {
    onSearchTermChanged(selectedOption.label);
    setOptionsExpanded(false);

    const placesService = new google.maps.places.PlacesService(
      document.createElement('div'),
    );

    placesService?.getDetails(
      {
        placeId: selectedOption.value,
        fields: ['address_components', 'formatted_address'],
      },
      async (placeDetails) => {
        if (placeDetails) {
          const detailsParsed = new GoogleAddressParser(
            placeDetails.address_components as AddressComponent[],
          ).result();
          setValue(
            `${detailsParsed.street_number} ${detailsParsed.street_name}`,
          );
          onOptionSelected?.(placeDetails);
        }
      },
    );
  }, []);

  const { submitCount } = useFormikContext();

  const showError =
    ((showErrorAfterFormSubmit && submitCount > 0) ||
      (showErrorAfterTouch && touched)) ??
    false;

  return (
    <InputShape
      $error={showError && !!error}
      $optionsExpanded={optionsExpanded}>
      <SearchPrefix />
      <InputField
        value={searchTerm}
        onChange={onSearchChanged}
        placeholder={placeholder}
        $truncate={!!value}
      />

      {!!value && (
        <ClearButton onClick={onClearClick}>
          <ClearCross />
        </ClearButton>
      )}

      {optionsExpanded && (
        <OptionsContainer $error={showError && !!error}>
          {options.length == 0 && !predictionsLoading && (
            <NoResultsPlaceholder>No results found</NoResultsPlaceholder>
          )}

          {options.map((option, i) => (
            <Option
              onClick={() => onOptionClick(option)}
              key={`${option.value}_${i}`}>
              {option.label}
            </Option>
          ))}

          {options.length == 0 && predictionsLoading && (
            <LoadingSpinnerContainer>
              <BeatLoader
                color="#a6aeba"
                css="display: flex;"
                size={4}
                margin={1}
                loading={predictionsLoading}
              />
            </LoadingSpinnerContainer>
          )}
        </OptionsContainer>
      )}
    </InputShape>
  );
};

export default GooglePlacesSelectField;
